import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link
import { useTranslation } from "react-i18next";
import { useAuth } from "../useAuth"; // Import the useAuth hook
import { analytics, logEvent } from "../firebase-config";

function SupportPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(null); // null, 'success', 'error'
  const { t } = useTranslation("SupportPage"); // Use 'global' or your specific namespace
  const { currentUser } = useAuth(); // Get current user
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("support.title") + " - ProRecruit";
    logEvent(analytics, "page_view", {
      page_title: t("support.title"), //  Send  current  page  title
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
    });
    return () => {
      document.title = "ProRecruit";
    };
  }, [t]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmissionStatus(null); // Reset status

    // Basic validation
    if (!name || !email || !subject || !message) {
      alert(t("formIncomplete")); // Use a better UI than alert()
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert(t("invalidEmail"));
      return;
    }

    // TODO: Replace with actual API call to submit the form data
    // For example, using fetch to a Cloud Function endpoint:

    try {
      const response = await fetch("/api/submit-support-request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, subject, message }),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit form: ${response.status}`);
      }

      // const data = await response.json(); // If your API returns data
      setSubmissionStatus("success");
      // Optionally clear the form after successful submission:
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <div className="font-sans antialiased bg-gray-50 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          {t("support.title")}
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Contact Form */}
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-semibold mb-6 text-gray-700">
              {t("contactForm.title")}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("contactForm.name")}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("contactForm.email")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("contactForm.subject")}
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("contactForm.message")}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {t("contactForm.submit")}
                </button>
              </div>
              {submissionStatus === "success" && (
                <div className="text-green-600 text-center">
                  {t("contactForm.success")}
                </div>
              )}
              {submissionStatus === "error" && (
                <div className="text-red-600 text-center">
                  {t("contactForm.error")}
                </div>
              )}
            </form>
          </div>

          {/* FAQ Section */}
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-semibold mb-6 text-gray-700">
              {t("faq.title")}
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-800">
                  {t("faq.question1")}
                </h3>
                <p className="text-gray-600">{t("faq.answer1")}</p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-800">
                  {t("faq.question2")}
                </h3>
                <p className="text-gray-600">{t("faq.answer2")}</p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-800">
                  {t("faq.question3")}
                </h3>
                <p className="text-gray-600">{t("faq.answer3")}</p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-800">
                  {t("faq.question4")}
                </h3>
                <p className="text-gray-600">{t("faq.answer4")}</p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-800">
                  {t("faq.question5")}
                </h3>
                <p className="text-gray-600">{t("faq.answer5")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-600">
          <p>
            {t("moreHelp")}{" "}
            <Link to="/faq" className="text-blue-600 hover:underline">
              {t("fullFaq")}
            </Link>
            .
          </p>
          {currentUser ? (
            <div className="mt-4">
              <button
                onClick={() => navigate("/dashboard")}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {t("goToDashboard")}
              </button>
            </div>
          ) : (
            <div className="mt-4">
              <Link to="/signup" className="text-blue-600 hover:underline">
                {t("createAccount")}
              </Link>{" "}
              {t("or")}{" "}
              <Link to="/signin" className="text-blue-600 hover:underline">
                {t("signIn")}
              </Link>{" "}
              {t("toAccessDashboard")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SupportPage;
