//TagModal.js
import React, { useState, useEffect } from 'react';
import Modal from '../components/Modal'; // Assuming you have a Modal component
import { useTranslation } from 'react-i18next';

const TagModal = ({ isOpen, onClose, onAddTag, candidateId, tenantId }) => {
     const { t } = useTranslation('CandidateDetailsModal');
    const [tagText, setTagText] = useState("");
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState(null);

    const handleAddTag = async () => {
      if (!tagText.trim()) {
        setError(t('candidateDetailsModal.tagsModal.errorEmptyTag'));
        return;
      }
        setAdding(true);
        setError(null);
        try {
            await onAddTag(tagText);
            setTagText(""); // Clear input on success
        } catch (error) {
           setError(t('candidateDetailsModal.tagsModal.errorAddingTag'));
            console.error("Error adding tag:", error);

        }
        finally{
            setAdding(false);
        }
    };

    useEffect(() => {
        if(isOpen){
            setTagText("");
            setError(null);
        }
    },[isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="p-6">
                <h3 className="text-xl font-semibold mb-4">{t('candidateDetailsModal.tagsModal.title')}</h3>
                <input
                    type="text"
                    className="w-full p-2 border rounded-md mb-4"
                    placeholder={t('candidateDetailsModal.tagsModal.placeholder')} // Translate
                    value={tagText}
                    onChange={(e) => setTagText(e.target.value)}
                />
                 {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                <div className="flex justify-end">
                     <button
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md mr-2"
                        onClick={onClose}
                        disabled={adding}
                    >
                       {t('candidateDetailsModal.tagsModal.cancel')}
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md disabled:opacity-50"
                        onClick={handleAddTag}
                        disabled={adding}
                    >
                        {adding ? t('candidateDetailsModal.tagsModal.adding') : t('candidateDetailsModal.tagsModal.addTag')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default TagModal;