import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail, updateProfile } from "firebase/auth"; // Import sendPasswordResetEmail
import { auth, db } from "./firebase-config"; // Your Firebase config
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore"; // Import updateDoc and serverTimestamp
import { ColorRing } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import myLogo from "./public/ProRecruitBusinessLogoTransparent2.png";


function SignInPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false); // Track if reset email was sent
    const navigate = useNavigate();
    const { t } = useTranslation("SignIn");  //  Correct the "namespace"


    useEffect(() => {
        document.title = t('signInPageTitle') + " - ProRecruit";
        return () => {
            document.title = "ProRecruit";
        };
    }, [t]);

    const handleSignIn = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            // 1. Sign in the user
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // 2. Check email verification
            if (!user.emailVerified) {
                await auth.signOut();
                setError(t("emailNotVerified")); // Use i18next
                return;
            }

            // 3. Fetch user data (including loginTime)
            const userDocRef = doc(db, 'users', user.email);
            const userDocSnap = await getDoc(userDocRef);

            if (!userDocSnap.exists()) {
                throw new Error("User data not found in Firestore."); // More specific error
            }
            const userData = userDocSnap.data().userData;

            if (!user.displayName) {
                updateProfile(user, { displayName: userData.displayName });
            }

            // 4. Update login count and last login
            let loginCount = userData.loginCount || 0; // Default to 0 if it doesn't exist

            // Create the update object. Only update loginTime if <= 3.
            const updateData = {
                'userData.lastLogin': serverTimestamp() // ALWAYS update lastLogin
            };

            if (loginCount <= 3) {
                loginCount++;
                updateData['userData.loginCount'] = loginCount;
            }

            await updateDoc(userDocRef, updateData); // Use updateDoc!


            // 5. Navigate based on loginTime
            if (loginCount <= 3) {
                navigate("/GettingStartedGuide", { state: { shouldSeeWelcomePopup: true } }); // First 3 logins
            } else {
                navigate("/dashboard"); // Subsequent logins
            }

        } catch (error) {
            console.error("Sign in error:", error);

            // Provide more specific error messages based on the error code.
            let errorMessage = t('signInError'); // Default error message
            if (error.code === 'auth/invalid-email') {
                errorMessage = t('invalidEmail');
            } else if (error.code === 'auth/user-disabled') {
                errorMessage = t('userDisabled');
            } else if (error.code === 'auth/user-not-found') {
                errorMessage = t('userNotFound');
            } else if (error.code === 'auth/wrong-password') {
                errorMessage = t('wrongPassword');
            }
            setError(errorMessage);

        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setError(t("enterEmailForReset")); // Use i18next
            return;
        }

        setLoading(true); // Start loading (optional, but good UX)
        setError(null);     // Clear any previous errors

        try {
            await sendPasswordResetEmail(auth, email);
            setResetEmailSent(true);
            setError(null); // Clear error if email sent successfully
        } catch (error) {
            console.error("Password reset error:", error);
            let errorMessage = t("passwordResetError"); // Default error message.
            if (error.code === 'auth/invalid-email') {
              errorMessage = t('invalidEmail');  // Re-use the invalid email message.
            } else if (error.code === 'auth/user-not-found') {
              errorMessage = t('userNotFound'); // Re-use user-not-found.
            }
            setError(errorMessage);

        } finally {
            setLoading(false); // Stop loading
        }
    };


    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src={myLogo}  // Replace with your logo path
                    alt="ProRecruit"
                />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        {t("signInTitle")}
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        {t("or")} {' '}
                        <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                            {t("createAccount")}
                        </Link>
                    </p>
                    {error && (
                        <p className="mt-2 text-center text-sm text-red-600">{error}</p>
                    )}
                    {/* Success message for password reset */}
                    {resetEmailSent && (
                      <p className="mt-2 text-center text-sm text-green-600">{t("passwordResetSent")}</p>
                    )}
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">{t("email")}</label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder={t("emailPlaceholder")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">{t("password")}</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder={t("passwordPlaceholder")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Forgot Password Link */}
                    <div className="flex items-center justify-between">
                        <div className="text-sm">
                            <button
                                type="button"  //  Important:  Make this button type="button"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                onClick={handleForgotPassword}
                            >
                                {t("forgotPassword")}
                            </button>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        {loading ? (
                        <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[
                        "#e15b64",
                        "#f47e60",
                        "#f8b26a",
                        "#abbd81",
                        "#849b87",
                        ]}
                    />
                    ) : (
                    t("signInButton")
                    )}
                        </button>
                    </div>
                </form>
                    <div className="text-center">
                        <Link to="/signup">
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {t("signUpButton")}
                        </button>
                        </Link>
                    </div>
            </div>
        </div>
    );
}

export default SignInPage;