/* eslint-disable no-useless-catch */
//CandidateDetailsModal.js

import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  collection,
  addDoc,
  getDoc,
} from "firebase/firestore"; // Import Firestore functions
import { db, Timestamp } from "../firebase-config"; // Import your Firestore instance
import { useAuth } from "../useAuth";
import Modal from "../components/Modal";
import Accordion from "../components/Accordion";
import { useTranslation } from "react-i18next"; // Import
import { closeCandidateDetailsModal } from "../utils/redux/candidateSlice";
import JobPostingDescription from "../components/JobPostingDescription";
//Import Modal
import EmailModal from "../components/EmailModal";
import NotesModal from "../components/NotesModal";
import TagModal from "../components/TagModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEnvelope,
  faPlus,
  faStickyNote,
  faTag,
  faUser,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import CandidateScoreReason from "./CandidateScoreReason";

const CandidateDetailsModal = () => {
  const { t } = useTranslation("CandidateDetailsModal"); // Hook
  const dispatch = useDispatch();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language); // Initialize with current language
  const { currentUser } = useAuth();

  //Detect Browser Language and set on first load
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage; // Get browser language
    const languageCode = browserLanguage.split("-")[0]; // Extract language code (e.g., "en" from "en-US")

    // Only set the language if it's different from the current language and supported
    if (
      languageCode !== i18next.language &&
      (languageCode === "en" || languageCode === "fr")
    ) {
      i18next.changeLanguage(languageCode);
      setCurrentLanguage(languageCode);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleCloseModal = () => {
    dispatch(closeCandidateDetailsModal());
  };

  const isCandidateDetailsModalOpen = useSelector(
    (state) => state.candidates.isCandidateDetailsModalOpen
  );
  console.log("isCandidateDetailsModalOpen", isCandidateDetailsModalOpen);
  const selectedCandidate = useSelector(
    (state) => state.candidates.selectedCandidate
  );
  if (!isCandidateDetailsModalOpen || !selectedCandidate) {
    return null;
  }

  const candidate = selectedCandidate;

  const handleSendEmail = async (subject, body) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const emailData = {
        to: candidate.ContactInformation.Email, // Use the candidate's email
        message: { subject: subject, text: body },
        createdAt: Timestamp.now(),
        // html: // You can also include an HTML version if needed
      };

      const emailRef = collection(db, "send-grid-emails");

      await addDoc(emailRef, emailData);
      console.log("Email document successfully written!");
      setIsEmailModalOpen(false); // Close on success
    } catch (error) {
      throw error; //Re-throw for handling in modal
    }
  };

  const handleAddNote = async (noteText) => {
    const candidateRef = doc(
      db,
      `/job-descriptions/${candidate.tenantId}/active/${candidate.jobUuid}/applications`,
      candidate.applicantId
    );
    // eslint-disable-next-line no-useless-catch
    try {
      await updateDoc(candidateRef, {
        notes: arrayUnion({
          text: noteText,
          authorId: currentUser.email, // Replace with actual user ID
          authorName: currentUser.displayName, // Replace with actual user name
          timestamp: Timestamp.now(),
        }),
      });
      setIsNotesModalOpen(false);
      console.log("Note added successfully");
    } catch (error) {
      throw error;
    }
  };

  const handleAddTag = async (tagText) => {
    const candidateRef = doc(
      db,
      `/job-descriptions/${candidate.tenantId}/active/${candidate.jobUuid}/applications`,
      candidate.applicantId
    );
    try {
      // Check if the tag already exists
      const docSnap = await getDoc(candidateRef);
      if (docSnap.exists()) {
        const existingTags = docSnap.data().tags || [];
        if (existingTags.includes(tagText)) {
          console.log("Tag already exists");
          return; // Don't add the tag
        }
      }

      await updateDoc(candidateRef, {
        tags: arrayUnion(tagText),
      });
      setIsTagModalOpen(false);
      console.log("Tag added successfully");
    } catch (error) {
      throw error; //Re-throw for handling in modal
    }
  };

  const handleDeleteTag = async (tagToDelete) => {
    const candidateRef = doc(
      db,
      `/job-descriptions/${candidate.tenantId}/active/${candidate.jobUuid}/applications`,
      candidate.applicantId
    );
    try {
      const docSnap = await getDoc(candidateRef);
      if (docSnap.exists()) {
        const existingTags = docSnap.data().tags || [];
        // Filter out the tag to delete, creating a new array without that tag
        const updatedTags = existingTags.filter((tag) => tag !== tagToDelete);

        // Update the document with the new array
        await updateDoc(candidateRef, {
          tags: updatedTags,
        });
        console.log("Tag deleted successfully");
      }
    } catch (error) {
      throw error; //Re-throw
    }
  };

  return (
    <Modal isOpen={isCandidateDetailsModalOpen} onClose={handleCloseModal}>
      <EmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSend={handleSendEmail}
        candidateEmail={
          candidate.ContactInformation ? candidate.ContactInformation.Email : ""
        }
      />
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        onAddNote={handleAddNote}
        candidateId={candidate.applicantId} // Pass these for updates
        tenantId={candidate.tenantId}
      />
      <TagModal
        isOpen={isTagModalOpen}
        onClose={() => setIsTagModalOpen(false)}
        onAddTag={handleAddTag}
        candidateId={candidate.applicantId}
        tenantId={candidate.tenantId}
      />
      <div className="space-y-4 p-6">
        <div className="flex justify-between items-start">
          <h2 className="text-2xl font-bold text-gray-800 flex items-center">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            {candidate.FullName}
            {["Rejeter", "Rejected"].includes(candidate.ApplicationStatus) && (
              <span className="ml-2 text-red-600 text-sm">
                ({t("candidateDetailsModal.rejected")})
              </span>
            )}
          </h2>
          {/* Action Buttons */}
          <div className="flex space-x-2">
            <button
              onClick={() => setIsEmailModalOpen(true)}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
              title={t("candidateDetailsModal.sendEmail")}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </button>
            <button
              onClick={() => setIsNotesModalOpen(true)}
              className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md"
              title={t("candidateDetailsModal.addNote")}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
              onClick={() => setIsTagModalOpen(true)}
              className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-md"
              title={t("candidateDetailsModal.addTag")}
            >
              <FontAwesomeIcon icon={faTag} />
            </button>
            {/* <button onClick={handleCloseModal} className="px-3 py-1 text-gray-600 hover:text-gray-800">
                            <FontAwesomeIcon icon={faTimesCircle} size="lg" />
                        </button> */}
          </div>
        </div>

        <Accordion title={t("candidateDetailsModal.contactInfo.title")}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p className="text-gray-700">
                <strong>
                  {t("candidateDetailsModal.contactInfo.fullName")}:
                </strong>{" "}
                {candidate.FullName}
              </p>
              <p className="text-gray-700">
                <strong>{t("candidateDetailsModal.contactInfo.email")}:</strong>{" "}
                {candidate.ContactInformation
                  ? candidate.ContactInformation.Email
                  : ""}
              </p>
              <p className="text-gray-700">
                <strong>{t("candidateDetailsModal.contactInfo.phone")}:</strong>{" "}
                {candidate.ContactInformation
                  ? candidate.ContactInformation.Phone
                  : ""}
              </p>
            </div>
            <div>
              <p className="text-gray-700">
                <strong>
                  {t("candidateDetailsModal.contactInfo.address")}:
                </strong>{" "}
                {candidate.ContactInformation
                  ? candidate.ContactInformation.Address
                  : ""}
              </p>
              <p className="text-gray-700">
                <strong>{t("candidateDetailsModal.contactInfo.city")}:</strong>{" "}
                {candidate.ContactInformation
                  ? candidate.ContactInformation.City
                  : ""}
              </p>
              <p className="text-gray-700">
                <strong>{t("candidateDetailsModal.contactInfo.state")}:</strong>{" "}
                {candidate.ContactInformation
                  ? candidate.ContactInformation.State
                  : ""}
              </p>
              <p className="text-gray-700">
                <strong>
                  {t("candidateDetailsModal.contactInfo.country")}:
                </strong>{" "}
                {candidate.ContactInformation.Country
                  ? candidate.ContactInformation.Country.name
                  : ""}
              </p>
            </div>
          </div>
        </Accordion>

        <Accordion title={t("candidateDetailsModal.score.title")}>
          <p className="text-gray-700">
            <strong>{t("candidateDetailsModal.score.ranking")}: </strong>
            {candidate.score_list.total_score}/100 -{" "}
            {t("candidateDetailsModal.score.experience")}: 60,{" "}
            {t("candidateDetailsModal.score.education")}: 20
          </p>
          <p className="text-gray-700">
            <strong>{t("candidateDetailsModal.score.explanation")}:</strong>{" "}
            <JobPostingDescription
              description={candidate.score_list.summary?.[i18next.languages[0]]}
            />
            <CandidateScoreReason description={candidate.score_list} />
          </p>
        </Accordion>

        {candidate.assessmentNames && candidate.assessmentNames.length > 0 && (
          <Accordion title={t("candidateDetailsModal.assessments.title")}>
            {candidate.assessmentNames.map((assessment, index) => (
              <div
                key={index}
                className="border-b pb-2 mb-2 last:border-b-0 last:pb-0"
              >
                <p>
                  <strong>{assessment.title}</strong>: {assessment.score}/100
                </p>
                <a
                  href={`${process.env.REACT_APP_BASE_URL}assessmentresults/${assessment.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {t("candidateDetailsModal.assessments.viewResults")}
                </a>
              </div>
            ))}
          </Accordion>
        )}

        <Accordion title={t("candidateDetailsModal.documents.title")}>
          <div className="space-y-2">
            {candidate.Resume && (
              <div>
                <strong>{t("candidateDetailsModal.documents.resume")}:</strong>
                <a
                  href={candidate.resumeURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline ml-2"
                >
                  {t("candidateDetailsModal.documents.download")}
                </a>
              </div>
            )}
            {candidate.CoverLetter && (
              <div>
                <strong>
                  {t("candidateDetailsModal.documents.coverLetter")}:
                </strong>
                <a
                  href={candidate.coverLetterURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline ml-2"
                >
                  {t("candidateDetailsModal.documents.download")}
                </a>
              </div>
            )}
            {candidate.Certificates && (
              <div>
                <strong>
                  {t("candidateDetailsModal.documents.certificates")}:
                </strong>
                {candidate.certificationURL ? (
                  <a
                    href={candidate.certificationURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline ml-2"
                  >
                    {t("candidateDetailsModal.documents.download")}
                  </a>
                ) : (
                  t("candidateDetailsModal.documents.notAvailable")
                )}
              </div>
            )}
            {candidate.Diplomas && (
              <div>
                <strong>
                  {t("candidateDetailsModal.documents.diplomas")}:
                </strong>
                {candidate.diplomaURL ? (
                  <a
                    href={candidate.diplomaURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline ml-2"
                  >
                    {t("candidateDetailsModal.documents.download")}
                  </a>
                ) : (
                  t("candidateDetailsModal.documents.notAvailable")
                )}
              </div>
            )}
          </div>
        </Accordion>

        <Accordion title={t("candidateDetailsModal.education.title")}>
          {candidate.Education && candidate.Education.length > 0 ? (
            candidate.Education.map((edu, index) => (
              <p key={index} className="text-gray-700">
                <strong>
                  {edu.Degree} {t("candidateDetailsModal.education.in")}{" "}
                  {edu.FieldOfStudy}
                </strong>
                , {edu.Institution} ({edu.GraduationYear})
              </p>
            ))
          ) : (
            <p className="text-gray-700">
              {t("candidateDetailsModal.education.noEducation")}
            </p>
          )}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.workExperience.title")}>
          {candidate.WorkExperience && candidate.WorkExperience.length > 0 ? (
            candidate.WorkExperience.map((exp, index) => (
              <div key={index} className="mb-4">
                <p className="text-gray-700">
                  <strong>{exp.Role}</strong>{" "}
                  {t("candidateDetailsModal.workExperience.at")}{" "}
                  {exp.CompanyName},{" "}
                  {t("candidateDetailsModal.workExperience.from")}{" "}
                  {exp.StartDate} {t("candidateDetailsModal.workExperience.to")}{" "}
                  {exp.EndDate}
                </p>
                <p className="text-gray-600">{exp.Responsibilities}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-700">
              {t("candidateDetailsModal.workExperience.noExperience")}
            </p>
          )}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.skills.title")}>
          <p className="text-gray-700">{candidate.Skills}</p>
        </Accordion>
        <Accordion title={t("candidateDetailsModal.certifications.title")}>
          {candidate.Certifications && candidate.Certifications.length > 0
            ? candidate.Certifications.map((cert, index) => (
                <p key={index} className="text-gray-700">
                  {cert.CertificationName}{" "}
                  {t("candidateDetailsModal.certifications.from")}{" "}
                  {cert.IssuingOrganization},
                  {t("candidateDetailsModal.certifications.obtained")}{" "}
                  {cert.DateObtained}.{" "}
                  {cert.ExpiryDate
                    ? `${t("candidateDetailsModal.certifications.expires")} ${
                        cert.ExpiryDate
                      }`
                    : ""}
                </p>
              ))
            : t("candidateDetailsModal.certifications.none")}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.languages.title")}>
          {candidate.Languages && candidate.Languages.length > 0 ? (
            candidate.Languages.map((lang, index) => (
              <p key={index} className="text-gray-700">
                {lang.Language} - {lang.Proficiency}
              </p>
            ))
          ) : (
            <p className="text-gray-700">
              {t("candidateDetailsModal.languages.none")}
            </p>
          )}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.references.title")}>
          {candidate.References && candidate.References.length > 0 ? (
            candidate.References.map((ref, index) => (
              <p key={index} className="text-gray-700">
                <strong>{ref.RefereeName}</strong>, {ref.Relation},{" "}
                {ref.ContactInformation}
              </p>
            ))
          ) : (
            <p className="text-gray-700">
              {t("candidateDetailsModal.references.none")}
            </p>
          )}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.personalStatement")}>
          <p className="text-gray-700">{candidate.PersonalStatement}</p>
        </Accordion>

        <Accordion title={t("candidateDetailsModal.socialMedia.title")}>
          {candidate.SocialMediaProfiles && (
            <p className="text-gray-700">
              {candidate.SocialMediaProfiles.LinkedIn ? (
                <>
                  <a
                    href={candidate.SocialMediaProfiles.LinkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    LinkedIn
                  </a>
                  <br />
                </>
              ) : (
                ""
              )}
              {candidate.SocialMediaProfiles.Behance ? (
                <a
                  href={candidate.SocialMediaProfiles.Behance}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Behance
                </a>
              ) : (
                ""
              )}
            </p>
          )}
        </Accordion>

        <Accordion title={t("candidateDetailsModal.portfolio")}>
          <p>
            {candidate.Portfolio ? (
              <a
                href={candidate.Portfolio}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {t("candidateDetailsModal.viewPortfolio")}
              </a>
            ) : (
              t("candidateDetailsModal.noPortfolio")
            )}
          </p>
        </Accordion>

        <Accordion title={t("candidateDetailsModal.volunteerExperience")}>
          <p className="text-gray-700">{candidate.VolunteerExperience}</p>
        </Accordion>

        <Accordion title={t("candidateDetailsModal.availability")}>
          <p className="text-gray-700">{candidate.Availability}</p>
        </Accordion>
        {/* Notes Display */}
        <Accordion title={t("candidateDetailsModal.notes.title")}>
          {candidate.notes && candidate.notes.length > 0 ? (
            candidate.notes.map((note, index) => (
              <div key={index} className="border-b pb-2 mb-2 last:border-b-0">
                <p className="text-gray-700">{note.text}</p>
                <p className="text-sm text-gray-500">
                  {t("candidateDetailsModal.notes.author")}: {note.authorName} -{" "}
                  {new Date(note.timestamp._seconds * 1000).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p className="text-gray-600">
              {t("candidateDetailsModal.notes.noNotes")}
            </p>
          )}
        </Accordion>

        {/* Tags Display */}
        <Accordion title={t("candidateDetailsModal.tags.title")}>
          <div className="flex flex-wrap gap-2">
            {candidate.tags && candidate.tags.length > 0 ? (
              candidate.tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-200 text-gray-700 px-2 py-1 rounded text-sm"
                >
                  {tag}
                </span>
              ))
            ) : (
              <p className="text-gray-600">
                {t("candidateDetailsModal.tags.noTags")}
              </p>
            )}
          </div>
        </Accordion>
      </div>
    </Modal>
  );
};

export default CandidateDetailsModal;
