//EmailModal
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../components/Modal'; // Assuming you have a Modal component

const EmailModal = ({ isOpen, onClose, onSend, candidateEmail }) => {
  const { t } = useTranslation('CandidateDetailsModal');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSend = async () => {
    if (!subject || !body) {
      setError(t('candidateDetailsModal.emailModal.errorFillFields'));
      return;
    }

    setSending(true);
    setError(null);
    setSuccess(false);
    try {
      await onSend(subject, body);
      setSuccess(true);
      // Optionally clear the form after successful sending
      // setSubject('');
      // setBody('');
    } catch (error) {
      setError(t('candidateDetailsModal.emailModal.errorSendingEmail') + error.message);
    } finally {
      setSending(false);
    }
  };
//reset when open.
    useEffect(() => {
    if(isOpen){
        setSubject("");
        setBody("");
        setError(null);
        setSuccess(false);

    }
  },[isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-4">{t('candidateDetailsModal.emailModal.title')}</h3>
         <p><strong >{t('candidateDetailsModal.emailModal.recipient')}</strong> {candidateEmail}</p>
        <div className="mb-4">
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
            {t('candidateDetailsModal.emailModal.subject')}
          </label>
          <input
            type="text"
            id="subject"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="body" className="block text-sm font-medium text-gray-700">
             {t('candidateDetailsModal.emailModal.body')}
          </label>
          <textarea
            id="body"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows="5"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
         {error && <p className="text-red-500 text-sm">{error}</p>}
        {success && <p className="text-green-500 text-sm">{t('candidateDetailsModal.emailModal.success')}</p>}
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md"
            onClick={onClose}
            disabled={sending}
          >
            {t('candidateDetailsModal.emailModal.cancel')}
          </button>
          <button
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md disabled:opacity-50"
            onClick={handleSend}
            disabled={sending}
          >
            {sending ? t('candidateDetailsModal.emailModal.sending') : t('candidateDetailsModal.emailModal.send')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmailModal;