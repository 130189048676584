import React, { useState, useEffect } from "react";
import {
  updatePassword,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signOut,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase-config"; // Import your Firebase config
import { useTranslation } from "react-i18next";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function UserSettingsPage() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordUpdateMessage, setPasswordUpdateMessage] = useState("");
  const [passwordUpdateError, setPasswordUpdateError] = useState("");

  const [newEmail, setNewEmail] = useState("");
  const [emailUpdateMessage, setEmailUpdateMessage] = useState("");
  const [emailUpdateError, setEmailUpdateError] = useState("");

  const [firstName, setFirstName] = useState(""); // For updating first name
  const [lastName, setLastName] = useState(""); // For updating last name
  const [updateProfileMessage, setUpdateProfileMessage] = useState("");
  const [updateProfileError, setUpdateProfileError] = useState("");
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [logoutLoading, setLogoutLoading] = useState(false);

  const { t } = useTranslation("UserProfile");
  const navigate = useNavigate();

    useEffect(() => {
      document.title = "User Profile Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDocRef = doc(db, "users", currentUser.email);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data().userData;
            setUserData(data);
            setFirstName(data.firstName || ""); // Initialize
            setLastName(data.lastName || ""); // Initialize
          } else {
            setError("User data not found.");
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
          setError("Failed to load user data.");
        } finally {
          setLoading(false);
        }
      } else {
        setUser(null);
        setUserData(null);
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await signOut(auth);
      navigate("/login"); // Redirect to login page
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const handleDashboard = async () => {
    setLogoutLoading(true);
    try {
      navigate("/dashboard"); // Redirect to login page
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const handlePasswordUpdate = async () => {
    setPasswordUpdateError("");
    setPasswordUpdateMessage("");

    if (newPassword !== confirmNewPassword) {
      setPasswordUpdateError(t("passwordMismatch"));
      return;
    }
    if (newPassword.length < 6) {
      setPasswordUpdateError(t("weakPassword"));
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setPasswordUpdateMessage(t("passwordUpdated"));
      setIsEditingPassword(false); // Exit edit mode on success
    } catch (error) {
      console.error("Password update error:", error);
      switch (error.code) {
        case "auth/wrong-password":
          setPasswordUpdateError(t("incorrectPassword"));
          break;
        case "auth/weak-password":
          setPasswordUpdateError(t("weakPassword"));
          break;
        default:
          setPasswordUpdateError(t("passwordUpdateFailed") + error.message);
      }
    }
  };

  const handleEmailUpdate = async () => {
    setEmailUpdateError("");
    setEmailUpdateMessage("");

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setEmailUpdateError(t("invalidEmail"));
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      ); //Reauthenticate.
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);
      //Update on firestore as well
      const userDocRef = doc(db, "users", user.email);
      await updateDoc(userDocRef, { "userData.email": newEmail }); // Critical: Update the user's document.
      //todo: Option to send a new email to verify new email.
      setEmailUpdateMessage(t("emailUpdated")); // Show success
      setIsEditingEmail(false);
    } catch (error) {
      console.error("Email update error:", error);
      switch (error.code) {
        case "auth/wrong-password":
          setEmailUpdateError(t("incorrectPassword"));
          break;
        case "auth/email-already-in-use":
          setEmailUpdateError(t("emailInUse"));
          break;
        case "auth/invalid-email":
          setEmailUpdateError(t("invalidEmail"));
          break;
        default:
          setEmailUpdateError(t("emailUpdateFailed") + error.message);
      }
    }
  };

  const handleProfileUpdate = async () => {
    setUpdateProfileError("");
    setUpdateProfileMessage("");
    try {
      const userDocRef = doc(db, "users", user.email); //Use email since it is the ID
      await updateDoc(userDocRef, {
        "userData.firstName": firstName,
        "userData.lastName": lastName,
        "userData.displayName": `${firstName} ${lastName}`, // Update displayName as well
      });
      setUpdateProfileMessage(t("profileUpdated")); // Use i18next
      setIsEditingProfile(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setUpdateProfileError(t("profileUpdateFailed") + error.message); // Use i18next.
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-4">{error}</div>;
  }

  if (!user || !userData) {
    return (
      <div className="text-center mt-4">Please log in to view settings.</div>
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-2xl">
      <h1 className="text-3xl font-bold text-center mb-6">
        {t("userSettings")}
      </h1>

      {/* Profile Section */}
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-semibold mb-4">{t("profile")}</h2>
        {!isEditingProfile && (
          <button
            onClick={() => setIsEditingProfile(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("editProfile")}
          </button>
        )}

        {isEditingProfile ? (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="firstName"
              >
                {t("firstName")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lastName"
              >
                {t("lastName")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleProfileUpdate}
              >
                {t("saveProfile")}
              </button>

              <button
                onClick={() => setIsEditingProfile(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
              >
                {t("cancel")}
              </button>
            </div>
            {updateProfileMessage && (
              <p className="text-green-500 text-sm mt-2">
                {updateProfileMessage}
              </p>
            )}
            {updateProfileError && (
              <p className="text-red-500 text-sm mt-2">{updateProfileError}</p>
            )}
          </>
        ) : (
          <>
            <p className="text-gray-700 mb-2">
              <strong>{t("firstName")}:</strong> {userData.firstName}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>{t("lastName")}:</strong> {userData.lastName}
            </p>
          </>
        )}
      </div>

      {/* Email Update Section */}
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-semibold mb-4">{t("email")}</h2>

        {!isEditingEmail && (
          <button
            onClick={() => setIsEditingEmail(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("changeEmail")}
          </button>
        )}

        {isEditingEmail ? (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="newEmail"
              >
                {t("newEmail")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newEmail"
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="currentPasswordEmail"
              >
                {t("currentPassword")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="currentPasswordEmail"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleEmailUpdate}
              >
                {t("updateEmail")}
              </button>
              <button
                onClick={() => setIsEditingEmail(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
              >
                {t("cancel")}
              </button>
            </div>

            {emailUpdateMessage && (
              <p className="text-green-500 text-sm mt-2">
                {emailUpdateMessage}
              </p>
            )}
            {emailUpdateError && (
              <p className="text-red-500 text-sm mt-2">{emailUpdateError}</p>
            )}
          </>
        ) : (
          <p className="text-gray-700 mb-2">
            <strong>{t("email")}:</strong> {userData.email}
          </p>
        )}
      </div>

      {/* Password Update Section */}
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-semibold mb-4">{t("password")}</h2>

        {!isEditingPassword && (
          <button
            onClick={() => setIsEditingPassword(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("changePassword")}
          </button>
        )}

        {isEditingPassword ? (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="currentPassword"
              >
                {t("currentPassword")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="currentPassword"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="newPassword"
              >
                {t("newPassword")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="confirmNewPassword"
              >
                {t("confirmNewPassword")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="confirmNewPassword"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handlePasswordUpdate}
              >
                {t("updatePassword")}
              </button>
              <button
                onClick={() => setIsEditingPassword(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
              >
                {t("cancel")}
              </button>
            </div>
            {passwordUpdateMessage && (
              <p className="text-green-500 text-sm mt-2">
                {passwordUpdateMessage}
              </p>
            )}
            {passwordUpdateError && (
              <p className="text-red-500 text-smmt-2">{passwordUpdateError}</p>
            )}
          </>
        ) : (
          <p className="text-gray-700 mb-2">
            <strong>{t("currentPassword")}:</strong> ********{" "}
            {/* Never display the actual password */}
          </p>
        )}
      </div>
      {/* Logout Button */}
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <button
          onClick={handleDashboard}
          className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={logoutLoading}
        >
          Dashboard
        </button>
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={logoutLoading}
        >
          {logoutLoading ? (
            <ColorRing
              visible={true}
              height="20"
              width="20"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          ) : (
            t("logout")
          )}
        </button>
      </div>
    </div>
  );
}

export default UserSettingsPage;
