import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase-config";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react'; // Correct imports
import useDateUtils from "../utils/fromStringToTimestamp";


function JobListingsPage() {
  const [jobs, setJobs] = useState([]);
  const { formatToFrenchLongDate } = useDateUtils();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const { t } = useTranslation("JobListingsPage");
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
      document.title = "Job Listings Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const today = new Date();
        today.setHours(23, 59, 59, 59); // Set time to 00:00:00:000 for accurate comparison
        
    
        // Simplified query for debugging
        const q = query(collectionGroup(db, "active"));
        /* const q = query(
          collectionGroup(db, "active"),
          where("Statut", "==", "Submissions"),
          // where("DateLimiteDeCandidature", "<=", Timestamp.fromDate(today)) // Compare with Firestore Timestamp

        ); */
        const querySnapshot = await getDocs(q);
        const jobsData = [];
        querySnapshot.forEach((doc) => {
          const tenantId = doc.ref.parent.parent.id;
          jobsData.push({
            id: doc.id,  // ALWAYS include the document ID
            JobID: doc.data().JobID,
            title: doc.data().Titre,
            company: doc.data().Entreprise,
            type: doc.data().TypeDePoste,
            location: doc.data().Lieu,
            tenantId: tenantId,
            deadline: formatToFrenchLongDate(doc.data().DateLimiteDeCandidature),
            description: doc.data().Description,
            requiredSkills: doc.data().CompetencesRequises,
            companyDescription: doc.data().DescriptionDeLentreprise,
            experienceLevel: doc.data().NiveauDExperience,
            qualifications: doc.data().Qualifications,
            responsibilities: doc.data().Responsibilities,
            additionalInformation: doc.data().additionalInformation,
            createdOn: formatToFrenchLongDate(doc.data().createdOn),
            benefits: doc.data().Avantages,
            ...doc.data(),
          });
        });
        setJobs(jobsData);
        setFilteredJobs(jobsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const filtered = jobs.filter((job) => {
      const jobIDMatch = job.JobID.toLowerCase().includes(searchQuery.toLowerCase());
      const titleMatch = job.title.toLowerCase().includes(searchQuery.toLowerCase());
      return jobIDMatch || titleMatch;
    });
    setFilteredJobs(filtered);
  }, [searchQuery, jobs]);

  const openModal = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-8">{t('jobListingsTitle')}</h1>

      <div className="mb-4">
        <input
          type="text"
          placeholder={t('searchPlaceholder')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {filteredJobs.length === 0 ? (
        <p className="text-center text-gray-600">{t('noJobsFound')}</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredJobs.map((job) => (
            <div
              key={job.JobID}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300"
            >
              <h2
                className="text-xl font-semibold mb-2 text-blue-600 hover:underline cursor-pointer"
                onClick={() => openModal(job)}
              >
                {job.title}
              </h2>
              <p className="text-gray-600 mb-2">{job.company}</p>
              <p className="text-gray-500 mb-4">
                {job.type} - {job.location}
              </p>
              <Link
                to={`/jobs/${job.JobID}`}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {t('applyNow')}
              </Link>
              <p className="text-sm text-gray-400 mt-2">
                {t('tenant')}: {job.tenantId}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Modal using Headless UI */}
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">{selectedJob?.title}</DialogTitle>
                  <div className="mt-2">
                  <p className="text-sm text-gray-500"><strong>{t('company')}:</strong> {selectedJob?.company}</p>
                    {selectedJob?.companyDescription && (
                      <p className="text-sm text-gray-500"><strong>{t('companyDescription')}:</strong> {selectedJob?.companyDescription}</p>
                    )}
                    <p className="text-sm text-gray-500"><strong>{t('location')}:</strong> {selectedJob?.location}</p>
                    <p className="text-sm text-gray-500"><strong>{t('jobType')}:</strong> {selectedJob?.type}</p>
                    <p className="text-sm text-gray-500"><strong>{t('experienceLevel')}:</strong> {selectedJob?.experienceLevel}</p>
                    <p className="text-sm text-gray-500"><strong>{t('deadline')}:</strong> {selectedJob?.deadline}</p>
                     <p className="text-sm text-gray-500">
                       <strong>{t('createdOn')}:</strong> {formatToFrenchLongDate(selectedJob?.createdOn)}
                     </p>


                    <div className="mt-4"><p className="text-base font-medium text-gray-700">{t('jobDescription')}:</p><p className="text-sm text-gray-600">{selectedJob?.description}</p></div>
                    <div className="mt-4"><p className="text-base font-medium text-gray-700">{t('requiredSkills')}:</p><p className="text-sm text-gray-600">{selectedJob?.requiredSkills}</p></div>
                    <div className="mt-4"><p className="text-base font-medium text-gray-700">{t('qualifications')}:</p><p className="text-sm text-gray-600">{selectedJob?.qualifications}</p></div>
                    <div className="mt-4"><p className="text-base font-medium text-gray-700">{t('responsibilities')}:</p><p className="text-sm text-gray-600">{selectedJob?.responsibilities}</p></div>
                    {selectedJob?.additionalInformation && (<div className="mt-4"><p className="text-base font-medium text-gray-700">{t('additionalInformation')}:</p><p className="text-sm text-gray-600">{selectedJob?.additionalInformation}</p></div>)}
                    {selectedJob?.benefits && (<div className="mt-4"><p className="text-base font-medium text-gray-700">{t('benefits')}:</p> <p className="text-sm text-gray-600">{selectedJob?.benefits}</p></div>)}
                   </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Link
                  to={`/jobs/${selectedJob?.JobID}`}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                >
                {t('applyNow')}
                </Link>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={closeModal}
                  >
                    {t('close')}
                  </button>
                </div>
              </div>
            </TransitionChild>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>

    </div>
  );
}

export default JobListingsPage;