import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// --- Icons ---

const SuccessIcon = () => (
  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  </svg>
);

// DIFFERENT ERROR ICON
const ErrorIcon = () => (
  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  </svg>
);

const WarningIcon = () => (
  <svg className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  </svg>
);

const InfoIcon = () => (
  <svg className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21a9 9 0 019-9m-9 9a9 9 0 009-9m-9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 01-9-9" />
  </svg>
);

// --- Close Button ---

const CloseButton = ({ onClick, color }) => {
    let buttonColor = 'text-gray-500 hover:text-gray-700 focus:ring-gray-400'; // Default gray

    switch (color) {
        case 'green':
            buttonColor = 'text-green-500 hover:text-green-700 focus:ring-green-400';
            break;
        case 'red':
            buttonColor = 'text-red-500 hover:text-red-700 focus:ring-red-400';
            break;
        case 'yellow':
            buttonColor = 'text-yellow-500 hover:text-yellow-700 focus:ring-yellow-400';
            break;
        case 'blue':
            buttonColor = 'text-blue-500 hover:text-blue-700 focus:ring-blue-400';
            break;
    }


    return (
        <button
            type="button"
            className={`ml-auto -mx-1.5 -my-1.5 rounded-md p-1.5 focus:outline-none focus:ring-2 transition ${buttonColor}`}
            onClick={onClick}
            aria-label="Close"
        >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
        </button>
    )
};

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    color: PropTypes.oneOf(['green', 'red', 'yellow', 'blue', 'gray']),
};


// --- ToastContainer (manages multiple toasts) ---

function ToastContainer({ children }) {
    return (
        <div className="fixed top-0 right-0 p-4 space-y-4 z-50">
            {children}
        </div>
    );
}

ToastContainer.propTypes = {
    children: PropTypes.node,
};

// --- Toast Component ---

function Toast({ type, message, dismissible, onClose, autoDismissTime = 5000, id }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        let timer;
        if (autoDismissTime > 0) {
            timer = setTimeout(() => {
                setIsVisible(false);
                onClose && onClose(id); // Pass the ID to the onClose handler
            }, autoDismissTime);
        }

        return () => clearTimeout(timer);
    }, [autoDismissTime, onClose, id]);  // Include id in the dependencies

    if (!isVisible) return null;

    let bgColor = 'bg-gray-100'; // Default
    let textColor = 'text-gray-700'; //Default
    let borderColor = "border-gray-500"; //Default
    let icon = <InfoIcon />;
    let closeButtonColor = 'gray';

    switch (type) {
        case 'success':
            bgColor = 'bg-green-100';
            textColor = 'text-green-800';
            borderColor = "border-green-500";
            icon = <SuccessIcon />;
            closeButtonColor = 'green';
            break;
        case 'error':
            bgColor = 'bg-red-100';
            textColor = 'text-red-800';
            borderColor = "border-red-500";
            icon = <ErrorIcon />;
            closeButtonColor = 'red';
            break;
        case 'warning':
            bgColor = 'bg-yellow-100';
            textColor = 'text-yellow-800';
            borderColor = "border-yellow-500";
            icon = <WarningIcon />;
            closeButtonColor = 'yellow';
            break;
        case 'info':
            bgColor = 'bg-blue-100';
            textColor = 'text-blue-800';
            borderColor = "border-blue-500";
            icon = <InfoIcon />;
            closeButtonColor = 'blue';
            break;
        // Add more cases as needed (e.g., 'loading')
    }

    return (
        <div
            className={` ${bgColor} ${textColor} border-l-4 ${borderColor} p-4 rounded-md shadow-lg flex items-start  max-w-sm`} // Added max-w-sm
            role="alert"
        >
            {icon}
            <div className="ml-3 flex-1">
                <p className="text-sm font-medium">{message}</p>
            </div>
            {dismissible && (
                <CloseButton
                    color={closeButtonColor}
                    onClick={() => {
                        setIsVisible(false);
                        onClose && onClose(id); // Pass the ID
                    }}
                />
            )}
        </div>
    );
}

Toast.propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
    message: PropTypes.string.isRequired,
    dismissible: PropTypes.bool,
    onClose: PropTypes.func,
    autoDismissTime: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Add ID prop
};

// --- Toast Provider (Context API) ---

const ToastContext = React.createContext();

function ToastProvider({ children }) {
    const [toasts, setToasts] = useState([]);

    const addToast = (toast) => {
        const id = Date.now(); // Unique ID for each toast
        setToasts((prevToasts) => [...prevToasts, { ...toast, id }]);
    };

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer>
                {toasts.map((toast) => (
                    <Toast key={toast.id} {...toast} onClose={removeToast} />
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// --- useToast Hook ---

function useToast() {
    const context = React.useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
}

export { ToastProvider, useToast };