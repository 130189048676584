import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs, Timestamp } from "firebase/firestore";
import { db, auth } from "../../firebase-config";

const usersAdapter = createEntityAdapter({
  selectId: (userData) => userData.email,
});

const initialState = usersAdapter.getInitialState({ loading: false });

// Fetch a single user by email
export const fetchUserByEmail = createAsyncThunk(
  "userData/fetchUserByEmail",
  async (email, { rejectWithValue }) => {
    try {
      const userDocRef = doc(db, "users", email);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        auth.tenantId = userData.userData.originalTenantId || process.env.REACT_APP_DEFAULT_TENANT_ID;

        // Convert Timestamp to ISO String *HERE*
        const processedUserData = { ...userData.userData }; // Create a copy
        if (processedUserData.lastLogin && processedUserData.lastLogin.toDate) { // Check if lastLogin is valid.
            processedUserData.lastLogin = processedUserData.lastLogin.toDate().toISOString();
        }
        if (processedUserData.createdDate && processedUserData.createdDate.toDate) { // Check if lastLogin is valid.
            processedUserData.createdDate = processedUserData.createdDate.toDate().toISOString();
        }
        if (processedUserData.modifiedDate && processedUserData.modifiedDate.toDate) { // Check if lastLogin is valid.
            processedUserData.modifiedDate = processedUserData.modifiedDate.toDate().toISOString();
        }
        if (processedUserData.lastActivityDate && processedUserData.lastActivityDate.toDate) { // Check if lastLogin is valid.
            processedUserData.lastActivityDate = processedUserData.lastActivityDate.toDate().toISOString();
        }
        if (Array.isArray(processedUserData.modifications)) {
          console.log(`Array.isArray`, processedUserData.modifications[0].modifiedDate.toDate().toISOString(),(processedUserData.modifications[0].modifiedDate instanceof Timestamp))
          processedUserData.modifications = processedUserData.modifications.map(modification => ({
              ...modification,
              modifiedDate: modification.modifiedDate.toDate().toISOString(),
          }));
       }


        return processedUserData; // Return the modified data
      } else {
        return rejectWithValue("User document does not exist.");
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

// Fetch all users
export const fetchAllUsers = createAsyncThunk(
  "userData/fetchAllUsers",
  async (tenantId, { rejectWithValue }) => {
    try {
      const usersCollectionRef = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollectionRef);

      const users = usersSnapshot.docs.map((doc) => {
            const userData = doc.data().userData;

            // Convert Timestamp to ISO string here.
            const processedUserData = { ...userData }; // Create a copy to avoid direct modification.
            if (processedUserData.lastLogin && processedUserData.lastLogin.toDate) {
               processedUserData.lastLogin = processedUserData.lastLogin.toDate().toISOString();
            }
            return processedUserData;

        });
      const allUsers = users.filter((user) => user.tenantId === tenantId); // Filter users by tenantId
      return allUsers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    removeUser: usersAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        usersAdapter.upsertOne(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchUserByEmail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        usersAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Selectors
export const { selectAll: selectAllUsers, selectById: selectUserByEmail } =
  usersAdapter.getSelectors((state) => state.userData);

export const { removeUser } = userDataSlice.actions;
export default userDataSlice.reducer;