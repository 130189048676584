import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const Modal = ({ isOpen, onClose, title, children, size = 'md' }) => {
  const modalRef = useRef(null);
  const { t } = useTranslation('global'); // Assuming translations in a 'global' namespace


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      document.addEventListener('mousedown', handleOutsideClick); // Use mousedown for broader compatibility
      document.body.style.overflow = 'hidden'; // Prevent scrolling of the background
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'auto'; // Restore scrolling
    };
  }, [isOpen, onClose]); // Add onClose to dependencies

  if (!isOpen) return null;

  let maxWidthClass = 'max-w-lg'; // Default size
  if (size === 'sm') {
    maxWidthClass = 'max-w-sm';
  } else if (size === 'lg') {
    maxWidthClass = 'max-w-3xl';
  } else if (size === 'xl') {
    maxWidthClass = 'max-w-5xl';
  }
  // Add more size options as needed

    return (
        <div
            id="modal-background"
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-start overflow-y-auto pt-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div ref={modalRef} className={`bg-white p-6 rounded-lg shadow-xl ${maxWidthClass} w-full mx-4 my-10 relative`}>
                <div className="flex items-center justify-between mb-4">
                  <h2 id="modal-title" className="text-2xl font-semibold">{title}</h2>
                  <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-red-600 rounded-full p-2 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={onClose}
                      aria-label={t("modalClose")}
                  >
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                  </button>
                </div>
                <div className="overflow-y-auto max-h-[calc(80vh-6rem)]"> {/* Adjusted max-h */}
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']), // Define the allowed sizes
};

export default Modal;