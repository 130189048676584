import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { analytics, logEvent } from "../firebase-config";

function StripePaymentReturn() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("StripePaymentReturn");

  useEffect(() => {
    const statusParam = searchParams.get("status");
    const sessionId = searchParams.get("session_id");
    // console.log(statusParam, sessionId);

    const fetchSessionStatus = async () => {
      if (/* statusParam === 'success' &&  */ sessionId) {
        try {
          const response = await fetch(
            `/api/StripeSessionStatus?session_id=${sessionId}`
          );
          const session = await response.json();

          if (response.ok) {
            if (session.status === "open") {
              // You *could* technically re-mount the Checkout form here,
              // BUT it's generally better to direct the user back to
              // the checkout page, or show a message explaining
              // that the session is still open and they should try again.
              // Re-mounting here is complex, as you'd need to pass all
              // the necessary props (basicLicenses, proLicenses, etc.)
              //  back to the StripeCheckoutForm.  A redirect is cleaner.
              setMessage(t("paymentSessionOpen"));
              setStatus("pending"); // Add a 'pending' state
              setLoading(false);
              return; // Exit early
            } else if (session.status === "complete") {
              console.log(session.metadata.line_items);
              console.log(session);
              var lineItems = [];
              if (session.metadata.basicLicenses > 0) {
                lineItems.push({
                  item_id: "PRORECRUIT_LICENSE_BASIC",
                  item_name: "ProRecruit Basic License",
                  unit_price: session.metadata.basicLicensesPrice,
                  type: "Basic",
                  price:
                    session.metadata.basicLicensesPrice *
                    (1 - session.metadata.discountPercent / 100), // Price PER UNIT, use decimal for non xof, after discount.
                  quantity: session.metadata.basicLicenses,
                });
              }

              if (session.metadata.proLicenses > 0) {
                lineItems.push({
                  item_id: "PRORECRUIT_LICENSE_PRO",
                  item_name: "ProRecruit Pro License",
                  unit_price: session.metadata.proLicensesPrice ,
                  type: "Pro",
                  price:
                    session.metadata.proLicensesPrice *
                    (1 - session.metadata.discountPercent / 100), // Price PER UNIT, use decimal for non xof, after discount.
                  quantity: session.metadata.proLicenses,
                });
              }

              logEvent(analytics, "purchase", {
                currency: session.currency.toUpperCase(), // Uppercase for consistency
                value: session.amount_total, // Total amount, correctly converted from cents if needed
                transaction_id: sessionId, // Use the session ID
                coupon: "", //  Extract from metadata if you added to session.
                items: lineItems.map((item, index) => ({ // Correctly access line items
                  item_id: item.item_id,  // Use product metadata, set in Cloud Function
                  item_name: item.item_name,    // Get product name
                  affiliation: "ProRecruit",            // Consistent value
                  coupon: "", // Get from metadata IF passed
                  discount: (item.unit_price * item.quantity * (session.metadata.discountPercent/100)),    //  calculate discount based on metadata
                  index: index + 1,           // Index in the cart (starts at 1)
                  item_brand: "ProRecruit",          // Consistent value
                  item_category: "Software License",     // Consistent
                  item_category2: "Recruitment",      // Consistent
                  item_category3: "License",        // Consistent
                  item_category4: item.type === 'Basic' ? 'Basic': 'Pro', // Get type from metadata
                  item_category5: "",             // Add more categories if needed
                  item_list_id: "checkout_page",      // Where the purchase happened
                  item_list_name: "Checkout Page",      // User-friendly name
                  item_variant: "",                // Add if applicable
                  location_id: "",                // Not applicable for digital goods
                  price: item.price,   // Price PER UNIT, use decimal for non xof, after discount.
                  quantity: item.quantity             // Number of units of THIS item
                })
                ),
                // Include affiliate information IF it exists.
                ...(session.metadata.affiliateID && {
                  affiliate: session.metadata.affiliateID,
                }),
                // Add shipping and tax if applicable, but likely zero for digital goods.
                shipping: 0,
                tax: 0,
              });
              setStatus("success");
              setMessage(t("paymentSuccess")); // Or customize based on session data
            } else {
              setStatus("error");
              setMessage(
                t("paymentError") +
                  " - completed-" +
                  t("unexpectedSessionStatus") +
                  session.status
              );
            }
          } else {
            // Handle non-200 responses from your /session_status endpoint
            setStatus("error");
            setMessage(
              t("paymentError") +
                " non 200" +
                (session.error || "Unknown error")
            );
          }
        } catch (error) {
          setStatus("error");
          setMessage(t("paymentError") + " error " + error.message); // Or a generic error
          logEvent(analytics, "payment_return_error", {
            error_message: error.message,
            error_code: error.code, // This will exist for Firebase errors
            session_id: sessionId,
          });
        } finally {
          setLoading(false);
        }
      } else if (statusParam === "failed") {
        logEvent(analytics, "payment_failed", {
          session_id: sessionId,
          reason: searchParams.get("reason"),
        });
        setStatus("failed");
        setMessage(t("paymentFailed") + " " + searchParams.get("reason"));
        setLoading(false);
      } else if (statusParam === "error") {
        // Added error handling
        logEvent(analytics, "payment_error", {
          session_id: sessionId,
          error_message: searchParams.get("message"),
        });
        setStatus("error");
        setMessage(t("paymentError") + " " + searchParams.get("message"));
        setLoading(false);
      } else {
        // Handle the case where there's no status, or an invalid status
        setStatus("error");
        setMessage(t("invalidPaymentStatus"));
        setLoading(false);
      }
    };

    fetchSessionStatus();
  }, [searchParams, t]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">
            {t("paymentStatus")}
          </h1>

          {status === "success" && (
            <div
              className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded"
              role="alert"
            >
              <p className="font-bold">{t("success")}</p>
              <p>{message}</p>
            </div>
          )}
          {status === "failed" && (
            <div
              className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded"
              role="alert"
            >
              <p className="font-bold">{t("paymentFailed")}</p>
              <p>{message}</p>
            </div>
          )}
          {status === "error" && (
            <div
              className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded"
              role="alert"
            >
              <p className="font-bold">{t("paymentError")}</p>
              <p>{message}</p>
            </div>
          )}
          {status === "pending" && (
            <div
              className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded"
              role="alert"
            >
              <p className="font-bold">{t("paymentPending")}</p>
              <p>{message}</p>
            </div>
          )}

          <div className="mt-8 space-x-4">
            <Link to="/dashboard">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded">
                {t("goToDashboard")}
              </button>
            </Link>
            <Link to="/checkout">
              <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded">
                {t("backToCheckout")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StripePaymentReturn;
