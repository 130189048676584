import React, { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db, Timestamp } from "../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from "../useAuth";
import Navbar from "../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import TawkToChat from "../components/TawkToChat";
import {
  selectAllUsers,
  selectUserByEmail,
  fetchUserByEmail,
} from "../utils/redux/userDataSlice";
import { signOut } from "firebase/auth";
import { displaySanitizedInput, sanitizeInput } from "../utils/inputSanitizer";
import {
  fetchAssessments,
  selectAssessments,
} from "../utils/redux/assessmentSlice";
import Select from "react-select";
import useDateUtils from "../utils/fromStringToTimestamp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const JobPostingDetailsPage = () => {
  const { formatToDDMMYYYY, stringToTimestamp,  } = useDateUtils();
  const { t } = useTranslation("JobPostingDetailsPage");
  // const { state } = useLocation();
  const { jobId, tenantId } = useParams();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const userData = useSelector((state) =>
    selectUserByEmail(state, currentUser.email)
  );
  const assessments = useSelector(selectAssessments);
  const [jobData, setJobData] = useState(null);
  const [jobDataDeadline, setJobDataDeadline] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    document.title = "Job Postings Details Page - ProRecruit"; // Set the title dynamically

    // Cleanup function: Reset the title when the component unmounts
    return () => {
      document.title = "ProRecruit"; // Reset to a default title
    };
  }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    dispatch(fetchUserByEmail(currentUser.email));
    const fetchJobData = async () => {
      try {
        const docRef = doc(db, `job-descriptions/${tenantId}/active`, jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const jobData = docSnap.data();
          setJobData(displaySanitizedInput(jobData));
          const date = jobData.DateLimiteDeCandidature; // Convert to JavaScript Date
          console.log(date)

          // Format to MM/DD/YYYY
          const formattedDate = formatToDDMMYYYY(date);
          console.log(formattedDate)
          setJobDataDeadline(formattedDate);
        } else {
          setError(t("jobPostingNotFound"));
        }

        dispatch(fetchAssessments({ tenantId: tenantId }));
      } catch (error) {
        setError(t("errorFetchingJobData"));
        console.error("Error fetching job data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobData();
  }, [jobId, tenantId, t]);

  const handleSignOut = useCallback(async () => {
    try {
      // Backend session logout
      await fetch(`${API_ENDPOINT}api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      // Firebase sign-out
      await signOut(currentUser);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);
  const handleDeadlineChange = (e) =>{
    setJobDataDeadline(e.target.value);
    console.log(e.target.name, e.target.value, typeof(e.target.value))
    const t = stringToTimestamp(e.target.value);
    console.log(t)
    setJobData({ ...jobData, DateLimiteDeCandidature: t });
  }

  const handleChange = (e) => {
    // setJobData({ ...jobData, [e.target.name]: e.target.value });
    // console.log(e.target.name, e.target.value)

    if (e && e.target) {
      // Handle standard input change
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else {
      // Handle react-select change for assessments
      console.log(e);

      // Merge new assessments with the existing ones
      setJobData((jobData) => ({
        ...jobData,
        assessments: Array.isArray(e) ? e : [...(jobData.assessments || []), e],
      }));
    }
  };

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  function transformArray(inputArray) {
    try {
      return inputArray.map((item) => ({
        value: item.id,
        label: item.title,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(
        doc(db, `job-descriptions/${tenantId}/active`, jobId),
        sanitizeInput(jobData)
      );
      alert(t("jobUpdatedSuccess"));
      navigate("/job-postings"); // Redirect back to job postings list
    } catch (error) {
      console.error("Error updating job posting:", error);
      alert(t("errorUpdatingJobPosting"));
    }
  };

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  if (error) {
    return (
      <div>
        {t("error")}: {error}
      </div>
    );
  }

  if (!jobData) {
    return null; // Or handle this case differently
  }

  return (
    <div>
      <Navbar
        // onSignOut={handleSignOut}
        onCreateJob={toggleCreateModal}
        userData={userData}
        extraButton={{ label: t("listJobPostings"), path: "/jobpostings" }}
      />
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6">{t("editJobPosting")}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="Titre"
              className="block text-sm font-medium text-gray-700"
            >
              {t("title")}
            </label>
            <input
              type="text"
              id="Titre"
              name="Titre"
              value={jobData.Titre || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="Entreprise"
              className="block text-sm font-medium text-gray-700"
            >
              {t("companyName")}
            </label>
            <input
              type="text"
              id="Entreprise"
              name="Entreprise"
              value={jobData.Entreprise || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="DateLimiteDeCandidature"
              className="block text-sm font-medium text-gray-700"
            >
              {t("applicationDeadline")}
            </label>
            <input
              type="date"
              id="DateLimiteDeCandidature"
              name="DateLimiteDeCandidature"
              value={jobDataDeadline || ""}
              placeholder={jobDataDeadline}
              onChange={handleDeadlineChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="EntrepriseDescription"
              className="block text-sm font-medium text-gray-700"
            >
              {t("companyDescription")}
            </label>
            <textarea
              type="text"
              id="EntrepriseDescription"
              name="EntrepriseDescription"
              value={jobData.EntrepriseDescription || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="Description"
              className="block text-sm font-medium text-gray-700"
            >
              {t("jobDescription")}
            </label>
            <textarea
              type="text"
              id="Description"
              name="Description"
              value={jobData.Description || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="Qualifications"
              className="block text-sm font-medium text-gray-700"
            >
              {t("professionalQualifications")}
            </label>
            <textarea
              type="text"
              id="Qualifications"
              name="Qualifications"
              value={jobData.Qualifications || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="Responsibilities"
              className="block text-sm font-medium text-gray-700"
            >
              {t("responsibilities")}
            </label>
            <textarea
              type="text"
              id="Responsibilities"
              name="Responsibilities"
              value={jobData.Responsibilities || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="additionalInformation"
              className="block text-sm font-medium text-gray-700"
            >
              {t("additionalInformation")}
            </label>
            <textarea
              type="text"
              id="additionalInformation"
              name="additionalInformation"
              value={jobData.additionalInformation || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="minimumRelevantYearsOfExperience"
              className="block text-sm font-medium text-gray-700"
            >
              {t("minimumExperience")}
            </label>
            <input
              type="number"
              id="minimumRelevantYearsOfExperience"
              name="minimumRelevantYearsOfExperience"
              value={jobData.minimumRelevantYearsOfExperience || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label>
              {" "}
              Test d'Evaluations
              {jobData.type.toLowerCase() === "basic" ? (
                " - Les tests d'evaluations ne sont disponible que pour les versions Pro."
              ) : (
                <Select
                  isMulti
                  options={transformArray(assessments)}
                  name="assessments"
                  value={jobData.assessments}
                  onChange={handleChange}
                />
              )}
            </label>
          </div>

          <button
            type="submit"
            className="mt-6 w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {t("saveChanges")}
          </button>
        </form>
      </div>
      <TawkToChat />
    </div>
  );
};

export default JobPostingDetailsPage;
