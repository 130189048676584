// candidateActions.js

export const FETCH_CANDIDATES_REQUEST = "FETCH_CANDIDATES_REQUEST";
export const FETCH_CANDIDATES_SUCCESS = "FETCH_CANDIDATES_SUCCESS";
export const FETCH_CANDIDATES_FAILURE = "FETCH_CANDIDATES_FAILURE";
export const UPDATE_CANDIDATE_STATUS = "UPDATE_CANDIDATE_STATUS";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_FILTER_KEY = "SET_FILTER_KEY";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const OPEN_CANDIDATE_DETAILS_MODAL = "OPEN_CANDIDATE_DETAILS_MODAL";
export const CLOSE_CANDIDATE_DETAILS_MODAL = "CLOSE_CANDIDATE_DETAILS_MODAL";
export const DOWNLOAD_DOCUMENT_REQUEST = "DOWNLOAD_DOCUMENT_REQUEST";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_FAILURE = "DOWNLOAD_DOCUMENT_FAILURE";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchCandidatesRequest = () => ({
  type: FETCH_CANDIDATES_REQUEST,
});
export const fetchCandidatesSuccess = (candidates, tenantId, jobUuid) => ({
  type: FETCH_CANDIDATES_SUCCESS,
  payload: { candidates, tenantId, jobUuid },
});
export const fetchCandidatesFailure = (error) => ({
  type: FETCH_CANDIDATES_FAILURE,
  payload: error,
});
// ... other actions for updating status, setting tabs, sort, filters, etc.

export const openCandidateDetailsModal = (candidate) => ({
  type: OPEN_CANDIDATE_DETAILS_MODAL,
  payload: candidate,
});

export const closeCandidateDetailsModal = () => ({
  type: CLOSE_CANDIDATE_DETAILS_MODAL,
});

export const downloadDocumentRequest = () => ({
  type: DOWNLOAD_DOCUMENT_REQUEST,
});

export const downloadDocumentSuccess = () => ({
  type: DOWNLOAD_DOCUMENT_SUCCESS,
});

export const downloadDocumentFailure = (error) => ({
  type: DOWNLOAD_DOCUMENT_FAILURE,
  payload: error,
});

export const fetchCandidates = (tenantID, jobID) => {
  return async (dispatch) => {
    dispatch(fetchCandidatesRequest());
    try {
      const response = await fetch(
        `${API_ENDPOINT}api/v1/tenants/${tenantID}/jobpostings/${jobID}/applications`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();
      if (data.user) {
        dispatch(fetchCandidatesSuccess(data.user, tenantID, jobID));
      } else {
        // Dispatch an error action if needed
        dispatch(
          fetchCandidatesFailure("Candidates not found or invalid response.")
        );
      }
    } catch (error) {
      dispatch(fetchCandidatesFailure(error.message));
    }
  };
};
export const updateCandidateStatus = (
  candidate,
  newStatus,
  tenantId,
  jobUuid
) => {
  return async (dispatch) => {
    try {
      // You would typically make an API call here to update the status
      // For now, we'll just simulate the update
      var data = {
        ContactInformation: candidate.ContactInformation,
        FullName: candidate.FullName,
      };
      const response = await fetch(
        `${API_ENDPOINT}/api/v1/tenants/${tenantId}/jobpostings/${jobUuid}/applications/${candidate.uuid}/status/${newStatus}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        var candidateId = candidate.uuid;
        dispatch({
          type: UPDATE_CANDIDATE_STATUS,
          payload: { candidateId, newStatus },
        });
      } else {
        // Dispatch an error action if the update fails
        dispatch({
          type: "UPDATE_CANDIDATE_STATUS_ERROR",
          payload: "Failed to update candidate status",
        });
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CANDIDATE_STATUS_ERROR",
        payload: error.message,
      });
    }
  };
};
export const downloadDocument = (url, type) => {
  return async (dispatch) => {
    dispatch(downloadDocumentRequest());
    try {
      var f = url.split("/");
      var fn = f[f.length - 1];
      const response = await fetch(
        `${API_ENDPOINT}/api/v1/storages/downloads/${fn}/type/${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
        }
      );
      if (response.status === 200) {
        const blob = await response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileURL;
        console.log(blob);
        a.download = `${url}.${blob.type.split("/")[1]}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch(downloadDocumentSuccess());
      } else {
        console.error(`Error downloading: ${response.body}`);
        alert("Une erreur s'est produit. Veuillez essayer ultérieurement");
        dispatch(downloadDocumentFailure(response.body));
      }
    } catch (error) {
      console.error(`Error downloading: ${error}`);
      alert("Une erreur s'est produit. Veuillez essayer ultérieurement");
      dispatch(downloadDocumentFailure(error.message));
    }
  };
};
export const setSelectedTab = (tab, allCandidates) => {
  return async (dispatch) => {
    try {
      console.log(`setSelectedTab ${allCandidates.length}`);
      if (tab === "all") {
        const candidates = allCandidates;
        dispatch({
          type: SET_SELECTED_TAB,
          payload: { allCandidates, candidates, tab },
        });
      } else {
        const candidates = allCandidates.filter(
          (item) => item.ApplicationStatus === tab
        );

        console.log(`setSelectedTab finsh ${candidates.length}`);
        dispatch({
          type: SET_SELECTED_TAB,
          payload: { allCandidates, candidates, tab },
        });
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CANDIDATE_STATUS_ERROR",
        payload: error.message,
      });
    }
  };
};

export const setSortBy = (sortKey, allCandidates) => {
  return async (dispatch) => {
    try {
      console.log(`setSelectedTab ${sortKey} ${allCandidates[0].FullName}`);

      const candidates = allCandidates.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return 1;
        if (a[sortKey] > b[sortKey]) return -1;
        return 0;
      });

      console.log(`setSelectedTab finsh ${candidates[0].FullName}`);
      dispatch({
        type: SET_SORT_BY,
        payload: { sortKey, candidates },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CANDIDATE_STATUS_ERROR",
        payload: error.message,
      });
    }
  };
};

export const setFilterKey = (filterKey) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_FILTER_KEY,
        payload: filterKey,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CANDIDATE_STATUS_ERROR",
        payload: error.message,
      });
    }
  };
};

export const setFilterValue = (filterKey, filterValue, candidatesToFilter) => {
  return async (dispatch) => {
    try {
      console.log(`setSelectedTab ${candidatesToFilter.length}`);
      if (filterKey === "") {
        const candidates = candidatesToFilter;
        dispatch({
          type: SET_FILTER_VALUE,
          payload: { candidates, filterKey },
        });
      } else {
        const candidates = candidatesToFilter.filter((item) =>
          item[filterKey].includes(filterValue)
        );

        console.log(`setSelectedTab finsh ${candidates.length}`);
        dispatch({
          type: SET_FILTER_VALUE,
          payload: { candidates, filterValue },
        });
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CANDIDATE_STATUS_ERROR",
        payload: error.message,
      });
    }
  };
};
