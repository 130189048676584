import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { db, analytics } from "../firebase-config"; //  Import  analytics
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { Transition, Popover } from "@headlessui/react";
import PropTypes from 'prop-types';

/**
 * Generates the text content for the affiliate signup thank you email (French).
 * @param {object} applicantData - The applicant's data.
 * @param {string} applicantData.name - The applicant's name.
 * @param {string} applicantData.email - The applicant's email.
 * @return {string} The text content for the thank you email.
 */
function generateAffiliateSignupEmailText(applicantData) {
  return `
      Bonjour ${applicantData.name},
  
      Merci beaucoup pour votre intérêt à rejoindre le programme d'affiliation de ProRecruit ! Nous avons bien reçu votre candidature.
  
      Nous allons examiner attentivement les informations que vous nous avez fournies (votre audience, votre expérience en affiliation, etc.).  Nous vous recontacterons dans les plus brefs délais pour vous informer de l'état de votre candidature.
  
      Si votre candidature est retenue, vous recevrez des instructions détaillées sur la façon de commencer à promouvoir ProRecruit et à gagner des commissions.
  
      En attendant, n'hésitez pas à consulter notre site web pour en savoir plus sur ProRecruit : https://prorecruit.tech/
  
      Cordialement,
  
      L'équipe ProRecruit
      https://app.prorecruit.tech/
  
      ---
      Vous recevez cet email car vous avez soumis une candidature au programme d'affiliation de ProRecruit.
      `;
}

/**
 * Generates the HTML content for the affiliate signup thank you email (French).
 * @param {object} applicantData - The applicant's data.
 * @param {string} applicantData.name - The applicant's name.
 * @param {string} applicantData.email - The applicant's email.
 * @return {string} The HTML content for the thank you email.
 */
function generateAffiliateSignupEmailHTML(applicantData) {
  return `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Merci pour votre candidature !</title>
        <style>
          body { font-family: sans-serif; line-height: 1.6; color: #333; }
          .container { max-width: 600px; margin: 0 auto; padding: 20px; }
          .header { text-align: center; margin-bottom: 30px; }
          .logo { max-width: 150px; } /* Adjust as needed */
          .button { background-color: rgb(81, 131, 16); color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; display: inline-block; margin-top: 20px; }
          .footer { text-align: center; margin-top: 30px; font-size: 0.8em; color: #666; }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <img src="https://prorecruit.tech/assets/images/image11.png" alt="ProRecruit Logo" class="logo">
          </div>
  
          <h1>Merci pour votre candidature, ${applicantData.name} !</h1>
  
          <p>Nous avons bien reçu votre demande pour rejoindre le programme d'affiliation de ProRecruit.  Nous vous remercions de votre intérêt.</p>
  
          <p>Notre équipe va examiner votre candidature avec attention.  Nous prendrons en compte votre audience, votre expérience en affiliation, et les autres informations que vous avez fournies.</p>
  
          <p>Nous vous recontacterons dans les plus brefs délais pour vous informer de notre décision. Si votre candidature est acceptée, vous recevrez toutes les informations nécessaires pour commencer, y compris votre lien d'affilié unique et des ressources marketing.</p>
  
          <p>En attendant, vous pouvez visiter notre site web pour en savoir plus sur ProRecruit :</p>
          <a href="https://prorecruit.tech/" class="button">Découvrir ProRecruit</a>
  
          <p>Cordialement,<br>
          L'équipe ProRecruit</p>
  
          <div class="footer">
            <p>Vous recevez cet email car vous avez postulé au programme d'affiliation de ProRecruit.<br>
            <a href="https://www.prorecruit.tech/ ">ProRecruit</a></p>
          </div>
        </div>
      </body>
      </html>
      `;
}

function AffiliatePage() {
  const { t, i18n } = useTranslation("AffiliatePage");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [otherSocial, setOtherSocial] = useState("");
  const [audienceDescription, setAudienceDescription] = useState("");
  const [hasAffiliateExperience, setHasAffiliateExperience] = useState(false);
  const [
    affiliateExperienceDescription,
    setAffiliateExperienceDescription,
  ] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    document.title = t("affiliatePageTitle") + "  -  ProRecruit";
    logEvent(analytics, "page_view", {
      page_title: t("affiliatePageTitle"), //  Send  current  page  title
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
    });

    return () => {
      document.title = "ProRecruit";
    };
  }, [t]); //  Re-run  effect  if  the  translation  changes.

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setIsSubmitting(true);

    logEvent(analytics, "affiliate_form_start", {
      //You  can  log  details  like  form  id  if  there  is  one,  or  simply  the  name  of  the  page
      form_name: "affiliate_signup_form",
      page_title: document.title,
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
    });

    if (!name || !email) {
      setMessage(t("formIncomplete"));
      setIsSubmitting(false);
      logEvent(analytics, "affiliate_form_error", {
        error_type: "validation_error",
        error_message: t("formIncomplete"),
        field: !name ? "name" : "email",
        page_title: document.title,
        page_location: window.location.href, //  Send  current  URL
        page_path: window.location.pathname, //  Send  current  path
      });
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setMessage(t("invalidEmail"));
      setIsSubmitting(false);
      logEvent(analytics, "affiliate_form_error", {
        error_type: "validation_error",
        error_message: t("invalidEmail"),
        field: "email",
        page_title: document.title,
        page_location: window.location.href, //  Send  current  URL
        page_path: window.location.pathname, //  Send  current  path
      });
      return;
    }

    const formData = {
      name,
      email,
      whatsapp,
      telegram,
      website,
      socialMedia: {
        facebook,
        twitter,
        instagram,
        tiktok,
        linkedin,
        other: otherSocial,
      },
      audienceDescription,
      hasAffiliateExperience,
      affiliateExperienceDescription: hasAffiliateExperience
        ? affiliateExperienceDescription
        : "",
      submittedAt: serverTimestamp(),
    };

    try {
      const docRef = await addDoc(
        collection(db, "affiliate_applications"),
        formData
      );
      console.log("Document  written  with  ID:  ", docRef.id);
      const WelcomeEmailBody = generateAffiliateSignupEmailText(formData);
      const WelcomeEmailHTML = generateAffiliateSignupEmailHTML(formData);
      addDoc(collection(db, "send-grid-emails"), {
        to: formData.email,
        message: {
          subject:
            "Merci pour votre candidature au programme d'affiliation ProRecruit !",
          text: WelcomeEmailBody, // Use plain text for simplicity
          html: WelcomeEmailHTML, // Use HTML for styling
        },
      });
      setMessage(t("signupSuccess"));
      logEvent(analytics, "affiliate_form_submit", {
        form_name: "affiliate_signup_form",
        doc_id: docRef.id, //  Helpful  to  have  the  document  ID  for  debugging
        name: name, //  Log  important  form  data
        email: email,
        has_experience: hasAffiliateExperience, //  Example:  track  experience
        page_location: window.location.href, //  Send  current  URL
        page_path: window.location.pathname, //  Send  current  path
        page_title: document.title,
      });

      setName("");
      setEmail("");
      setWhatsapp("");
      setTelegram("");
      setWebsite("");
      setFacebook("");
      setTwitter("");
      setInstagram("");
      setTiktok("");
      setLinkedin("");
      setOtherSocial("");
      setAudienceDescription("");
      setHasAffiliateExperience(false);
      setAffiliateExperienceDescription("");
    } catch (error) {
      console.error("Error  adding  document:  ", error);
      setMessage(t("signupError") + error.message);
      logEvent(analytics, "affiliate_form_error", {
        error_type: "database_error",
        error_message: error.message,
        firebase_error_code: error.code, //  Add  Firebase  error  code
        page_location: window.location.href, //  Send  current  URL
        page_path: window.location.pathname, //  Send  current  path
        page_title: document.title,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    logEvent(analytics, "language_changed", { language: language,
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
      page_title: document.title, });
  };

  //  Example  usage  for  clicking  the  "How  It  Works"  section.    Best  placed  on  a  link/button.
  const handleHowItWorksClick = () => {
    logEvent(analytics, "how_it_works_click", { section: "affiliate_page",
        page_location: window.location.href, //  Send  current  URL
        page_path: window.location.pathname, //  Send  current  path
        page_title: document.title, });
  };

  //  Example  usage  for  clicking  the  "Benefits"  section.
  const handleBenefitsClick = () => {
    logEvent(analytics, "benefits_click", { section: "affiliate_page",
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
      page_title: document.title, });
  };

  return (
    <div className="font-sans  antialiased  bg-gray-100  min-h-screen">
      <div className="max-w-6xl  mx-auto  p-8">
        <div className="absolute  top-4  right-4  space-x-2">
          <button
            onClick={() => handleLanguageChange("en")}
            className={`px-3  py-1  rounded  ${
              i18n.language === "en"
                ? "bg-blue-600  text-white"
                : "bg-gray-200  text-gray-700  hover:bg-gray-300"
            }`}
          >
            EN
          </button>
          <button
            onClick={() => handleLanguageChange("fr")}
            className={`px-3  py-1  rounded  ${
              i18n.language === "fr"
                ? "bg-blue-600  text-white"
                : "bg-gray-200  text-gray-700  hover:bg-gray-300"
            }`}
          >
            FR
          </button>
        </div>

        <h1 className="text-4xl  font-extrabold  text-center  text-gray-800  mb-6">
          {t("affiliateTitle")}
        </h1>
        <p className="text-xl  text-gray-600  text-center  mb-10">
          {t("affiliateSubtitle")}
        </p>

        <div className="grid  grid-cols-1  md:grid-cols-2  gap-12">
          {/*  Benefits  Section  */}
          <div>
            <h2
              className="text-2xl  font-semibold  mb-4  text-gray-800"
              onClick={handleBenefitsClick}
              style={{ cursor: "pointer" }}
            >
              {t("affiliateBenefitsTitle")}
            </h2>
            <ul className="list-disc  list-inside  space-y-4  text-gray-700">
              <li>
                <strong className="text-gray-900">
                  {t("commissionBenefit")}:
                </strong>{" "}
                {t("commissionBenefitDesc")}
              </li>
              <li>
                <strong className="text-gray-900">
                  {t("supportBenefit")}:
                </strong>{" "}
                {t("supportBenefitDesc")}
              </li>
              <li>
                <strong className="text-gray-900">{t("easyBenefit")}:</strong>{" "}
                {t("easyBenefitDesc")}
              </li>
              <li>
                <strong className="text-gray-900">{t("impactBenefit")}:</strong>{" "}
                {t("impactBenefitDesc")}
              </li>
              <li>
                <strong className="text-gray-900">
                  {t("flexibleBenefit")}:
                </strong>{" "}
                {t("flexibleBenefitDesc")}
              </li>
            </ul>
          </div>

          {/*  Signup  Form  */}
          <div className="bg-white  rounded-lg  shadow-md  p-8">
            <h2 className="text-2xl  font-semibold  mb-6  text-center  text-gray-800">
              {t("affiliateSignupTitle")}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("fullName")}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("email")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="whatsapp"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("whatsapp")}
                </label>
                <input
                  type="text"
                  id="whatsapp"
                  name="whatsapp"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="telegram"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("telegram")}
                </label>
                <input
                  type="text"
                  id="telegram"
                  name="telegram"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="website"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("website")}
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                  placeholder="https://example.com"
                />
              </div>

              {/*  Social  Media  Section  */}
              <div className="space-y-2">
                <label className="block  text-sm  font-medium  text-gray-700">
                  {t("socialMedia")}
                </label>
                <div className="grid  grid-cols-2  gap-2">
                  <SocialMediaInput
                    label={t("facebook")}
                    value={facebook}
                    onChange={setFacebook}
                  />
                  <SocialMediaInput
                    label={t("twitter")}
                    value={twitter}
                    onChange={setTwitter}
                  />
                  <SocialMediaInput
                    label={t("instagram")}
                    value={instagram}
                    onChange={setInstagram}
                  />
                  <SocialMediaInput
                    label={t("tiktok")}
                    value={tiktok}
                    onChange={setTiktok}
                  />
                  <SocialMediaInput
                    label={t("linkedin")}
                    value={linkedin}
                    onChange={setLinkedin}
                  />
                  <SocialMediaInput
                    label={t("otherSocial")}
                    value={otherSocial}
                    onChange={setOtherSocial}
                  />
                </div>
              </div>

              {/*  Audience  Description  */}
              <div>
                <label
                  htmlFor="audienceDescription"
                  className="block  text-sm  font-medium  text-gray-700"
                >
                  {t("audienceDescriptionLabel")}
                  <Tooltip text={t("audienceDescriptionTooltip")} />
                </label>
                <textarea
                  id="audienceDescription"
                  name="audienceDescription"
                  value={audienceDescription}
                  onChange={(e) => setAudienceDescription(e.target.value)}
                  rows="3"
                  className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                ></textarea>
              </div>

              {/*  Affiliate  Experience  */}
              <div className="flex  items-center  space-x-2">
                <input
                  type="checkbox"
                  id="hasAffiliateExperience"
                  name="hasAffiliateExperience"
                  checked={hasAffiliateExperience}
                  onChange={(e) => setHasAffiliateExperience(e.target.checked)}
                  className="h-4  w-4  text-blue-600  focus:ring-blue-500  border-gray-300  rounded"
                />
                <label
                  htmlFor="hasAffiliateExperience"
                  className="text-sm  font-medium  text-gray-700"
                >
                  {t("affiliateExperienceLabel")}
                </label>
              </div>

              {/*  Conditional  Experience  Description  */}
              {hasAffiliateExperience && (
                <div>
                  <label
                    htmlFor="affiliateExperienceDescription"
                    className="block  text-sm  font-medium  text-gray-700"
                  >
                    {t("experienceDetails")}
                  </label>
                  <textarea
                    id="affiliateExperienceDescription"
                    name="affiliateExperienceDescription"
                    value={affiliateExperienceDescription}
                    onChange={(e) =>
                      setAffiliateExperienceDescription(e.target.value)
                    }
                    rows="3"
                    className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
                  ></textarea>
                </div>
              )}

              {/*  Submit  Button  */}
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full  flex  justify-center  py-2  px-4  border  border-transparent  rounded-md  shadow-sm  text-sm  font-medium  text-white  bg-blue-600  hover:bg-blue-700  focus:outline-none  focus:ring-2  focus:ring-offset-2  focus:ring-blue-500"
                >
                  {isSubmitting ? t("submitting") : t("signUpButton")}
                </button>
              </div>
              {message && (
                <div
                  className={`mt-4  text-center  ${
                    /success|merci|thank/i.test(message)
                      ? "text-green-600  font-bold"
                      : "text-red-600"
                  }`}
                >
                  {message}
                </div>
              )}
            </form>
          </div>
        </div>

        {/*  How  it  Works  (Step-by-Step)  */}
        <div className="mt-16">
          <h2
            className="text-2xl  font-semibold  mb-4  text-center  text-gray-800"
            onClick={handleHowItWorksClick}
            style={{ cursor: "pointer" }}
          >
            {t("howItWorksTitle")}
          </h2>
          <div className="grid  grid-cols-1  md:grid-cols-3  gap-8  text-center">
            <div>
              <div className="rounded-full  bg-blue-100  text-blue-600  p-3  inline-flex  items-center  justify-center">
                {/*  Icon  for  Step  1  -  Sign  Up  */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0  0  24  24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6  h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982  18.725A7.488  7.488  0  0  0  12  15.75a7.488  7.488  0  0  0-5.982  2.975m11.963  0a9  9  0  1  0-11.963  0m11.963  0A8.966  8.966  0  0  1  12  21a8.966  8.966  0  0  1-5.982-2.275M15  9.75a3  3  0  1  1-6  0  3  3  0  0  1  6  0Z"
                  />
                </svg>
              </div>
              <h3 className="mt-4  text-lg  font-medium  text-gray-900">
                {t("step1")}
              </h3>
              <p className="mt-2  text-gray-600">{t("step1Desc")}</p>
            </div>
            <div>
              <div className="rounded-full  bg-blue-100  text-blue-600  p-3  inline-flex  items-center  justify-center">
                {/*  Icon  for  Step  2  -  Promote  */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0  0  24  24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6  h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6  12  3.269  3.125A59.769  59.769  0  0  1  21.485  12  59.768  59.768  0  0  1  3.27  20.875L5.999  12Zm0  0h7.5"
                  />
                </svg>
              </div>
              <h3 className="mt-4  text-lg  font-medium  text-gray-900">
                {t("step2")}
              </h3>
              <p className="mt-2  text-gray-600">{t("step2Desc")}</p>
            </div>
            <div>
              <div className="rounded-full  bg-blue-100  text-blue-600  p-3  inline-flex  items-center  justify-center">
                {/*  Icon  for  Step  3  -  Earn  */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0  0  24  24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6  h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25  18.75a60.07  60.07  0  0  1  15.797  2.101c.727.198  1.453-.342  1.453-1.096V18.75M3.75  4.5v.75A.75.75  0  0  1  3  6h-.75m0  0v-.375c0-.621.504-1.125  1.125-1.125H20.25M2.25  6v9m18-10.5v.75c0  .414.336.75.75.75h.75m-1.5-1.5h.375c.621  0  1.125.504  1.125  1.125v9.75c0  .621-.504  1.125-1.125  1.125h-.375m1.5-1.5H21a.75.75  0  0  0-.75.75v.75m0  0H3.75m0  0h-.375a1.125  1.125  0  0  1-1.125-1.125V15m1.5  1.5v-.75A.75.75  0  0  0  3  15h-.75M15  10.5a3  3  0  1  1-6  0  3  3  0  0  1  6  0Zm3  0h.008v.008H18V10.5Zm-12  0h.008v.008H6V10.5Z"
                  />
                </svg>
              </div>
              <h3 className="mt-4  text-lg  font-medium  text-gray-900">
                {t("step3")}
              </h3>
              <p className="mt-2  text-gray-600">{t("step3Desc")}</p>
            </div>
          </div>
        </div>

        {/*  Footer  (Optional)  */}
        <footer className="text-center  mt-12  text-gray-500">
          &copy; {new Date().getFullYear()} ProRecruit. All rights reserved.
        </footer>
      </div>
    </div>
  );
}

//Helper  Component  for  Social  Media  Input  with  Tooltip
const SocialMediaInput = ({ label, value, onChange }) => {
  

  const { t } = useTranslation("AffiliatePage"); //  Use  'global'  namespace

  // Add prop validation
  if (!label) {
    console.error("SocialMediaInput: 'label' prop is required.");
    return null; // Or some fallback UI
  }
  return (
    <div className="flex  items-center">
      <label
        htmlFor={label}
        className="w-24  text-sm  font-medium  text-gray-700"
      >
        {label}:
      </label>
      <input
        type="url"
        id={label}
        name={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="mt-1  block  w-full  px-3  py-2  border  border-gray-300  rounded-md  shadow-sm  focus:outline-none  focus:ring-blue-500  focus:border-blue-500"
        placeholder={`https://`}
      />
      <Popover className="relative">
        <Popover.Button className="ml-2  text-gray-400  hover:text-gray-500  focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0  0  24  24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6  h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25  11.25l.041-.02a.75.75  0  011.063.852l-.708  2.836a.75.75  0  001.063.853l.041-.021M21  12a9  9  0  11-18  0  9  9  0  0118  0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition  ease-out  duration-200"
          enterFrom="opacity-0  translate-y-1"
          enterTo="opacity-100  translate-y-0"
          leave="transition  ease-in  duration-150"
          leaveFrom="opacity-100  translate-y-0"
          leaveTo="opacity-0  translate-y-1"
        >
          <Popover.Panel className="absolute  z-10  w-64  bg-white  rounded-lg  shadow-lg  p-4  mt-2  text-sm  text-gray-600  border  border-gray-200">
            {t(`${label.toLowerCase()}Tooltip`)}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

//Helper  Component  for  Tooltip
const Tooltip = ({ text }) => (
  <Popover className="relative">
    <Popover.Button className="ml-2  text-gray-400  hover:text-gray-500  focus:outline-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0  0  24  24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6  h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25  11.25l.041-.02a.75.75  0  011.063.852l-.708  2.836a.75.75  0  001.063.853l.041-.021M21  12a9  9  0  11-18  0  9  9  0  0118  0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
    </Popover.Button>
    <Transition
      as={Fragment}
      enter="transition  ease-out  duration-200"
      enterFrom="opacity-0  translate-y-1"
      enterTo="opacity-100  translate-y-0"
      leave="transition  ease-in  duration-150"
      leaveFrom="opacity-100  translate-y-0"
      leaveTo="opacity-0  translate-y-1"
    >
      <Popover.Panel className="absolute  z-10  w-64  bg-white  rounded-lg  shadow-lg  p-4  mt-2  text-sm  text-gray-600  border  border-gray-200">
        {text}
      </Popover.Panel>
    </Transition>
  </Popover>
);

SocialMediaInput.prototype = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Tooltip.prototype = {
  text: PropTypes.string.isRequired,
};


export default AffiliatePage;
