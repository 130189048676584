//NotesModal.js
import React, { useState, useEffect } from 'react';
import Modal from '../components/Modal'; // Assuming you have a Modal component
import { useTranslation } from 'react-i18next';

const NotesModal = ({ isOpen, onClose, onAddNote, candidateId, tenantId }) => {
    const { t } = useTranslation('CandidateDetailsModal');
    const [noteText, setNoteText] = useState("");
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState(null);

    const handleAddNote = async () => {
        if (!noteText.trim()) {
            setError(t('candidateDetailsModal.notesModal.errorEmptyNote')); // Translate
            return;
        }

        setAdding(true);
        setError(null); // Clear previous errors.
        try {
            await onAddNote(noteText);
            setNoteText(""); // Clear the input after successful addition

        } catch (error) {
           setError(t('candidateDetailsModal.notesModal.errorAddingNote')); // Translate
            console.error("Error adding note:", error);

        }
        finally{
            setAdding(false)
        }
    };
    //reset on open
    useEffect(() => {
    if(isOpen){
        setNoteText("");
        setError(null);
    }
  },[isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="p-6">
                <h3 className="text-xl font-semibold mb-4">{t('candidateDetailsModal.notesModal.title')}</h3>
                <textarea
                    className="w-full p-2 border rounded-md mb-4"
                    placeholder={t('candidateDetailsModal.notesModal.placeholder')} // Translate
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                />
                {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                <div className="flex justify-end">

                    <button
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md mr-2"
                        onClick={onClose}
                        disabled={adding}
                    >
                       {t('candidateDetailsModal.notesModal.cancel')}
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md disabled:opacity-50"
                        onClick={handleAddNote}
                        disabled={adding}
                    >
                        {adding ? t('candidateDetailsModal.notesModal.adding') : t('candidateDetailsModal.notesModal.addNote')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default NotesModal;