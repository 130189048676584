import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faUsers,
  faBriefcase,
  faHandshake,
  faChartLine,
  faUserShield,
  faThumbsUp,
  faBullseye,
  faComments,
  faUnlock,
  faSearch,
  faStar,
  faUserFriends,
  faDatabase,
  faCalendarAlt,
  faInfinity,
  faBell,
  faEnvelope,
  faEnvelopeOpenText,
  faBrain,
  faCog,
  faCogs,
  faBook,
  faList,
  faSlidersH,
  faFileAlt,
  faSortAmountUp,
  faTrophy,
  faPalette,
  faPercentage,
  faPercent,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import TawkToChat from "../components/TawkToChat"; // Assuming you might use the chat here too.
import { useAuth } from "../useAuth";

const Pricing = () => {
  const { t, i18n } = useTranslation("Pricing"); // Correct namespace
  const [prices, setPrices] = useState(null);
  const [currency, setCurrency] = useState("XOF"); // Default currency
  const [currencySymbol, setCurrencySymbol] = useState("FCFA");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  const db = getFirestore();

  const currencies = {
    XOF: { symbol: "FCFA", label: "CFA Franc BCEAO (XOF)" },
    USD: { symbol: '$', label: "US Dollar (USD)" },
    EUR: { symbol: "€", label: "Euro (EUR)" },
    CAD: { symbol: "$", label: "Canadian Dollar (CAD)" },
    AED: { symbol: "AED", label: "United Arab Emirates Dirham (AED)" },
    AFN: { symbol: "Af", label: "Afghan Afghani (AFN)" },
    ALL: { symbol: "Lek", label: "Albanian Lek (ALL)" },
    AMD: { symbol: "AMD", label: "Armenian Dram (AMD)" },
    ANG: { symbol: "NAƒ", label: "Netherlands Antillean Guilder (ANG)" },
    AOA: { symbol: "Kz", label: "Angolan Kwanza (AOA)" },
    ARS: {
      symbol: '$',
      label: "Argentine Peso (ARS)",
    },
    AUD: { symbol: "$", label: "Australian Dollar (AUD)" },
    AWG: { symbol: "Afl.", label: "Aruban Florin (AWG)" },
    AZN: { symbol: "₼", label: "Azerbaijani Manat (AZN)" },
    BAM: { symbol: "KM", label: "Bosnia-Herzegovina Convertible Mark (BAM)" },
    BBD: {
      symbol: '$',
      label: "Barbadian Dollar (BBD)",
    },
    BDT: { symbol: "৳", label: "Bangladeshi Taka (BDT)" },
    BGN: { symbol: "лв", label: "Bulgarian Lev (BGN)" },
    BIF: { symbol: "FBu", label: "Burundian Franc (BIF)" },
    BMD: { symbol: "</span>", label: "Bermudan Dollar (BMD)" },
    BND: { symbol: '$', label: "Brunei Dollar (BND)" },
    BOB: { symbol: "Bs", label: "Bolivian Boliviano (BOB)" },
    BRL: { symbol: "R</span>", label: "Brazilian Real (BRL)" },
    BSD: {
      symbol: '$',
      label: "Bahamian Dollar (BSD)",
    },
    BWP: { symbol: "P", label: "Botswanan Pula (BWP)" },
    BYN: { symbol: "Br", label: "Belarusian Ruble (BYN)" },
    BZD: { symbol: "</span>", label: "Belize Dollar (BZD)" },
    CDF: { symbol: "Fr", label: "Congolese Franc (CDF)" },
    CHF: { symbol: "CHF", label: "Swiss Franc (CHF)" },
    CLP: { symbol: '$', label: "Chilean Peso (CLP)" },
    CNY: { symbol: "¥", label: "Chinese Yuan (CNY)" },
    COP: { symbol: "</span>", label: "Colombian Peso (COP)" },
    CRC: { symbol: "₡", label: "Costa Rican Colón (CRC)" },
    CVE: {
      symbol: '$',
      label: "Cape Verdean Escudo (CVE)",
    },
    CZK: { symbol: "Kč", label: "Czech Koruna (CZK)" },
    DJF: { symbol: "Fdj", label: "Djiboutian Franc (DJF)" },
    DKK: { symbol: "kr", label: "Danish Krone (DKK)" },
    DOP: { symbol: "</span>", label: "Dominican Peso (DOP)" },
    DZD: { symbol: "د.ج", label: "Algerian Dinar (DZD)" },
    EGP: { symbol: "E£", label: "Egyptian Pound (EGP)" },
    ETB: { symbol: "Br", label: "Ethiopian Birr (ETB)" },
    FJD: { symbol: '$', label: "Fijian Dollar (FJD)" },
    FKP: { symbol: "£", label: "Falkland Islands Pound (FKP)" },
    GBP: { symbol: "£", label: "British Pound Sterling (GBP)" },
    GEL: { symbol: "₾", label: "Georgian Lari (GEL)" },
    GIP: { symbol: "£", label: "Gibraltar Pound (GIP)" },
    GMD: { symbol: "D", label: "Gambian Dalasi (GMD)" },
    GNF: { symbol: "Fr", label: "Guinean Franc (GNF)" },
    GTQ: { symbol: "Q", label: "Guatemalan Quetzal (GTQ)" },
    GYD: { symbol: "</span>", label: "Guyanaese Dollar (GYD)" },
    HKD: {
      symbol: '$',
      label: "Hong Kong Dollar (HKD)",
    },
    HNL: { symbol: "L", label: "Honduran Lempira (HNL)" },
    HRK: { symbol: "kn", label: "Croatian Kuna (HRK)" },
    HTG: { symbol: "G", label: "Haitian Gourde (HTG)" },
    HUF: { symbol: "Ft", label: "Hungarian Forint (HUF)" },
    IDR: { symbol: "Rp", label: "Indonesian Rupiah (IDR)" },
    ILS: { symbol: "₪", label: "Israeli New Sheqel (ILS)" },
    INR: { symbol: "₹", label: "Indian Rupee (INR)" },
    ISK: { symbol: "kr", label: "Icelandic Króna (ISK)" },
    JMD: { symbol: "</span>", label: "Jamaican Dollar (JMD)" },
    JPY: { symbol: "¥", label: "Japanese Yen (JPY)" },
    KES: { symbol: "KSh", label: "Kenyan Shilling (KES)" },
    KGS: { symbol: "с", label: "Kyrgystani Som (KGS)" },
    KHR: { symbol: "៛", label: "Cambodian Riel (KHR)" },
    KMF: { symbol: "Fr", label: "Comorian Franc (KMF)" },
    KRW: { symbol: "₩", label: "South Korean Won (KRW)" },
    KYD: {
      symbol: '$',
      label: "Cayman Islands Dollar (KYD)",
    },
    KZT: { symbol: "₸", label: "Kazakhstani Tenge (KZT)" },
    LAK: { symbol: "₭", label: "Laotian Kip (LAK)" },
    LBP: { symbol: "L£", label: "Lebanese Pound (LBP)" },
    LKR: { symbol: "Rs", label: "Sri Lankan Rupee (LKR)" },
    LRD: { symbol: "</span>", label: "Liberian Dollar (LRD)" },
    LSL: { symbol: "L", label: "Lesotho Loti (LSL)" },
    MAD: { symbol: "MAD", label: "Moroccan Dirham (MAD)" },
    MDL: { symbol: "L", label: "Moldovan Leu (MDL)" },
    MGA: { symbol: "Ar", label: "Malagasy Ariary (MGA)" },
    MKD: { symbol: "ден", label: "Macedonian Denar (MKD)" },
    MMK: { symbol: "K", label: "Myanmar Kyat (MMK)" },
    MNT: { symbol: "₮", label: "Mongolian Tugrik (MNT)" },
    MOP: { symbol: "P", label: "Macanese Pataca (MOP)" },
    MRU: { symbol: "UM", label: "Mauritanian Ouguiya (MRU)" },
    MUR: { symbol: "₨", label: "Mauritian Rupee (MUR)" },
    MVR: { symbol: "ރ.", label: "Maldivian Rufiyaa (MVR)" },
    MWK: { symbol: "MK", label: "Malawian Kwacha (MWK)" },
    MXN: { symbol: '$', label: "Mexican Peso (MXN)" },
    MYR: { symbol: "RM", label: "Malaysian Ringgit (MYR)" },
    MZN: { symbol: "MT", label: "Mozambican Metical (MZN)" },
    NAD: { symbol: "</span>", label: "Namibian Dollar (NAD)" },
    NGN: { symbol: "₦", label: "Nigerian Naira (NGN)" },
    NIO: { symbol: "C$", label: "Nicaraguan Córdoba (NIO)" },
    NOK: { symbol: "kr", label: "Norwegian Krone (NOK)" },
    NPR: { symbol: "₨", label: "Nepalese Rupee (NPR)" },
    NZD: {
      symbol: '$',
      label: "New Zealand Dollar (NZD)",
    },
    PAB: { symbol: "B/.", label: "Panamanian Balboa (PAB)" },
    PEN: { symbol: "S/.", label: "Peruvian Sol (PEN)" },
    PGK: { symbol: "K", label: "Papua New Guinean Kina (PGK)" },
    PHP: { symbol: "₱", label: "Philippine Peso (PHP)" },
    PKR: { symbol: "₨", label: "Pakistani Rupee (PKR)" },
    PLN: { symbol: "zł", label: "Polish Zloty (PLN)" },
    PYG: { symbol: "₲", label: "Paraguayan Guarani (PYG)" },
    QAR: { symbol: "ر.ق", label: "Qatari Riyal (QAR)" },
    RON: { symbol: "lei", label: "Romanian Leu (RON)" },
    RSD: { symbol: "din", label: "Serbian Dinar (RSD)" },
    RUB: { symbol: "₽", label: "Russian Ruble (RUB)" },
    RWF: { symbol: "FRw", label: "Rwandan Franc (RWF)" },
    SAR: { symbol: "ر.س", label: "Saudi Riyal (SAR)" },
    SBD: { symbol: "</span>", label: "Solomon Islands Dollar (SBD)" },
    SCR: { symbol: "₨", label: "Seychellois Rupee (SCR)" },
    SEK: { symbol: "kr", label: "Swedish Krona (SEK)" },
    SGD: {
      symbol: '$',
      label: "Singapore Dollar (SGD)",
    },
    SHP: { symbol: "£", label: "Saint Helena Pound (SHP)" },
    SLE: { symbol: "Le", label: "Sierra Leonean Leone (SLE)" },
    SOS: { symbol: "Sh", label: "Somali Shilling (SOS)" },
    SRD: { symbol: "</span>", label: "Surinamese Dollar (SRD)" },
    STD: { symbol: "Db", label: "São Tomé and Príncipe Dobra (STD)" },
    SZL: { symbol: "L", label: "Swazi Lilangeni (SZL)" },
    THB: { symbol: "฿", label: "Thai Baht (THB)" },
    TJS: { symbol: "ЅМ", label: "Tajikistani Somoni (TJS)" },
    TOP: { symbol: "T$", label: "Tongan Paʻanga (TOP)" },
    TRY: { symbol: "₺", label: "Turkish Lira (TRY)" },
    TTD: {
      symbol: '$',
      label: "Trinidad and Tobago Dollar (TTD)",
    },
    TWD: { symbol: "NT$", label: "New Taiwan Dollar (TWD)" },
    TZS: { symbol: "TSh", label: "Tanzanian Shilling (TZS)" },
    UAH: { symbol: "₴", label: "Ukrainian Hryvnia (UAH)" },
    UGX: { symbol: "USh", label: "Ugandan Shilling (UGX)" },
    UYU: {
      symbol: '$',
      label: "Uruguayan Peso (UYU)",
    },
    UZS: { symbol: "som", label: "Uzbekistani Som (UZS)" },
    VES: { symbol: "Bs", label: "Venezuelan Bolívar (VES)" },
    VND: { symbol: "₫", label: "Vietnamese Dong (VND)" },
    VUV: { symbol: "Vt", label: "Vanuatu Vatu (VUV)" },
    WST: { symbol: "T", label: "Samoan Tala (WST)" },
    XAF: { symbol: "FCFA", label: "Central African CFA Franc (XAF)" },
    XCD: { symbol: "$", label: "East Caribbean Dollar (XCD)" },
    XPF: { symbol: "₣", label: "CFP Franc (XPF)" },
    YER: { symbol: "﷼", label: "Yemeni Rial (YER)" },
    ZAR: { symbol: "R", label: "South African Rand (ZAR)" },
    ZMW: { symbol: "ZK", label: "Zambian Kwacha (ZMW)" },
  };

  // Fetch prices and user's country on component mount
  useEffect(() => {
    const fetchPricingAndCurrency = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch prices
        const basicPriceDoc = await getDoc(doc(db, "price", "basic"));
        const proPriceDoc = await getDoc(doc(db, "price", "pro"));
        const premiumPriceDoc = await getDoc(doc(db, "price", "premium")); // Add premium
        const subscriptionPriceDoc = await getDoc(
          doc(db, "price", "subscription")
        ); //Add sub

        if (
          !basicPriceDoc.exists() ||
          !proPriceDoc.exists() ||
          !premiumPriceDoc.exists() ||
          !subscriptionPriceDoc.exists()
        ) {
          throw new Error("Pricing data not found in Firestore.");
        }

        setPrices({
          basic: basicPriceDoc.data().price,
          pro: proPriceDoc.data().price,
          premium: premiumPriceDoc.data().price,
          subscription: subscriptionPriceDoc.data().price,
        });

        // Set currency based on user
        let userCurrency = "XOF"; // Default to XOF

        if (currentUser) {
          // If user is logged in, fetch currency preference from their document
          const userDocRef = doc(db, "users", currentUser.email); // Assuming email is the user ID
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data().userData;
            userCurrency = userData.companyCountry?.currency || "XOF";
          } else {
            console.warn(
              "User data not found for authenticated user:",
              currentUser.uid
            );
            //keep default XOF
          }
        } else {
          // No user logged in, use IP-based detection as fallback
          try {
            const ipResponse = await fetch("https://ipapi.co/json/");
            if (!ipResponse.ok) {
              console.warn("IP API request failed. Status:", ipResponse.status);
              // Keep default currency, or set to USD.
            } else {
              const ipData = await ipResponse.json();
              const userCountryCode = ipData?.country || "TG"; // Default to Togo (TG) if no country found
              userCurrency = getCurrencyFromCountryCode(userCountryCode);
            }
          } catch (ipError) {
            console.warn("IP API request failed", ipError);
          }
        }
        if (currencies[userCurrency.toUpperCase()]) {
          setCurrency(userCurrency.toUpperCase()); // Use uppercase
          setCurrencySymbol(currencies[userCurrency.toUpperCase()].symbol);
        } else {
          setCurrency("USD"); // Set to USD if not found or not supported
          setCurrencySymbol("$");
        }
      } catch (error) {
        console.error("Error fetching pricing or currency:", error);
        setError("Failed to load pricing information. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPricingAndCurrency();
  }, [currentUser, db]);

  const getCurrencyFromCountryCode = (countryCode) => {
    const countryCurrencyMap = {
      TG: "XOF", // Togo
      BJ: "XOF", // Benin
      BF: "XOF", // Burkina Faso
      CI: "XOF", // Côte d'Ivoire
      GW: "XOF", // Guinea-Bissau
      ML: "XOF", // Mali
      NE: "XOF", // Niger
      SN: "XOF", // Senegal
      // ... add other countries and their currencies ...
      US: "USD",
      FR: "EUR", // France
      DE: "EUR", // Germany
      // ... etc.
    };
    return countryCurrencyMap[countryCode] || "USD"; // Default to USD if not found
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setCurrencySymbol(currencies[event.target.value].symbol);
  };

  const formatPrice = (priceData, selectedCurrency) => {
    const lowerCaseCurrency = selectedCurrency.toLowerCase();
    if (priceData && typeof priceData[lowerCaseCurrency] === "number") {
      const price = priceData[lowerCaseCurrency];
      if (lowerCaseCurrency === "xof") {
        return price.toLocaleString("fr-FR");
      }
      return price.toFixed(2); // Always 2 decimal places for USD, EUR, etc.
    }
    return priceData?.usd
      ? (priceData.usd * (currency === "XOF" ? 1 : 100)).toFixed(2)
      : "N/A"; // Handle the case if prices are not available
  };

  // Helper function for subscription price
  const formatSubscriptionPrice = (priceData, selectedCurrency) => {
    const lowerCaseCurrency = selectedCurrency.toLowerCase();
    if (
      priceData &&
      typeof priceData[lowerCaseCurrency] === "object" &&
      typeof priceData[lowerCaseCurrency].basePrice === "number" &&
      typeof priceData[lowerCaseCurrency].perJobPercentage === "number"
    ) {
      const basePrice = priceData[lowerCaseCurrency].basePrice;
      const perJob = priceData[lowerCaseCurrency].perJobPercentage;
      if (lowerCaseCurrency === "xof") {
        return `${basePrice.toLocaleString("fr-FR")} + ${perJob}${t("perJobPercentage")}`;
      }
      return `${basePrice.toFixed(2)} + ${perJob}${t(
        "perJobPercentage"
      )}`;
    }
    return "N/A";
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    ); // Or a loading spinner
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!prices) {
    return <div className="text-center">Pricing data not available.</div>;
  }

  return (
    <div className="bg-gray-100 py-12 font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t("pricingTitle")}
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            {t("pricingDescription1")}
          </p>
          <p className="mt-2 text-gray-600">{t("pricingDescription2")}</p>
        </div>

        {/* Currency Selector */}
        <div className="text-center mt-6">
          <label htmlFor="currency" className="mr-2 font-medium text-gray-700">
            {t("selectCurrency")}:
          </label>
          <select
            id="currency"
            name="currency"
            value={currency}
            onChange={handleCurrencyChange}
            className="border rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {Object.entries(currencies).map(([code, { label }]) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Basic Package */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col">
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div className="text-center">
                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                  {t("basicPackage")}
                </h3>
              </div>
              <div className="mt-4 flex justify-center text-4xl font-extrabold text-gray-900">
                <span>{currencySymbol}</span>
                <span className="ml-1">
                  {formatPrice(prices.basic, currency)}
                </span>
              </div>
            </div>
            <div className="flex-1 px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <ul className="list-none space-y-4">
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("basicFeature1")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faDatabase}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("basicFeature2")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("basicFeature3")}
                  </span>
                </li>
              </ul>
              <div className="mt-6 text-center">
                <Link to={currentUser ? "/checkout" : "/signup"}>
                  <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {currentUser ? t("buyNow") : t("tryFree")}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* Professional Package - Featured */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden relative hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col">
            <div className="absolute top-0 right-0 mt-4 mr-4">
              <span className="px-2.5 py-0.5 rounded-full text-xs font-semibold bg-yellow-400 text-white">
                ★ {t("mostPopular")}
              </span>
            </div>
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div className="text-center">
                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                  {t("professionalPackage")}
                </h3>
              </div>
              <div className="mt-4 flex justify-center text-4xl font-extrabold text-gray-900">
                <span>{currencySymbol}</span>
                <span className="ml-1">
                  {formatPrice(prices.pro, currency)}
                </span>
              </div>
            </div>
            <div className="flex-1 px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <ul className="list-none space-y-4">
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("basicPackIncluded")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faInfinity}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("unlimitedApplications")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faEnvelopeOpenText}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("automaticEmailNotification")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faBrain}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("psychoanalyticAssessments")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faList}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("technicalAssessments")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faSlidersH}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("createAssessments")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faSortAmountUp}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("automaticCVAnalysis")}
                  </span>
                </li>
              </ul>
              <div className="mt-6 text-center">
                <Link to={currentUser ? "/checkout" : "/signup"}>
                  <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {currentUser ? t("buyNow") : t("tryFree")}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* Premium Package */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col">
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div className="text-center">
                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                  {t("premiumPackage")}
                </h3>
              </div>
              <div className="mt-4 flex justify-center text-4xl font-extrabold text-gray-900">
                <span>{currencySymbol}</span>
                <span className="ml-1">
                  {formatPrice(prices.premium, currency)}
                </span>
              </div>
            </div>
            <div className="flex-1 px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <ul className="list-none space-y-4">
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("professionalPackIncluded")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">{t("sourcing")}</span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faPalette}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("brandedApplicationPage")}
                  </span>
                </li>
              </ul>
              <div className="mt-6 text-center">
                <Link to={currentUser ? "/checkout" : "/signup"}>
                  <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {currentUser ? t("buyNow") : t("tryFree")}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* Subscription Package */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col">
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div className="text-center">
                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                  {t("subscriptionPack")}
                </h3>
              </div>
              <div className="mt-4 flex justify-center text-3xl font-extrabold text-gray-900">
                <span>{currencySymbol}</span>
                <span className="ml-1">
                  {formatSubscriptionPrice(prices.subscription, currency)}
                </span>
              </div>
            </div>
            <div className="flex-1 px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <ul className="list-none space-y-4">
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("professionalPackIncluded")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faPercent}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("discountOnJobPostings")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faUserTie}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("dedicatedAccountManager")}
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faInfinity}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-700">
                    {t("unlimitedDataAccess")}
                  </span>
                </li>
              </ul>
              <div className="mt-6 text-center">
                <Link to={currentUser ? "/checkout" : "/signup"}>
                  <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {currentUser ? t("buyNow") : t("tryFree")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <TawkToChat />
      </div>
    </div>
  );
};

export default Pricing;
