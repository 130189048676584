/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
// Remove TawkToChat import if not essential for the dashboard
// import TawkToChat from "../components/TawkToChat";  // Consider if this is truly necessary on the *dashboard*.
import * as Sentry from "@sentry/react";
import { analytics, db, Timestamp } from "../firebase-config";
import { useSelector, useDispatch } from "react-redux";
import { logEvent } from "firebase/analytics";
import { sanitizeInput } from "../utils/inputSanitizer";
import { useToast } from "../components/Alerts";
import PropTypes from "prop-types";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import AssignJobOfferModal from "../components/AssignJobOfferModal";
import UsersTable from "../components/UsersTable";
import AssessmentsTable from "../components/AssessmentsTable";
import {
  fetchJobPostings,
  selectJobPostings,
  selectJobPostingsLoading,
  selectJobPostingsError,
} from "../utils/redux/jobPostingsSlice";
import {
  selectAllUsers,
  selectUserByEmail,
  fetchUserByEmail,
  fetchAllUsers,
} from "../utils/redux/userDataSlice";
import CreateJobModal from "../components/createJobModal";
import LicenseRequiredModal from "../components/LicenseRequiredModal";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";
import {
  fetchAssessments,
  selectAssessments,
} from "../utils/redux/assessmentSlice";
import { useAuth } from "../useAuth";
import { useNavigate } from "react-router-dom";
//Import for spinner
// import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next"; // Import useTranslation
import useDateUtils from "../utils/fromStringToTimestamp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// --- Helper function for consistent button styling ---
const ActionButton = ({
  children,
  onClick,
  color = "blue",
  disabled = false,
}) => (
  <button
    className={`px-4 py-2 rounded-md text-white font-semibold transition duration-200 ease-in-out ${
      disabled
        ? "bg-gray-400 cursor-not-allowed"
        : `bg-${color}-500 hover:bg-${color}-700`
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const DashboardPage = () => {
  const { stringToTimestamp } = useDateUtils();
  const { currentUser } = useAuth();
  const jobPostings = useSelector(selectJobPostings);
  const loading = useSelector(selectJobPostingsLoading);
  const error = useSelector(selectJobPostingsError);
  const assessments = useSelector(selectAssessments);
  const [activeTab, setActiveTab] = useState("users");
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [newJobData, setNewJobData] = useState({});
  const [disableButtons, setDisableButtons] = useState(false);
  const userData = useSelector((state) =>
    selectUserByEmail(state, currentUser.email)
  );
  const usersData = useSelector((state) =>
    selectAllUsers(state, userData ? userData.tenantId : "")
  );
  const companyData = useSelector(selectAllCompanys);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  //Loading state
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation("DashboardPage");
  const { addToast } = useToast();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    document.title = t("dashboardTitle"); // More concise title
    return () => {
      document.title = t("defaultTitle");
    };
  }, [t]);

  useEffect(() => {
    setIsLoading(true); // Start loading
    const fetchData = async () => {
      if (currentUser) {
        const u = await dispatch(fetchUserByEmail(currentUser.email));
        const all = await dispatch(fetchAllUsers(u.payload.tenantId));
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
        const a = await dispatch(
          fetchAssessments({ tenantId: u.payload.tenantId })
        );
        setProLicenseCount(c.payload[0].proLicense);
        setBasicLicenseCount(c.payload[0].basicLicense);
        setHasAnyLicense(
          (c.payload[0]?.proLicense ?? 0) + (c.payload[0]?.basicLicense ?? 0) 
          + (c.payload[0]?.trialLicense ?? 0) > 0
        );
        setIsLoading(false); // Data fetching complete
      }
    };
    fetchData();
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (companyData && userData) {
      setIsLoading(true);
      setProLicenseCount(companyData[0]?.proLicense ?? 0);
      setBasicLicenseCount(companyData[0]?.basicLicense ?? 0);
      dispatch(fetchJobPostings({ tenantId: userData.tenantId })).then(() =>
        setIsLoading(false)
      );
    }
  }, [userData, companyData, dispatch]);

  const showSuccessToast = (m) => {
    addToast({
      type: "success",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };
  const showErrorToast = (m) => {
    addToast({
      type: "error",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleBuyMoreLicensesClick = () => {
    navigate("/checkout");
  };

  const handleCreateJobChange = (e) => {
    if (e && e.target) {
      setNewJobData({ ...newJobData, [e.target.name]: e.target.value });
    } else {
      // Handle react-select change for assessments, ensuring it's always an array
      setNewJobData((prevData) => ({
        ...prevData,
        assessments: Array.isArray(e) ? e : e ? [e] : [], // Correctly handle null/undefined
      }));
    }
  };

  const handleGetLicense = () => {
    navigate("/checkout"); // Use navigate for consistency
  };

  const handleLearnMore = () => {
    navigate("/pricing"); // Use navigate for consistency
  };

  const generateShortCode = async (docRef) => {
    try {
      const applicationUrlIdDocRef = doc(db, "job", "5tdpJAv6JNwmawkDKPSH");
      const applicationUrlIdDoc = await getDoc(applicationUrlIdDocRef);

      if (applicationUrlIdDoc.exists()) {
        const data = applicationUrlIdDoc.data();
        let applicationUrlId = Math.random().toString(36).substring(2, 8);
        if (!data[applicationUrlId]) {
          await setDoc(
            applicationUrlIdDocRef,
            { [applicationUrlId]: docRef },
            { merge: true }
          );
          return applicationUrlId;
        }
      } else {
        const error = new Error("Document '5tdpJAv6JNwmawkDKPSH' not found!");
        console.error(error.message);

        throw error; // Re-throw the error for consistent handling
      }
    } catch (error) {
      console.error(error.message);
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
    }
  };

  const handleCreateJobSubmit = async (event) => {
    event.preventDefault();
    setDisableButtons(true);
    setIsLoading(true); // Start loading
    console.log(event.target)

    try {
      const formData = new FormData(event.target);
      const newJobDataObject = Object.fromEntries(formData.entries());
      const sanitizedJobData = Object.fromEntries(
        Object.entries(newJobDataObject).map(([key, value]) => [
          key,
          sanitizeInput(value),
        ])
      );

      logEvent(analytics, "select_content", {
        content_type: "handleCreateJobSubmit",
        content_id: newJobDataObject?.Titre || "",
      });

      const docRef = doc(
        collection(db, `job-descriptions/${userData.tenantId}/active`)
      );
      console.log(sanitizedJobData)

      if (!sanitizedJobData.jobOfferType){
        sanitizedJobData.jobOfferType = "Free"
      }

      const applicationUrlId = await generateShortCode(docRef);
      var licenseUpdateJson = {};
      if (sanitizedJobData.jobOfferType.toLowerCase().includes("basic")) {
        licenseUpdateJson = {
          "companyData.basicLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "basic";
      }

      if (sanitizedJobData.jobOfferType.toLowerCase().includes("pro")) {
        licenseUpdateJson = {
          "companyData.proLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "pro";
      }
      if (sanitizedJobData.jobOfferType.toLowerCase().includes("free")) {
        licenseUpdateJson = {
          "companyData.trialLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "free";
      }

      const jobPosting = {
        licenseType: sanitizedJobData.type,
        JobID: applicationUrlId,
        resumeAnalysisQuota:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 10
            : 3,
        resumeLimit:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 10
            : 1000,
        type: sanitizedJobData.type,
        uuid: docRef.id,
        Titre: sanitizedJobData.Titre || "",
        Entreprise: sanitizedJobData.Entreprise,
        EntrepriseDescription: sanitizedJobData.DescriptionDeLentreprise,
        Lieu: sanitizedJobData.Lieu,
        TypeDePoste: sanitizedJobData.TypeDePoste,
        Secteur: sanitizedJobData.Secteur,
        CategorieDeRole: sanitizedJobData.CategorieDeRole,
        Description: sanitizedJobData.Description,
        Qualifications: sanitizedJobData.Qualifications,
        NiveauDExperience: sanitizedJobData.NiveauDExperience,
        CompetencesRequises: sanitizedJobData.CompetencesRequises
          ? sanitizedJobData.CompetencesRequises.split(",")
          : [],
        additionalInformation: sanitizedJobData.additionalInformation,
        // FourchetteDeSalaire: sanitizedJobData.FourchetteDeSalaire,
        Avantages: sanitizedJobData.Avantages
          ? sanitizedJobData.Avantages.split(",")
          : [],
        DateLimiteDeCandidature: stringToTimestamp(
          sanitizedJobData.DateLimiteDeCandidature
        ),
        PublicationDate: stringToTimestamp(sanitizedJobData.DateDePublication),
        ProcedureDeCandidature: "",
        minimumRelevantYearsOfExperience:
          newJobDataObject.minimumRelevantYearsOfExperience,
        linkToApply: `${process.env.REACT_APP_BASE_URL}jobs/${applicationUrlId}`,
        Responsibilities: sanitizedJobData.Responsibilities || "",
        InformationsDeContact: {
          NomDuContactRH: sanitizedJobData.NomDuContactRH,
          Email: sanitizedJobData.Email,
          Telephone: sanitizedJobData.Telephone,
        },
        CreatedBy: {
          fullName: userData.displayName || "",
          email: userData.email,
          uuid: userData.uuid || "",
        },
        createdOn: serverTimestamp(),
        updatedOn: serverTimestamp(),
        Statut: "Submissions",
        tenantId: userData.tenantId,
        language: sanitizedJobData.language,
        // LogoDeLentreprise: sanitizedJobData.LogoDeLentreprise,
        // DeclarationDeDiversite: sanitizedJobData.DeclarationDeDiversite,
      };

      await setDoc(docRef, jobPosting);
      const companyRef = doc(collection(db, `company`), `${userData.tenantId}`);
      await updateDoc(companyRef, licenseUpdateJson);

      logEvent(analytics, "createdJobPosting", {
        tenantId: userData.tenantId,
        jobId: jobPosting.uuid,
        email: userData.email,
      });

      // Consider using a library like `axios` for cleaner API calls
      /* await fetch(`${API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}job-description-txt-builder`,
          payload: jobPosting,
        }),
      }); */
      showSuccessToast(t("jobCreatedSuccess"));
      setDisableButtons(false);
      // alert(t("jobCreatedSuccess"));
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating job posting:", error);
      setDisableButtons(false);
      setIsLoading(false); // Stop loading
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      showErrorToast(t("createJobError"));
    }
  };

  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const toggleCreateModal = () => {
      logEvent(analytics, "select_content", {
        content_type: "ClickCreateJobPosting",
        content_id: "New",
      });
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };

  const handleEditCompany = () => {
    navigate("/company-edit");
  };

  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      {" "}
      {/* Use a consistent font (e.g., system fonts for Apple-like feel) */}{" "}
      <Navbar
        // onSignOut={handleSignOut}
        onCreateJob={toggleCreateModal}
        userData={userData}
        extraButton={{ label: "Job Offers", path: "/jobpostings", title: "jobOfferLabel" }}
      />
      {/* Main Content Area */}{" "}
      <div className="container mx-auto px-4 py-8">
        {/* License Modal */}{" "}
        {isLicenseModalOpen && (
          <LicenseRequiredModal
            isOpen={isLicenseModalOpen}
            onClose={() => setIsLicenseModalOpen(false)}
            onGetLicense={handleGetLicense}
            onLearnMore={handleLearnMore}
          />
        )}
        {/* Create Job Modal */}{" "}
        <CreateJobModal
          isOpen={isCreateModalOpen}
          onClose={toggleCreateModal}
          onSubmit={handleCreateJobSubmit}
          onChange={handleCreateJobChange}
          data={newJobData}
          disableButtons={disableButtons}
          assessments={assessments}
        />
        {/* License Summary Cards -  Refined styling */}{" "}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {" "}
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            {" "}
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              {t("basicLicenses")}{" "}
            </h3>{" "}
            <p className="text-gray-600 mb-4">
              {" "}
              {t("licenseCount", {
                count: basicLicenseCount,
                type: "basic",
              })}{" "}
            </p>{" "}
            <ActionButton onClick={handleBuyMoreLicensesClick} color="lime">
              {t("buyMore")}{" "}
            </ActionButton>{" "}
          </div>{" "}
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            {" "}
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              {t("proLicenses")}{" "}
            </h3>{" "}
            <p className="text-gray-600 mb-4">
              {" "}
              {t("licenseCount", { count: proLicenseCount, type: "pro" })}{" "}
            </p>{" "}
            <ActionButton onClick={handleBuyMoreLicensesClick} color="lime">
              {t("buyMore")}{" "}
            </ActionButton>{" "}
          </div>{" "}
        </div>
        {/* Edit company button */}
        {userData && userData.canUpdateCompany && (
          <div className="mb-4">
            <ActionButton onClick={handleEditCompany} color="lime">
              {t("editCompany")}
            </ActionButton>
          </div>
        )}
        {/* Tabs - More Apple-like styling */}{" "}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          {" "}
          <div className="flex border-b border-gray-200">
            {" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "users"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("users")}
            >
              {t("usersTab")}{" "}
            </button>{" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "jobOffers"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("jobOffers")}
            >
              {t("jobOffersTab")}{" "}
            </button>{" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "Assessments"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("Assessments")}
            >
              {t("assessmentsTab")}{" "}
            </button>{" "}
          </div>
          {/* Conditionally render loading indicator or content */}{" "}
          {isLoading ? (
            <div className="flex justify-center items-center p-8">
              {" "}
              <ClipLoader
                color={"#3B82F6"}
                loading={isLoading}
                size={50}
              />{" "}
            </div>
          ) : (
            <>
              {" "}
              {/* Tab Content - Using components for better organization */}{" "}
              {activeTab === "users" && (
                <UsersTable adminInfo={userData} data={usersData} />
              )}{" "}
              {activeTab === "jobOffers" && (
                <JobOffersTable
                  data={jobPostings}
                  onOpenAssignModal={handleOpenAssignModal}
                />
              )}{" "}
              {activeTab === "Assessments" && (
                <AssessmentsTable
                  data={assessments}
                  adminInfo={userData}
                  onOpenAssignModal={handleOpenAssignModal}
                />
              )}{" "}
            </>
          )}{" "}
        </div>
        {/* Assign Job Offers Modal */}{" "}
        <AssignJobOfferModal
          isOpen={isAssignModalOpen}
          onClose={handleCloseAssignModal}
          jobOffers={jobPostings}
          users={usersData}
          userData={userData}
        />{" "}
      </div>{" "}
    </div>
  );
};

// --- Improved JobOffersTable Component ---
const JobOffersTable = ({ data, onOpenAssignModal }) => {
  const { t } = useTranslation("DashboardPage");
  const navigate = useNavigate();

  const handleEdit = (jobId, tenantId) => {
    navigate(`/jobpostings/${jobId}/tenants/${tenantId}`);
  };

  return (
    <div className="overflow-x-auto">
      {/* overflow-x-auto for horizontal scrolling on small screens */}{" "}
      <table className="min-w-full bg-white border border-gray-200">
        {" "}
        <thead className="bg-gray-50">
          {" "}
          <tr>
            {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableId")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableTitle")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableRecruiter")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableStatus")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableDeadline")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableLicense")}{" "}
            </th>{" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("tableOptions")}{" "}
            </th>{" "}
          </tr>{" "}
        </thead>{" "}
        <tbody className="bg-white divide-y divide-gray-200">
          {" "}
          {data.map((jobPosting) => (
            <tr key={jobPosting.uuid} className="hover:bg-gray-50">
              {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {jobPosting.JobID}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {jobPosting.Titre}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {jobPosting.InformationsDeContact.Email}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {jobPosting.Statut}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {" "}
                {new Date(
                  jobPosting.DateLimiteDeCandidature
                ).toLocaleDateString("fr-FR")}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {jobPosting.type}{" "}
              </td>{" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                {" "}
                <ActionButton
                  onClick={() =>
                    handleEdit(jobPosting.uuid, jobPosting.tenantId)
                  }
                  color="lime"
                >
                  {t("editButton")}{" "}
                </ActionButton>{" "}
                {/*  <ActionButton
                  onClick={onOpenAssignModal} // Consider if Assign should be per-row or separate
                  color="blue"
                >
                  Assign
                </ActionButton>*/}{" "}
              </td>{" "}
            </tr>
          ))}{" "}
        </tbody>{" "}
      </table>{" "}
    </div>
  );
};

export default DashboardPage;
