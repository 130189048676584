// /utils/redux/candidateSlice.js

import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const candidatesAdapter = createEntityAdapter({
  selectId: (candidate) => candidate.uuid,
  sortComparer: (a, b) => b.Score - a.Score,
});

// Async Thunk for fetching candidates
export const fetchCandidates = createAsyncThunk(
  "candidates/fetchCandidates",
  async ({ tenantID, jobID }) => {
    const response = await fetch(
      `${API_ENDPOINT}api/v1/tenants/${tenantID}/jobpostings/${jobID}/applications`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await response.json();
    if (data.user) {
      console.log(data)
      console.log(tenantID, jobID )
      return { allCandidates: data.user, candidates: data.user, selectedCandidates: data.user, tenantId: tenantID, jobUuid: jobID };
    } else {
      throw new Error("Candidates not found or invalid response.");
    }
  }
);

// Async Thunk for updating candidate status
export const updateCandidateStatus = createAsyncThunk(
  "candidates/updateCandidateStatus",
  async ({ candidate, newStatus, tenantId, jobUuid }) => {
    const data = {
      ContactInformation: candidate.ContactInformation,
      FullName: candidate.FullName,
    };
    const response = await fetch(
      `${API_ENDPOINT}/api/v1/tenants/${tenantId}/jobpostings/${jobUuid}/applications/${candidate.uuid}/status/${newStatus}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status === 200) {
      return { candidateId: candidate.uuid, newStatus };
    } else {
      throw new Error("Failed to update candidate status");
    }
  }
);

// Async Thunk for downloading documents
export const downloadDocument = createAsyncThunk(
  "candidates/downloadDocument",
  async ({ url, type }) => {
    var f = url.split("/");
    var fn = f[f.length - 1];
    const response = await fetch(
      `${API_ENDPOINT}/api/v1/storages/downloads/${fn}/type/${type}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "GET",
      }
    );

    if (response.status === 200) {
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = `${url}.${blob.type.split("/")[1]}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      throw new Error(`Error downloading document: ${response.statusText}`);
    }
  }
);

const initialState = candidatesAdapter.getInitialState({
  loading: false,
  error: null,
  selectedTab: "all",
  sortBy: "Score",
  filterKey: "FullName",
  filterValue: "",
  isCandidateDetailsModalOpen: false,
  selectedCandidates: [],
  selectedCandidate:{},
  allCandidates:[],
  candidates:[],
  currentJob: null,
  tenantId: null,
  jobUuid: null,
  downloading: false,
  downloadError: null,
});

const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload.tab;
      if (action.payload.tab === "all") {
        console.log(action.payload.tab)
        console.log(state.allCandidates)
        state.selectedCandidates = [...state.allCandidates];
        console.log(state.selectedCandidates)
      } else {
        console.log(action.payload.tab)
        console.log(action.payload.allCandidates)
        state.selectedCandidates = state.allCandidates.filter(
          (item) => item.ApplicationStatus === action.payload.tab
        );
        console.log(state.selectedCandidates)
      }
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortKey;
      state.selectedCandidates = [...state.selectedCandidates].sort((a, b) => {
        if (a[action.payload.sortKey] < b[action.payload.sortKey]) return 1;
        if (a[action.payload.sortKey] > b[action.payload.sortKey]) return -1;
        return 0;
      });
    },
    setFilterKey: (state, action) => {
      state.filterKey = action.payload;
    },
    setFilterValue: (state, action) => {
      console.log(action.payload.filterKey)
      console.log(action.payload.filterValue)
      state.filterValue = action.payload.filterValue;
      if (action.payload.filterValue === "" || action.payload.filterValue === undefined ) {
        return state.selectedCandidates = state.allCandidates;
      } else {
        state.selectedCandidates = state.allCandidates.filter((item) =>{
          if(action.payload.filterKey === "ContactInformation.Email"){
            console.log("email");
            console.log(item.ContactInformation.Email);
            return (item.ContactInformation.Email.toLowerCase()).includes(action.payload.filterValue.toLowerCase())

          }else{
            console.log("not email");
            return (item[action.payload.filterKey].toLowerCase()).includes(action.payload.filterValue.toLowerCase())

          }}
        );
      }
    },
    openCandidateDetailsModal: (state, action) => {
      console.log("openCandidateDetailsModal", action.payload);
      state.isCandidateDetailsModalOpen = true;
      state.selectedCandidate = action.payload;
    },
    closeCandidateDetailsModal: (state) => {
      state.isCandidateDetailsModalOpen = false;
      state.selectedCandidate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidates.fulfilled, (state, action) => {
        console.log(action)
        state.loading = false;
        candidatesAdapter.setAll(state, action.payload.candidates);
        state.allCandidates = action.payload.allCandidates;
        state.selectedCandidates = action.payload.selectedCandidates;
        state.tenantId = action.payload.tenantId;
        state.jobUuid = action.payload.jobUuid;
      })
      .addCase(fetchCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCandidateStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCandidateStatus.fulfilled, (state, action) => {
        state.loading = false;
        const { candidateId, newStatus } = action.payload;
        candidatesAdapter.updateOne(state, {
          id: candidateId,
          changes: { ApplicationStatus: newStatus },
        });
      })
      .addCase(updateCandidateStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(downloadDocument.pending, (state) => {
        state.downloading = true;
        state.downloadError = null;
      })
      .addCase(downloadDocument.fulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        state.downloading = false;
        state.downloadError = action.error.message;
      });
  },
});

export const {
  setSelectedTab,
  setSortBy,
  setFilterKey,
  setFilterValue,
  openCandidateDetailsModal,
  closeCandidateDetailsModal,
} = candidateSlice.actions;

export const {
  selectAll: selectAllCandidates,
  selectById: selectCandidateById,
  selectIds: selectCandidateIds,
} = candidatesAdapter.getSelectors((state) => state.candidates);

export const selectCandidatesLoading = (state) => state.candidates.loading;
export const selectCandidatesError = (state) => state.candidates.error;
export const selectSelectedTab = (state) => state.candidates.selectedTab;
export const selectSelectedCandidates = (state) => state.candidates.selectedCandidates;
export const selectSortBy = (state) => state.candidates.sortBy;
export const selectFilterKey = (state) => state.candidates.filterKey;
export const selectFilterValue = (state) => state.candidates.filterValue;

export default candidateSlice.reducer;
