import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faUsers,
  faBriefcase,
  faHandshake,
  faChartLine,
  faUserShield,
  faThumbsUp,
  faBullseye,
  faComments,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TawkToChat from "../components/TawkToChat";
import { useAuth } from "../useAuth";

const Pricing = () => {
  const { t, i18n } = useTranslation("Pricing");
  const [prices, setPrices] = useState(null);
  const [currency, setCurrency] = useState("XOF"); // Default currency
  const [currencySymbol, setCurrencySymbol] = useState("FCFA");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  const db = getFirestore();

  const currencies = {
    XOF: { symbol: "FCFA", label: "CFA Franc BCEAO (XOF)" },
    USD: { symbol: "$", label: "US Dollar (USD)" },
    EUR: { symbol: "€", label: "Euro (EUR)" },
    CAD: { symbol: "$", label: "Canadian Dollar (CAD)" },
    AED: { symbol: "AED", label: "United Arab Emirates Dirham (AED)" },
    AFN: { symbol: "Af", label: "Afghan Afghani (AFN)" },
    // ... (rest of your currencies)
  };

  // Fetch prices and user's country on component mount
  useEffect(() => {
    const fetchPricingAndCurrency = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch prices
        const basicPriceDoc = await getDoc(doc(db, "price", "basic"));
        const proPriceDoc = await getDoc(doc(db, "price", "pro"));
        const premiumPriceDoc = await getDoc(doc(db, "price", "premium"));
        const subscriptionPriceDoc = await getDoc(
          doc(db, "price", "subscription")
        );

        if (
          !basicPriceDoc.exists() ||
          !proPriceDoc.exists() ||
          !premiumPriceDoc.exists() ||
          !subscriptionPriceDoc.exists()
        ) {
          throw new Error("Pricing data not found in Firestore.");
        }

        setPrices({
          basic: basicPriceDoc.data().price,
          pro: proPriceDoc.data().price,
          premium: premiumPriceDoc.data().price,
          subscription: subscriptionPriceDoc.data().price,
        });

        // Get user's country (IP or logged-in user data)
        let userCurrency = "XOF"; // Default

        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.email);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data().userData;
            userCurrency = userData.companyCountry?.currency || "XOF";
          } else {
            // Handle case where user document doesn't exist.
            console.warn(
              "User data not found for authenticated user:",
              currentUser.email
            );
            // Keep default or set to USD.
          }
        } else {
          // Use IP API
          try {
            const ipResponse = await fetch("https://ipapi.co/json/");
            if (!ipResponse.ok) {
              console.warn("IP API request failed. Status:", ipResponse.status);
              //Keep Default.
            } else {
              const ipData = await ipResponse.json();
              const userCountryCode = ipData?.country || "TG";
              userCurrency = getCurrencyFromCountryCode(userCountryCode);
            }
          } catch (ipError) {
            console.warn("IP API request failed.", ipError);
          }
        }

        // Ensure currency is supported.
        if (currencies[userCurrency.toUpperCase()]) {
          setCurrency(userCurrency.toUpperCase());
          setCurrencySymbol(currencies[userCurrency.toUpperCase()].symbol);
        } else {
          setCurrency("USD"); // Set to USD if not found or not supported
          setCurrencySymbol("$");
        }
      } catch (error) {
        console.error("Error fetching pricing or currency:", error);
        setError("Failed to load pricing information. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPricingAndCurrency();
  }, [currentUser, db]);

  const getCurrencyFromCountryCode = (countryCode) => {
    const countryCurrencyMap = {
      TG: "XOF", // Togo
      BJ: "XOF", // Benin
      BF: "XOF", // Burkina Faso
      CI: "XOF", // Côte d'Ivoire
      GW: "XOF", // Guinea-Bissau
      ML: "XOF", // Mali
      NE: "XOF", // Niger
      SN: "XOF", // Senegal
      // ... add other countries and their currencies ...
      US: "USD",
      FR: "EUR",
      DE: "EUR",
    };
    return countryCurrencyMap[countryCode] || "USD";
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setCurrencySymbol(currencies[event.target.value].symbol);
  };

  const formatPrice = (priceData, selectedCurrency) => {
    const lowerCaseCurrency = selectedCurrency.toLowerCase();
    if (priceData && typeof priceData[lowerCaseCurrency] === "number") {
      const price = priceData[lowerCaseCurrency];
      if (lowerCaseCurrency === "xof") {
        return price.toLocaleString("fr-FR");
      }
      return price.toFixed(2);
    }

    return priceData?.usd
      ? (priceData.usd * (currency === "XOF" ? 1 : 100)).toFixed(2)
      : "N/A";
  };

  const formatSubscriptionPrice = (priceData, selectedCurrency) => {
    const lowerCaseCurrency = selectedCurrency.toLowerCase();
    if (
      priceData &&
      typeof priceData[lowerCaseCurrency] === "object" &&
      typeof priceData[lowerCaseCurrency].basePrice === "number" &&
      typeof priceData[lowerCaseCurrency].perJob === "number"
    ) {
      const basePrice = priceData[lowerCaseCurrency].basePrice;
      const perJob = priceData[lowerCaseCurrency].perJob;
      if (lowerCaseCurrency === "xof") {
        return `${basePrice.toLocaleString(
          "fr-FR"
        )} + ${perJob.toLocaleString('fr-FR')} ${t('perJobUnit')}`;
      }
      return `${basePrice.toFixed(
        2
      )} + ${perJob.toFixed(2)}${t('perJobUnit')}`;
    }
    return "N/A";
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!prices) {
    return <div className="text-center">Pricing data not available.</div>;
  }

  return (
    <div className="bg-gray-100 py-12 font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            {t("pricingTitle")}
          </h1>
          <p className="mt-4 text-xl text-gray-600 sm:text-2xl">
            {t("pricingDescription1")}
          </p>
        </div>

        {/* Currency Selector */}
        <div className="text-center mt-8">
          <label htmlFor="currency" className="mr-2 font-medium text-gray-700">
            {t("selectCurrency")}:
          </label>
          <select
            id="currency"
            name="currency"
            value={currency}
            onChange={handleCurrencyChange}
            className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {Object.entries(currencies).map(([code, { label }]) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
          {/* Basic Package */}
          <div className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("basicPackage")}
              </h3>
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl font-extrabold tracking-tight">
                  {currencySymbol}
                  {formatPrice(prices.basic, currency)}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  /{t("perJobUnit")}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{t("basicDescription")}</p>
              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("basicFeature1")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("basicFeature2")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("basicFeature3")}
                  </span>
                </li>
              </ul>
            </div>
            <Link
              to={currentUser ? "/checkout" : "/signup"}
              className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              {currentUser ? t("buyNow") : t("tryFree")}
            </Link>
          </div>

          {/* Professional Package */}
          <div className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("professionalPackage")}
              </h3>
              <div className="absolute top-0 translate-y-1/2  ml-4 px-3 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-yellow-400 text-white transform">
                {t("mostPopular")}
              </div>
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl font-extrabold tracking-tight">
                  {currencySymbol}
                  {formatPrice(prices.pro, currency)}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  /{t("perJobUnit")}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{t("proDescription")}</p>
              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">{t("proFeature1")}</span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">{t("proFeature2")}</span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faComments}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">{t("proFeature3")}</span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">{t("proFeature4")}</span>
                </li>
              </ul>
            </div>
            <Link
              to={currentUser ? "/checkout" : "/signup"}
              className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              {currentUser ? t("buyNow") : t("startNow")}
            </Link>
          </div>

          {/* Premium Package */}
          <div className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("premiumPackage")}
              </h3>
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl font-extrabold tracking-tight">
                  {currencySymbol}
                  {formatPrice(prices.premium, currency)}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  /{t("perJobUnit")}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{t("premiumDescription")}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("premiumFeature1")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faBullseye}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("premiumFeature2")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("premiumFeature3")}
                  </span>
                </li>
              </ul>
            </div>

            <Link
              to={currentUser ? "/checkout" : "/signup"}
              className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              {currentUser ? t("buyNow") : t("startNow")}
            </Link>
          </div>

          {/* Subscription Package */}
          <div className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("subscriptionPack")}
              </h3>

              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl font-extrabold tracking-tight">
                  {currencySymbol}
                  {formatSubscriptionPrice(prices.subscription, currency)}
                </span>
              </p>
              <p className="mt-6 text-gray-500">
                {t("subscriptionDescription")}
              </p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("subscriptionFeature1")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("subscriptionFeature2")}
                  </span>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="flex-shrink-0 w-6 h-6 text-blue-500"
                  />
                  <span className="ml-3 text-gray-500">
                    {t("subscriptionFeature3")}
                  </span>
                </li>
              </ul>
            </div>

            <Link
              to={currentUser ? "/checkout" : "/signup"}
              className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              {currentUser ? t("buyNow") : t("startNow")}
            </Link>
          </div>
        </div>
        <TawkToChat />
      </div>
    </div>
  );
};

export default Pricing;
