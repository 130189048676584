/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect, useCallback } from "react";
import { signOut } from "firebase/auth";
import Navbar from "../components/Navbar";
// Remove TawkToChat import if not essential for the dashboard
// import TawkToChat from "../components/TawkToChat";  // Consider if this is truly necessary on the *dashboard*.
import * as Sentry from "@sentry/react";
import { auth, analytics, db, Timestamp } from "../firebase-config";
import { useSelector, useDispatch } from "react-redux";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { sanitizeInput } from "../utils/inputSanitizer";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import AssignJobOfferModal from "../components/AssignJobOfferModal";
import UsersTable from "../components/UsersTable";
import AssessmentsTable from "../components/AssessmentsTable";
import {
  fetchJobPostings,
  selectJobPostings,
  selectJobPostingsLoading,
  selectJobPostingsError,
  loadMoreJobPostings,
} from "../utils/redux/jobPostingsSlice";
import {
  selectAllUsers,
  selectUserByEmail,
  fetchUserByEmail,
  fetchAllUsers,
} from "../utils/redux/userDataSlice";
import CreateJobModal from "../components/createJobModal";
import LicenseRequiredModal from "../components/LicenseRequiredModal";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";
import {
  fetchAssessments,
  selectAssessments,
} from "../utils/redux/assessmentSlice";
import { useAuth } from "../useAuth";
import { useNavigate } from "react-router-dom";
//Import for spinner
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

// --- Helper function for consistent button styling ---
const ActionButton = ({
  children,
  onClick,
  color = "blue",
  disabled = false,
}) => (
  <button
    className={`px-4 py-2 rounded-md text-white font-semibold transition duration-200 ease-in-out ${
      disabled
        ? "bg-gray-400 cursor-not-allowed"
        : `bg-${color}-500 hover:bg-${color}-600`
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const DashboardPage = () => {
  const { currentUser } = useAuth();
  const jobPostings = useSelector(selectJobPostings);
  const loading = useSelector(selectJobPostingsLoading);
  const error = useSelector(selectJobPostingsError);
  const assessments = useSelector(selectAssessments);
  const [activeTab, setActiveTab] = useState("users");
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [newJobData, setNewJobData] = useState({});
  const userData = useSelector((state) =>
    selectUserByEmail(state, currentUser.email)
  );
  const usersData = useSelector((state) =>
    selectAllUsers(state, userData ? userData.tenantId : "")
  );
  const companyData = useSelector(selectAllCompanys);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  //Loading state
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Dashboard - ProRecruit"; // More concise title
    return () => {
      document.title = "ProRecruit";
    };
  }, []);

  useEffect(() => {
    setIsLoading(true); // Start loading
    const fetchData = async () => {
      if (currentUser) {
        const u = await dispatch(fetchUserByEmail(currentUser.email));
        const all = await dispatch(fetchAllUsers(u.payload.tenantId));
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
        const a = await dispatch(
          fetchAssessments({ tenantId: u.payload.tenantId })
        );
        setProLicenseCount(c.payload[0].proLicense);
        setBasicLicenseCount(c.payload[0].basicLicense);
        setHasAnyLicense(
          (c.payload[0]?.proLicense ?? 0) + (c.payload[0]?.basicLicense ?? 0) >
            0
        );
        setIsLoading(false); // Data fetching complete
      }
    };
    fetchData();
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (companyData && userData) {
      setIsLoading(true);
      setProLicenseCount(companyData[0]?.proLicense ?? 0);
      setBasicLicenseCount(companyData[0]?.basicLicense ?? 0);
      dispatch(fetchJobPostings({ tenantId: userData.tenantId })).then(() =>
        setIsLoading(false)
      );
    }
  }, [userData, companyData, dispatch]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleBuyMoreLicensesClick = () => {
    navigate("/checkout");
  };

  const handleCreateJobChange = (e) => {
    if (e && e.target) {
      setNewJobData({ ...newJobData, [e.target.name]: e.target.value });
    } else {
      // Handle react-select change for assessments, ensuring it's always an array
      setNewJobData((prevData) => ({
        ...prevData,
        assessments: Array.isArray(e) ? e : e ? [e] : [], // Correctly handle null/undefined
      }));
    }
  };

  const handleGetLicense = () => {
    navigate("/checkout"); // Use navigate for consistency
  };

  const handleLearnMore = () => {
    navigate("/pricing"); // Use navigate for consistency
  };

  const generateShortCode = async (docRef) => {
    const applicationUrlIdDocRef = doc(db, "job", "5tdpJAv6JNwmawkDKPSH");
    const applicationUrlIdDoc = await getDoc(applicationUrlIdDocRef);

    if (applicationUrlIdDoc.exists()) {
      const data = applicationUrlIdDoc.data();
      while (true) {
        let applicationUrlId = Math.random().toString(36).substring(2, 8);
        if (!data[applicationUrlId]) {
          await setDoc(
            applicationUrlIdDocRef,
            { [applicationUrlId]: docRef },
            { merge: true }
          );
          return applicationUrlId;
        }
      }
    } else {
      const error = new Error("Document '5tdpJAv6JNwmawkDKPSH' not found!");
      console.error(error.message);
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      throw error; // Re-throw the error for consistent handling
    }
  };

  const handleCreateJobSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading

    try {
      const formData = new FormData(event.target);
      const newJobDataObject = Object.fromEntries(formData.entries());
      const sanitizedJobData = Object.fromEntries(
        Object.entries(newJobDataObject).map(([key, value]) => [
          key,
          sanitizeInput(value),
        ])
      );

      logEvent(analytics, "select_content", {
        content_type: "handleCreateJobSubmit",
        content_id: newJobDataObject?.Titre || "",
      });

      const docRef = doc(
        collection(db, `job-descriptions/${userData.tenantId}/active`)
      );

      const applicationUrlId = await generateShortCode(docRef);
      let licenseUpdateJson = { "companyData.basicLicense": increment(-1) };
      let jobType = "basic";

      if (sanitizedJobData.jobOfferType.toLowerCase().includes("pro")) {
        licenseUpdateJson = { "companyData.proLicense": increment(-1) };
        jobType = "pro";
      } else if (sanitizedJobData.jobOfferType === "free") {
        licenseUpdateJson = { "companyData.trialLicense": increment(-1) };
        jobType = "free";
      }

      const jobPosting = {
        licenseType: sanitizedJobData.jobOfferType,
        JobID: applicationUrlId,
        resumeAnalysisQuota:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 5
            : 3,
        resumeLimit:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 5
            : 1000,
        type: jobType,
        uuid: docRef.id,
        Titre: sanitizedJobData.Titre || "",
        Entreprise: sanitizedJobData.Entreprise,
        EntrepriseDescription: sanitizedJobData.DescriptionDeLentreprise,
        Lieu: sanitizedJobData.Lieu,
        TypeDePoste: sanitizedJobData.TypeDePoste,
        Secteur: sanitizedJobData.Secteur,
        CategorieDeRole: sanitizedJobData.CategorieDeRole,
        Description: sanitizedJobData.Description,
        Qualifications: sanitizedJobData.Qualifications,
        NiveauDExperience: sanitizedJobData.NiveauDExperience,
        CompetencesRequises: sanitizedJobData.CompetencesRequises
          ? sanitizedJobData.CompetencesRequises.split(",")
          : [],
        additionalInformation: sanitizedJobData.additionalInformation,
        // FourchetteDeSalaire: sanitizedJobData.FourchetteDeSalaire,  // Consider removing if unused
        Avantages: sanitizedJobData.Avantages
          ? sanitizedJobData.Avantages.split(",")
          : [],
        DateLimiteDeCandidature: Timestamp.fromDate(
          new Date(sanitizedJobData.DateLimiteDeCandidature)
        ),
        PublicationDate: Timestamp.fromDate(
          new Date(sanitizedJobData.DateDePublication)
        ),
        ProcedureDeCandidature: "",
        minimumRelevantYearsOfExperience:
          newJobDataObject.minimumRelevantYearsOfExperience,
        linkToApply: `<span class="math-inline">\{process\.env\.REACT\_APP\_BASE\_URL\}jobs/</span>{applicationUrlId}`,
        Responsibilities: sanitizedJobData.Responsibilities || "",
        InformationsDeContact: {
          NomDuContactRH: sanitizedJobData.NomDuContactRH,
          Email: sanitizedJobData.Email,
          Telephone: sanitizedJobData.Telephone,
        },
        CreatedBy: {
          fullName: userData.displayName || "",
          email: userData.email,
          uuid: userData.uuid || "",
        },
        createdOn: serverTimestamp(),
        Statut: "Submissions",
        tenantId: userData.tenantId,
        assessments: newJobData.assessments || [], // Ensure assessments is always an array
        // LogoDeLentreprise: sanitizedJobData.LogoDeLentreprise, // Consider removing if unused
        // DeclarationDeDiversite: sanitizedJobData.DeclarationDeDiversite,  // Consider removing if unused
      };

      await setDoc(docRef, jobPosting);
      const companyRef = doc(collection(db, `company`), `${userData.tenantId}`);
      await updateDoc(companyRef, licenseUpdateJson);

      logEvent(analytics, "createdJobPosting", {
        tenantId: userData.tenantId,
        jobId: jobPosting.uuid,
        email: userData.email,
      });

      // Consider using a library like `axios` for cleaner API calls
      await fetch(`${API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}job-description-txt-builder`,
          payload: jobPosting,
        }),
      });

      setIsLoading(false); // Stop loading
      alert("Job posting created successfully! 👏😎🎊"); // More concise message
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating job posting:", error);
      setIsLoading(false); // Stop loading
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      // More user-friendly error message
      alert(
        "An error occurred while creating the job posting. Please try again later. If the problem persists, contact support."
      );
    }
  };

  const handleSignOut = useCallback(async () => {
    try {
      setIsLoading(true);
      await fetch(`${API_ENDPOINT}api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);

  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const toggleCreateModal = () => {
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };

  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      {/* Use a consistent font (e.g., system fonts for Apple-like feel) */}
           {" "}
      <Navbar
        onSignOut={handleSignOut}
        onCreateJob={toggleCreateModal}
        userData={userData}
        extraButton={{ label: "Job Offers", path: "/jobpostings" }}
      />
      {/* Main Content Area */}     {" "}
      <div className="container mx-auto px-4 py-8">
        {/* License Modal */}       {" "}
        {isLicenseModalOpen && (
          <LicenseRequiredModal
            isOpen={isLicenseModalOpen}
            onClose={() => setIsLicenseModalOpen(false)}
            onGetLicense={handleGetLicense}
            onLearnMore={handleLearnMore}
          />
        )}
        {/* Create Job Modal */}
               {" "}
        <CreateJobModal
          isOpen={isCreateModalOpen}
          onClose={toggleCreateModal}
          onSubmit={handleCreateJobSubmit}
          onChange={handleCreateJobChange}
          data={newJobData}
          assessments={assessments}
          company={companyData}
        />
        {/* License Summary Cards -  Refined styling */}       {" "}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                   {" "}
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
                       {" "}
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Basic Licenses
            </h3>
                       {" "}
            <p className="text-gray-600 mb-4">
                            You have{" "}
              <span className="font-bold">{basicLicenseCount}</span> basic      
                      licenses.            {" "}
            </p>
                       {" "}
            <ActionButton onClick={handleBuyMoreLicensesClick} color="green">
                            Buy More            {" "}
            </ActionButton>
                     {" "}
          </div>
                   {" "}
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
                       {" "}
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Pro Licenses
            </h3>
                       {" "}
            <p className="text-gray-600 mb-4">
                            You have{" "}
              <span className="font-bold">{proLicenseCount}</span> pro          
                  licenses.            {" "}
            </p>
                       {" "}
            <ActionButton onClick={handleBuyMoreLicensesClick} color="green">
                            Buy More            {" "}
            </ActionButton>
                     {" "}
          </div>
                 {" "}
        </div>
        {/* Tabs - More Apple-like styling */}       {" "}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                   {" "}
          <div className="flex border-b border-gray-200">
                       {" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "users"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("users")}
            >
                            Users            {" "}
            </button>
                       {" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "jobOffers"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("jobOffers")}
            >
                            Job Offers            {" "}
            </button>
                       {" "}
            <button
              className={`flex-1 py-3 text-center text-sm font-medium text-gray-600 uppercase tracking-wider transition duration-200 ease-in-out ${
                activeTab === "Assessments"
                  ? "bg-gray-100 text-blue-600 border-b-2 border-blue-600"
                  : "hover:bg-gray-50"
              }`}
              onClick={() => handleTabClick("Assessments")}
            >
                            Assessments            {" "}
            </button>
                     {" "}
          </div>
          {/* Conditionally render loading indicator or content */}
          {isLoading ? (
            <div className="flex justify-center items-center p-8">
              <ClipLoader color={"#3B82F6"} loading={isLoading} size={50} />
            </div>
          ) : (
            <>
              {/* Tab Content - Using components for better organization */}   
                         {" "}
              {activeTab === "users" && (
                <UsersTable adminInfo={userData} data={usersData} />
              )}
                             {" "}
              {activeTab === "jobOffers" && (
                <JobOffersTable
                  data={jobPostings}
                  onOpenAssignModal={handleOpenAssignModal}
                />
              )}
                             {" "}
              {activeTab === "Assessments" && (
                <AssessmentsTable
                  data={assessments}
                  adminInfo={userData}
                  onOpenAssignModal={handleOpenAssignModal}
                />
              )}
            </>
          )}
                 {" "}
        </div>
                {/* Assign Job Offers Modal */}
               {" "}
        <AssignJobOfferModal
          isOpen={isAssignModalOpen}
          onClose={handleCloseAssignModal}
          jobOffers={jobPostings}
          users={usersData}
          userData={userData}
        />
             {" "}
      </div>
         {" "}
    </div>
  );
};

// --- Improved JobOffersTable Component ---
const JobOffersTable = ({ data, onOpenAssignModal }) => {
  const navigate = useNavigate();

  const handleEdit = (jobId, tenantId) => {
    navigate(
      `/jobpostings/<span class="math-inline">{jobId}/tenants/</span>{tenantId}`
    );
  };

  return (
    <div className="overflow-x-auto">
      {/* overflow-x-auto for horizontal scrolling on small screens */}     {" "}
      <table className="min-w-full bg-white border border-gray-200">
               {" "}
        <thead className="bg-gray-50">
                   {" "}
          <tr>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Title            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Recruiter            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Deadline            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            License            {" "}
            </th>
                       {" "}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Options            {" "}
            </th>
                     {" "}
          </tr>
                 {" "}
        </thead>
               {" "}
        <tbody className="bg-white divide-y divide-gray-200">
                   {" "}
          {data.map((jobPosting) => (
            <tr key={jobPosting.uuid} className="hover:bg-gray-50">
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {jobPosting.JobID}             {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {jobPosting.Titre}             {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {jobPosting.InformationsDeContact.Email}       
                     {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {jobPosting.Statut}             {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                               {" "}
                {new Date(
                  jobPosting.DateLimiteDeCandidature.seconds * 1000
                ).toLocaleDateString("fr-FR")}
                             {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {jobPosting.type}             {" "}
              </td>
                           {" "}
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                               {" "}
                <ActionButton
                  onClick={() =>
                    handleEdit(jobPosting.uuid, jobPosting.tenantId)
                  }
                  color="indigo"
                >
                                    Edit                {" "}
                </ActionButton>
                               {" "}
                {/*  <ActionButton
                  onClick={onOpenAssignModal} // Consider if Assign should be per-row or separate
                  color="blue"
                >
                  Assign
                </ActionButton>*/}
                             {" "}
              </td>
                         {" "}
            </tr>
          ))}
                 {" "}
        </tbody>
             {" "}
      </table>
         {" "}
    </div>
  );
};

export default DashboardPage;
