import React, { useState, useEffect } from "react";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"; // For file uploads
import { useAuth } from "../useAuth"; // Your authentication context
import { useTranslation } from "react-i18next";
import { db, storage } from "../firebase-config";
import { useNavigate } from "react-router-dom";

function CompanyEditPage() {
  const { currentUser } = useAuth();
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(""); // Store the URL, even before upload
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation("CompanyEditPage");

  // Fetch company data on component mount
  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!currentUser) return;

      setLoading(true);
      setError(null);
      try {
        const userDocRef = doc(db, "users", currentUser.email);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          setError(t("userNotFound")); // i18next
          return;
        }
        const userData = userDocSnap.data().userData;

        if (!userData.canUpdateCompany) {
          setError(t("noPermission"));
          setLoading(false);
          return; // Exit early if no permission
        }

        const companyRef = doc(db, "company", userData.tenantId); // Get company doc
        const companySnap = await getDoc(companyRef);

        if (companySnap.exists()) {
          setCompanyData(companySnap.data().companyData); // Access the nested companyData
          setLogoUrl(companySnap.data().companyData.logoUrl || ""); // Get current logo URL, if any.
        } else {
          setError(t("companyNotFound"));
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
        setError(t("fetchError") + error.message);
      } finally {
        setLoading(false);
      }
    };
    if (currentUser) {
      fetchCompanyData();
    }
  }, [currentUser, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if a file was actually selected
      if (!file.type.startsWith("image/")) {
        setError(t("invalidFileType")); // Use a translation key
        return;
      }
      //Clear error, if no error.
      setError("");
      setLogoFile(file);
      // Optionally, display a preview of the image (client-side)
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoUrl(reader.result); // Show preview (local URL)
      };
      reader.readAsDataURL(file);
    } else {
      setLogoFile(null);
      setLogoUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      let newLogoUrl = logoUrl; // Keep existing URL by default
      if (logoFile) {
        // Upload the new logo image to Firebase Storage.
        setIsUploading(true); // Set uploading state
        const storageRef = ref(
          storage,
          `companyLogos/${companyData.tenantId}/${logoFile.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, logoFile);

        // Monitor upload progress
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload failed:", error);
            setError("Upload failed: " + error.message); // Or a translated message
            setIsUploading(false);
            setLoading(false); // Make sure to stop loading
            throw error; // re-throw to be caught by outer catch.
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            newLogoUrl = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("File available at", newLogoUrl);
            setIsUploading(false); // Clear uploading state
            continueUpdate(newLogoUrl);
          }
        );
      } else {
        await continueUpdate(newLogoUrl); // If no new logo, continue
      }
    } catch (error) {
      setError(t("updateError") + error.message); // Use i18next and more specific message
      setLoading(false);
    }
  };

  
    const navigate = useNavigate();
  // Function to go to dashboard
  const goToDashboard = () => {
    navigate("/dashboard"); // Redirect to the dashboard
  };

  const continueUpdate = async (newLogoUrl) => {
    try {
      // Prepare the updated company data
      const updatedCompanyData = {
        ...companyData, // Existing data
        logoUrl: newLogoUrl, // Updated URL
        // Add other fields you want to update here
      };

      // Update the document in Firestore
      const userDocRef = doc(db, "users", currentUser.email);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data().userData;
      const companyRef = doc(db, "company", userData.tenantId); // Use the correct ID
      await updateDoc(companyRef, { companyData: updatedCompanyData });

      setSuccess(true); // Show success message
      setMessage(""); // Reset message
    } catch (error) {
      console.error("Error Update company data:", error);
      setError(t("updateError") + error.message); // Use i18next and more specific message
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!companyData) {
    return <div>No company data found.</div>;
  }

  return (
    <div className="container mx-auto p-4 max-w-2xl">
        <button
          onClick={goToDashboard}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("goToDashboard")}
        </button>
      <h1 className="text-3xl font-bold text-center mb-6">
        {t("editCompany")}
      </h1>

      {success && (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          {t("updateSuccess")}
        </div>
      )}

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          {t("updateError")}
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="companyName"
          >
            {t("companyName")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="companyName"
            type="text"
            placeholder={t("companyName")}
            name="name"
            value={companyData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        {/* Add similar input fields for other company details (address, city, country, etc.) */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="companyAddress"
          >
            {t("companyAddress")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="companyAddress"
            type="text"
            placeholder={t("companyAddress")}
            name="address"
            value={companyData.address}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="companyCity"
          >
            {t("companyCity")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="companyCity"
            type="text"
            placeholder={t("companyCity")}
            name="city"
            value={companyData.city}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Image upload */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("companyLogo")}
          </label>
          {logoUrl && (
            <img
              src={logoUrl}
              alt="Company Logo Preview"
              className="mb-2 h-24 w-auto"
            />
          )}

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {isUploading && (
            <div className="mt-2">
              <progress value={uploadProgress} max="100" className="w-full" />
              <span className="text-sm">{uploadProgress.toFixed()}%</span>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            disabled={isUploading}
          >
            {t("saveChanges")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CompanyEditPage;
