import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function WelcomePopup({ shouldOpen }) { // Destructure and provide default
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('WelcomePopup');
  console.log(shouldOpen)

  useEffect(() => {
    const seenPopup = localStorage.getItem('hasSeenWelcomePopup');
    if (!seenPopup && shouldOpen) {
      setIsOpen(true);
    }
  }, [shouldOpen]); // Depend on shouldOpen

  const closePopup = () => {
    setIsOpen(false);
    localStorage.setItem('hasSeenWelcomePopup', 'true');
  };

    // Prevent scrolling on the body when the modal is open
    useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
        return () => {
      document.body.style.overflow = 'unset';
    };

    }, [isOpen]);

  if (!isOpen) return null;

  return (
     <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={closePopup}>
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild
                as="Fragment"
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {/* <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"  onClick={closePopup}/> */}
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* Replace with your ProRecruit logo or an appropriate icon */}
                        <svg xmlns="prorecruit.tech/logo" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-blue-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664M19.5 12h-15" />
                        </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {t('welcomePopup.title')}
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                           {t('welcomePopup.intro')}
                        </p>
                        {/* Video Player */}
                        <div className="relative pt-[56.25%] my-4"> {/* 16:9 aspect ratio */}
                          <iframe
                            className="absolute top-0 left-0 w-full h-full rounded-lg"
                            sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation allow-presentation"
                            src="https://www.youtube.com/embed/vioc9xOBwRY?autoplay=1&modestbranding=1&rel=0"  // Replace with YOUR video URL
                            title="Welcome Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                         <p className="text-sm text-gray-500">
                            {t('welcomePopup.videoIntro')}:
                        </p>
                        <ul className="list-disc list-inside text-sm mt-2 space-y-1">
                          <li>{t('welcomePopup.feature1')}</li>
                          <li>{t('welcomePopup.feature2')}</li>
                          <li>{t('welcomePopup.feature3')}</li>
                          <li>{t('welcomePopup.feature4')}</li>
                          <li>{t('welcomePopup.feature5')}</li>
                          <li>{t('welcomePopup.feature6')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                   <Link to="/GettingStartedGuide">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={closePopup}
                            >
                            {t('welcomePopup.ctaDashboard')}
                        </button>
                    </Link>

                  <Link to="/GettingStartedGuide">
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                         onClick={closePopup}
                    >
                         {t('welcomePopup.ctaClose')}

                    </button>
                  </Link>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
  );
}

WelcomePopup.propTypes = {
  shouldOpen: PropTypes.bool,
}

WelcomePopup.defaultProps = {
  shouldOpen: true,
}
export default WelcomePopup;