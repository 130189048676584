import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import {
    selectAllCompanys,
} from "../utils/redux/companySlice";
import {
    selectAssessments,
} from "../utils/redux/assessmentSlice";
import PropTypes from 'prop-types';

const supportedLanguages = [
  { value: "en", label: "English" },
  { value: "zh", label: "中文 (Mandarin Chinese)" },
  { value: "es", label: "Español (Spanish)" },
  { value: "ar", label: "العربية (Arabic)" },
  { value: "hi", label: "हिन्दी (Hindi)" },
  { value: "pt", label: "Português (Portuguese)" },
  { value: "fr", label: "Français (French)" },
  { value: "de", label: "Deutsch (German)" },
  { value: "ru", label: "Русский (Russian)" },
  { value: "ja", label: "日本語 (Japanese)" },
];

const CreateJobModal = ({
  isOpen,
  onClose,
  onSubmit,
  onChange,
  data,
  disableButtons,
}) => {
  const { t } = useTranslation("createJobModal");
  const [jobOfferType, setJobOfferType] = useState("pro"); // Default to basic job offer type
  const companyData = useSelector(selectAllCompanys);
  const [usingFreeTrial, setUsingFreeTrial] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const assessments = useSelector(selectAssessments);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  //console.log(transformArray(assessments));

  /*   useEffect(() => {
    if (currentUser) {
      dispatch(fetchAndSetCompanyData({ tenantId: currentUser.tenantId }));
      dispatch(fetchAssessments({ tenantId: currentUser.tenantId }));
    }
  }, [currentUser, dispatch]); */
  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    const defaultLang = supportedLanguages.find(
      (lang) => lang.value === browserLang
    ) || { value: "en", label: "English" };
    setSelectedLanguage(defaultLang);
  }, []);


  useEffect(() => {
    companyData[0]
      ? companyData[0].proLicense > 0
        ? setShowForm(true)
        : setShowForm(false)
      : setShowForm(false);
    setUsingFreeTrial(false);
  }, []);


  const handleJobOfferTypeChange = (event) => {
    setJobOfferType(event.target.value);
    if (event.target.value === "basic") {
      companyData[0]
        ? companyData[0].basicLicense > 0
          ? setShowForm(true)
          : setShowForm(false)
        : setShowForm(false);
      setUsingFreeTrial(false);
    }

    if (event.target.value === "pro") {
      companyData[0]
        ? companyData[0].proLicense > 0
          ? setShowForm(true)
          : setShowForm(false)
        : setShowForm(false);
      setUsingFreeTrial(false);
    }
    onChange({ target: { name: "jobOfferType", value: event.target.value } });
    onChange({ target: { name: "type", value: event.target.value } });
  };

  function transformArray(inputArray) {
    try {
      return inputArray.map((item) => ({
        value: item.id,
        label: item.title,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const hasLicense = (type) => {
    if (type === "basic")
      return companyData[0] ? companyData[0].basicLicense > 0 : false;
    if (type === "pro")
      return companyData[0] ? companyData[0].proLicense > 0 : false;
    return false;
  };

  const hasFreeTrial = () => {
    return companyData[0] ? companyData[0].trialLicense > 0 : false;
  };
  const handleFreeTrialClick = () => {
    // Logic to use the free trial (e.g., decrement trialLicense in your database)
    //...
    setJobOfferType("Free");
    data.type = "Free";
    data.jobOfferType = "Free";
    // console.log(data);
    setUsingFreeTrial(true);
    setShowForm(true); // Show the form after confirming free trial usage
    onChange({ target: { name: "jobOfferType", value: "Free" } });
    onChange({ target: { name: "type", value: "Free" } });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("createNewJobPosting")}>
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label>
            <input
              type="radio"
              name="jobOfferType"
              value="basic"
              checked={jobOfferType === "basic"}
              onChange={handleJobOfferTypeChange}
            />
            {t("basicLicense", {
              count: companyData[0]?.basicLicense || 0,
            })}
          </label>
          <label className="ml-4">
            <input
              type="radio"
              name="jobOfferType"
              value="pro"
              checked={jobOfferType === "pro"}
              onChange={handleJobOfferTypeChange}
            />
            {t("proLicense", { count: companyData[0]?.proLicense || 0 })} {"  "}
            <p>
              <a href="/checkout" className="text-blue-600 underline">
                {t("acquireMoreLicenses")}
              </a>{" "}
            </p>
          </label>
        </div>

        {hasFreeTrial() &&
        !usingFreeTrial && ( // Show free trial message if applicable
            <div className="mt-2 p-4 bg-yellow-100 text-yellow-800 rounded">
              <p>{t("freeTrialAvailable")}</p>
              <button
                onClick={handleFreeTrialClick}
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
              >
                {t("yesUseFreeTrial")}
              </button>
            </div>
          )}

        {!hasLicense(jobOfferType) && !usingFreeTrial && (
          <div className="mt-2 p-4 bg-red-100 text-red-800 rounded">
            <p>
              <Trans i18nKey="noLicense">
                Vous n&apos;avez pas de licence{" "}
                <strong className="font-medium">
                  <span>{{ jobOfferType }}</span>
                </strong>{" "}
                . Veuillez{" "}
                <a href="/checkout" className="text-blue-600 underline">
                  acheter la licence
                </a>{" "}
                ou choisir l&lsquo;autre type.
              </Trans>
            </p>
          </div>
        )}

        {showForm && (
          <>
            <div>
              <label>
                {t("selectLanguage")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
              </label>
              <select
                name="language"
                required
                value={data.language || selectedLanguage}
                onChange={onChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
              >
                <option value="en">English</option>
                <option value="zh">中文</option>
                <option value="es">Español</option>
                <option value="ar">العربية</option>
                <option value="hi">हिन्दी</option>
                <option value="pt">Português</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="ru">Русский</option>
                <option value="ja">日本語</option>
              </select>
            </div>
            <div>
              <label>
                {t("title")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="Titre"
                  type="text"
                  required
                  value={data.Titre || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("companyName")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="Entreprise"
                  type="text"
                  value={data.Entreprise || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("companyDescription")}
                <textarea
                  name="DescriptionDeLentreprise"
                  type="text"
                  value={data.DescriptionDeLentreprise || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("location")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}. {t("cityCountry")}
                </span>
                <input
                  name="Lieu"
                  type="text"
                  value={data.Lieu || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("publicationDate")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="DateDePublication"
                  type="date"
                  required
                  value={data.DateDePublication || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("hrContactName")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="NomDuContactRH"
                  type="text"
                  value={data.NomDuContactRH || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("hrEmail")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="Email"
                  type="email"
                  value={data.Email || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("hrPhone")}
                <input
                  name="Telephone"
                  type="tel"
                  value={data.Telephone || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("jobType")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <select
                  name="TypeDePoste"
                  required
                  value={data.TypeDePoste || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                >
                  <option value="">{t("selectJobType")}</option>
                  <option value="Temps plein">{t("fullTime")}</option>
                  <option value="Temps partiel">{t("partTime")}</option>
                  <option value="Contrat">{t("contract")}</option>
                  <option value="Stage">{t("internship")}</option>
                  <option value="Freelance">{t("freelance")}</option>
                </select>
              </label>
            </div>

            <div>
              <label>
                {t("sector")}
                <input
                  name="Secteur"
                  type="text"
                  value={data.Secteur || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("applicationDeadline")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="DateLimiteDeCandidature"
                  type="date"
                  required
                  value={data.DateLimiteDeCandidature || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("roleCategory")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="CategorieDeRole"
                  type="text"
                  required
                  value={data.CategorieDeRole || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("experienceLevel")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <select
                  name="NiveauDExperience"
                  required
                  value={data.NiveauDExperience || ""}
                  onChange={onChange}
                  className="mt-1 block w-md rounded-md border-black-300 shadow-md"
                >
                  <option value="">{t("selectExperienceLevel")}</option>
                  <option value="Stage">{t("Intern")}</option>
                  <option value="Debutant">{t("Entry")}</option>
                  <option value="Mid">{t("Mid")}</option>
                  <option value="Senior">{t("Senior")}</option>
                  <option value="Manager">{t("Manager")}</option>
                  <option value="Directeur">{t("Director")}</option>
                  <option value="Executif">{t("Executif")}</option>
                </select>
              </label>
            </div>

            <div>
              <label>
                {t("jobDescription")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <textarea
                  name="Description"
                  required
                  value={data.Description || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-700 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("minimumExperience")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <input
                  name="minimumRelevantYearsOfExperience"
                  type="number"
                  required
                  value={data.minimumRelevantYearsOfExperience || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("qualifications")}{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  {t("important")}
                </span>
                <textarea
                  name="Qualifications"
                  value={data.Qualifications || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("responsibilities")}
                <textarea
                  name="Responsibilities"
                  type="text"
                  value={data.Responsibilities || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                {t("additionalInformation")}
                <textarea
                  name="additionalInformation"
                  type="text"
                  value={data.additionalInformation || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>
            <div>
              <label>
                {" "}
                {t("assessmentTests")}
                {jobOfferType === "basic" ? (
                  t("assessmentTestsProOnly")
                ) : (
                  <Select
                    isMulti
                    options={transformArray(assessments)}
                    name="assessments"
                    value={data.assessments || []}
                    onChange={onChange}
                    // defaultValue={[transformArray(assessments)[0], transformArray(assessments)[1]]}
                  />
                )}
              </label>
            </div>

            <button
              type="submit"
              disabled={disableButtons}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              {t("submit")}
            </button>
          </>
        )}
      </form>
    </Modal>
  );
};

CreateJobModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Must be a boolean and required
  onClose: PropTypes.func.isRequired, // Must be a function and required
  onSubmit: PropTypes.func.isRequired, // Must be a function and required
  onChange: PropTypes.func.isRequired, // Must be a function and required
  data: PropTypes.object.isRequired, // Must be an object and required
  // company: PropTypes.string.isRequired, // Must be a string and required
  disableButtons: PropTypes.bool, // Must be a boolean but optional
};

// Default Props (if needed)
CreateJobModal.defaultProps = {
  disableButtons: false, // Default to false if not provided
};

export default CreateJobModal;
