import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/Assessment.css"; // Ensure to create this CSS file
import bannerImage from "../img/pexels-tirachard-kumtanom-733857.jpg";
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firebase-config";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const CLOUD_FUNCTION_BASE_URL = process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL;

const Assessments = () => {
  const { assessmentID, testID } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [tenantID, setTenantID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobUuid, setJobUuid] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(0);
  const [lostFocusCount, setLostFocusCount] = useState(0);
  const [visibilityChangeCount, setVisibilityChangeCount] = useState(0);
  const [contextMenuCount, setContextMenuCount] = useState(0);
  const [showSplash, setShowSplash] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isTaken, setIsTaken] = useState(false);
  const [score, setScore] = useState(0);
  const [currentScore, setCurrentScore] = useState(0);
  const [assessmentExist, setAssessmentExist] = useState(true);
  const [thankYouPageObject, setThankYouPageObject] = useState({});
  const [hrInfo, setHRInfo] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const docRef = doc(db, "candidates_assessments", assessmentID);
  // var score = 0;
  // var currentScore = 0;
    useEffect(() => {
      document.title = "Assessments Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  // Fetch job details
  useEffect(() => {
    // console.log(`Starting ${jobID}`);
    try {
      getDoc(docRef).then((docSnap) => {
        setLoading(false);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          if (!docSnap.data().assessments[testID].isTaken) {
            setEmail(docSnap.data().email);
            setFullName(docSnap.data().fullName);
            setTenantID(docSnap.data().tenantID);
            setCompanyName(docSnap.data().tenantName);
            setPhoneNumber(docSnap.data().phoneNumber);
            setJobUuid(docSnap.data().jobUUID);
            setAssessments(docSnap.data().assessments);
            setQuestions(docSnap.data().assessments[testID].questions);
            setHRInfo({
              recruiterName: docSnap.data().recruiterName,
              recruiterEmail: docSnap.data().HREmail,
              phoneNumber: docSnap.data().phoneNumber,
              jobTitle: docSnap.data().jobTitle,
            });
          } else setIsTaken(true);
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          setAssessmentExist(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [assessmentID]);

  useEffect(() => {
    // console.log(showSplash);
    if (!showSplash && assessmentExist) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          setVisibilityChangeCount(visibilityChangeCount + 1);
          alert(
            "Veuillez vous concentrer sur l’évaluation. La navigation loin est notée."
          );
        }
      };

      const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenuCount(contextMenuCount + 1);
        alert("Le clic droit est désactivé pendant l'évaluation.");
      };

      const handleBlur = () => {
        setLostFocusCount(lostFocusCount + 1);
        console.warn("Concentration perdue depuis la fenêtre d'évaluation !");
        // alert("Concentration perdue depuis la fenêtre d'évaluation !");
        // Implement additional actions or logging here
      };
      const handleKeyDown = (event) => {
        // Check for Ctrl+V or Cmd+V
        if ((event.ctrlKey || event.metaKey) && event.key === "v") {
          console.warn("Paste action detected!");
          event.preventDefault(); // Prevents the paste action
          alert(
            "Le collage de texte n'est pas autorisé dans cette évaluation."
          );
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("contextmenu", handleContextMenu);
      window.addEventListener("blur", handleBlur);
      // Add the event listener to the window object
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        window.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("blur", handleBlur);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    showSplash,
    lostFocusCount,
    contextMenuCount,
    visibilityChangeCount,
    assessmentExist,
  ]);

  useEffect(() => {
    if (!showSplash) {
      if (timer > 0) {
        const interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        return () => clearInterval(interval);
      } else {
        if (questions.length && currentQuestionIndex < questions.length) {
          handleNextQuestion();
        }
      }
    }
  }, [timer, questions, currentQuestionIndex, showSplash]);

  useEffect(() => {
    setThankYouPageObject({
      tenantId: tenantID,
      jobId: jobUuid,
      fullName: fullName,
      email: email,
      phoneNumber: phoneNumber,
      referrer: "assessment",
    });
  }, [tenantID, jobUuid, fullName, email, phoneNumber]);

  const handleStart = () => {
    setIsStarted(true);
    setScore(0);
    setCurrentScore(0);
    setShowSplash(false);
    setTimer(questions[0].system.duration);
    var newAssessment = assessments;
    newAssessment[testID].startdate = new Date(Date.now()).toISOString();
    newAssessment[testID].isTaken = true;
    setAssessments(newAssessment);
    updateDoc(docRef, {
      assessments: newAssessment,
    })
      .catch((e) => {
        console.log(e);
      })
      .then((r) => {
        console.log(r);
      });
  };
  const handleNextQuestion = () => {
    const updatedQuestions = [...questions];
    const currentQuestion = updatedQuestions[currentQuestionIndex];
    var s = 0;
    for (let i = 0; i < currentQuestion.user.answer.length; i++) {
      const e = currentQuestion.user.answer[i];
      if (e.isSelected) {
        s = s + e.score;
      }
    }
    updatedQuestions[currentQuestionIndex].user.score = s;
    setQuestions(updatedQuestions);
    // console.log(s);
    setCurrentScore(s);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimer(questions[currentQuestionIndex + 1].system.duration);
    } else {
      handleSubmitAssessment();
    }
  };

  const handleSelectAnswer = (answerIndex) => {
    // console.log(`answerIndex: ${answerIndex}`);
    const updatedQuestions = [...questions];
    const currentQuestion = updatedQuestions[currentQuestionIndex];
    // console.log(questions[0].user);
    // console.log(currentQuestion.user.answer[1]);
    // console.log(currentQuestionIndex);
    // console.log(updatedQuestions[currentQuestionIndex].user.answer[0]);

    if (
      currentQuestion.type === "SingleChoice" ||
      currentQuestion.type === "TrueFalse"
    ) {
      // console.log(currentQuestion);
      currentQuestion.user.answer[answerIndex].isSelected = true;
      setCurrentScore(currentQuestion.user.answer[answerIndex].score);
      // console.log(
        // `weigth: ${currentQuestion.user.answer[answerIndex].score} reponse: ${currentQuestion.user.answer[answerIndex].choice} ${currentQuestion.user.answer[answerIndex].isSelected}`
      // );
      currentQuestion.user.answer.forEach((answer, index) => {
        answer.isSelected = index === answerIndex;
      });
    } else if (currentQuestion.type === "MultipleChoice") {
      // console.log(currentQuestion.user.answer[answerIndex].isSelected);
      /* console.log(
        `weigth: ${currentQuestion.user.answer[answerIndex].score} reponse: ${currentQuestion.user.answer[answerIndex].choice} ${currentQuestion.user.answer[answerIndex].isSelected}`
      ); */
      // Multiple choice logic: toggle selection
      currentQuestion.user.answer[answerIndex].isSelected =
        !currentQuestion.user.answer[answerIndex].isSelected;

      currentQuestion.user.answer.forEach((answer, index) => {
        if (answer.isSelected) {
          setCurrentScore(currentScore + answer.score);
        }
      });
    } else if (currentQuestion.type === "text") {
      currentQuestion.user.answer = answerIndex;
    }
    setQuestions(updatedQuestions);
  };

  const handleSubmitAssessment = async () => {
    var s = 0;
    var t = 0;
    for (let i = 0; i < questions.length; i++) {
      const e = questions[i];
      t = t + e.system.score;
      for (let k = 0; k < e.user.answer.length; k++) {
        const v = e.user.answer[k];
        if (v.isSelected) {
          s = s + v.score;
        }
      }
    }
    // console.log(`score: ${s} out of ${t}. ${(s * 100) / t}`);

    assessments[testID].questions = questions;
    assessments[testID].visibilityChangeCount = visibilityChangeCount;
    assessments[testID].lostFocusCount = lostFocusCount;
    assessments[testID].contextMenuCount = contextMenuCount;
    assessments[testID].isTaken = true;
    assessments[testID].score = Math.round((s * 100) / t);
    assessments[testID].endDate = new Date(Date.now()).toISOString();

    try {
      /* await axios.post(
        `API_ENDPOINTassessments/${assessmentID}/submit`,
        questions
      ); */

      var applicationDocRef = doc(
        db,
        `job-descriptions/${tenantID}/active/${jobUuid}/applications`,
        assessmentID
      );
      updateDoc(applicationDocRef, {
        assessmentScore: increment(assessments[testID].score),
        assessmentTaken: increment(1),
        assessmentLinks: arrayUnion(`${assessmentID}/test/${testID}`),
        assessmentNames: arrayUnion({name: assessments[testID].name || "",title: assessments[testID].title || "",description: assessments[testID].description || "",score: assessments[testID].score, link:`${assessmentID}/test/${testID}`}),
      });
      updateDoc(docRef, { assessments: assessments });
      // console.log(assessments);

      //email notification candidate
      var p = {
        url: `${CLOUD_FUNCTION_BASE_URL}email-notification`,
        payload: {
          toEmail: email,
          fromEmail: "customer@keobrand.com",
          template_id: "ProRecruitApplicatcantAssessmentCompletedCandidate_fr",
          emailData: {
            applicationId: assessmentID,
            jobTitle: hrInfo.jobTitle,
            tenantName: companyName,
            HREmail: hrInfo.recruiterEmail,
            recruiterEmail: hrInfo.recruiterEmail,
            recruiterPhoneNumber: hrInfo.phoneNumber,
            fullName: fullName,
            assessmentName: assessments[testID].name,
          },
        },
      };
      //email notification recruiter
      var pr = {
        url: `${CLOUD_FUNCTION_BASE_URL}email-notification`,
        payload: {
          toEmail: hrInfo.recruiterEmail,
          fromEmail: "customer@keobrand.com",
          template_id: "ProRecruitApplicatcantAssessmentCompletedRecruiter_fr",
          emailData: {
            applicationId: assessmentID,
            jobTitle: hrInfo.jobTitle,
            tenantName: companyName,
            recruiterFullName: hrInfo.recruiterName,
            recruiterEmail: hrInfo.recruiterEmail,
            recruiterPhoneNumber: hrInfo.phoneNumber,
            fullName: fullName,
            assessmentName: assessments[testID].name,
            assessmentScore: assessments[testID].score,
            assessmentLink: `${REACT_APP_BASE_URL}assessmentresults/${assessmentID}/test/${testID}`,
          },
        },
      };
      fetch(`${API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(p),
      });

      fetch(`${API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pr),
      });
      navigate("/thank-you", { state: thankYouPageObject, replace: true });
    } catch (error) {
      console.error("Error submitting assessment:", error);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  if (isTaken && !isStarted) {
    return (
      <div className="splash-screen">
        <h1>
          <strong>Vous avez déjà passé l'évaluation</strong>
        </h1>
      </div>
    );
  }

  if (showSplash) {
    return (
      <div className="splash-screen">
        <h1>
          <strong>Bienvenue à l'évaluation</strong>
        </h1>
        <p>Veuillez lire attentivement les instructions avant de commencer.</p>
        <ol>
          <li key="1">
            Assurez-vous d'être prêt avant de commencer. Vous aurez besoin d'une
            connexion Internet stable, d'un navigateur compatible et d'un
            environnement calme pour vous concentrer.
          </li>
          <li key="2">
            Avant de commencer le test, assurez-vous d'avoir suffisamment de
            temps disponible, car le test peut prendre entre 20 et 40 minutes.
          </li>
          <li key="3">
            Une fois prêt, cliquez sur le bouton "Commencer le test". Le test
            débutera immédiatement, et chaque question sera chronométrée.
          </li>
          <li key="4">
            Pendant le test, assurez-vous de rester sur la page du test. Si vous
            ouvrez un autre navigateur ou vous éloignez de la page, le test sera
            annulé et vous ne pourrez pas le reprendre.
          </li>
          <li key="5">
            Répondez à chaque question en suivant les instructions données.
            Certaines questions peuvent être à choix multiple, d'autres peuvent
            nécessiter plusieurs réponses, et d'autres encore peuvent être des
            questions ouvertes où vous devez fournir une réponse écrite.
          </li>
          <li key="6">
            Si vous rencontrez des problèmes techniques pendant le test, prenez
            une capture d'écran ou, mieux encore, enregistrez une vidéo de
            l'erreur et envoyez-la à l'adresse e-mail de support de KEO TECH :
            support@keotech.com.
          </li>
          <li key="7">
            Une fois que vous avez répondu à toutes les questions, le système
            enverra automatiquement vos réponses et votre score au responsable
            des ressources humaines pour évaluation. Ils seront en mesure de
            consulter vos réponses et de prendre des décisions en conséquence.
          </li>
          <li key="8">Bonne chance! 👍</li>
        </ol>
        <button onClick={() => handleStart()}>Commencer le test</button>
      </div>
    );
  }

  return (
    <div className="assessment-container">
      <div className="question-header">
        <img
          src={bannerImage}
          alt="Assessment Banner"
          className="full-width-image"
        />
        <div className="header">
          <div className="time-left">
            <h1 className={timer <= 10 ? "time-critical" : ""}>
              Temps restant: {timer}
            </h1>
          </div>
          <div className="question-info">
            <h2>
              Question {currentQuestionIndex + 1} of {questions.length}
            </h2>
          </div>
        </div>
      </div>
      {currentQuestion ? (
        <div className={`question-container`}>
          <p>{currentQuestion.system.question}</p>
          {(currentQuestion.type === "SingleChoice" ||
            currentQuestion.type === "TrueFalse") && (
            <ul>
              {currentQuestion.user.answer.map((answer, index) => (
                <li
                  key={index}
                  className={answer.isSelected ? "selected" : ""}
                  onClick={() => handleSelectAnswer(index)}
                >
                  {answer.choice}
                </li>
              ))}
            </ul>
          )}
          {currentQuestion.type === "text" && (
            <textarea
              value={currentQuestion.user.answer || ""}
              onChange={(e) => handleSelectAnswer(e.target.value)}
              rows="4" // Sets the initial number of visible text lines
              cols="50" // Sets the width of the textarea (in average character widths)
            />
          )}
          {currentQuestion.type === "MultipleChoice" && (
            <ul>
              {currentQuestion.user.answer.map((answer, index) => (
                <li
                  key={index}
                  className={answer.isSelected ? "selected" : ""}
                  onClick={() => handleSelectAnswer(index)}
                >
                  {answer.choice}
                </li>
              ))}
            </ul>
          )}
          <button onClick={handleNextQuestion}>Suivant</button>
        </div>
      ) : (
        <div className="thank-you-screen">
          <h1>Thank you for completing the assessment!</h1>
          <button
            onClick={() =>
              navigate("/thank-you", {
                state: thankYouPageObject,
                replace: true,
              })
            }
          >
            Go to Dashboard
          </button>
        </div>
      )}
    </div>
  );
};

export default Assessments;
