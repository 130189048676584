import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth, db, analytics, Timestamp } from "./firebase-config"; // Your Firebase config
import {
  addDoc,
  setDoc,
  collection,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { Country } from "country-state-city";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { ColorRing } from "react-loader-spinner";
import myLogo from "./public/ProRecruitBusinessLogoTransparent2.png";
import {useToast} from "./components/Alerts";
import { logEvent } from "firebase/analytics";
import i18next from "i18next";

function generateUniqueId16(companyName) {
  if (!companyName) {
    return generateRandomId(16); // Or throw an error, or return a default value
  }

  // 1. Sanitize the company name:
  const sanitizedName = companyName
    .toLowerCase()
    .normalize("NFD") // Decompose accented characters
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^a-z0-9]+/g, "") // Keep only letters and numbers
    .substring(0, 10); // Limit length to 10 characters

  // 2. Generate a timestamp-based component (compact):
  const timestamp = Date.now().toString(36); // Convert to base36 for compactness

  // 3. Generate a random component:
  const random = Math.random().toString(36).substring(2, 8); // 6 random characters

  // 4. Combine (ensure 16 total characters):
  const uniqueId = `${sanitizedName
    .padEnd(5, "0")
    .substring(0, 5)}${timestamp.slice(-6)}${random}`;

  return uniqueId.length > 16 ? uniqueId.substring(0, 16) : uniqueId; // Ensure 16 characters (trim if longer)
}

//Helper Function.
function generateRandomId(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

/**
 * Generates the text content for the welcome email.
 * @param {object} companyData - The company data.
 * @return {string} The text content for the welcome email.
 */
// eslint-disable-next-line no-unused-vars, require-jsdoc
function generateWelcomeEmailText(companyData) {
  // ... (build the plain text email body) ...
  return `
    Bonjour ${companyData.name},
  
  Bienvenue sur ProRecruit ! Nous sommes ravis de vous accueillir.
  
  Votre essai gratuit vous permet de gérer jusqu'à 10 candidatures et de découvrir comment ProRecruit peut transformer votre processus de recrutement :
  
  * Présélection automatique des CV grâce à l'IA
  * Élimination des biais inconscients
  * Évaluations psychoanalytiques des candidats
  * Gestion centralisée et simplifiée des candidatures
  * Notifications automatiques pour un suivi optimal
  
  Pour commencer, ajoutez votre première offre d'emploi dès maintenant :
  [Lien direct vers la page d'ajout d'offre d'emploi]
  
  Vous pouvez également consulter notre guide de démarrage rapide pour une prise en main facile :
  [Lien vers le guide de démarrage/vidéo]
  
  Si vous avez des questions, n'hésitez pas à nous contacter. Nous sommes là pour vous aider !
  
  Cordialement,
  
  L'équipe ProRecruit
  https://app.prorecruit.tech/
  
  ---
  Vous recevez cet email car vous vous êtes inscrit à un essai gratuit de ProRecruit.
  Pour vous désabonner, cliquez ici : [Lien de désabonnement]
  `;
}

/**
 * Generates the HTML content for the welcome email.
 * @param {object} companyData - The company data.
 * @return {string} The HTML content for the welcome email.
 */
function generateWelcomeEmailHTML(companyData) {
  return `
    <!DOCTYPE html>
  <html>
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Bienvenue sur ProRecruit !</title>
    <style>
      body { font-family: sans-serif; line-height: 1.6; color: #333; }
      .container { max-width: 600px; margin: 0 auto; padding: 20px; }
      .header { text-align: center; margin-bottom: 30px; }
      .logo { max-width: 150px; } /* Adjust as needed */
      .button { background-color:rgb(81, 131, 16); /* Your primary color */ color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; display: inline-block; margin-top: 20px; }
      .footer { text-align: center; margin-top: 30px; font-size: 0.8em; color: #666; }
      .ul-email { list-style: disc; margin-left: 20px;}
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <img src="https://prorecruit.tech/assets/images/image11.png" alt="ProRecruit Logo" class="logo">  </div>
  
      <h1>Bienvenue sur ProRecruit, ${companyData.name} !</h1>
  
      <p>Nous sommes ravis de vous accueillir. Votre essai gratuit vous permet de gérer jusqu'à 10 candidatures et de découvrir comment ProRecruit peut transformer votre processus de recrutement.</p>
  
      <p><strong>Avantages clés de ProRecruit :</strong></p>
        <ul class="ul-email">
          <li>Présélection automatique des CV grâce à l'IA</li>
          <li>Élimination des biais inconscients</li>
          <li>Évaluations psychoanalytiques des candidats</li>
          <li>Gestion centralisée et simplifiée des candidatures</li>
          <li>Notifications automatiques pour un suivi optimal</li>
        </ul>
  
      <p>Pour commencer, ajoutez votre première offre d'emploi dès maintenant :</p>
      <a href="https://www.prorecruit.tech/" class="button">Ajouter une Offre d'Emploi</a>
  
      <p>Vous pouvez également consulter notre guide de démarrage rapide pour une prise en main facile : <a href="[Lien vers le guide de démarrage/vidéo]">Guide de démarrage</a></p>
  
      <p>Si vous avez des questions, n'hésitez pas à nous contacter.</p>
  
      <p>Cordialement,<br>
      L'équipe ProRecruit</p>
  
      <div class="footer">
        <p>Vous recevez cet email car vous vous êtes inscrit à un essai gratuit de ProRecruit.<br>
        <a href="[Lien de désabonnement]">Se désabonner</a></p>
        <p><a href="https://www.prorecruit.tech/ ">ProRecruit</a></p>
      </div>
    </div>
  </body>
  </html>
  `;
}

function SignUpPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordMatchMessage, setPasswordMatchMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState(null); // Use null, not ""
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState(""); //For affiliate
  const [referer, setReferer] = useState(""); //For affiliate
  const [affiliateID, setAffiliateID] = useState("");
  const { addToast } = useToast();
  // const navigate = useNavigate();
  const { t } = useTranslation("SignUpPage"); // Hook
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language); // Initialize with current language

  logEvent(analytics, "signup_country_change", {
    //You  can  log  details  like  form  id  if  there  is  one,  or  simply  the  name  of  the  page
    form_name: "prorecruit_signup_form",
    page_title: document.title,
  });

    // Function to toggle language
    const toggleLanguage = () => {
      const newLang = currentLanguage === 'en' ? 'fr' : 'en';
      i18next.changeLanguage(newLang);
      setCurrentLanguage(newLang);
  };
  //Detect Browser Language and set on first load
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage; // Get browser language
    const languageCode = browserLanguage.split('-')[0]; // Extract language code (e.g., "en" from "en-US")

    // Only set the language if it's different from the current language and supported
    if (languageCode !== i18next.language && (languageCode === 'en' || languageCode === 'fr')) {
      i18next.changeLanguage(languageCode);
      setCurrentLanguage(languageCode);
    }

 }, []); // Empty dependency array ensures this runs only once on mount


  logEvent(analytics, "signup_country_change", {
    //You  can  log  details  like  form  id  if  there  is  one,  or  simply  the  name  of  the  page
    form_name: "prorecruit_signup_form",
    page_title: document.title,
  });

  useEffect(() => {
    document.title = t("signUpTitle") + " - ProRecruit";

    // Cleanup function
    return () => {
      document.title = "ProRecruit";
    };
  }, [t]); // Dependency array with 't': re-runs if 't' changes (language changes)

  // Get affiliate code.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateCode = urlParams.get("affiliate");
    const refererCode = urlParams.get("referer");
    const referenceCode = urlParams.get("reference");
    logEvent(analytics, "page_view", {
      page_title: document.title, //  Send  current  page  title
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
      affiliateCode: affiliateCode,
      refererCode: refererCode,
      referenceCode: referenceCode,
    });
    console.log("Affiliate Code:", affiliateCode);
    console.log("Referer Code:", refererCode);
    console.log("Reference Code:", referenceCode);
    if (refererCode) {
      setReferer(refererCode);
    }
    if (referenceCode) {
      setReference(referenceCode);
    }
    if (affiliateCode) {
      setAffiliateID(affiliateCode);
      // setReference(affiliateCode); // Keep track in 'reference'
    }
  }, []);

  useEffect(() => {
    if (password !== confirmPassword) {
      setPasswordMatchMessage(t("passwordMismatch"));
    } else {
      setPasswordMatchMessage("");
    }
  }, [password, confirmPassword, t]);

  const showSuccessToast = (m) => {
    addToast({
      type: 'success',
      message: m,
      dismissible: true,
    });
  };
    const showErrorToast = (m) => {
    addToast({
      type: 'error',
      message: m,
        dismissible:true
    });
  };

  const handleCountryChange = (event) => {
    logEvent(analytics, "signup_country_change", {
      //You  can  log  details  like  form  id  if  there  is  one,  or  simply  the  name  of  the  page
      form_name: "prorecruit_signup_form",
      page_title: document.title,
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
    });
    const countryCode = event.target.value;
    setCompanyCountry(Country.getCountryByCode(countryCode)); // Store *object*
  };

  const handlePhoneNumberChange = (e) => {
    logEvent(analytics, "signup_phone_number_change", {
      //You  can  log  details  like  form  id  if  there  is  one,  or  simply  the  name  of  the  page
      form_name: "prorecruit_signup_form",
      page_title: document.title,
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
    });
    const inputValue = e.target.value;

    // Basic input validation (prevent non-numeric characters except +, space, -)
    if (!/^[0-9+ -]*$/.test(inputValue)) {
      return; // Stop if invalid characters
    }
    setPhoneNumber(inputValue); // Update the state *immediately* with the raw input
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(null);
    setPhoneNumberError("");

    // --- Validation ---
    if (password !== confirmPassword) {
      setError(t("passwordMismatch"));
      showErrorToast(t("passwordMismatch"));
      return;
    }

    const number = parsePhoneNumberFromString(phoneNumber);
    if (!phoneNumber) {
      setPhoneNumberError(t("phoneNumberRequired"));
      return;
    }
    if (!number || !isValidPhoneNumber(`${number.number}`)) {
      setPhoneNumberError(t("invalidPhoneNumber"));
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showErrorToast(t("invalidEmail"));
      setError(t("invalidEmail"));
      return;
    }
    if (!companyCountry) {
      // Check if companyCountry is null
      showErrorToast(t("countryRequired"));
      setError(t("countryRequired")); // Use i18next
      return;
    }

    const formattedNumber = number.format("E.164");

    setLoading(true);
    try {
      // 1. Create User (Firebase Auth)
      // console.log(auth)
      // Use the tenant-aware auth instance
      // const tenantAuth = auth.tenantManager().authForTenant(process.env.REACT_APP_DEFAULT_TENANT_ID);
      // await auth.tenantId = tenantId;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // --- Send Verification Email ---
      await sendEmailVerification(user);
      const tenantID = generateUniqueId16(companyName);

      // 2. Prepare Data for Firestore
      const userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: formattedNumber,
        displayName: `${firstName} ${lastName}`,
        companyName: companyName,
        companyAddress: companyAddress,
        companyCity: companyCity,
        companyCountry: companyCountry.name, // Use country NAME
        reference: reference,
        affiliateID: affiliateID, // Use retrieved affiliate ID, if any
        referer: referer, // Use retrieved referer, if any
        tenantId: tenantID, //Use UID, more secure, easier.
        originalTenantId: process.env.REACT_APP_DEFAULT_TENANT_ID,
        createdDate: serverTimestamp(), // Use ISO strings
        modifiedDate: serverTimestamp(),
        basicLicense: 0,
        proLicense: 0,
        fullLicense: 0,
        trialLicense: 1, // Give them a trial license
        canUpdateCompany: true,
        canCreateAssessment:true,
        canAddJobOffer:true,
        hasPurchasedLicense: false,
        lastActivityDate: serverTimestamp(),
      };

      const companyData = {
        // Create companyData
        name: companyName,
        address: companyAddress,
        city: companyCity,
        country: companyCountry, //store the full object.
        primaryContact: email,
        tenantId: tenantID, //Use UID
        originalTenantId: process.env.REACT_APP_DEFAULT_TENANT_ID, // Keep
        createdDate: serverTimestamp(),
        modifiedDate: serverTimestamp(),
        reference: reference,
        affiliateCode: affiliateID,
        referer: referer,
        proLicense: 0,
        fullLicense: 0,
        basicLicense: 0,
        trialLicense: 1, // Give them a trial license.
        //Add missing data.
        candidatesProcessed: 0,
        hasPurchasedLicense: false,
        lastActivityDate: serverTimestamp(),
        hasUsedFreeTrial: false, // They are using free trial.
        hasUsedPaidLicense: false,
      };
      const marketingData = {
        emailStatus: {
          welcomeSent: true,
          featureHighlight1Sent: false,
          featureHighlight2Sent: false,
          usageTipSent: false,
          approachingLimitSent: false,
          limitReachedSent: false,
          reactivationSent: false,
        },
      };

      // 3. Store user and company data in Firestore

      const WelcomeEmailBody = generateWelcomeEmailText(companyData);
      const WelcomeEmailHTML = generateWelcomeEmailHTML(companyData);
      await Promise.all([
        setDoc(doc(db, "users", user.email), { userData }), //user data
        setDoc(doc(db, "company", tenantID), { companyData, marketingData }), //company data
        addDoc(collection(db, "send-grid-emails"), {
          to: userData.email,
          message: {
            subject: "Bienvenue sur ProRecruit ! Démarrez votre essai gratuit.",
            text: WelcomeEmailBody, // Use plain text for simplicity
            html: WelcomeEmailHTML, // Use HTML for styling
          },
        }),
      ]);

      // Log successful signup event
      logEvent(analytics, "sign_up", {
        method: "email", // Or other methods if you offer them (Google, Facebook, etc.)
        tenant_id: tenantID, // Add the tenant ID
        company_name: companyName, //Good Practice.
        company_address: companyAddress,
        company_city: companyCity,
        company_country: companyCountry.name,
        affiliateID: affiliateID,
        referer: referer,
        reference: reference,
      });
      logEvent(analytics, "qualify_lead", {
        currency: "XOF",
        value: 100000,
      });

      // Don't automatically navigate.  Tell the user to check their email.
      // alert(t("verificationEmailSent")); // Use i18n, and use a better UI than alert()
      // navigate("/signin"); // Redirect to the *signin* page
      signOut(auth);
      showSuccessToast(t("verificationEmailSent"));
      setLoading(false);
      setVerificationSent(true);
    } catch (error) {
      console.error("Signup error:", error);

      // Log detailed error information:
      logEvent(analytics, "exception", {
        description: error.message || "Unknown error", //  A human-readable description of the error.
        fatal: false, // Set to 'true' if the error is fatal, 'false' otherwise
        error_code: error.code, // Firebase error code
        page: "SignUp", // Page name
        data: {
          //Additional Contextual Data.
          email: email,
          companyName: companyName,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          companyCountry: companyCountry,
          companyAddress: companyAddress,
          companyCity: companyCity,
        },
      });

      await addDoc(collection(db, "prorecruit_error"), {
        page: "SignUp",
        data: {
          email: email,
          companyName: companyName,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          companyCountry: companyCountry,
          companyAddress: companyAddress,
          companyCity: companyCity,
        },
        message: error.message || "Unknown error",
        stack: error.stack || "No stack trace",
        name: error.name || "Error",
        timestamp: Timestamp.now(),
      });
      let errorMessage = "An unexpected error occurred."; // Default error message

      // Handle specific Firebase Auth errors
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = t("emailInUse"); // Use i18next
          break;
        case "auth/phone-number-already-exists":
          errorMessage = t("phoneInUse"); // Use i18next
          break;
        case "auth/invalid-email":
          errorMessage = t("invalidEmail"); // Use i18next
          break;
        case "auth/weak-password":
          errorMessage = t("weakPassword"); // Use i18next
          break;
        // Add more specific error cases as needed
        default:
        // Keep the default error message for unexpected errors.
      }
      showErrorToast(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="absolute top-4 right-4">
        <button
          onClick={toggleLanguage}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
        >
          {currentLanguage === 'en' ? 'Français' : 'English'}
        </button>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={myLogo} // Replace with your actual logo path
          alt="ProRecruit"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("createAccountTitle")}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {t("or")}{" "}
          <Link
            to="/signin"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t("signInAccount")}
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <p className="mt-2 text-center text-sm text-red-600">{error}</p>
          )}
          {verificationSent ? (
            // Verification Message
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <h3 className="mt-2 text-lg font-medium text-gray-900">
                {t("checkYourEmail")}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("verificationEmailSent")}
              </p>
              <div className="mt-6">
                <Link
                  to="/signin"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("goToLogin")}
                </Link>
              </div>
            </div>
          ) : (
            // Signup Form (as before, but now conditionally rendered)
            <form className="space-y-6" onSubmit={handleSignUp}>
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("firstName")}
                </label>
                <div className="mt-1">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("lastName")}
                </label>
                <div className="mt-1">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("email")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("phoneNumber")}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {/* Flag (conditional rendering based on country) */}
                    {companyCountry && (
                      <span className="text-gray-500 sm:text-sm">
                        {companyCountry.flag}
                      </span>
                    )}
                  </div>
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16  sm:text-sm border-gray-300 rounded-md"
                    placeholder="+228 99 99 99 99"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </div>
                {phoneNumberError && (
                  <p className="mt-2 text-sm text-red-600">
                    {phoneNumberError}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("companyName")}
                </label>
                <div className="mt-1">
                  <input
                    id="companyName"
                    name="companyName"
                    type="text"
                    autoComplete="organization"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="companyAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("companyAddress")}
                </label>
                <div className="mt-1">
                  <input
                    id="companyAddress"
                    name="companyAddress"
                    type="text"
                    autoComplete="street-address"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="companyCity"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("companyCity")}
                </label>
                <div className="mt-1">
                  <input
                    id="companyCity"
                    name="companyCity"
                    type="text"
                    autoComplete="address-level2"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={companyCity}
                    onChange={(e) => setCompanyCity(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="companyCountry"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("companyCountry")}
                </label>
                <div className="mt-1">
                  <select
                    id="companyCountry"
                    name="companyCountry"
                    autoComplete="country-name"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={companyCountry ? companyCountry.isoCode : ""} // Use the isoCode
                    onChange={handleCountryChange}
                  >
                    <option value="">{t("selectCountry")}</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("confirmPassword")}
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {passwordMatchMessage && (
                  <p className="mt-2 text-sm text-red-600">
                    {passwordMatchMessage}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="reference"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("referenceCode")}
                </label>
                <div className="mt-1">
                  <input
                    id="reference"
                    name="reference"
                    type="text"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Code de refernce (facultatif)"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </div>
              </div>

              {error && (
                <p className="mt-2 text-center text-sm text-red-600">{error}</p>
              )}
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {loading ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#e15b64",
                        "#f47e60",
                        "#f8b26a",
                        "#abbd81",
                        "#849b87",
                      ]}
                    />
                  ) : (
                    t("signUpButton")
                  )}
                </button>
              </div>
            </form>
          )}
          <div className="mt-6 text-center">
            {/* Added text here */}
            <p className="text-sm text-gray-600">
              {t("alreadyHaveAccount")}{" "}
              <Link
                to="/signin"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t("signIn")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
