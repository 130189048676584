import { useEffect } from "react";

const CinetPayButton = ({ amount, currency, onPaymentSuccess, onPaymentFailure }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.cinetpay.com/seamless/main.js";
    script.type = "text/javascript";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handlePayment = () => {
    const transactionId = generateTransactionId("PRL")
    if (window.CinetPay) {
      window.CinetPay.setConfig({
        apikey: "65028287966da74a7704381.68605803", // Replace with your API key
        site_id: "5879352", // Replace with your site ID
        notify_url: `${process.env.REACT_APP_API_ENDPOINT}invoice`, // Replace with your notification URL
        mode: "PRODUCTION", // Use "TEST" for testing
        type: "WEB",
        close_after_response: true,
        return_url: `${process.env.REACT_APP_BASE_URL}checkout`,
      });
      console.log(transactionId, 'Starting');

      window.CinetPay.getCheckout({
        transaction_id: transactionId,
        amount,
        currency,
        channels: "MOBILE_MONEY",
        notify_url: `${process.env.REACT_APP_API_ENDPOINT}invoice`, // Replace with your notification URL
        mode: "PRODUCTION", // Use "TEST" for testing
        type: "WEB",
        description: "Payment Description",
        return_url: `${process.env.REACT_APP_BASE_URL}checkout`,
      });
      console.log(transactionId, 'getCheckout');

      window.CinetPay.waitResponse(response => {
        if (response.status === "ACCEPTED") {
            console.log(transactionId, 'success', response);
            onPaymentSuccess(response, transactionId);
        } else if (response.status === "REFUSED") {
            console.log(transactionId, 'REFUSED', response);
            onPaymentSuccess(response, transactionId);
        } else {
          console.log(transactionId, 'failure', response);
          onPaymentFailure(response, transactionId);
        }
      });

      window.CinetPay.onClose(response => {
        if (response.status === "ACCEPTED") {
          console.log(transactionId, 'success', response);
          onPaymentSuccess(response, transactionId);
        } else if (response.status === "REFUSED") {
          console.log(transactionId, 'REFUSED', response);
          onPaymentSuccess(response, transactionId);
        } else { 
          console.log(transactionId, 'failure', response);
          onPaymentFailure(response, transactionId);
        }
      });


      window.CinetPay.onError(function(response) {
        console.log(transactionId, 'onError');
        console.log(transactionId, 'error', response);
        onPaymentFailure(response, transactionId);
    });
    } else {
      console.error("CinetPay script not loaded");
    }
  };

  return <button onClick={handlePayment}>Cinetpay (Paiement mobile et Wave)</button>;
};
function generateTransactionId(prefix = "TXN") {
    const timestamp = Date.now().toString(36); // Convert timestamp to base36 for compactness
    const randomString = Math.random().toString(36).substring(2, 10).toUpperCase(); // Random 8-character alphanumeric
    return `${prefix}-${timestamp}-${randomString}`;
}

export default CinetPayButton;
