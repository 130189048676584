import React, { useState, Fragment, useEffect } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  CheckIcon,
  XMarkIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import "react-tooltip/dist/react-tooltip.css";
import { useToast } from "../components/Alerts";
// import { FaUserCircle } from 'react-icons/fa';

/**
 * UsersTable component to display and manage users.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.adminInfo - Information about the current admin user.  This should
 *   include `companyName`, `originalTenantId`, `tenantId`, and `email`.
 * @param {Array} props.data - An array of user objects to display in the table.  Each user
 *   object should have the following properties: `displayName`, `email`, `role`, `status`,
 *   `canUpdateCompany`, `canCreateAssessment`, `canAddJobOffer`.
 * @param {Function} props.onDataChange - Callback function to be executed when the data in the table is updated.
 * @returns {JSX.Element} The rendered UsersTable component.
 * @throws {TypeError} If `adminInfo` or `data` are not provided or are of incorrect types.
 */
const UsersTable = ({ adminInfo, data, onDataChange }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { t } = useTranslation("usersTable");
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [formErrors, setFormErrors] = useState({}); //for individual field
  const { addToast } = useToast();

  const [userForm, setUserForm] = useState({
    displayName: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    role: "",
    status: "",
    canUpdateCompany: false,
    canCreateAssessment: false,
    canAddJobOffer: false,
  });

  const showSuccessToast = (m) => {
    addToast({
      type: "success",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };
  const showErrorToast = (m) => {
    addToast({
      type: "error",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };

  // Input Validation function, checks all fields at once
  const validateForm = (formData) => {
    const errors = {};

    if (!formData.displayName.trim()) {
      errors.displayName = t("usersTable.errors.displayNameRequired");
    }
    if (!formData.fullName.trim()) {
      errors.fullName = t("usersTable.errors.fullNameRequired");
    }
    if (!formData.email.trim()) {
      errors.email = t("usersTable.errors.emailRequired");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = t("usersTable.errors.emailInvalid");
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = t("usersTable.errors.phoneNumberRequired");
    } else if (!/^\+\d{1,15}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = t("usersTable.errors.phoneNumberInvalid");
    }
    if (!formData.role.trim()) {
      errors.role = t("usersTable.errors.roleRequired");
    }
    if (!formData.status.trim()) {
      errors.status = t("usersTable.errors.statusRequired");
    }

    return errors;
  };

  // useEffect to clear error when modal is closed
  useEffect(() => {
    if (!isEditModalOpen && !isCreateUserModalOpen) {
      setError(null);
      setFormErrors({});
    }
  }, [isEditModalOpen, isCreateUserModalOpen]);

  const onOpenEditModal = (user) => {
    if (adminInfo?.canUpdateCompany === false) {
      const profileIcon = <FontAwesomeIcon icon={faCircleUser} />;
      showErrorToast(
        t("usersTable.noPermissionToUpdateCompany", { profileIcon })
      );
      return;
    }
    const modifiedUser = structuredClone(user);

    if (!Array.isArray(modifiedUser.modifications)) {
      modifiedUser.modifications = [];
    }

    modifiedUser.modifications.push({
      modifiedBy: adminInfo.email,
      modifiedDate: Timestamp.now(),
      modificationType: "Edit",
      beforeModification: structuredClone(user),
    });

    setSelectedUser(modifiedUser);
    setUserForm(modifiedUser);
    setIsEditModalOpen(true);
    setFormErrors({}); // Clear form errors
  };

  const onOpenCreateUserModal = () => {
    if (adminInfo?.canUpdateCompany === false) {
      const profileIcon = <UserCircleIcon className="inline text-xl" />;
      showErrorToast(
        t("usersTable.noPermissionToUpdateCompany", { profileIcon })
      );
      return;
    }
    setSelectedUser({});
    setUserForm({
      displayName: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      role: "",
      status: "",
      canUpdateCompany: false,
      canCreateAssessment: false,
      canAddJobOffer: false,
      companyName: adminInfo.companyName,
      originalTenantId: adminInfo.originalTenantId,
      tenantId: adminInfo.tenantId,
      modifications: [
        {
          modifiedBy: adminInfo.email,
          modifiedDate: Timestamp.now(),
          modificationType: "Create",
        },
      ],
    });
    setIsCreateUserModalOpen(true);
    setFormErrors({});
  };

  const handleEditUser = async () => {
    const errors = validateForm(userForm);
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return; // Stop if there are errors
    }

    setLoading(true);
    setError(null);
    try {
      const userDoc = doc(db, `users/${selectedUser.email}`);
      await updateDoc(userDoc, { ...userForm }); // Update the entire userForm
      // alert(t("usersTable.userUpdatedSuccessfully"));  //removed because of onDataChange
      showSuccessToast(t("usersTable.userUpdatedSuccessfully"));
      setIsEditModalOpen(false);
      onDataChange(); // Notify parent component
    } catch (error) {
      console.error("Error updating user:", error);
      // alert(t("usersTable.updateError")); //removed because of new error handling
      setError(t("usersTable.updateError"));
    } finally {
      setLoading(false);
    }
  };

  const handleCreateUser = async () => {
    console.log(`handleCreateUser`);
    const errors = validateForm(userForm);
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`/api/addUserToTenant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userForm),
      });
      const responseObject = await response.json();

      if (!responseObject.error) {
        //alert(t("usersTable.userCreatedSuccessfully")); //removed
        setIsCreateUserModalOpen(false);
        onDataChange(); // Notify parent component
      } else {
        throw new Error(responseObject.error.message);
      }
    } catch (error) {
      console.error("Error creating user:", error);
      //alert(t("usersTable.createError")); //removed
      setError(t("usersTable.createError"));
    } finally {
      setLoading(false);
    }
  };

  // Generic Change Handler.  Much cleaner.
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Clear the specific field error when the user starts typing
    if (formErrors[name]) {
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  return (
    <>
      {error && (
        <div className="mt-2 text-red-600">
          <ExclamationCircleIcon className="h-6 w-6 inline-block" /> {error}
        </div>
      )}
      <Tooltip id="global-tooltip" float positionStrategy="absolute" />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.name")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.email")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.role")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.status")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.canUpdateCompany")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.canCreateAssessment")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("usersTable.canAddJobOffer")}
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">{t("usersTable.actions")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((user, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.displayName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.role}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.status}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.canUpdateCompany ? (
                          <CheckIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <XMarkIcon className="h-5 w-5 text-red-500" />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.canCreateAssessment ? (
                          <CheckIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <XMarkIcon className="h-5 w-5 text-red-500" />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.canAddJobOffer ? (
                          <CheckIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <XMarkIcon className="h-5 w-5 text-red-500" />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => onOpenEditModal(user)}
                        >
                          {t("usersTable.edit")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <button
                onClick={onOpenCreateUserModal}
                // disabled={!adminInfo?.canUpdateCompany}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t("usersTable.createUser")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit User Modal */}
      <Transition show={isEditModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setIsEditModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild
              as={"Fragment"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" /> */}
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {t("usersTable.editUser")}
                    </DialogTitle>
                    {error && ( //global error on top
                      <div className="mt-2 text-red-600">
                        <ExclamationCircleIcon className="h-6 w-6 inline-block" />{" "}
                        {error}
                      </div>
                    )}
                    <div className="mt-2">
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="displayName"
                        >
                          {t("usersTable.displayName")}
                        </label>
                        <input
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            formErrors.displayName ? "border-red-500" : ""
                          }`}
                          id="displayName"
                          type="text"
                          name="displayName"
                          value={userForm.displayName}
                          onChange={handleInputChange}
                        />
                        {formErrors.displayName && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.displayName}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="email"
                        >
                          {t("usersTable.email")}
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="email"
                          type="email"
                          name="email"
                          value={userForm.email}
                          onChange={handleInputChange}
                          disabled // Email should be disabled for editing
                        />
                        {/* No error message for email since it's disabled */}
                      </div>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="role"
                        >
                          {t("usersTable.role")}
                        </label>
                        <input
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            formErrors.role ? "border-red-500" : ""
                          }`}
                          id="role"
                          type="text"
                          name="role"
                          value={userForm.role}
                          onChange={handleInputChange}
                        />
                        {formErrors.role && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.role}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="status"
                        >
                          {t("usersTable.status")}
                        </label>
                        <input
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            formErrors.status ? "border-red-500" : ""
                          }`}
                          id="status"
                          type="text"
                          name="status"
                          value={userForm.status}
                          onChange={handleInputChange}
                        />
                        {formErrors.status && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.status}
                          </p>
                        )}
                      </div>

                      {/* Toggle for canUpdateCompany */}
                      <div className="mb-4">
                        <label className="flex items-center space-x-2">
                          <span className="text-gray-700 text-sm font-bold">
                            {t("usersTable.canUpdateCompany")}
                          </span>
                          <Tooltip
                            id="canUpdateCompanyEdit-tooltip"
                            place="top"
                            effect="solid"
                          />
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            data-tooltip-id="canUpdateCompanyEdit-tooltip"
                            data-tooltip-content={t(
                              "usersTable.canUpdateCompanyTooltip"
                            )}
                            className="text-gray-400 cursor-pointer"
                          />
                          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              name="canUpdateCompany"
                              id="canUpdateCompany"
                              checked={userForm.canUpdateCompany}
                              onChange={handleInputChange}
                              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            />
                            <label
                              htmlFor="canUpdateCompany"
                              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                          </div>
                        </label>
                      </div>

                      {/* Toggle for canCreateAssessment */}
                      <div className="mb-4">
                        <label className="flex items-center space-x-2">
                          <span className="text-gray-700 text-sm font-bold">
                            {t("usersTable.canCreateAssessment")}
                          </span>
                          <Tooltip
                            id="canCreateAssessmentEdit-tooltip"
                            place="top"
                            effect="solid"
                          />
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            data-tooltip-id="canCreateAssessmentEdit-tooltip"
                            data-tooltip-content={t(
                              "usersTable.canCreateAssessmentTooltip"
                            )}
                            className="text-gray-400 cursor-pointer"
                          />

                          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              name="canCreateAssessment"
                              id="canCreateAssessment"
                              checked={userForm.canCreateAssessment}
                              onChange={handleInputChange}
                              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            />
                            <label
                              htmlFor="canCreateAssessment"
                              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                          </div>
                        </label>
                      </div>

                      {/* Toggle for canAddJobOffer */}
                      <div className="mb-4">
                        <label className="flex items-center space-x-2">
                          <span className="text-gray-700 text-sm font-bold">
                            {t("usersTable.canAddJobOffer")}
                          </span>
                          <Tooltip
                            id="canAddJobOfferEdit-tooltip"
                            place="top"
                            effect="solid"
                          />
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            data-tooltip-id="canAddJobOfferEdit-tooltip"
                            data-tooltip-content={t(
                              "usersTable.canAddJobOfferTooltip"
                            )}
                            className="text-gray-400 cursor-pointer"
                          />
                          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              name="canAddJobOffer"
                              id="canAddJobOffer"
                              checked={userForm.canAddJobOffer}
                              onChange={handleInputChange}
                              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            />
                            <label
                              htmlFor="canAddJobOffer"
                              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        onClick={handleEditUser}
                        disabled={loading} // Disable when loading
                      >
                        {loading
                          ? t("usersTable.saving")
                          : t("usersTable.save")}
                      </button>
                    </div>{" "}
                    <div className="py-2">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-48 py-2 bg-red-400 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsEditModalOpen(false)}
                      >
                        {t("usersTable.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Create User Modal */}
      <Transition show={isCreateUserModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setIsCreateUserModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild
              as={"Fragment"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" /> */}
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Your content modal */}
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {t("usersTable.createUser")}
                    </DialogTitle>

                    {error && (
                      <div className="mt-2 text-red-600">
                        <ExclamationCircleIcon className="h-6 w-6 inline-block" />{" "}
                        {error}
                      </div>
                    )}
                    <div className="mt-2">
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          {" "}
                          {t("usersTable.displayName")}:
                        </label>
                        <input
                          type="text"
                          name="displayName"
                          value={userForm.displayName}
                          required
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.displayName ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.displayName && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.displayName}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          {t("usersTable.fullName")}:
                        </label>
                        <input
                          type="text"
                          name="fullName"
                          value={userForm.fullName}
                          required
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.fullName ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.fullName && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.fullName}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          {t("usersTable.phoneNumber")}:
                        </label>
                        <input
                          type="tel"
                          name="phoneNumber"
                          value={userForm.phoneNumber}
                          required
                          pattern="^\+\d{1,15}$"
                          placeholder="+1234567890"
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.phoneNumber ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.phoneNumber && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.phoneNumber}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          Email:
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={userForm.email}
                          required
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.email ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.email && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.email}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          Role:
                        </label>
                        <input
                          type="text"
                          name="role"
                          value={userForm.role}
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.role ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.role && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.role}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                          Status:
                        </label>
                        <input
                          type="text"
                          name="status"
                          value={userForm.status}
                          onChange={handleInputChange}
                          className={`block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                            formErrors.status ? "border-red-500" : ""
                          }`}
                        />
                        {formErrors.status && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.status}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* Toggle for canUpdateCompany */}
                    <div className="mb-4">
                      <label className="flex items-center space-x-2">
                        <span className="text-gray-700 text-sm font-bold">
                          {t("usersTable.canUpdateCompany")}
                        </span>
                        <Tooltip
                          id="canUpdateCompanyCreate-tooltip"
                          place="top"
                          effect="solid"
                        />
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id="canUpdateCompanyCreate-tooltip"
                          data-tooltip-content={t(
                            "usersTable.canUpdateCompanyTooltip"
                          )}
                          className="text-gray-400 cursor-pointer"
                        />

                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            id="canUpdateCompany"
                            type="checkbox"
                            name="canUpdateCompany"
                            checked={userForm.canUpdateCompany}
                            onChange={handleInputChange}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          />
                          <label
                            htmlFor="canUpdateCompany"
                            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </label>
                    </div>
                    {/* Toggle for canCreateAssessment */}
                    <div className="mb-4">
                      <label className="flex items-center space-x-2">
                        <span className="text-gray-700 text-sm font-bold">
                          {t("usersTable.canCreateAssessment")}
                        </span>
                        <Tooltip
                          id="canCreateAssessmentCreate-tooltip"
                          place="top"
                          effect="solid"
                        />
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id="canCreateAssessmentCreate-tooltip"
                          data-tooltip-content={t(
                            "usersTable.canCreateAssessmentTooltip"
                          )}
                          className="text-gray-400 cursor-pointer"
                        />

                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            name="canCreateAssessment"
                            id="canCreateAssessment"
                            checked={userForm.canCreateAssessment}
                            onChange={handleInputChange}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          />
                          <label
                            htmlFor="canCreateAssessment"
                            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </label>
                    </div>

                    {/* Toggle for canAddJobOffer */}
                    <div className="mb-4">
                      <label className="flex items-center space-x-2">
                        <span className="text-gray-700 text-sm font-bold">
                          {t("usersTable.canAddJobOffer")}
                        </span>
                        <Tooltip
                          id="canAddJobOfferCreate-tooltip"
                          place="top"
                          effect="solid"
                        />
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id="canAddJobOfferCreate-tooltip"
                          data-tooltip-content={t(
                            "usersTable.canAddJobOfferTooltip"
                          )}
                          className="text-gray-400 cursor-pointer"
                        />

                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            name="canAddJobOffer"
                            id="canAddJobOffer"
                            checked={userForm.canAddJobOffer}
                            onChange={handleInputChange}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          />
                          <label
                            htmlFor="canAddJobOffer"
                            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={handleCreateUser}
                      disabled={loading} // Disable when loading
                    >
                      {loading
                        ? t("usersTable.creatingUser")
                        : t("usersTable.createUser")}
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-48 py-2 bg-red-400 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setIsCreateUserModalOpen(false)}
                    >
                      {t("usersTable.cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

UsersTable.propTypes = {
  adminInfo: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    originalTenantId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    canUpdateCompany: PropTypes.bool,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      canUpdateCompany: PropTypes.bool.isRequired,
      canCreateAssessment: PropTypes.bool.isRequired,
      canAddJobOffer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onDataChange: PropTypes.func.isRequired,
};

export default UsersTable;
