import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { openCandidateDetailsModal } from "../utils/redux/candidateSlice";
import CandidateScoreReason from "./CandidateScoreReason";
import CandidateStatusUpdateModal from "./CandidateStatusUpdateModal";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faStar,
  faClipboardCheck,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import JobPostingDescription from "./JobPostingDescription";
import i18next from "i18next";

const CandidateItem = ({ candidate, tenantId, jobUuid }) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language); // Initialize with current language

  const dispatch = useDispatch();
  const { t } = useTranslation("CandidateItem"); // Use 'global' or your specific namespace

  //Detect Browser Language and set on first load
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage; // Get browser language
    const languageCode = browserLanguage.split("-")[0]; // Extract language code (e.g., "en" from "en-US")

    // Only set the language if it's different from the current language and supported
    if (
      languageCode !== i18next.language &&
      (languageCode === "en" || languageCode === "fr")
    ) {
      i18next.changeLanguage(languageCode);
      setCurrentLanguage(languageCode);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleOpenModal = () => {
    dispatch(openCandidateDetailsModal(candidate));
  };
  const handleOpenStatusModal = (event) => {
    event.stopPropagation(); // Prevent the card click from triggering
    setIsStatusModalOpen(true);
  };

  // Helper function for consistent status styling.
  const getStatusStyle = (status) => {
    switch (status) {
      case "Offered":
        return "text-green-500 bg-green-100";
      case "Rejected":
      case "Rejeter":
        return "text-red-500 bg-red-100";
      default:
        return "text-gray-700 bg-gray-100";
    }
  };
  const getScoreStyle = (score) => {
    if (score > 50) {
      return "text-green-500";
    } else {
      return "text-red-500";
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-xl p-4 mb-4 border border-gray-200 hover:shadow-xl transition duration-200 ease-in-out cursor-pointer">
      <CandidateStatusUpdateModal
        isOpen={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        candidate={candidate}
        tenantId={tenantId}
        jobUuid={jobUuid}
        // onUpdateStatus={handleUpdateJobPostingStatus}  //  You'll need to implement this if you have a callback
      />

      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center" onClick={handleOpenModal}>
          <FontAwesomeIcon
            icon={faUser}
            className="text-gray-500 mr-2"
            size="lg"
          />
          <h2
            className={`text-xl font-semibold ${
              ["Rejeter", "Rejected"].includes(candidate.ApplicationStatus)
                ? "text-red-700"
                : "text-blue-800"
            }`}
          >
            {candidate.FullName}
            {["Rejeter", "Rejected"].includes(candidate.ApplicationStatus) && (
              <span className="ml-2 text-red-600 text-sm">
                ({t("candidateItem.rejected")})
              </span>
            )}
          </h2>
        </div>
        <button
          onClick={handleOpenStatusModal}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-full text-sm transition duration-200 ease-in-out"
        >
          {t("candidateItem.changeStatus")}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="text-gray-700 flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="text-gray-400 mr-2" />
            {candidate.ContactInformation
              ? candidate.ContactInformation.Email
              : ""}
          </p>
          <p className="text-gray-700 flex items-center mt-1">
            <FontAwesomeIcon icon={faPhone} className="text-gray-400 mr-2" />
            {candidate.ContactInformation
              ? candidate.ContactInformation.Phone
              : ""}
          </p>

          <p className="text-gray-700 mt-4 flex items-center">
            <FontAwesomeIcon
              icon={
                ["Rejeter", "Rejected"].includes(candidate.ApplicationStatus)
                  ? faTimesCircle
                  : faClipboardCheck
              }
              className="text-gray-400 mr-2"
            />
            {t("candidateItem.status")}:{" "}
            <span
              className={`font-semibold px-2 py-1 rounded-full ${getStatusStyle(
                candidate.ApplicationStatus
              )}`}
            >
              {candidate.ApplicationStatus}
            </span>
          </p>

          <p className="text-gray-700 mt-2 flex items-center">
            <FontAwesomeIcon icon={faStar} className={`text-gray-400 mr-2`} />
            {t("candidateItem.ranking")}:{" "}
            <span className={getScoreStyle(candidate.score_list?.total_score)}>
              {candidate.score_list?.total_score}/100 -{" "}
              {t("candidateItem.experiences")} 60,{" "}
              {t("candidateItem.educations")} 20
            </span>
          </p>
          <p className="text-gray-700 mt-2">
            {t("candidateItem.explanation")}:{" "}
            <span className="text-gray-600">
              <JobPostingDescription
                description={candidate.score_list?.summary?.[i18next.languages[0]]}
              />
            </span>
          </p>
        </div>

        <div>
          {candidate.assessmentNames?.map((
            assessment // Use optional chaining
          ) => (
            <div
              key={assessment.link}
              className="bg-gray-50 rounded-lg p-3 mb-3 border border-gray-200"
            >
              <p className="text-gray-700 flex items-center">
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  className="text-gray-400 mr-2"
                />
                <strong>{t("candidateItem.assessmentTest")}:</strong>{" "}
                {assessment.title}
              </p>
              <p className="text-gray-700">
                {t("candidateItem.score")}:{" "}
                <span className={getScoreStyle(assessment.score)}>
                  {assessment.score}/100
                </span>
              </p>
              <a
                className="text-blue-600 hover:underline block mt-1"
                href={`${process.env.REACT_APP_BASE_URL}assessmentresults/${assessment.link}`}
                rel="noreferrer"
                target="_blank"
              >
                {t("candidateItem.assessmentResult")}
              </a>
              <p className="text-gray-700 mt-2">
                {t("candidateItem.explanation")}:{" "}
                <span className="text-gray-600">{assessment.description}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Define prop types for CandidateItem
CandidateItem.propTypes = {
  candidate: PropTypes.shape({
    FullName: PropTypes.string.isRequired,
    ApplicationStatus: PropTypes.string.isRequired,
    ContactInformation: PropTypes.shape({
      Email: PropTypes.string,
      Phone: PropTypes.string,
    }),
    score_list: PropTypes.object.isRequired,
    assessmentNames: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        link: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ),
  }).isRequired,
  tenantId: PropTypes.string.isRequired,
  jobUuid: PropTypes.string.isRequired,
};

export default CandidateItem;
