import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firebase-config";

const AssessmentResults = () => {
  const { assessmentID, testID } = useParams();
  const [assessment, setAssessment] = useState(null);

  const docRef = doc(db, "candidates_assessments", assessmentID);
    useEffect(() => {
      document.title = "Assessment Results Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    const fetchAssessment = async () => {
      getDoc(docRef).then((doc) => {
        if (doc.exists) {
          setAssessment(doc.data());
        } else {
          console.error("No such document!");
        }
      });
    };

    fetchAssessment();
  }, [docRef]);

  if (!assessment) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4" style={styles.assessmentResults}>
      <h1>Assessment Results</h1>
      <div style={styles.assessmentHeader}>
        <p>
          <strong>Candidate Name:</strong> {assessment.fullName}
        </p>
        <p>
          <strong>Assessment Name:</strong>{" "}
          {assessment.assessments[testID].title}
        </p>
        <p>
          <strong>Assessment Description:</strong>{" "}
          {assessment.assessments[testID].description}
        </p>
        <p>
          <strong>Score:</strong> {assessment.assessments[testID].score}/100
        </p>
        <p>
          <strong>Context Menu Count:</strong>{" "}
          {assessment.assessments[testID].contextMenuCount}
        </p>
        <p>
          <strong>Lost Focus Count:</strong>{" "}
          {assessment.assessments[testID].lostFocusCount}
        </p>
      </div>
      {assessment.assessments[testID].questions.map((question, index) => (
        <div
          key={index}
          className="bg-blue-50 shadow-md rounded-lg p-4"
          style={styles.questionSection}
        >
          <h3>Question {index + 1} :</h3>
          <p>{question.system.question}</p>
          <p>
            <strong>Score:</strong> {question.user.score}
          </p>
          {question.user.answer.map((answer, idx) => (
            <p
              key={idx}
              style={{
                ...styles.answerOption,
                ...(answer.score > 0 ? styles.correctAnswer : {}),
                ...(answer.isSelected ? styles.selectedAnswer : {}),
              }}
            >
              {idx + 1 + "- "}
              {answer.score > 0
                ? answer.isSelected
                  ? answer.choice + "✔️ 👈"
                  : answer.choice
                : answer.isSelected
                ? answer.choice + "🚫 👈 "
                : answer.choice}
              <hr style={{ borderTop: "1px solid lightgrey" }}></hr>
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  assessmentResults: {
    padding: "20px",
  },
  assessmentHeader: {
    marginBottom: "20px",
  },
  questionSection: {
    marginTop: "20px",
  },
  answerOption: {
    padding: "5px",
  },
  correctAnswer: {
    backgroundColor: "#d4edda",
  },
  selectedAnswer: {
    fontWeight: "bold",
  },
};

export default AssessmentResults;
