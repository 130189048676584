// src/firebase-config.js
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth"; //  getAuth, NOT initializeAuth
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, Timestamp } from "firebase/firestore";

let app, auth, analytics, db, storage;

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

if (!getApps().length) {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  // Initialize Firebase Authentication and get a reference to the service
  auth = getAuth(app); // Use getAuth for client-side
  auth.useDeviceLanguage();
  auth.tenantId = process.env.REACT_APP_DEFAULT_TENANT_ID;
  analytics = getAnalytics(app);
  storage = getStorage(app);
  db = getFirestore(app);
} else {
  app = getApp();
  auth = getAuth(app);
  auth.tenantId = process.env.REACT_APP_DEFAULT_TENANT_ID;
  analytics = getAnalytics(app);
  storage = getStorage(app, process.env.REACT_APP_STORAGE_BUCKET);
  db = getFirestore(app);
}

export { auth, analytics, db, Timestamp, storage, ref, getDownloadURL, logEvent };