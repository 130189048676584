import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import Link and useNavigate
import { useAuth } from "../useAuth"; // Import your authentication context
import WelcomePopup from "../components/WelcomePopup";

function GettingStartedGuide() {
  const { t, i18n } = useTranslation("GettingStartedGuide"); // Use the 'global' namespace
  const location = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  //Detect Browser Language and set on first load
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage; // Get browser language
    console.log(browserLanguage);
    const languageCode = browserLanguage.split("-")[0]; // Extract language code (e.g., "en" from "en-US")
    console.log(languageCode);
    console.log(i18n.language);
    // Only set the language if it's different from the current language and supported
    if (
      languageCode !== i18n.language &&
      (languageCode === "en" || languageCode === "fr")
    ) {
      i18n.changeLanguage(languageCode);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    document.title = t("gettingStartedTitle") + " - ProRecruit";

    return () => {
      document.title = "ProRecruit";
    };
  }, [t]); // Depend on 't'
  // Function to go to dashboard
  const goToDashboard = () => {
    navigate("/dashboard"); // Redirect to the dashboard
  };
  console.log(location.state);
  console.log(location.state?.shouldSeeWelcomePopup != null);

  return (
    <>
      <WelcomePopup
        shouldOpen={
          location.state?.shouldSeeWelcomePopup != null
            ? location.state.shouldSeeWelcomePopup
            : true
        }
      />
      <div className="font-sans antialiased text-gray-900 bg-gray-50 min-h-screen">
        <button
          onClick={goToDashboard}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("goToDashboard")}
        </button>
        <div className="max-w-4xl mx-auto p-6 lg:p-10">
          <header className="text-center mb-12">
            <h1 className="text-4xl font-extrabold text-primary-600">
              {t("guideTitle")}
            </h1>
            <h2 className="text-2xl font-bold text-primary-700">
              {t("guideSubtitle")}
            </h2>
            <p className="mt-4 text-lg text-gray-700">{t("guideIntro")}</p>
            <p className="mt-2 text-base text-gray-600">
              <strong>{t("guideImportant")}:</strong> {t("guideFreeTrialLimit")}
            </p>
          </header>

          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              {t("step1Title")}
            </h2>

            <div className="bg-white rounded-lg shadow-md p-6">
              {/*Link to dashboard only if logged in, otherwise, don't show it.*/}
              {currentUser ? (
                <>
                  <p className="mb-4 text-gray-700">
                    {t("step1IntroLoggedIn")}
                    <Link
                      to="/dashboard"
                      className="text-blue-600 hover:underline"
                    >
                      {t("goToDashboard")}
                    </Link>
                  </p>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">
                    {t("step1Subtitle")}
                  </h3>
                  <p className="text-gray-700">{t("dashBoardIntro")}</p>
                </>
              ) : (
                <p className="mb-4 text-gray-700">
                  {t("step1IntroLoggedOut")}
                  <Link to="/signup" className="text-blue-600 hover:underline">
                    {t("createAccount")}
                  </Link>
                  <br />
                  {t("alreadyHaveAccount")}
                  <Link to="/login" className="text-blue-600 hover:underline">
                    {t("login")}
                  </Link>
                </p>
              )}
              <p className="mt-4 text-gray-700">
                <strong className="text-gray-900">{t("whyImportant")}:</strong>{" "}
                {t("step1WhyImportant")}
              </p>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              {t("step2Title")}
            </h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="mb-4 text-gray-700">{t("step2Intro")}</p>
              <ol className="list-decimal list-inside space-y-2 text-gray-700">
                <li>{t("step2Instruction1")}</li>
                <li>
                  <strong className="text-gray-900">{t("jobTitle")}:</strong>{" "}
                  {t("step2Instruction2")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("jobDescription")}:
                  </strong>{" "}
                  {t("step2Instruction3")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("requiredSkills")}:
                  </strong>{" "}
                  {t("step2Instruction4")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("location")}:</strong>{" "}
                  {t("step2Instruction5")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("contractType")}:
                  </strong>{" "}
                  {t("step2Instruction6")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("salary")}:</strong>{" "}
                  {t("step2Instruction7")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("affiliateCode")}:
                  </strong>{" "}
                  {t("step2Instruction8Opt")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("applicationInstructions")}:
                  </strong>{" "}
                  {t("step2Instruction9")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("assessment")}:</strong>{" "}
                  {t("step2Instruction10")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("preview")}:</strong>{" "}
                  {t("step2Instruction11")}
                </li>
                <li>{t("step2Instruction12")}</li>
              </ol>
              <p className="mt-4 text-gray-700">
                <strong className="text-gray-900">{t("tips")}:</strong>
                {t("step2Tips")}
              </p>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              {t("step3Title")}
            </h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="mb-4 text-gray-700">{t("step3Intro")}</p>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                {t("accessApplications")}
              </h3>
              <p className="text-gray-700">{t("step3Instruction1")}</p>
              <h3 className="text-xl font-semibold mt-4 mb-2 text-gray-800">
                {t("keyFeatures")}
              </h3>
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>
                  <strong className="text-gray-900">
                    {t("automatedScreening")}:
                  </strong>{" "}
                  {t("step3Feature1")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("candidateProfiles")}:
                  </strong>{" "}
                  {t("step3Feature2")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("statusTracking")}:
                  </strong>{" "}
                  {t("step3Feature3")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("notesAndTags")}:
                  </strong>{" "}
                  {t("step3Feature4")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("communication")}:
                  </strong>{" "}
                  {t("step3Feature5")}
                </li>
                <li>
                  <strong className="text-gray-900">
                    {t("schedulingInterviews")}:
                  </strong>{" "}
                  {t("step3Feature6")}
                </li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              {t("additionalTips")}
            </h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>
                  <strong className="text-gray-900">
                    {t("notifications")}:
                  </strong>{" "}
                  {t("additionalTip1")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("filters")}:</strong>{" "}
                  {t("additionalTip2")}
                </li>
                <li>
                  <strong className="text-gray-900">{t("support")}:</strong>{" "}
                  {t("additionalTip3")}{" "}
                  <a href="/support" className="text-blue-600 hover:underline">
                    {t("supportLink")}
                  </a>
                </li>
              </ul>
            </div>
          </section>

          <footer className="mt-12 text-center">
            {currentUser ? (
              <div className="mt-4">
                <button
                  onClick={() => navigate("/dashboard")}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("goToDashboard")}
                </button>
              </div>
            ) : (
              <div className="mt-4">
                <p className="text-lg text-gray-700">
                  <Link to="/signup" className="text-blue-600 hover:underline">
                    {t("createAccount")}
                  </Link>{" "}
                  {t("or")}{" "}
                  <Link to="/signin" className="text-blue-600 hover:underline">
                    {t("signIn")}
                  </Link>{" "}
                  {t("toAccessDashboard")}
                </p>
                <p className="mt-4 text-lg text-gray-700">
                  {t("conclusion1")}{" "}
                  <Link to="/pricing" className="text-blue-600 hover:underline">
                    {t("pricingLink")}
                  </Link>{" "}
                </p>
              </div>
            )}
            <p className="mt-4 text-gray-700">{t("conclusion2")}</p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default GettingStartedGuide;
