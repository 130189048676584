import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TawkToChat from "../components/TawkToChat"
import {
  fetchCandidates,
  setSelectedTab,
  setSortBy,
  setFilterKey,
  setFilterValue,
  selectAllCandidates,
  selectCandidatesLoading,
  selectCandidatesError,
  selectSelectedTab,
  selectSortBy,
  selectFilterKey,
  selectFilterValue,
  selectSelectedCandidates,
} from "../utils/redux/candidateSlice";
import { ColorRing } from "react-loader-spinner";
import * as XLSX from "xlsx";
import Navbar from "../components/Navbar";
import JobPostingDescription from "../components/JobPostingDescription";
import CandidateList from "../components/CandidateList";
import CandidateDetailsModal from "../components/CandidateDetailsModal";
import { signOut } from "firebase/auth";
import {
  selectAllUsers,
  selectUserByEmail,
  fetchUserByEmail,
} from "../utils/redux/userDataSlice";
import { useAuth } from "../useAuth";


const CANDIDATES_PER_PAGE = 10;

const CandidateListPage = () => {
  const { jobID, tenantID } = useParams();
  const { currentUser } = useAuth();
  const { state } = useLocation();
  const dispatch = useDispatch();
    const userData = useSelector((state) => selectUserByEmail(state, currentUser.email));
  const candidates = useSelector(selectAllCandidates);
  console.log(candidates)
  const selectedCandidates = useSelector(selectSelectedCandidates);
  console.log(selectedCandidates)
  const loading = useSelector(selectCandidatesLoading);
  console.log(loading)
  const error = useSelector(selectCandidatesError);
  console.log(error)
  const selectedTab = useSelector(selectSelectedTab);
  console.log(selectedTab)
  const sortBy = useSelector(selectSortBy);
  console.log(sortBy)
  const filterKey = useSelector(selectFilterKey);
  console.log(filterKey)
  const filterValue = useSelector(selectFilterValue);
  console.log(filterValue)

  const [currentPage, setCurrentPage] = useState(1);
  const [reportButtonBusy, setReportButtonBusy] = useState(false);

    useEffect(() => {
      document.title = "Candidates List Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchCandidates({ tenantID, jobID }));
      dispatch(fetchUserByEmail(currentUser.email));
    }
    fetchData()
  }, [tenantID, jobID, dispatch, currentUser]);

  // Calculate the index range for the current page
  console.log(selectedCandidates)
  const indexOfLastCandidate = currentPage * CANDIDATES_PER_PAGE;
  const indexOfFirstCandidate = indexOfLastCandidate - CANDIDATES_PER_PAGE;
  const currentCandidates = selectedCandidates.slice(
    indexOfFirstCandidate,
    indexOfLastCandidate
  );

  const handleTabChange = (tab) => {
    console.log(candidates)
    dispatch(setSelectedTab({ tab, allCandidates: candidates }));
    setCurrentPage(1);
  };

  const handleSortChange = (sortByValue) => {
    dispatch(setSortBy({ sortKey: sortByValue }));
    setCurrentPage(1);
  };

  const handleFilterKeyChange = (e) => {
    dispatch(setFilterKey(e.target.value));
    setCurrentPage(1);
  };

  const handleFilterValueChange = (e) => {
    console.log(e)
    dispatch(setFilterValue({ filterKey, filterValue: e }));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onReportClick = () => {
    try {
      setReportButtonBusy(true);

      const reportArray = candidates.map((candidate) => ({
        // ... your report data mapping ...
      }));

      const worksheet = XLSX.utils.json_to_sheet(reportArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "DataSheet.xlsx");

      setReportButtonBusy(false);
    } catch (error) {
      setReportButtonBusy(false);
      console.error("Error generating report:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center">
        <h1 className="text-3xl font-bold text-red-500 mb-4">Error</h1>
        <p className="text-lg text-gray-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Navbar
        onSignOut={() => dispatch(signOut())}
        onCreateJob={() => {
          /* Handle create job logic */
        }}
        userData={userData}
        extraButton={{ label: "Liste Des Offres", path: "/jobpostings" }}
      />

      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold text-center mb-6 text-blue-800">
          Liste des candidats pour {state.posting.Titre} -{" "}
          {state.posting.numberOfApplicants}
        </h1>

        {/* Filter and Sort Bar */}
        <div className="flex flex-wrap items-center mb-6">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <label
              htmlFor="sort"
              className="block text-gray-700 font-bold mb-2"
            >
              Trier par:
            </label>
            <select
              id="sort"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={sortBy}
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <option key="Score" value="Score">
                Score
              </option>
              <option key="ApplicationDate" value="ApplicationDate">
                Date
              </option>
            </select>
          </div>

          <div className="w-full md:w-2/3">
            <div className="flex items-center">
              <div className="w-1/2 mr-4">
                <label
                  htmlFor="filterKey"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Filtrer:
                </label>
                <select
                  id="filterKey"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={filterKey}
                  onChange={handleFilterKeyChange}
                >
                  <option
                    key="ContactInformation.Email"
                    value="ContactInformation.Email"
                  >
                    Email
                  </option>
                  <option key="FullName" value="FullName">
                    Nom
                  </option>
                  <option key="Sex" value="Sex">
                    Sexe
                  </option>
                  <option key="Skills" value="Skills">
                    Compétences
                  </option>
                  {/* Add more filter options */}
                </select>
              </div>

              <div className="w-1/2">
                <input
                  className="shadow appearance-none border rounded w-full mt-4 pt-4 py-0 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Filtrer value"
                  value={filterValue}
                  onChange={(e) => handleFilterValueChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Tab Bar */}
        <div className="flex border-b border-gray-300 mb-6">
          {["all", "Rejeter", "Evaluation", "Interview", "Offer"].map((tab) => (
            <button
              key={tab}
              className={`py-2 px-4 font-medium text-gray-700 hover:text-blue-500 focus:outline-none ${
                selectedTab === tab
                  ? "border-b-2 border-blue-500"
                  : "border-b-2 border-transparent"
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab === "all" ? "Tout" : tab}
            </button>
          ))}
        </div>

        {/* Job Posting Description */}
        <div className="bg-white shadow rounded p-6 mb-6">
          <JobPostingDescription description={state.posting.Description} />
        </div>

        {/* Report Button */}
        <button
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
          type="button"
          onClick={onReportClick}
          disabled={reportButtonBusy}
        >
          Generate Report
        </button>

        {/* Candidate List */}
        <CandidateList candidates={currentCandidates} tenantId={tenantID} jobUuid={jobID} />

        {/* Pagination */}
        <div className="flex justify-center mt-6">
          {[...Array(Math.ceil(selectedCandidates.length / CANDIDATES_PER_PAGE))].map(
            (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={`px-3 py-1 rounded-md mx-1 focus:outline-none ${
                  currentPage === i + 1
                    ? "bg-blue-500 text-white"
                    : "bg-white text-gray-700 hover:bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      </div>

      {/* Modals */}
      <CandidateDetailsModal />
      <TawkToChat />
      {/* <StatusUpdateModal />  We'll use the inline version in CandidateItem */}
    </div>
  );
};

export default CandidateListPage;