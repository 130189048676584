import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import logo from './your-logo.png'; // Replace with your logo path!
import logo from "../public/ProRecruitBusinessLogoTransparent2.png";
import { db } from "../firebase-config"; // Import your Firebase config
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function ContactPage() {
  const { t } = useTranslation("ContactPage");
  const [formData, setFormData] = useState({
    fullName: "",
    workEmail: "",
    companyName: "",
    jobTitle: "",
    phoneNumber: "",
    referralSource: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
    useEffect(() => {
      document.title = "Contact Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.fullName.trim()) {
      formIsValid = false;
      errors.fullName = t("fullNameError"); // Use translation key
    }

    if (!formData.workEmail.trim()) {
      formIsValid = false;
      errors.workEmail = t("workEmailError"); // Use translation key
    } else if (!/\S+@\S+\.\S+/.test(formData.workEmail)) {
      formIsValid = false;
      errors.workEmail = t("workEmailInvalid"); // Use translation key
    }

    if (!formData.companyName.trim()) {
      formIsValid = false;
      errors.companyName = t("companyNameError"); // Use translation key
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Form Data:", formData);
      try {
        formData.submittedAt = serverTimestamp();
        // Send data to Firestore
        const docRef = await addDoc(
          collection(db, "demo_request"),
          formData
        );
        console.log("Document written with ID: ", docRef.id);
        // setMessage(t('signupSuccess'));  // Use i18next
      } catch (error) {
        // Handle errors
        console.error("Error adding document: ", error);
        setErrors(t("signupError") + error.message);
      } finally {
        setIsSubmitted(true);
      }
    }
  };

  if (isSubmitted) {
    return (
      <div className="container mx-auto p-4">
        <div className="text-left mb-8">
          <img src={logo} alt={t("logoAlt")} className="h-12" />
        </div>
        <div className="bg-gray-100 p-8 rounded-lg shadow-md text-center">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">
            {t("thankYouTitle")}
          </h2>
          <p className="text-lg">{t("thankYouMessage")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="text-left mb-8">
        <img src={logo} alt={t("logoAlt")} className="h-14" />
      </div>
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">{t("headline")}</h1>
        <p className="text-lg mb-8">{t("subheadline")}</p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="fullName" className="text-left font-bold mb-1">
              {t("fullNameLabel")}
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder={t("fullNamePlaceholder")}
              className={`border rounded-md p-2 ${
                errors.fullName ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              required
            />
            {errors.fullName && (
              <div className="text-red-500 text-sm mt-1 text-left">
                {errors.fullName}
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="workEmail" className="text-left font-bold mb-1">
              {t("workEmailLabel")}
            </label>
            <input
              type="email"
              id="workEmail"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleChange}
              placeholder={t("workEmailPlaceholder")}
              className={`border rounded-md p-2 ${
                errors.workEmail ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              required
            />
            {errors.workEmail && (
              <div className="text-red-500 text-sm mt-1 text-left">
                {errors.workEmail}
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="companyName" className="text-left font-bold mb-1">
              {t("companyNameLabel")}
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder={t("companyNamePlaceholder")}
              className={`border rounded-md p-2 ${
                errors.companyName ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              required
            />
            {errors.companyName && (
              <div className="text-red-500 text-sm mt-1 text-left">
                {errors.companyName}
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="jobTitle" className="text-left font-bold mb-1">
              {t("jobTitleLabel")}
            </label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              placeholder={t("jobTitlePlaceholder")}
              className="border rounded-md p-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="phoneNumber" className="text-left font-bold mb-1">
              {t("phoneNumberLabel")}
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder={t("phoneNumberPlaceholder")}
              className="border rounded-md p-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="referralSource"
              className="text-left font-bold mb-1"
            >
              {t("referralSourceLabel")}
            </label>
            <select
              id="referralSource"
              name="referralSource"
              value={formData.referralSource}
              onChange={handleChange}
              className="border rounded-md p-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">{t("referralSourcePlaceholder")}</option>
              <option value="Google">{t("referralSourceGoogle")}</option>
              <option value="Social Media">
                {t("referralSourceSocialMedia")}
              </option>
              <option value="Referral">{t("referralSourceReferral")}</option>
              <option value="Other">{t("referralSourceOther")}</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="message" className="text-left font-bold mb-1">
              {t("messageLabel")}
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t("messagePlaceholder")}
              rows="4"
              className="border rounded-md p-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="text-left">
            <p>
              {t("privacyTextPreLink")}{" "}
              <a
                href="/privacy-policy"
                className="text-blue-600 hover:underline"
              >
                {t("privacyTextLink")}
              </a>
            </p>
          </div>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {t("buttonText")}
          </button>
        </form>

        <div className="mt-8">
          <p>{t("socialProofText")}</p>
          {/* Add your logos here */}
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
