import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { auth } from "../firebase-config";
import {
  Bars3Icon,
  XMarkIcon,
  GlobeAltIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import myLogo from "../public/ProRecruitBusinessLogoTransparent2.png";
import { useAuth } from "../useAuth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// --- Sub-components ---

const LanguageDropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
  changeLanguage,
}) => {
  const { t } = useTranslation("Navbar");

  return (
    <div className="relative">
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="text-gray-900 bg-transparent hover:text-blue-500"
        title={t("change_language")}
      >
        <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1 z-50">
          <button
            onClick={() => changeLanguage("en")}
            className="block w-full text-left px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-100"
          >
            English
          </button>
          <button
            onClick={() => changeLanguage("fr")}
            className="block w-full text-left px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-100"
          >
            Français
          </button>
        </div>
      )}
    </div>
  );
};

const DesktopNavItem = ({ onClick, children, className = "" , title = ""}) => (
  <button
    onClick={onClick}
    className={`text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500 ${className}`}
    title={title}
  >
    {children}
  </button>
);

const MobileNavItem = ({ onClick, children, className = "" }) => (
  <button
    onClick={onClick}
    className={`block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500 ${className}`}
  >
    {children}
  </button>
);

// --- Main Navbar Component ---

export default function Navbar({ onCreateJob, extraButton, userData }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSolid, setIsSolid] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation("Navbar");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSolid(offset > 80);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

    const signout = useCallback(
        async (event) => {
            function noop() { }
            event ? event.preventDefault() : noop();
            //Added a check for userData
            if (!userData || !userData.tenantId) {
                console.error("userData or tenantId is missing.");
                signOut(auth); // still sign out of auth.
                return;
            }

            fetch(`${API_ENDPOINT}api/v1/usersession/sessionLogout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    tenantId: userData.tenantId,
                }),
                credentials: "include",
            })
                .then((r) => {
                    signOut(auth).catch((error) => {
                        console.error(
                            `Sign-out failed. currentUser ${JSON.stringify(
                                currentUser
                            )}, ${error}`
                        );
                    });
                })
                .catch((error) => {
                    console.error(
                        `Sign-out failed. currentUser ${JSON.stringify(
                            currentUser
                        )}, ${error}`
                    );
                });
        },
        [currentUser, userData]
    );

  const handleLogout = async () => {
    try {
      await signout();
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleExtraButton = () => {
    navigate(extraButton.path);
  };

  const handleDashboardButton = () => {
    navigate("/dashboard");
  };

  const handleSupportClick = () => {
    navigate("/support", { target: "_blank" });
  };

  const handleDocumentationClick = () => {
    navigate("/documentation", { target: "_blank" });
  };


  const handleProfileClick = () => {
    navigate("/profile");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  return (
    <header className="sticky top-0 z-50">
      <nav
        className={`mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 ${
          isSolid ? "bg-prorecruitbgcolor" : "bg-gradient-to-b"
        }`}
        aria-label="Global"
      >
        {/* Logo */}
        <div className="flex lg:flex-1">
          <Link to="/dashboard" className="-m-1.5 p-1.5">
            <span className="sr-only">ProRecruit</span>
            <img className="h-12 w-auto" src={myLogo} alt="ProRecruit" />
          </Link>
        </div>

        {/* Desktop Navigation Items */}
        <div className="hidden lg:flex lg:gap-x-4">
          {extraButton?.label && extraButton?.path && (
            <DesktopNavItem
              onClick={handleExtraButton}
              className={`${isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
              title={t(extraButton.title)}
            
            >
              {t(extraButton.title)}
            </DesktopNavItem>
          )}
          {currentUser && (
            <DesktopNavItem
              onClick={onCreateJob}
              className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
            >
              {t("Creer Offre D'Emploi")}
            </DesktopNavItem>
          )}
          {currentUser && (
            <DesktopNavItem
              onClick={handleDashboardButton}
              className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
            >
              {t("Dashboard")}
            </DesktopNavItem>
          )}
          {/* Support and Documentation Links (Desktop) */}
          <DesktopNavItem onClick={handleSupportClick}
          className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
          title={t("support")}
          >
            <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
          </DesktopNavItem>
          <DesktopNavItem onClick={handleDocumentationClick}
          className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
          title={t("documentation")}
          >
            <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
          </DesktopNavItem>
          <DesktopNavItem onClick={handleProfileClick} 
          className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} px-3 py-2 rounded`}
          title={t("profile")}
          >
            <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
          </DesktopNavItem>
        </div>

        {/* Language and User/signin */}
        <div className={`${!isSolid ? "bg-bgTransparent" : "bg-navBarMiddleButtonBg"} flex items-center gap-x-4`}>
          <LanguageDropdown
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            changeLanguage={changeLanguage}
          />

          {currentUser ? (
            <div className="hidden lg:flex items-center">
              <p className="text-sm font-semibold leading-6 text-gray-900 mr-2">
                {t("Bienvenue ,")} {currentUser.displayName}
              </p>
              <DesktopNavItem
                onClick={handleLogout}
                className="bg-logoutButtonColor px-3 py-1 rounded"
              >
                {t("Se déconnecter")}
              </DesktopNavItem>
            </div>
          ) : (
            <Link
              to="/signin"
              className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
            >
              {t("Se connecter")}
            </Link>
          )}

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 hover:text-blue-500"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Transition show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="lg:hidden fixed inset-0 z-50 overflow-y-auto"
          onClose={setMobileMenuOpen}
        >
            <div className="min-h-screen px-4 text-center flex items-center justify-center">
           

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <TransitionChild
              as={"Fragment"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" onClick={() => setMobileMenuOpen(false)}/> */}
            </TransitionChild>

            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                {/* ... rest of your mobile menu content, including close button and links ... */}
                 <div className="flex items-center justify-between mb-4">
                    <Link to="/" onClick={() => setMobileMenuOpen(false)} className="mr-auto">
                    <span className="sr-only">ProRecruit</span>
                    <img className="h-12 w-auto" src={myLogo} alt="ProRecruit" />
                    </Link>
                    <button
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 hover:text-blue-500"
                    onClick={() => setMobileMenuOpen(false)}
                    >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 px-2 space-y-4">
                    {/* Mobile Menu Items */}
                    {currentUser && (
                    <>
                        <MobileNavItem onClick={() => { onCreateJob(); setMobileMenuOpen(false); }}>
                        {t("Creer Offre D'Emploi")}
                        </MobileNavItem>

                        <MobileNavItem onClick={() => { handleDashboardButton(); setMobileMenuOpen(false); }}>
                            {t("Dashboard")}
                        </MobileNavItem>

                        <MobileNavItem onClick={() => { handleSupportClick(); setMobileMenuOpen(false) }}>
                            <div className="flex items-center">
                                <QuestionMarkCircleIcon className="h-6 w-6 mr-2" aria-hidden="true"/>
                                <span>{t("Support")}</span>
                            </div>
                        </MobileNavItem>

                        <MobileNavItem  onClick={() => { handleDocumentationClick(); setMobileMenuOpen(false); }}>
                            <div className="flex items-center">
                                 <DocumentTextIcon className="h-6 w-6 mr-2" aria-hidden="true"/>
                                <span>{t("Documentation")}</span>
                            </div>
                        </MobileNavItem>


                        <p className="text-sm font-semibold leading-6 text-gray-900">
                        {t("Bienvenue ,")} {currentUser.displayName}
                        </p>
                        <MobileNavItem onClick={() => { handleLogout(); setMobileMenuOpen(false); }}>
                            {t("Se déconnecter")}
                        </MobileNavItem>
                    </>
                    )}
                    {!currentUser && (
                    <Link
                        to="/signin"
                        onClick={() => setMobileMenuOpen(false)}
                        className="block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
                    >
                        {t("Se connecter")}
                    </Link>
                    )}
                    <LanguageDropdown
                        isDropdownOpen={isDropdownOpen}
                        setIsDropdownOpen={setIsDropdownOpen}
                        changeLanguage={changeLanguage}
                        />

                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </header>
  );
}