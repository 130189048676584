import axios from 'axios';

// ExchangeRate-API URL (free tier)
const API_URL = 'https://v6.exchangerate-api.com/v6/YOUR_API_KEY/latest/'; // Replace YOUR_API_KEY with your actual API key

// Function to get exchange rate and apply the 5% fee
const convertCurrencyWithFee = async (amount, fromCurrency, toCurrency) => {
  console.log(amount, fromCurrency, toCurrency)
  const apiKey = 'a50e974ceb6ef2a271c559a7'; // Replace with your actual API key from ExchangeRate-API
  const baseUrl = `https://v6.exchangerate-api.com/v6/${apiKey}/latest/${fromCurrency}`;

  try {
    // Fetch exchange rates from the API
    const response = await fetch(baseUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch exchange rates');
    }

    const data = await response.json();
    if (data.result !== 'success') {
      throw new Error('API response error');
    }

    // Get the exchange rate for the specified currency
    const rate = data.conversion_rates[toCurrency];
    console.log(`conversion rate is ${rate}`)
    if (!rate) {
      throw new Error('Currency not supported');
    }

    // Convert the amount
    const convertedAmount = amount * rate;

    // Apply the 5% fee
    const feeAdjustedAmount = convertedAmount * 1.05;

    console.log(`amount: ${amount} convertedAmount: ${convertedAmount} - feeAdjustedAmount: ${feeAdjustedAmount} - Difference: ${feeAdjustedAmount-convertedAmount}`);

    return feeAdjustedAmount;

  } catch (error) {
    console.error('Error:', error);
    throw new Error('Unable to fetch exchange rate');
  }
};

export default convertCurrencyWithFee;
