// PrivacyPolicy.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation("PrivacyPolicy");
    useEffect(() => {
      document.title = "Privacy Policy Page - ProRecruit"; // Set the title dynamically
  
      // Cleanup function: Reset the title when the component unmounts
      return () => {
        document.title = "ProRecruit"; // Reset to a default title
      };
    }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{t('privacyPolicy.title')}</h1>
      <p className="text-gray-600 mb-4">{t('privacyPolicy.effectiveDate')}: {t('privacyPolicy.effectiveDateValue')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.introduction.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.introduction.body')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.informationWeCollect.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.informationWeCollect.intro')}</p>
      <ul className="list-disc pl-5 mb-4 space-y-2">
        <li>
          <strong>{t('privacyPolicy.informationWeCollect.personalInformation.title')}</strong>: {t('privacyPolicy.informationWeCollect.personalInformation.description')}
          <ul className="list-disc pl-5 mt-2 space-y-1">
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.name')}</li>
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.email')}</li>
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.phone')}</li>
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.jobTitle')}</li>
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.company')}</li>
            <li>{t('privacyPolicy.informationWeCollect.personalInformation.examples.other')}</li>
          </ul>
        </li>
        <li>
          <strong>{t('privacyPolicy.informationWeCollect.usageData.title')}</strong>: {t('privacyPolicy.informationWeCollect.usageData.description')}
          <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>{t('privacyPolicy.informationWeCollect.usageData.examples.ip')}</li>
              <li>{t('privacyPolicy.informationWeCollect.usageData.examples.browser')}</li>
              <li>{t('privacyPolicy.informationWeCollect.usageData.examples.pagesVisited')}</li>
              <li>{t('privacyPolicy.informationWeCollect.usageData.examples.referringPage')}</li>
              <li>{t('privacyPolicy.informationWeCollect.usageData.examples.timestamps')}</li>
          </ul>
        </li>
        <li>
          <strong>{t('privacyPolicy.informationWeCollect.cookies.title')}</strong>: {t('privacyPolicy.informationWeCollect.cookies.description')}
            <a href="#cookies" className="text-blue-600 hover:underline">{t('privacyPolicy.informationWeCollect.cookies.linkText')}</a>.
        </li>
      </ul>


      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.howWeUseInformation.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.howWeUseInformation.intro')}</p>
      <ul className="list-disc pl-5 mb-4 space-y-2">
        <li>{t('privacyPolicy.howWeUseInformation.purposes.provideServices')}</li>
        <li>{t('privacyPolicy.howWeUseInformation.purposes.personalizeExperience')}</li>
        <li>{t('privacyPolicy.howWeUseInformation.purposes.communicate')}</li>
        <li>{t('privacyPolicy.howWeUseInformation.purposes.improveServices')}</li>
        <li>{t('privacyPolicy.howWeUseInformation.purposes.legalObligations')}</li>
        <li>{t('privacyPolicy.howWeUseInformation.purposes.protectInterests')}</li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.informationSharing.title')}</h2>
       <p className="mb-4">{t('privacyPolicy.informationSharing.intro')}</p>
        <ul className="list-disc pl-5 mb-4 space-y-2">
          <li>
            <strong>{t('privacyPolicy.informationSharing.serviceProviders.title')}</strong>: {t('privacyPolicy.informationSharing.serviceProviders.description')}
          </li>
          <li>
            <strong>{t('privacyPolicy.informationSharing.legalRequirements.title')}</strong>: {t('privacyPolicy.informationSharing.legalRequirements.description')}
          </li>
          <li>
            <strong>{t('privacyPolicy.informationSharing.businessTransfers.title')}</strong>: {t('privacyPolicy.informationSharing.businessTransfers.description')}
          </li>
            <li>
            <strong>{t('privacyPolicy.informationSharing.consent.title')}</strong>: {t('privacyPolicy.informationSharing.consent.description')}
          </li>
        </ul>


      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.dataSecurity.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.dataSecurity.description')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.yourRights.title')}</h2>
        <p className="mb-4">{t('privacyPolicy.yourRights.intro')}</p>
         <ul className="list-disc pl-5 mb-4 space-y-2">
                <li>{t('privacyPolicy.yourRights.access')}</li>
                <li>{t('privacyPolicy.yourRights.rectification')}</li>
                <li>{t('privacyPolicy.yourRights.erasure')}</li>
                <li>{t('privacyPolicy.yourRights.restrictProcessing')}</li>
                <li>{t('privacyPolicy.yourRights.dataPortability')}</li>
                <li>{t('privacyPolicy.yourRights.object')}</li>
                <li>{t('privacyPolicy.yourRights.withdrawConsent')}</li>
         </ul>
        <p>{t('privacyPolicy.yourRights.exercise')}</p>

        <h2 id="cookies" className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.cookies.title')}</h2>
        <p className="mb-4">{t('privacyPolicy.cookies.description')}</p>
         <ul className="list-disc pl-5 mb-4 space-y-2">
            <li>
                <strong>{t('privacyPolicy.cookies.essential.title')}</strong>: {t('privacyPolicy.cookies.essential.description')}
            </li>
             <li>
                <strong>{t('privacyPolicy.cookies.analytics.title')}</strong>: {t('privacyPolicy.cookies.analytics.description')}
            </li>
            <li>
                <strong>{t('privacyPolicy.cookies.marketing.title')}</strong>: {t('privacyPolicy.cookies.marketing.description')}
            </li>
         </ul>
        <p>{t('privacyPolicy.cookies.manage')}</p>


      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.internationalTransfers.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.internationalTransfers.description')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.childrensPrivacy.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.childrensPrivacy.description')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.changesToPrivacyPolicy.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.changesToPrivacyPolicy.description')}</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">{t('privacyPolicy.contactUs.title')}</h2>
      <p className="mb-4">{t('privacyPolicy.contactUs.description')}</p>
    </div>
  );
}

export default PrivacyPolicy;