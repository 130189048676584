import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import TawkToChat from "../components/TawkToChat";
import { analytics, db, Timestamp } from "../firebase-config";
import useDateUtils from "../utils/fromStringToTimestamp";
import { useToast } from "../components/Alerts";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  increment,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useAuth } from "../useAuth";
import { ColorRing } from "react-loader-spinner";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
// import DialogflowMessenger from "../components/DialogflowMessenger";
import CreateJobModal from "../components/createJobModal";
import Navbar from "../components/Navbar";
import StatusModal from "../components/StatusModal";
import JobPostingActions from "../components/JobPostingActions";
import { sanitizeInput } from "../utils/inputSanitizer";
import JobPostingDescription from "../components/JobPostingDescription";
import { useSelector, useDispatch } from "react-redux";
import LicenseRequiredModal from "../components/LicenseRequiredModal";
import {
  fetchJobPostings,
  selectJobPostings,
  selectJobPostingsError,
  loadMoreJobPostings,
  selectJobPostingsLoading,
} from "../utils/redux/jobPostingsSlice";
import {
  // selectAllUsers,
  selectUserByEmail,
  fetchUserByEmail,
} from "../utils/redux/userDataSlice";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const JobPostingsPage = () => {
  const { t } = useTranslation("jobpostingpage");
  const { currentUser } = useAuth();
  const { stringToTimestamp } = useDateUtils();
  const dispatch = useDispatch();
  const userData = useSelector((state) =>
    selectUserByEmail(state, currentUser.email)
  );
  const companyData = useSelector(selectAllCompanys);
  const jobPostings = useSelector(selectJobPostings);
  const loading = useSelector(selectJobPostingsLoading);
  const [disableButtons, setDisableButtons] = useState(false);
  const error = useSelector(selectJobPostingsError);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [currentJobPosting, setCurrentJobPosting] = useState();
  const [newJobData, setNewJobData] = useState({});
  const [pageURL, setPageURL] = useState(0);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    document.title = "Job Postings Page - ProRecruit"; // Set the title dynamically

    // Cleanup function: Reset the title when the component unmounts
    return () => {
      document.title = "ProRecruit"; // Reset to a default title
    };
  }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    document.title = t("JobPostingsTitle") + " - ProRecruit";

    // Cleanup function
    return () => {
      document.title = "ProRecruit";
    };
  }, [t]); // Dependency array with 't':  re-runs if 't' changes (language changes)

  useEffect(() => {
    setPageURL(window.location.href);
  }, [pageURL]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        console.log(currentUser);
        setUserId(analytics, currentUser ? currentUser.uid : "");
        setUserProperties(analytics, {
          fullName: currentUser ? currentUser.displayName : "unknown",
          email: currentUser ? currentUser.email : "unknown",
        });
        // Wait for fetchUserByEmail to complete:
        const u = await dispatch(fetchUserByEmail(currentUser.email));
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));

        console.log(c.payload);
        console.log(u.payload);
        if (c.payload.proLicense + c.payload.basicLicense + c.payload.trialLicense <= 0) {
          setHasAnyLicense(false);
        } else {
          setHasAnyLicense(true);
        }
        console.log(c);
        await dispatch(fetchJobPostings({ tenantId: u.payload.tenantId }));
      }
    };
    fetchData(); // Call the async function
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (userData) {
      dispatch(fetchJobPostings({ tenantId: userData.tenantId }));
    }
  }, [userData, dispatch]);

  const showSuccessToast = (m) => {
    addToast({
      type: "success",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };
  const showErrorToast = (m) => {
    addToast({
      type: "error",
      message: m,
      dismissible: true,
      autoDismissTime: 10000,
    });
  };

  const handleCreateJobChange = (e) => {
    // console.log(newJobData);
    // console.log(e);
    // Check if e is an event or a value from react-select
    if (e && e.target) {
      // Handle standard input change
      setNewJobData({ ...newJobData, [e.target.name]: e.target.value });
    } else {
      // Handle react-select change for assessments
      console.log(e);

      // Merge new assessments with the existing ones
      setNewJobData((newJobData) => ({
        ...newJobData,
        assessments: Array.isArray(e)
          ? e
          : [...(newJobData.assessments || []), e],
      }));
    }
  };

  const handleGetLicense = () => {
    // Logique pour rediriger l'utilisateur vers la page d'achat de licence
    window.location.href = "/checkout"; // Exemple
  };

  const handleLearnMore = () => {
    // Logique pour rediriger l'utilisateur vers la page d'informations sur les licences
    window.location.href = "/pricing"; // Exemple
  };
  const generateShortCode = async (docRef) => {
    const applicationUrlIdDocRef = doc(db, "job", "5tdpJAv6JNwmawkDKPSH");
    const applicationUrlIdDoc = await getDoc(applicationUrlIdDocRef);

    if (applicationUrlIdDoc.exists()) {
      const data = applicationUrlIdDoc.data();
      // while (true) {
      let applicationUrlId = Math.random().toString(36).substring(2, 8);
      if (!data[applicationUrlId]) {
        await setDoc(
          applicationUrlIdDocRef,
          { [applicationUrlId]: docRef },
          { merge: true }
        );
        return applicationUrlId;
      }
      // }
    } else {
      console.error("Document '5tdpJAv6JNwmawkDKPSH' not found!");
      throw new Error("Document not found for short code generation");
    }
  };

  const handleOpenStatusModal = (posting) => {
    logEvent(analytics, "select_content", {
      content_type: "ClickChangeStatus",
      content_id: posting.uuid,
    });
    // console.log(posting);
    setCurrentJobPosting(posting);
    setIsStatusModalOpen(true);
  };

  const handleUpdateJobPostingStatus = async (uuid, newStatus) => {
    logEvent(analytics, "select_content", {
      content_type: "ChangedJobPostingStatus",
      content_id: `${currentJobPosting.uuid}${newStatus}`,
    });

    try {
      const updatedJobPosting = { ...currentJobPosting, Statut: newStatus };
      // console.log(userData);
      // console.log(currentJobPosting);

      await fetch(
        `${API_ENDPOINT}api/v1/tenants/${userData.tenantId}/jobpostings/${currentJobPosting.uuid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedJobPosting),
        }
      );
      if (
        updatedJobPosting.Statut === "Completer" ||
        updatedJobPosting.Statut === "Annuler"
      ) {
        const payload = {
          documentId: updatedJobPosting.uuid,
          tenantId: userData.tenantId,
          status: updatedJobPosting.Statut,
          tenantName: updatedJobPosting.Entreprise,
          recruiterName: userData.displayName,
          recruiterEmail: userData.email,
          jobTitle: updatedJobPosting.Titre,
          fromCollectionName: "active",
          collectionName:
            updatedJobPosting.Statut === "Completer" ? "completed" : "canceled",
        };

        await fetch(`${API_ENDPOINT}api/v1/tasks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}cancel-job-descriptions`,
            payload: payload,
          }),
        });
      }

      setIsStatusModalOpen(false);
    } catch (error) {
      console.error("Error updating job posting status:", error);
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
    }
  };

  const toggleCreateModal = () => {
    logEvent(analytics, "select_content", {
      content_type: "ClickCreateJobPosting",
      content_id: "New",
    });
    // console.log(hasAnyLicense);
    // console.log(basicLicenseCount);
    // console.log(proLicenseCount);
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };

  const handleCreateJobSubmit = async (event) => {
    event.preventDefault();
    setDisableButtons(true);
    console.log(event.target)

    try {
      const formData = new FormData(event.target);
      const newJobDataObject = Object.fromEntries(formData.entries());
      const sanitizedJobData = Object.fromEntries(
        Object.entries(newJobDataObject).map(([key, value]) => [
          key,
          sanitizeInput(value),
        ])
      );

      logEvent(analytics, "select_content", {
        content_type: "handleCreateJobSubmit",
        content_id: newJobDataObject ? newJobDataObject.Titre : "",
      });
      console.log(sanitizedJobData);
      const docRef = doc(
        collection(db, `job-descriptions/${userData.tenantId}/active`)
      );

      if (!sanitizedJobData.jobOfferType) {
        sanitizedJobData.jobOfferType = "Free";
      }

      const applicationUrlId = await generateShortCode(docRef);
      var licenseUpdateJson = {};
      if (sanitizedJobData.jobOfferType.toLowerCase().includes("basic")) {
        licenseUpdateJson = {
          "companyData.basicLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "basic";
      }

      if (sanitizedJobData.jobOfferType.toLowerCase().includes("pro")) {
        licenseUpdateJson = {
          "companyData.proLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "pro";
      }
      if (sanitizedJobData.jobOfferType.toLowerCase().includes("free")) {
        licenseUpdateJson = {
          "companyData.trialLicense": increment(-1),
          "companyData.lastActivityDate": Timestamp.now(),
        };
        sanitizedJobData.type = "free";
      }

      const jobPosting = {
        licenseType: sanitizedJobData.type,
        JobID: applicationUrlId,
        resumeAnalysisQuota:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 10
            : 3,
        resumeLimit:
          sanitizedJobData.jobOfferType === "pro"
            ? 30000
            : sanitizedJobData.jobOfferType === "free"
            ? 10
            : 1000,
        type: sanitizedJobData.jobOfferType,
        uuid: docRef.id,
        Titre: sanitizedJobData.Titre || "",
        Entreprise: sanitizedJobData.Entreprise,
        EntrepriseDescription: sanitizedJobData.DescriptionDeLentreprise,
        Lieu: sanitizedJobData.Lieu,
        TypeDePoste: sanitizedJobData.TypeDePoste,
        Secteur: sanitizedJobData.Secteur,
        CategorieDeRole: sanitizedJobData.CategorieDeRole,
        Description: sanitizedJobData.Description,
        Qualifications: sanitizedJobData.Qualifications,
        NiveauDExperience: sanitizedJobData.NiveauDExperience,
        CompetencesRequises: sanitizedJobData.CompetencesRequises
          ? sanitizedJobData.CompetencesRequises.split(",")
          : [],
        additionalInformation: sanitizedJobData.additionalInformation,
        // FourchetteDeSalaire: sanitizedJobData.FourchetteDeSalaire,
        Avantages: sanitizedJobData.Avantages
          ? sanitizedJobData.Avantages.split(",")
          : [],
        DateLimiteDeCandidature: stringToTimestamp(
          sanitizedJobData.DateLimiteDeCandidature
        ),
        PublicationDate: stringToTimestamp(sanitizedJobData.DateDePublication),
        ProcedureDeCandidature: "",
        minimumRelevantYearsOfExperience:
          newJobDataObject.minimumRelevantYearsOfExperience,
        linkToApply: `${process.env.REACT_APP_BASE_URL}jobs/${applicationUrlId}`,
        Responsibilities: sanitizedJobData.Responsibilities || "",
        InformationsDeContact: {
          NomDuContactRH: sanitizedJobData.NomDuContactRH,
          Email: sanitizedJobData.Email,
          Telephone: sanitizedJobData.Telephone,
        },
        CreatedBy: {
          fullName: userData.displayName || "",
          email: userData.email,
          uuid: userData.uuid || "",
        },
        createdOn: serverTimestamp(),
        updatedOn: serverTimestamp(),
        Statut: "Submissions",
        tenantId: userData.tenantId,
        language: sanitizedJobData.language,
        // LogoDeLentreprise: sanitizedJobData.LogoDeLentreprise,
        // DeclarationDeDiversite: sanitizedJobData.DeclarationDeDiversite,
      };

      // console.log(sanitizedJobData);
      // console.log(docRef);

      await setDoc(docRef, jobPosting);
      const companyRef = doc(collection(db, `company`), `${userData.tenantId}`);
      await updateDoc(companyRef, licenseUpdateJson);

      // console.log(putCompany);

      logEvent(analytics, "createdJobPosting", {
        tenantId: userData.tenantId,
        jobId: jobPosting.uuid,
        email: userData.email,
      });
/* 
      await fetch(`${API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}job-description-txt-builder`,
          payload: jobPosting,
        }),
      }); */
      showSuccessToast(t("jobCreatedSuccess"));
      setDisableButtons(false);
      // alert(t("jobCreatedSuccess"));
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating job posting:", error);
      setDisableButtons(false);
      // alert(t("errorCreatingJobPosting"));

      // Log error to Sentry
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error, "error creating a new job offer");
      }
      showErrorToast(t("errorCreatingJobPosting"));

      // Log error to Firestore
      try {
        await addDoc(collection(db, "prorecruit_error"), {
          page: "JobCreationPage",
          data: {
            companyName: companyData[0].name || "",
            tenantId: userData.tenantId || "",
            email: userData.email || "",
            uuid: userData.uuid || "",
            displayName: userData.displayName || "",
          },
          url: pageURL,
          message: error.message || "Unknown error",
          stack: error.stack || "No stack trace",
          name: error.name || "Error",
          timestamp: Timestamp.now(),
        });
      } catch (firestoreError) {
        console.error("Error saving error log to Firestore:", firestoreError);
        if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
          Sentry.captureException(
            error,
            "Error sending to proreceruit error collection"
          );
        }
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center">
        <h1 className="text-3xl font-bold text-red-500 mb-4">
          500 Internal Server Error
        </h1>
        <p className="text-lg text-gray-600">
          {t("errorLoading")}: {error}
        </p>
      </div>
    );
  }

  return (
    <>
      <Navbar
        // onSignOut={handleSignOut}
        onCreateJob={toggleCreateModal}
        userData={userData}
      />
      {isLicenseModalOpen && (
        <LicenseRequiredModal
          isOpen={isLicenseModalOpen}
          onClose={() => setIsLicenseModalOpen(false)}
          onGetLicense={handleGetLicense}
          onLearnMore={handleLearnMore}
        />
      )}

      <CreateJobModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateModal}
        onSubmit={handleCreateJobSubmit}
        onChange={handleCreateJobChange}
        disableButtons={disableButtons}
        data={newJobData}
      />

      <StatusModal
        isOpen={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        currentJobPosting={currentJobPosting}
        onUpdateStatus={handleUpdateJobPostingStatus}
      />

      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800">
            {t("jobPostings")}: {jobPostings.length}
          </h1>
        </div>

        {jobPostings.length > 0 ? (
          <ul>
            {jobPostings.map((posting, index) => (
              <li
                key={posting.uuid}
                className={`bg-white rounded-lg shadow-md p-6 mb-4 ${
                  index !== 0 ? "mt-6" : ""
                }`}
              >
                <Link
                  to={`/jobpostings/${posting.uuid}/tenants/${posting.tenantId}`}
                  className="text-xl font-bold text-blue-500 hover:underline"
                >
                  {posting.Titre} - {posting.JobID}
                </Link>
                <p className="text-gray-600">
                  {posting.Entreprise}, {posting.Lieu}
                </p>
                <a
                  className="text-blue-500 hover:underline"
                  href={
                    posting.linkToApply
                      ? `${process.env.REACT_APP_BASE_URL}jobs/${posting.JobID}`
                      : `${process.env.REACT_APP_BASE_URL}applications/${posting.uuid}/tenants/${userData.tenantId}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("applyLink")}
                </a>
                <p
                  className={
                    posting.DateLimiteDeCandidature + 86400000 - 1 >
                    Timestamp.now().toMillis()
                      ? "text-gray-500"
                      : "text-red-600"
                  }
                >
                  {t("applicationDeadline")}:{" "}
                  {new Date(
                    posting.DateLimiteDeCandidature
                  ).toLocaleDateString()}
                </p>
                <p className="text-gray-600">
                  {t("Status")}:{" "}
                  <span
                    className={
                      posting.Statut === "Offres" ? "text-green-500" : ""
                    }
                  >
                    {posting.Statut}
                  </span>{" "}
                  <button
                    onClick={() => handleOpenStatusModal(posting)}
                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
                  >
                    {t("changeStatus")}
                  </button>
                </p>
                <Link
                  to={`/candidates/${posting.uuid}/tenants/${posting.tenantId}`}
                  state={{ userData, posting }}
                  className="text-blue-500 hover:underline"
                >
                  {t("viewCandidates")}:{" "}
                  {posting.numberOfApplicants ? posting.numberOfApplicants : 0}
                </Link>
                <JobPostingDescription description={posting.Description} />
                <JobPostingActions
                  posting={posting}
                  userData={userData}
                  onOpenStatusModal={handleOpenStatusModal}
                />
                {/* ... (rest of your job posting content) ... */}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">{t("noJobToDisplay")}</p>
        )}
        {/* Load More Button */}
        {jobPostings.length > 0 && (
          <div className="flex justify-center mt-6">
            <button
              onClick={() =>
                dispatch(loadMoreJobPostings({ tenantId: userData.tenantId }))
              }
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {t("loadMore")}
            </button>
          </div>
        )}
      </div>
      <TawkToChat />

      {/* {showChatbot && (
        <DialogflowMessenger
          userId={userData.uuid}
          userParams={{ name: userData.displayName }}
        />
      )} */}
    </>
  );
};

export default JobPostingsPage;
