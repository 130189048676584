import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import i18n from './i18n'; // Import your i18n configuration
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../public/ProRecruitBusinessLogoTransparent2.png";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import i18next from "i18next";
import { logEvent } from "firebase/analytics";
import { analytics, db } from "../firebase-config";
// import { current } from "@reduxjs/toolkit";

function HiringCostCalculator() {
  const { t, i18n } = useTranslation("HiringCostCalculator");
  //Get email from url if exist
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const [providedEmail, setProvidedEmail] = useState(null);
  // Local state to manage the email input field value
  const [emailInputValue, setEmailInputValue] = useState("");
  const [firstNameInputValue, setFirstNameInputValue] = useState("");
  const [lastNameInputValue, setlastNameInputValue] = useState("");
  const [numHRStaff, setNumHRStaff] = useState(1);
  const [jobOpeningsPerMonth, setJobOpeningsPerMonth] = useState(1);
  const [avgHrSalary, setAvgHrSalary] = useState(0);
  const [applicationsPerOpening, setApplicationsPerOpening] = useState(200);
  const [screeningTimePerCV, setScreeningTimePerCV] = useState(10);
  const [
    candidatesInterviewedPerOpening,
    setCandidatesInterviewedPerOpening,
  ] = useState(10);
  const [interviewDuration, setInterviewDuration] = useState(30);
  const [emailsPerCandidate, setEmailsPerCandidate] = useState(3);
  const [advertisingCost, setAdvertisingCost] = useState(0);
  const [percentageApplications, setPercentageApplications] = useState(10);
  const [timePerEmail, setTimePerEmail] = useState(10);
  const [taskSwitchingHours, setTaskSwitchingHours] = useState(10); // Hours, not cost
  const [badHirePercentage, setBadHirePercentage] = useState(10); // Percentage, not cost
  const [currentSection, setCurrentSection] = useState(1);
  const workingDays = 22;
  const [showTooltip, setShowTooltip] = useState(null); // State to manage which tooltip is visible
  const [currency, setCurrency] = useState("USD"); // Default currency

  useEffect(() => {
    document.title = "Hiring Cost Calculator Page - ProRecruit"; // Set the title dynamically
    setProvidedEmail(email);
    logEvent(analytics, "page_view", {
      page_title: document.title, //  Send  current  page  title
      page_location: window.location.href, //  Send  current  URL
      page_path: window.location.pathname, //  Send  current  path
      page_referrer: document.referrer, //  Send  current  referrer
      email: email, //  Send  current  email
      language: i18next.languages[0],
    });

    // Cleanup function: Reset the title when the component unmounts
    return () => {
      document.title = "ProRecruit"; // Reset to a default title
    };
  }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  // Ref for the logo file input
  /* const logoInputRef = useRef(null);
  const [logoUrl, setLogoUrl] = useState(null);

  // Handle logo file selection
  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }; */

  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    CAD: "C$",
    AUD: "A$",
    CHF: "CHF",
    CNY: "¥",
    INR: "₹",
    BRL: "R$",
    FR: "CFA",
  };

  // Handler for form submission
  const handleEmailFormSubmit = (event) => {
    event.preventDefault(); // Prevent default page reload
    const trimmedEmail = emailInputValue.trim();
    if (trimmedEmail) {
      // Basic validation: check if not empty
      // Here you might add more robust email validation if needed
      setProvidedEmail(trimmedEmail); // Update the parent state
      // Log the lead generation event
      logEvent(analytics, "generate_lead", {
        // value: 1, // Optional: Assign a value if applicable
        // currency: 'USD', // Optional: Currency for the value
        method: "calculator_email_prompt", // Identify how the lead was generated
        email: email, //  Send  current  email
        language: i18next.languages[0],
      });
    } else {
      // Optional: Add some feedback if the email is empty or invalid
      console.error("Email cannot be empty");
      // You could set an error state here to display a message to the user
    }
  };

  // Format number with commas and currency symbol
  const formatCurrency = (amount) => {
    const symbol = currencySymbols[currency] || "$"; // Default to $ if unknown
    return (
      symbol +
      amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  // Calculation functions
  const calculateScreeningCost = () => {
    return parseFloat(
      (
        ((numHRStaff * avgHrSalary) / (workingDays * 8)) *
        ((jobOpeningsPerMonth * applicationsPerOpening * screeningTimePerCV) /
          60)
      ).toFixed(2)
    );
  };

  const calculateInterviewingCost = () => {
    return parseFloat(
      (
        ((numHRStaff * avgHrSalary) / (workingDays * 8)) *
        ((jobOpeningsPerMonth *
          candidatesInterviewedPerOpening *
          interviewDuration) /
          60)
      ).toFixed(2)
    );
  };

  const calculateCommunicationCost = () => {
    return parseFloat(
      (
        ((numHRStaff * avgHrSalary) / (workingDays * 8)) *
        ((((jobOpeningsPerMonth *
          applicationsPerOpening *
          percentageApplications) /
          100) *
          emailsPerCandidate *
          timePerEmail) /
          60)
      ).toFixed(2)
    );
  };

  const calculateTaskSwitchingCost = () => {
    // Calculate cost based on hours lost to task switching.
    return parseFloat(
      (
        ((numHRStaff * avgHrSalary) / (workingDays * 8)) *
        taskSwitchingHours
      ).toFixed(2)
    );
  };

  //   ***Improved Bad Hires Calculation (Fixed Recursion)***
  const calculateBadHiresCost = () => {
    const costOfHiring =
      calculateScreeningCost() +
      calculateInterviewingCost() +
      calculateCommunicationCost() +
      advertisingCost +
      calculateTaskSwitchingCost();
    const costOfOnboarding = avgHrSalary;
    const lostProductivity = (avgHrSalary / 2) * 3;
    const costOfReplacement = costOfHiring;

    return parseFloat(
      (
        (costOfHiring +
          costOfOnboarding +
          lostProductivity +
          costOfReplacement) *
        (badHirePercentage / 100)
      ).toFixed(2)
    );
  };

  // ***Fixed Total Monthly Cost (No Recursion)***
  const calculateTotalMonthlyCost = () => {
    return (
      calculateScreeningCost() +
      calculateInterviewingCost() +
      calculateCommunicationCost() +
      advertisingCost +
      calculateTaskSwitchingCost() +
      calculateBadHiresCost()
    );
  };

  const calculateTotalAnnualCost = () => {
    return parseFloat((calculateTotalMonthlyCost() * 12).toFixed(2));
  };
  // ***Fixed Total Cost Per Hire 2 decimal(No Recursion)***
  const calculateTotalCostPerHire = () => {
    if (jobOpeningsPerMonth === 0) return 0;
    return parseFloat(
      (calculateTotalMonthlyCost() / jobOpeningsPerMonth).toFixed(2)
    );
  };

  const nextSection = () => {
    setCurrentSection(currentSection + 1);
    setShowTooltip(null); // Hide tooltip on section change
    // Log event *before* updating the state
    logEvent(analytics, "calculator_section_complete", {
      section_number: currentSection, // Log the section *just completed*
      language: i18next.languages[0],
      // section_name: getSectionName(currentSection) // Optional: Add a name if you have one
    });
  };
  // Consider wrapping handleSendToFirestore in useCallback if its dependencies might change
  // or if you pass it down to memoized children.
  const handleSendToFirestore = useCallback(() => {
    // Ensure all variables (numHRStaff, etc.) and calculation functions are accessible here.
    try {
      console.log("Preparing data for Firestore...");

      // Determine the document ID - prioritize authenticated user email, fallback to providedEmail
      const docId = email || providedEmail; // Assuming 'email' is from auth state if available

      if (!docId) {
        console.error(
          "Firestore Error: No email available (auth email or providedEmail missing). Cannot save."
        );
        // Optionally: Set an error state to show a message to the user
        return; // Stop execution
      }

      // Ensure Firestore instance is ready
      if (!db) {
        console.error(
          "Firestore Error: Database instance (db) is not initialized."
        );
        // Optionally: Set an error state
        return;
      }
      console.log(firstNameInputValue, lastNameInputValue);

      // --- Execute calculation functions HERE ---
      const dataToSend = {
        // Input values
        numHRStaff,
        jobOpeningsPerMonth,
        avgHrSalary,
        applicationsPerOpening,
        screeningTimePerCV,
        candidatesInterviewedPerOpening,
        interviewDuration,
        emailsPerCandidate,
        advertisingCost,
        percentageApplications,
        timePerEmail,
        taskSwitchingHours,
        badHirePercentage,
        currency,
        // Calculated results - CALL the functions
        totalMonthlyCost: calculateTotalMonthlyCost(),
        totalAnnualCost: calculateTotalAnnualCost(),
        totalCostPerHire: calculateTotalCostPerHire(),
        screeningCost: calculateScreeningCost(),
        interviewingCost: calculateInterviewingCost(),
        communicationCost: calculateCommunicationCost(),
        taskSwitchingCost: calculateTaskSwitchingCost(),
        badHiresCost: calculateBadHiresCost(),
        // Metadata
        createdAt: serverTimestamp(), // Record when the calculation was saved
        submittedEmail: docId, // Store which email was used as the key
        firstName: firstNameInputValue,
        lastName: lastNameInputValue,
      };

      //date in milliseconds
      const now = Date.now();
      let date = new Date(now);

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      let day = String(date.getDate()).padStart(2, "0");
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");
      let seconds = String(date.getSeconds()).padStart(2, "0");

      let formattedDate = `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;

      // console.log(`Attempting to write to Firestore: collection=calculator, docId=${docId}_${formattedDate}`);
      // console.log("Data:", dataToSend);

      // --- Send data to Firestore ---
      // setDoc returns a promise
      setDoc(doc(db, "calculator", `${docId}_${formattedDate}`), dataToSend)
        .then(() => {
          console.log("Firestore Success: Document successfully written!");
          // Optionally: Set state to show a "Saved" confirmation message
        })
        .catch((error) => {
          console.error("Firestore Error: Error writing document: ", error);
          // Optionally: Set state to show an error message
        });
      triggerCalculatorResultEmail(
        providedEmail,
        dataToSend,
        i18next.languages[0]
      );
    } catch (error) {
      // Catch errors during data preparation or function calls
      console.error(
        "Error preparing data for Firestore or during setup: ",
        error
      );
      // Optionally: Set state to show an error message
    }
    // Add ALL external variables/state/props used inside this function to the dependency array below.
    // If calculation functions are defined outside or useCallback'd, they might not need to be listed if stable.
  }, [
    numHRStaff,
    jobOpeningsPerMonth,
    avgHrSalary,
    applicationsPerOpening,
    screeningTimePerCV,
    candidatesInterviewedPerOpening,
    interviewDuration,
    emailsPerCandidate,
    advertisingCost,
    percentageApplications,
    timePerEmail,
    taskSwitchingHours,
    badHirePercentage,
    currency,
    email,
    providedEmail,
    db, // Add other dependencies if needed
    // List calculation functions if they are props or defined in component scope and could change:
    calculateTotalMonthlyCost,
    calculateTotalAnnualCost,
    calculateTotalCostPerHire,
    calculateScreeningCost,
    calculateInterviewingCost,
    calculateCommunicationCost,
    calculateTaskSwitchingCost,
    calculateBadHiresCost,
  ]);

  // --- Add useEffect Hook ---
  useEffect(() => {
    // This effect runs when currentSection or providedEmail changes.
    if (currentSection === 5 && providedEmail) {
      console.log(
        "useEffect Triggered: Conditions met (Section 5, providedEmail exists). Calling handleSendToFirestore."
      );
      // Results are now visible
      const annualCost = calculateTotalAnnualCost(); // Get key results
      const hireCost = calculateTotalCostPerHire();

      logEvent(analytics, "calculator_results_viewed", {
        // Include some key (non-sensitive) results for context if desired
        // Be mindful of privacy, avoid sending exact sensitive inputs unless aggregated/anonymized
        total_annual_cost_range: Math.round(annualCost / 1000) * 1000, // Example: rounded value
        cost_per_hire_range: Math.round(hireCost / 100) * 100, // Example: rounded value
        currency: currency, // From your state/props
        email: email, //  Send  current  email
        language: i18next.languages[0],
      });
      handleSendToFirestore(); // Execute the function
    }
    // Dependencies: Run this effect if either the section changes or the email is provided.
    // Include handleSendToFirestore because it's defined with useCallback and its dependencies.
  }, [currentSection, providedEmail, handleSendToFirestore]);

  const previousSection = () => {
    setCurrentSection(currentSection - 1);
    setShowTooltip(null); // Hide tooltip on section change
    // Log event for going back (optional, but can be useful)
    logEvent(analytics, "calculator_section_previous", {
      from_section_number: currentSection,
      to_section_number: currentSection - 1,
      language: i18next.languages[0],
    });
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Tooltip handlers
  const handleMouseEnter = (tooltipId) => {
    setShowTooltip(tooltipId);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };

  return (
    <div className="font-sans bg-gray-100 min-h-screen">
      <div className="bg-white shadow-md py-4">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <div className="flex items-center">
            <div className="text-left mb-8">
              <img src={logo} alt={t("logoAlt")} className="h-14" />
            </div>
            {/* {logoUrl ? (
              <img
                src={logoUrl}
                alt="Company Logo"
                className="h-10 w-auto mr-4"
              />
            ) : (
              <div className="h-10 w-20 bg-gray-300 rounded mr-4"></div>
            )} */}

            {/* <button
              onClick={() => logoInputRef.current.click()}
              className="text-gray-600"
            >
              {t("uploadLogo")}
            </button>
            <input
              type="file"
              accept="image/*"
              onChange={handleLogoChange}
              className="hidden"
              ref={logoInputRef}
            /> */}
          </div>

          {/* Currency Dropdown */}
          <div className="flex items-center">
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className="px-7 py-1 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-4"
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="JPY">JPY</option>
              <option value="CAD">CAD</option>
              <option value="AUD">AUD</option>
              <option value="CHF">CHF</option>
              <option value="CNY">CNY</option>
              <option value="INR">INR</option>
              <option value="BRL">BRL</option>
              <option value="FR">CFA</option>
            </select>
            <div>
              <button
                onClick={() => changeLanguage("en")}
                className="px-3 py-1 rounded text-sm font-medium hover:bg-gray-200 transition duration-200"
              >
                EN
              </button>
              <button
                onClick={() => changeLanguage("fr")}
                className="px-3 py-1 rounded text-sm font-medium hover:bg-gray-200 transition duration-200"
              >
                FR
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">
          {t("calculatorTitle")}
        </h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          {t("calculatorSubheadline")}
        </p>
        {currentSection === 5 && providedEmail && (
          <div>
            <div className="flex justify-center space-x-4 mb-4">
              <a
                href="/signup"
                className="px-8 py-3 bg-indigo-600 text-white rounded-full font-semibold hover:bg-indigo-700 transition duration-200"
                onClick={() => {
                  logEvent(analytics, "sign_up", {
                    // Standard event
                    method: "calculator_results_cta", // Custom event
                    email: email, //  Send  current  email
                    // Where the sign up originated
                    language: i18next.languages[0],
                  });
                }}
              >
                {t("tryFreeButton")}
              </a>
              <a
                href="/support"
                className="px-8 py-3 bg-gray-300 text-gray-700 rounded-full font-semibold hover:bg-gray-400 transition duration-200"
                onClick={() => {
                  logEvent(analytics, "calculator_cta_click", {
                    // Custom event
                    cta_target: "demo_request_page",
                    cta_location: "demo_button",
                    language: i18next.languages[0],
                    email: email, //  Send  current  email
                  });
                }}
              >
                {t("contactDemoButton")}
              </a>
            </div>
            <div>
              {/**Add space and a message */}
              <p className="text-lg text-gray-600 text-center mb-4">
                {t("ctaResult3")}
              </p>
              {/**Add space and a message */}
            </div>
          </div>
        )}

        {/* Section 1: Your Team & Hiring Volume */}
        {currentSection === 1 && (
          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {t("section1Title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="relative">
                <label
                  htmlFor="numHRStaff"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("numHRStaffLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="numHRStaff"
                    value={numHRStaff}
                    onChange={(e) => setNumHRStaff(Number(e.target.value))}
                    min="1"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("numHRStaff")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "numHRStaff" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("numHRStaffTooltip")}
                    </div>
                  )}
                </div>
              </div>

              <div className="relative">
                <label
                  htmlFor="jobOpeningsPerMonth"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("jobOpeningsPerMonthLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="jobOpeningsPerMonth"
                    value={jobOpeningsPerMonth}
                    onChange={(e) =>
                      setJobOpeningsPerMonth(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("jobOpeningsPerMonth")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "jobOpeningsPerMonth" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("jobOpeningsPerMonthTooltip")}
                    </div>
                  )}
                </div>
              </div>

              <div className="relative">
                <label
                  htmlFor="avgHrSalary"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("avgHrSalaryLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="avgHrSalary"
                    value={avgHrSalary}
                    onChange={(e) => setAvgHrSalary(Number(e.target.value))}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("avgHrSalary")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "avgHrSalary" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("avgHrSalaryTooltip")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={nextSection}
                className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
              >
                {t("nextButton")}
              </button>
            </div>
          </div>
        )}

        {/* Section 2: Application Volume & Screening Time */}
        {currentSection === 2 && (
          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {t("section2Title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="relative">
                <label
                  htmlFor="applicationsPerOpening"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("applicationsPerOpeningLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="applicationsPerOpening"
                    value={applicationsPerOpening}
                    onChange={(e) =>
                      setApplicationsPerOpening(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("applicationsPerOpening")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "applicationsPerOpening" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("applicationsPerOpeningTooltip")}
                    </div>
                  )}
                </div>
              </div>

              <div className="relative">
                <label
                  htmlFor="screeningTimePerCV"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("screeningTimePerCVLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="screeningTimePerCV"
                    value={screeningTimePerCV}
                    onChange={(e) =>
                      setScreeningTimePerCV(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("screeningTimePerCV")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "screeningTimePerCV" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("screeningTimePerCVTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="percentageApplications"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("percentageApplicationsLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="percentageApplications"
                    value={percentageApplications}
                    onChange={(e) =>
                      setPercentageApplications(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("percentageApplications")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "percentageApplications" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("percentageApplicationsTooltip")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-between">
              <button
                onClick={previousSection}
                className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
              >
                {t("previousButton")}
              </button>
              <button
                onClick={nextSection}
                className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
              >
                {t("nextButton")}
              </button>
            </div>
          </div>
        )}

        {/* Section 3:  Interviewing & Communication */}
        {currentSection === 3 && (
          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {t("section3Title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="relative">
                <label
                  htmlFor="candidatesInterviewedPerOpening"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("candidatesInterviewedPerOpeningLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="candidatesInterviewedPerOpening"
                    value={candidatesInterviewedPerOpening}
                    onChange={(e) =>
                      setCandidatesInterviewedPerOpening(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("candidatesInterviewedPerOpening")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "candidatesInterviewedPerOpening" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("candidatesInterviewedPerOpeningTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="interviewDuration"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("interviewDurationLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="interviewDuration"
                    value={interviewDuration}
                    onChange={(e) =>
                      setInterviewDuration(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("interviewDuration")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "interviewDuration" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("interviewDurationTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="emailsPerCandidate"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("emailsPerCandidateLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="emailsPerCandidate"
                    value={emailsPerCandidate}
                    onChange={(e) =>
                      setEmailsPerCandidate(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("emailsPerCandidate")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "emailsPerCandidate" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("emailsPerCandidateTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="timePerEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("timePerEmailLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="timePerEmail"
                    value={timePerEmail}
                    onChange={(e) => setTimePerEmail(Number(e.target.value))}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("timePerEmail")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "timePerEmail" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("timePerEmailTooltip")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-between">
              <button
                onClick={previousSection}
                className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
              >
                {t("previousButton")}
              </button>
              <button
                onClick={nextSection}
                className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
              >
                {t("nextButton")}
              </button>
            </div>
          </div>
        )}

        {/* Section 4: Additional cost */}
        {currentSection === 4 && (
          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {t("section4Title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="relative">
                <label
                  htmlFor="advertisingCost"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("advertisingCostLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="advertisingCost"
                    value={advertisingCost}
                    onChange={(e) => setAdvertisingCost(Number(e.target.value))}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("advertisingCost")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "advertisingCost" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("advertisingCostTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="taskSwitchingHours"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("taskSwitchingLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="taskSwitchingHours"
                    value={taskSwitchingHours}
                    onChange={(e) =>
                      setTaskSwitchingHours(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("taskSwitchingHours")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "taskSwitchingHours" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("taskSwitchingTooltip")}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="badHirePercentage"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("badHiresLabel")}
                </label>
                <div className="relative mt-1">
                  <input
                    type="number"
                    id="badHirePercentage"
                    value={badHirePercentage}
                    onChange={(e) =>
                      setBadHirePercentage(Number(e.target.value))
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute right-3 top-2 text-gray-400 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter("badHirePercentage")}
                    onMouseLeave={handleMouseLeave}
                  />

                  {showTooltip === "badHirePercentage" && (
                    <div className="absolute z-10 top-full left-0 mt-2 w-64 bg-gray-800 text-white p-3 rounded-md shadow-lg">
                      {t("badHiresTooltip")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-between">
              <button
                onClick={previousSection}
                className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
              >
                {t("previousButton")}
              </button>
              <button
                onClick={nextSection}
                className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
              >
                {t("nextButton")}
              </button>
            </div>
          </div>
        )}

        {/* Results */}

        {/**if email exist? show section 5 ortherwise ask for an email  */}
        {currentSection === 5 && providedEmail && (
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {t("resultsTitle")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">
                  {t("screeningCostLabel")}
                </p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(calculateScreeningCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">
                  {t("interviewingCostLabel")}
                </p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(calculateInterviewingCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">
                  {t("communicationCostLabel")}
                </p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(calculateCommunicationCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">
                  {t("taskSwitchingCostLabel")}
                </p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(calculateTaskSwitchingCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">{t("badHiresCost")}</p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(calculateBadHiresCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-gray-100">
                <p className="text-sm text-gray-600">
                  {t("additionalCostLabel")}
                </p>
                <p className="text-xl font-bold text-gray-800">
                  {formatCurrency(advertisingCost)}
                </p>
              </div>

              <div className="p-4 rounded-md bg-indigo-100 col-span-full md:col-span-2">
                <p className="text-sm text-indigo-600">
                  {t("totalMonthlyCostLabel")}
                </p>
                <p className="text-3xl font-bold text-indigo-800">
                  {formatCurrency(calculateTotalMonthlyCost())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-indigo-100 col-span-full md:col-span-2">
                <p className="text-sm text-indigo-600">
                  {t("totalCostPerHireLabel")}
                </p>
                <p className="text-3xl font-bold text-indigo-800">
                  {formatCurrency(calculateTotalCostPerHire())}
                </p>
              </div>
              <div className="p-4 rounded-md bg-indigo-100 col-span-full md:col-span-2">
                <p className="text-sm text-indigo-600">
                  {t("totalAnnualCostLabel")}
                </p>
                <p className="text-3xl font-bold text-indigo-800">
                  {formatCurrency(calculateTotalAnnualCost())}
                </p>
              </div>
            </div>
            <div className="mt-8 flex justify-start">
              <button
                onClick={previousSection}
                className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
              >
                {t("previousButton")}
              </button>
            </div>
          </div>
        )}

        {/* Show Email Prompt only if it's section 5 AND an email has NOT been provided */}
        {currentSection === 5 && !providedEmail && (
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              {t("emailRequiredTitle", "Email Required to View Results")}
            </h2>
            <p className="text-gray-600 mb-4">
              {t(
                "emailRequiredDescription",
                "Please provide your email address to see your calculated recruitment costs."
              )}
            </p>

            {/* Email Input Form */}
            <form onSubmit={handleEmailFormSubmit} className="mt-4 space-y-3">
              <div>
                <label
                  htmlFor="firstNameInput"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("fisrtNameInputLabel", "First name")}
                </label>
                <input
                  type="text"
                  id="firstNameInput"
                  name="firstName"
                  value={firstNameInputValue}
                  onChange={(e) => setFirstNameInputValue(e.target.value)}
                  required // Basic HTML5 validation
                  placeholder={t("firstNameInputPlaceholder", "Eric")}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="lastNameInput"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("lastNameInputLabel", "Last name")}
                </label>
                <input
                  type="text"
                  id="lastNameInput"
                  name="lastName"
                  value={lastNameInputValue}
                  onChange={(e) => setlastNameInputValue(e.target.value)}
                  required // Basic HTML5 validation
                  placeholder={t("lastNameInputPlaceholder", "Dupont")}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="emailInput"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("emailInputLabel", "Email Address")}
                </label>
                <input
                  type="email"
                  id="emailInput"
                  name="email"
                  value={emailInputValue}
                  onChange={(e) => setEmailInputValue(e.target.value)}
                  required // Basic HTML5 validation
                  placeholder={t(
                    "emailInputPlaceholder",
                    "your.email@example.com"
                  )}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200"
                >
                  {t("submitEmailButton", "Show My Results")}
                </button>
              </div>
            </form>

            {/* Navigation Buttons */}
            <div className="mt-8 flex justify-start">
              <button
                onClick={previousSection}
                className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
              >
                {t("previousButton", "Previous")}
              </button>
            </div>
          </div>
        )}

        {/* CTA */}
        {currentSection === 5 && providedEmail && (
          <div className="mt-12 text-center">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              {t("ctaTitle")}
            </h2>
            <p className="text-lg text-gray-600 mb-8">{t("ctaBody")}</p>
            <div className="flex justify-center space-x-4">
              <a
                href="/signup"
                className="px-8 py-3 bg-indigo-600 text-white rounded-full font-semibold hover:bg-indigo-700 transition duration-200"
                onClick={() => {
                  logEvent(analytics, "sign_up", {
                    // Standard event
                    method: "calculator_results_cta", // Where the sign up originated
                    language: i18next.languages[0],
                    email: providedEmail,
                  });
                }}
              >
                {t("tryFreeButton")}
              </a>
              <a
                href="/support"
                className="px-8 py-3 bg-gray-300 text-gray-700 rounded-full font-semibold hover:bg-gray-400 transition duration-200"
                onClick={() => {
                  logEvent(analytics, "calculator_cta_click", {
                    // Custom event
                    cta_target: "demo_request_page",
                    cta_location: "demo_button",
                    language: i18next.languages[0],
                    email: providedEmail,
                  });
                }}
              >
                {t("contactDemoButton")}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Creates a document in 'marketing-ad-hoc-single' collection to trigger
 * the cloud function responsible for sending the calculator results email,
 * retrieving the SendGrid template ID from environment variables based on language.
 *
 * @param {string} recipientEmail - The email address where the results should be sent.
 * @param {object} dataToSend - An object containing all input values, calculated results,
 * and basic user info (like firstName, lastName, currency).
 * Example structure provided in user prompt.
 * @param {'en' | 'fr'} language - The language code ('en' or 'fr') to determine the template ID.
 *
 * @returns {Promise<{success: boolean, message?: string, error?: string}>} - Promise resolving to an object indicating success or failure.
 */
const triggerCalculatorResultEmail = async (
  recipientEmail,
  dataToSend,
  language
) => {
  console.log(
    `Attempting to trigger calculator result email for ${recipientEmail} in language: ${language}`
  );

  // --- Input Validation ---
  if (
    !recipientEmail ||
    typeof recipientEmail !== "string" ||
    !recipientEmail.includes("@")
  ) {
    const errorMsg = "Invalid or missing recipient email address.";
    console.error("triggerCalculatorResultEmail Error:", errorMsg);
    return { success: false, error: errorMsg };
  }
  if (
    !dataToSend ||
    typeof dataToSend !== "object" ||
    Object.keys(dataToSend).length === 0
  ) {
    const errorMsg = "Invalid or missing dataToSend object.";
    console.error("triggerCalculatorResultEmail Error:", errorMsg);
    return { success: false, error: errorMsg };
  }
  if (language !== "en" && language !== "fr") {
    const errorMsg = `Invalid language code provided: ${language}. Must be 'en' or 'fr'.`;
    console.error("triggerCalculatorResultEmail Error:", errorMsg);
    return { success: false, error: errorMsg };
  }
  if (!db) {
    const errorMsg = "Firestore database instance (db) is not available.";
    console.error("triggerCalculatorResultEmail Error:", errorMsg);
    return { success: false, error: errorMsg };
  }

  // --- Get Template ID from Environment Variables ---
  // Adjust the variable names based on your build tool (Vite example)
  const selectedTemplateId =
    language === "fr"
      ? process.env.REACT_APP_CALCULATOR_RESULT_SENDGRID_TEMPLATE_ID_FR
      : process.env.REACT_APP_CALCULATOR_RESULT_SENDGRID_TEMPLATE_ID_EN;

  if (!selectedTemplateId) {
    const errorMsg = `SendGrid Template ID environment variable (${selectedTemplateId}) is not set for language '${language}'.`;
    console.error("triggerCalculatorResultEmail Error:", errorMsg);
    return { success: false, error: "Email configuration error." }; // Don't expose env var name to user
  }
  console.log(`Using Template ID: ${selectedTemplateId}`);

  // --- Construct the Document Data for Firestore ---
  // Structure matches what the 'sendAdHocEmailSingle' cloud function expects
  const documentData = {
    email: recipientEmail,
    templateId: selectedTemplateId,

    // Data about the user (recipient) - extract from dataToSend
    userData: {
      firstName: dataToSend.firstName || "",
      lastName: dataToSend.lastName || "",
      company: dataToSend.company || "", // Add company to dataToSend if available
      language: language,
      // Add any other user-specific fields from dataToSend if needed by CF
    },

    // Data specifically needed for dynamic content within the SendGrid template
    // Map fields from dataToSend to the keys expected by your SendGrid templates
    templateData: {
      email: recipientEmail,
      firstName: dataToSend.firstName || "",
      recipient_name: dataToSend.firstName || "", // Template key example
      total_annual_cost: dataToSend.totalAnnualCost,
      cost_per_hire: dataToSend.totalCostPerHire, // Note: user object had totalCostPerHire 2 decimal
      screening_cost: dataToSend.screeningCost,
      currency_symbol: dataToSend.currency, // Use currency field from dataToSend
      // Add other calculated fields if the template uses them
      total_monthly_cost: dataToSend.totalMonthlyCost,
      interviewing_cost: dataToSend.interviewingCost,
      communication_cost: dataToSend.communicationCost,
      task_switching_cost: dataToSend.taskSwitchingCost,
      bad_hires_cost: dataToSend.badHiresCost,
      templateId: selectedTemplateId,
      // Add links or other static data if needed by template
      // calculator_link: "https://app.prorecruit.tech/calculator"
    },
    // You can include the raw input/calculated data as well if useful for CF debugging
    // rawCalculatorData: dataToSend
  };

  // --- Prepare Firestore Document Reference ---
  // Use the recipient's email as the unique Document ID
  const now = new Date();
  const timestamp = now.toISOString();
  const docRef = doc(
    db,
    "marketing-ad-hoc-single",
    `${recipientEmail}-${timestamp}`
  );

  // --- Write Document to Firestore ---
  try {
    console.log(
      `Writing document to marketing-ad-hoc-single/${recipientEmail}`
    );
    // Avoid logging potentially sensitive full dataToSend in production if possible
    // console.log("Document Data:", JSON.stringify(documentData, null, 2));

    await setDoc(docRef, documentData);
    // Log SUCCESSFUL trigger creation
    logEvent(analytics, "calculator_email_trigger_sent", {
      email_recipient: recipientEmail, // Use with caution if PII is a concern
      template_id: selectedTemplateId,
      language: language,
    });
    console.log(
      `Successfully created Firestore document for ${recipientEmail}. Cloud function should trigger.`
    );
    return { success: true, message: "Email request sent successfully!" };
  } catch (error) {
    console.error(
      `Error writing document to Firestore for ${recipientEmail}:`,
      error
    );
    // Log FAILED trigger creation
    logEvent(analytics, "calculator_email_trigger_error", {
      email_recipient: recipientEmail, // Use with caution
      template_id: selectedTemplateId,
      language: language,
      error_message: error.message || "Unknown Firestore error",
    });
    return {
      success: false,
      error: `Failed to send request: ${
        error.message || "Unknown Firestore error"
      }`,
    };
  }
};

export default HiringCostCalculator;
