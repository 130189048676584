// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./useAuth";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import JobPostingsPage from "./pages/JobPostingsPage";
import CandidateListPage from "./pages/CandidateListPage";
import ApplicationsPage from "./pages/ApplicationsPage";
import HiringCostCalculator from "./pages/HiringCostCalculator";
import ContactPage from "./pages/ContactPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AffiliatePage from "./pages/AffiliatePage";
import JobListingsPage from "./pages/JobListingsPage";
import ThankYouPage from "./pages/ThankYouPage";
import Profile from "./pages/UserProfile"
import TestPage from "./pages/test";
import AssessmentPage from "./pages/Assessments";
import AssessmentResultsPage from "./pages/AssessmentResults";
import Reviews from "./pages/Reviews";
import Pricing from "./pages/Pricing";
import Pricing2 from "./pages/Pricing copy";
import SupportPage from "./pages/SupportPage";
import DocumentationPage from "./pages/DocumentationPage";
import GettingStartedGuide from "./pages/GettingStartedGuide";
import LicenseBuyingPage from "./pages/LicenseBuyingPage";
import DashboardPage2 from "./pages/DashboardPage2";
import JobPostingDetailsPage from "./pages/JobPostingDetailsPage";
import DashboardPage from "./pages/DashboardPage";
import CreateAssessment from "./pages/CreateAssessment";
import UpdateAssessment from "./pages/UpdateAssessment";
import StripePaymentReturn from "./pages/StripePaymentReturn";
import { fetchUserByEmail } from "./utils/redux/userDataSlice";
import { useDispatch } from "react-redux";
import { ToastProvider } from "./components/Alerts";
import CompanyEditPage from "./pages/CompanyEditPage";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

// import DialogflowMessenger from "./components/DialogflowMessenger";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>404 - {t('404_message')}</h1>
    </>
  );
};

// eslint-disable-next-line no-unused-vars, react/prop-types
const PrivateRoute = ({ element, ...rest }) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  return currentUser ? (
    (dispatch(fetchUserByEmail(currentUser.email)), element)
  ) : (
    <Navigate to="/signin" replace />
  );
};

function App() {
  // const { t } = useTranslation();
  return (
    <AuthProvider>
      <Tooltip anchorSelect=".my-anchor-element"  place="top"/>
      <ToastProvider >
      <Router>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/pricing2" element={<Pricing2 />} />
          <Route path="/Affiliate" element={<AffiliatePage />} />
          <Route path="/Calculator" element={<HiringCostCalculator />} />
          <Route path="/DemoContact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/stripe_payment_return" element={<StripePaymentReturn />} />          
          <Route path="/documentation" element={<DocumentationPage />} />        
          <Route path="/support" element={<SupportPage />} />

          <Route path="/GettingStartedGuide" element={<GettingStartedGuide/>} />
          <Route path="/checkout" element={<PrivateRoute element={<LicenseBuyingPage/>} />} />
          <Route path="/company-edit" element={<PrivateRoute element={<CompanyEditPage/>} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/dashboard-old"
            element={<PrivateRoute element={<DashboardPage2 />} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<DashboardPage />} />}
          />
          <Route
            path="/profile"
            element={<PrivateRoute element={<Profile />} />}
          />
          <Route
            path="/createAssessment"
            element={<PrivateRoute element={<CreateAssessment />} />}
          />
          <Route
            path="/updateAssessment/:id"
            element={<PrivateRoute element={<UpdateAssessment />} />}
          />
          <Route
            path="/job-postings"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/jobpostings"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/jobpostings/:jobId/tenants/:tenantId"
            element={<PrivateRoute element={<JobPostingDetailsPage />} />}
          />
          <Route
            path="/candidates/:jobID/tenants/:tenantID"
            element={<PrivateRoute element={<CandidateListPage />} />}
          />
          {/* <Route
            path="/applications/:jobID/tenants/:tenantID"
            element={<PrivateRoute element={<ApplicationsPage />} />}
          /> */}
          <Route
            path="/assessmentresults/:assessmentID/test/:testID"
            element={<PrivateRoute element={<AssessmentResultsPage />} />}
          />
          <Route
            path="/assessments/:assessmentID/test/:testID"
            element={<AssessmentPage />}
          />
          <Route path="/jobs/:jobID" element={<ApplicationsPage />} />
          {/* <Route path="/jobs_test/:jobID" element={<ApplicationsPage />} /> */}
          <Route path="/job_listings" element={<JobListingsPage />} />
          {/* <Route path="/job_listings/:jobID" element={<ApplicationsPage />} /> */}
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/kwami" element={<Reviews />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Router>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
