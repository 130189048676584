import { useState, useEffect } from 'react';
import { Timestamp } from 'firebase/firestore'; // Or @google-cloud/firestore
import { format,  formatInTimeZone } from 'date-fns-tz';
import { fr, enUS } from 'date-fns/locale';

// Helper function to ensure correct imports in a React component
function useDateUtils() {
    const [timeZone, setTimeZone] = useState('UTC'); // Default to UTC

    useEffect(() => {
        try {
            const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setTimeZone(detectedTimeZone);
        } catch (error) {
            console.error("Error detecting timezone:", error);
            // Optionally, set a fallback timezone if detection fails.
            // setTimeZone('America/Los_Angeles'); // Example fallback
        }
    }, []); // Empty dependency array: run only on mount


    function stringToTimestamp(dateString) {
        if (!dateString) {
            console.warn("stringToTimestamp received an empty string");
            return null; // Or throw an error, or return a default Timestamp
        }
        console.log("stringToTimestamp received:", dateString);
    
        // Handle YYYY-MM-DD format
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
            const [year, month, day] = dateString.split('-').map(Number);
    
            if (isNaN(year) || isNaN(month) || isNaN(day)) {
                console.error("Invalid date components:", dateString);
                throw new Error("Invalid date components.");
            }
    
            if (month < 1 || month > 12) {
                console.error("Invalid month:", month);
                throw new Error("Invalid month. Month must be between 1 and 12.");
            }
    
            const date = new Date(year, month - 1, day); // Month is 0-indexed
    
            if (isNaN(date.getTime())) {
              console.error("Invalid date:", dateString);
              throw new Error("Invalid date.");
            }
    
            return Timestamp.fromDate(date);
        }
    
    
        const parts = dateString.split('/');
        let month, day, year;
    
        if (parts.length !== 3) {
            console.error("Invalid date string format:", dateString);
            throw new Error("Invalid date string format.  Must be MM/DD/YYYY or DD/MM/YYYY.");
        }
    
        month = parseInt(parts[0], 10);
        day = parseInt(parts[1], 10);
        year = parseInt(parts[2], 10);
    
    
        if (month > 12) {
            [month, day] = [day, month];
        }
    
        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            console.error("Invalid date components:", dateString);
            throw new Error("Invalid date components.");
        }
    
        if (month < 1 || month > 12) {
            console.error("Invalid month:", month);
            throw new Error("Invalid month. Month must be between 1 and 12.");
        }
    
        const date = new Date(year, month - 1, day); // IMPORTANT: Month is 0-indexed!
    
         if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            throw new Error("Invalid date.");
          }
    
        return Timestamp.fromDate(date);
    }


    const formatToFrenchLongDate = (timestamp) => {
        if (!(timestamp instanceof Timestamp)) {
            if (typeof timestamp === 'string' || timestamp instanceof Date) {
                console.warn("Invalid input type. Expected Firestore Timestamp. type: ", typeof timestamp);
                
                // Handle strings (assuming ISO 8601 format) and Date objects
                return new Date(timestamp);
            }else{
                console.warn("Invalid input type. Expected Firestore Timestamp.");
                return timestamp; // or some default string, or throw error, depending on use case

            }
        }
        try{
            const date = timestamp.toMillis();
            const zonedDate =  formatInTimeZone(date, timeZone);
            return format(zonedDate, 'PPP', { locale: fr });
        } catch(error){
            console.error("Error to format French date: ", error);
            return timestamp;
        }
    };

    const formatToEnglishLongDate = (timestamp) => {
        if (!(timestamp instanceof Timestamp)) {
           console.warn("Invalid input type. Expected Firestore Timestamp.");
          return '';
        }
         try{
            const date = timestamp.toDate();
            const zonedDate =  formatInTimeZone(date, timeZone);
            return format(zonedDate, 'PPP', { locale: enUS });
         }catch(error){
              console.error("Error to format English date: ", error);
            return '';
         }
    };

    const formatToDDMMYYYY = (timestamp, timeZone = 'UTC') => {
        console.log("formatToDDMMYYYY received:", typeof(timestamp), timestamp);
        console.log("timeZone:", timeZone)
         if (!(timestamp instanceof Timestamp)) {
            console.warn("Invalid input type. Expected Firestore Timestamp.");
          return '';
        }
        try{
            const date = timestamp.toDate();
            console.log("date:", date);
            console.log("timeZone:", timeZone)
            const zonedDate =  formatInTimeZone(date, timeZone, 'dd/MM/yyyy');
            return zonedDate
        } catch(error){
            console.error("Error to format DDMMYYYY date: ", error);
            return '';
        }
    };

    const formatToMMDDYYYY = (timestamp) => {
        if (!(timestamp instanceof Timestamp)) {
            console.warn("Invalid input type. Expected Firestore Timestamp.");
          return '';
        }
         try{
            const date = timestamp.toDate();
            const zonedDate =  formatInTimeZone(date, timeZone, 'MM/dd/yyyy');
            return zonedDate
        }catch(error){
             console.error("Error to format MMDDYYYY date: ", error);
            return '';
        }
    };
    const timestampToMilliseconds = (timestamp) => {
        console.log("timestampToMilliseconds received:", typeof(timestamp), timestamp);
        if (!(timestamp instanceof Timestamp)) {
            console.warn("Invalid input type. Expected Firestore Timestamp.");
            return null; // Or throw an error, depending on your needs.
        }
        return timestamp.toMillis();
    };

    return {
      stringToTimestamp,
      formatToFrenchLongDate,
      formatToEnglishLongDate,
      formatToDDMMYYYY,
      formatToMMDDYYYY,
      timestampToMilliseconds,
      timeZone,
    };
}

export default useDateUtils;