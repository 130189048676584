import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "libphonenumber-js";
import { ColorRing } from "react-loader-spinner";
import { db, analytics, Timestamp, storage } from "../firebase-config"; // Corrected import
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics"; // Assuming you still want to use analytics
import { useTranslation } from "react-i18next"; // Import useTranslation
import * as Sentry from "@sentry/react";
import { ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import useDateUtils from "../utils/fromStringToTimestamp";
// const pdfExtract = require("pdf-extraction"); 

//Allowed file types and Size.
const fileType = [
  "txt",
  "pdf",
  "doc",
  "docx",
  ".abw",
  "application/octet-stream",
  "application/pdf",
  "application/x-abiword",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const fileSizeLimit = 400001; //bytes
const jobDocRef = doc(db, `job`, "5tdpJAv6JNwmawkDKPSH"); //Still have to figure out how to use it.
// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const ApplicationsPage = () => {
  const navigate = useNavigate();
  const { formatToFrenchLongDate } = useDateUtils();
  const { jobID } = useParams();
  const [jobIdInput, setJobIdInput] = useState("");
  const [tenantID, setTenantID] = useState("");
  // const [jobStatus, setJobStatus] = useState("");
  const [jobUuid, setJobUuid] = useState("");
  const [jobLanguage, setJobLanguage] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [selectedCountryisoCode, setSelectedCountryisoCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState([
    { Institution: "", Degree: "", FieldOfStudy: "", GraduationYear: "" },
  ]);
  const [references, setReferences] = useState([
    { RefereeName: "", Relation: "", ContactInformation: "" },
  ]);
  const [certificates, setCertificates] = useState([
    {
      CertificationName: "",
      IssuingOrganization: "",
      DateObtained: "",
      ExpiryDate: "",
    },
  ]);
  const [certificatesDocument, setCertificatesDocument] = useState(null);
  const [diplomas, setDiplomas] = useState(null);
  const [languages, setLanguages] = useState([
    { Language: "", Proficiency: "" },
  ]);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [educationErrors, setEducationErrors] = useState([
    {
      Institution: false,
      Degree: false,
      FieldOfStudy: false,
      GraduationYear: false,
    },
  ]);
  const [fullName, setFullName] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [skills, setSkills] = useState("");
  const [sex, setSex] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [resumeError, setResumeError] = useState("");
  const [coverLetterError, setCoverLetterError] = useState("");
  const [certificateError, setCertificateError] = useState("");
  const [diplomaError, setDiplomaError] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [coverLetterName, setCoverLetterName] = useState("");
  const [diplomaName, setDiplomaName] = useState("");
  const [resumeUploadError, setResumeUploadError] = useState(false);
  // const [dateLimiteDeCandidature, setDateLimiteDeCandidature] = useState(0);
  const [jobNotFound, setJobNotFound] = useState(false); //for job not found.
  const [canApply, setCanApply] = useState(true);
  const { t } = useTranslation("ApplicationsPage"); // Use the hook for translations
  // eslint-disable-next-line no-unused-vars
  const [tempUserId, setTempUserId] = useState(() => {
    // Try to get existing ID from localStorage
    let uid = localStorage.getItem("tempUserId");
    if (!uid) {
      // If not in localStorage, generate a new one and store it
      uid = uuidv4();
      localStorage.setItem("tempUserId", uid);
    }
    return uid; // Return either the existing or new ID
  });

  useEffect(() => {
    document.title = "Job Applications Page - ProRecruit"; // Set the title dynamically

    // Cleanup function: Reset the title when the component unmounts
    return () => {
      document.title = "ProRecruit"; // Reset to a default title
    };
  }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  // Fetch job details
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (jobID && jobNotFound) {
        setJobNotFound(false);
        return;
      }
      if (!jobID) {
        navigate("/job_listings");
      }
      setLoading(true); // Start loading
      try {
        const jobObjectDoc = await getDoc(jobDocRef);
        const jobObjectData = jobObjectDoc.data();
        const jobReference = jobObjectData[jobID];
        const jobDoc = await getDoc(jobReference); // Get the actual job, using the ID.
        if (!jobDoc.exists()) {
          console.log(`job ${jobID} not found`);
          setJobNotFound(true);
          return; // Exit if job doesn't exist
        }

        const data = jobDoc.data();
        setTenantID(data.tenantId);
        setJobUuid(data.uuid);
        setJobLanguage(data.language? data.language : "fr");
        // setJobStatus(data.Statut);
        if (
          data.DateLimiteDeCandidature.toMillis() + 86400000 - 1 <
          Timestamp.now().toMillis()
        ) {
          setCanApply(false);
        }

        // setDateLimiteDeCandidature(data.DateLimiteDeCandidature);

        setJobDetails({
          Titre: data.Titre,
          DateLimiteDeCandidature: formatToFrenchLongDate(
            data.DateLimiteDeCandidature
          ),
          Description: data.Description,
          companyName: data.Entreprise, // Corrected: Access directly, no get()
          type: data.TypeDePoste, // Corrected: Access directly
        });
      } catch (error) {
        console.error("Error fetching job details:", error);
        setJobNotFound(true); // Set error state
        // Log error to Sentry in production
        if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
          Sentry.captureException(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [jobID, tenantID]); // Add db as a dependency

  const handleJobIdSubmit = (event) => {
    event.preventDefault();
    if (jobIdInput) {
      navigate(`/jobs/${jobIdInput}`); // Navigate to the new job ID
    }
  };

  const handleFileUpload = async (file, folder, fileNameExtraDetails) => {
    if (!file) {
      return ""; // Or throw an error, depending on your needs
    }
    try {
       // --- STEP 1: Preliminary PDF Validation (Client-Side) ---
    if (file.type === 'application/pdf') { // Only validate if it's supposed to be a PDF
      try {
        const arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
        const pdfData = new Uint8Array(arrayBuffer);

          //Basic pdf validation
          if (
            pdfData.length > 4 &&
            String.fromCharCode(pdfData[0], pdfData[1], pdfData[2], pdfData[3]) !== '%PDF'
            ) {
            throw new Error("Not a valid PDF file.");
          }
        // Attempt basic parsing (can't fully replace server-side check)
        //  await readPdf(arrayBuffer); // Await the readPdf function

      } catch (preliminaryError) {
        console.error("Preliminary PDF Check Failed:", preliminaryError);
        //  display a user-friendly error message.
        setResumeUploadError(true);
        alert(t("invalidPDFError")); // Use your translation function
        return ""; // Stop the upload process
      }
    }
      // Generate a unique filename using UUID
      const uniqueFilename = `${fileNameExtraDetails}_${uuidv4()}_${file.name}`;
      setResumeName(uniqueFilename);
      const storageRef = ref(
        storage,
        `uploads/${folder}/${tempUserId}/${uniqueFilename}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: file.type,
        size: file.size,
        name: uniqueFilename,
      });

      return new Promise((resolve, reject) => {
        //Use promise for easy handling in submit.
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            console.error("Upload Error:", error);
            reject(error); // Reject the promise on error
          },
          () => {
            // on success.
            // No need to get download URL here; cloud function handles that.
            console.log(`success upload to`);
            resolve(uniqueFilename); // Resolve with the unique filename
          }
        );
      });
    } catch (error) {
      console.error("General Error in handleFileUpload:", error);
      setResumeUploadError(true);
  
       //More descriptive error messages.
       if (error.message.includes("PDF")) {
          alert(t("invalidPDFError")); // Specific PDF error
      } else {
          alert(t("uploadError")); // General upload error
      }
      return "";
    }
  };

  // Handle country change
  const handleCountryChange = (event) => {
    const isoCode = event.target.value;
    setSelectedCountryisoCode(isoCode);
    setSelectedCountry(Country.getCountryByCode(isoCode));
    setSelectedState(""); // Reset state
    setSelectedCity(""); // Reset city
  };

  // Handle phone number change and validation
  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    setPhoneNumber(inputValue); //ALWAYS update for display.

    // Basic input validation (prevent non-numeric characters except +, space, -)
    if (!/^[0-9+ -]*$/.test(inputValue)) {
      return; // Stop if invalid characters
    }
    setPhoneNumberError(""); //Clear error message.

    //If empty, no validation needed
    if (!inputValue) {
      return;
    }

    // Try to parse and validate
    const number = parsePhoneNumberFromString(
      inputValue,
      selectedCountryisoCode || "TG"
    );

    if (!number) {
      setPhoneNumberError(t("invalidPhoneNumber")); // Use i18next
      return;
    }

    if (!isPossiblePhoneNumber(`${number.number}`)) {
      setPhoneNumberError(t("invalidPhoneNumber")); // Use i18next
      return;
    }

    if (!isValidPhoneNumber(`${number.number}`)) {
      setPhoneNumberError(t("invalidPhoneNumber")); // Use i18next
      return;
    }
    // No need to set formatted number to state here, we're already storing raw input.
  };

  // Handle file change - validation and setting state
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (!file) {
      // Handle the case where the user cancels the file selection
      // Clear the error and the file state if the user cancels file selection
      if (name === "resume") {
        setResumeError("");
        setResume(null);
      }
      if (name === "coverLetter") {
        setCoverLetterError("");
        setCoverLetter(null);
      }
      if (name === "certificatesDocument") {
        setCertificateError("");
        setCertificatesDocument(null);
      }
      if (name === "diplomas") {
        setDiplomaError("");
        setDiplomas(null);
      }
      return;
    }

    let error = "";
    if (!fileType.includes(file.type)) {
      error = t("invalidFileType"); // Use i18next
    } else if (file.size > fileSizeLimit) {
      error = t("fileTooLarge", { maxSize: fileSizeLimit / 1000 }); // Use i18next
    }

    // Set error and return if there's an error.
    if (error) {
      if (name === "resume") setResumeError(error);
      if (name === "coverLetter") setCoverLetterError(error);
      if (name === "certificatesDocument") setCertificateError(error);
      if (name === "diplomas") setDiplomaError(error);
      console.log(error);
      alert(error);
      return;
    }

    //If no errors, proceed.
    var filename = "unknown";
    var d = new Date().toUTCString();
    var fn = `${`${fullName.replace(/\s/g, "")}`}_${d}`;
    fn = fn.replaceAll(" ", "_");
    fn = fn.replaceAll(",", "");
    fn = fn.replaceAll(":", "_");
    switch (name) {
      case "resume":
        filename = `Resume_${fn}`;
        setResumeName(filename);
        setResume(file);
        setResumeError("");
        break;
      case "coverLetter":
        filename = `CoverLetter_${fn}`;
        setCoverLetterName(filename);
        setCoverLetter(file);
        setCoverLetterError("");
        break;
      case "certificatesDocument":
        filename = `Certificate_${fn}`;
        setCertificateName(filename);
        setCertificatesDocument(file);
        setCertificateError("");
        break;
      case "diplomas":
        filename = `Diploma_${fn}`;
        setDiplomaName(filename);
        setDiplomas(file);
        setDiplomaError("");
        break;
      default:
        break;
    }
  };

  // Add or remove items in arrays (education, references, certificates, languages)
  const addOrRemoveItem = (array, setArray, item = {}, index) => {
    if (item && Object.keys(item).length > 0) {
      // Check if item is not empty
      setArray([...array, item]);
    } else {
      const newArray = [...array];
      newArray.splice(index, 1);
      setArray(newArray);
    }
  };

  const addOrRemoveText = (text, setText) => {
    setText(text);
  };

  // Handle input change for arrays of objects
  const handleArrayInputChange = (array, setArray, index, event) => {
    const newArray = [...array];
    newArray[index][event.target.name] = event.target.value;
    setArray(newArray);
  };

/*   const generateShortCode = () => {
    return Math.random().toString(36).substring(2, 8);
  };
 */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableSubmitButton(true);
    setLoading(true);
    setSubmissionAttempted(true);
    // Reset errors
    setResumeUploadError(false);
    setResumeError("");
    setCoverLetterError("");
    setCertificateError("");
    setDiplomaError("");
    setPhoneNumberError("");

    // --- Validation ---
    const newEducationErrors = educations.map((edu) => ({
      Institution: !edu.Institution,
      Degree: !edu.Degree,
      FieldOfStudy: !edu.FieldOfStudy,
      GraduationYear: !edu.GraduationYear,
    }));
    setEducationErrors(newEducationErrors);

    const isValidEducation = educations.some(
      (edu) =>
        edu.Institution && edu.Degree && edu.FieldOfStudy && edu.GraduationYear
    );

    const isValid = !newEducationErrors.some((err) =>
      Object.values(err).includes(true)
    );

    if (!isValid || !isValidEducation) {
      const errorMessage = !isValidEducation
        ? "Veuillez remplir tous les champs requis de l'éducation."
        : "Veuillez corriger les erreurs avant de soumettre.";
      alert(errorMessage);
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }

    // Check if a file exist and required
    if (!resume) {
      setResumeError(t("resumeRequired")); // Use i18next
      alert(t("resumeRequired")); // Use i18next
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }

    // Phone number validation
    const number = parsePhoneNumberFromString(
      phoneNumber,
      selectedCountryisoCode
    );
    if (!phoneNumber) {
      setPhoneNumberError(t("phoneNumberRequired"));
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }

    if (!number || !isValidPhoneNumber(`${number.number}`)) {
      setPhoneNumberError(t("invalidPhoneNumber"));
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }
    const formattedNumber = number.format("E.164");

    // --- Prepare Data ---
    // Create the job application data object
    const jobApplicationId = doc(
      collection(
        db,
        `job-descriptions/${tenantID}/active/${jobUuid}/applications`
      )
    );

    // --- File Upload ---
    const uploadPromises = [];

    // Add tenantId, jobUuid, and applicantId to the filenames
    // const applicantId = generateShortCode(); // Generate a unique applicant ID.
    const baseFilename = `${tenantID}_${jobUuid}_${jobApplicationId.id}`;

    if (resume) {
      uploadPromises.push(
        handleFileUpload(resume, "resumesactive", baseFilename)
          .then((filename) => {
            setResumeName(`${filename}`); // Add prefix
          })
          .catch((e) => {
            console.log("resumesactive error", e);
            if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
              Sentry.captureException(e);
            }
            setResumeUploadError(true);
            return e;
          })
      );
    }

    if (coverLetter) {
      uploadPromises.push(
        handleFileUpload(coverLetter, "coverletteractive", baseFilename)
          .then((filename) => {
            setCoverLetterName(`${baseFilename}_${filename}`);
          })
          .catch((e) => {
            console.log("coverletteractive error", e);
            if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
              Sentry.captureException(e);
            }
            return e;
          })
      );
    }
    if (certificatesDocument) {
      uploadPromises.push(
        handleFileUpload(
          certificatesDocument,
          "certificationsactive",
          baseFilename
        )
          .then((filename) => {
            setCertificateName(`${baseFilename}_${filename}`);
          })
          .catch((e) => {
            console.log("certificationsactive error", e);
            if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
              Sentry.captureException(e);
            }
            return e;
          })
      );
    }
    if (diplomas) {
      uploadPromises.push(
        handleFileUpload(diplomas, "diplomasactive", baseFilename)
          .then((filename) => {
            setDiplomaName(`${filename}`);
          })
          .catch((e) => {
            console.log("diplomasactive error", e);
            if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
              Sentry.captureException(e);
            }
            return e;
          })
      );
    }
    const uploadResults = await Promise.all(uploadPromises);
    uploadResults.forEach((e) => {
      console.log(e);
    });

    // Check for upload errors *before* proceeding.  If *any* upload failed, stop.
    if (resumeUploadError) {
      //resume is required, so stop.
      alert(t("uploadError"));
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }

    // --- Submit to Firestore ---
    try {
      const jobApplication = {
        ApplicantID: jobApplicationId.id,
        applicantId: jobApplicationId.id,
        type: jobDetails.type || "",
        FullName: fullName,
        Sex: sex,
        Score: 0, // Initial score can be null or a default value
        ScoreReason: "",
        ContactInformation: {
          Email: email,
          Phone: formattedNumber, // Use formatted number
          Address: address,
          City: selectedCity,
          State: selectedState, // Include State/Region
          Country: selectedCountry.name, // Store the country NAME
        },
        PositionAppliedFor: jobDetails.Titre || "",
        companyName: jobDetails.companyName || "",
        ApplicationDate: Timestamp.now(), // Use ISO string for consistency
        Diploma: diplomaName || "",
        Resume: resumeName || "",
        CoverLetter: coverLetterName || "",
        Education: educations,
        qualifications: qualifications,
        Skills: skills,
        References: references,
        Portfolio: "", // You don't seem to be collecting this, but kept the field.
        Certifications: certificates,
        CertificationDocument: certificateName || "",
        Languages: languages,
        ApplicationStatus: "Submitted",
        tenantId: tenantID, // Include tenantId
        jobUuid: jobUuid, // Include jobUuid
      };
      const jobApplicationRef = doc(
        db,
        `job-descriptions/${tenantID}/active/${jobUuid}/applications`,
        jobApplicationId.id
      );
      await setDoc(jobApplicationRef, jobApplication, { merge: true });

      // --- Success ---
      logEvent(analytics, "application_submitted", {
        job_id: jobID,
        tenant_id: tenantID,
      }); // Log analytics event

      const taskPayload = {
        jobUUID: jobUuid,
        applicationUUID: jobApplicationId.id,
        tenantID: tenantID,
        language: jobLanguage,
      }

      await fetch(`${process.env.REACT_APP_API_ENDPOINT}api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}resumeAnalysisv4`,
          payload: taskPayload,
        }),
      });


      navigate("/thank-you", {
        // Navigate.
        state: {
          tenantId: tenantID,
          jobId: jobUuid,
          fullName: fullName,
          email: email,
          phoneNumber: phoneNumber,
          referrer: "application",
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error submitting application:", error);
      console.log(error);
      // Log error to Sentry in production environment
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      alert(
        "Une erreur s'est produite lors de la soumission de votre candidature.  Veuillez réessayer."
      ); // User-friendly message
    } finally {
      setDisableSubmitButton(false);
      setLoading(false);
    }
  };

  // This block is shown if there's NO jobID in the URL *or* if jobNotFound is true
  if (!jobID || jobNotFound) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md">
          <h1 className="text-2xl font-bold text-red-600 mb-4">
            {/*Conditional rendering of title base on the presence of jobID*/}
            {!jobID ? t("searchForJob") : t("jobNotFound")}
          </h1>
          <p className="text-gray-700 mb-4">
            {/*Conditional rendering of the text*/}
            {!jobID ? t("searchJobText") : t("jobNotFoundText")}
          </p>

          {/* Search Form */}
          <form onSubmit={handleJobIdSubmit} className="mb-4">
            <label
              htmlFor="jobIdInput"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              {t("enterJobId")}
            </label>
            <input
              type="text"
              id="jobIdInput"
              value={jobIdInput}
              onChange={(e) => setJobIdInput(e.target.value)}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={t("jobIdPlaceholder")}
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2"
            >
              {t("search")}
            </button>
          </form>

          <button
            onClick={() => navigate("/job_listings")}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("backToListings")}
          </button>
        </div>
      </div>
    );
  }
  {
    // console.log(dateLimiteDeCandidature, Timestamp.now().toMillis());
  }
  if (!canApply) {
    return (
      <div className="bg-african-sand p-6 rounded-lg shadow-md">
        Désolé, la date limite pour cette offre d&apos;emploi est dépassée. Cliquez
        <Link
          to="/job_listings"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          ici
        </Link>{" "}
        pour retourner à la liste des offres d&apos;emploi.
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      {loading && ( // Show loader when loading
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <ColorRing
            visible={true}
            height="180"
            width="180"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <h1 className="text-2xl font-bold text-center text-gray-800">
            {t("applicationForm")} - {jobDetails.Titre}
          </h1>
          <p className="text-center text-gray-500">
            {" "}
            {t("deadline")}:{" "}
            {jobDetails.length > 0
              ? new Date(
                  jobDetails.DateLimiteDeCandidature.toString()
                ).toLocaleDateString()
              : new Date().toLocaleDateString()}{" "}
          </p>

          <form onSubmit={handleSubmit} className="divide-y divide-gray-200">
            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
              {/* Full Name,  Sex, Email -  All standard text inputs */}
              <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="mb-4 sm:mb-0 sm:w-1/3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="fullName"
                  >
                    {t("fullName")}
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    onChange={(e) =>
                      addOrRemoveText(e.target.value, setFullName)
                    }
                    value={fullName}
                    required
                    placeholder={t("fullName")}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 sm:mb-0 sm:w-1/3">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("sex")}
                  </label>
                  <select
                    value={sex}
                    onChange={(e) => addOrRemoveText(e.target.value, setSex)}
                    name="sex"
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">{t("select")}</option>
                    <option value="Male">{t("male")}</option>
                    <option value="Female">{t("female")}</option>
                    <option value="Other">{t("other")}</option>
                  </select>
                </div>

                <div className="mb-4 sm:mb-0 sm:w-1/3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    {t("email")}
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => addOrRemoveText(e.target.value, setEmail)}
                    required
                    placeholder={t("email")}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>

              {/* Country, State, City, Address, Phone Number - Internationalized fields */}

              <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="mb-4 sm:mb-0 sm:w-1/4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("country")}
                  </label>
                  <select
                    onChange={handleCountryChange}
                    value={selectedCountryisoCode}
                    name="country"
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value=""> {t("selectCountry")}</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4 sm:mb-0 sm:w-1/4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("stateRegion")}
                  </label>
                  <select
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setSelectedCity(""); // Reset city
                    }}
                    disabled={!selectedCountryisoCode}
                    name="state"
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">{t("selectStateRegion")}</option>
                    {State.getStatesOfCountry(selectedCountryisoCode).map(
                      (state) => (
                        <option key={state.isoCode} value={state.name}>
                          {state.name}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="mb-4 sm:mb-0 sm:w-1/4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("city")}
                  </label>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    disabled={!selectedState}
                    name="city"
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value=""> {t("selectCity")}</option>
                    {City.getCitiesOfCountry(selectedCountryisoCode).map(
                      (city) => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="mb-4 sm:mb-0 sm:w-1/4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="address"
                  >
                    {t("address")}
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    placeholder={t("address")}
                    value={address}
                    onChange={(e) =>
                      addOrRemoveText(e.target.value, setAddress)
                    }
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>

              {/* Phone Number - Now with libphonenumber-js */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phoneNumber"
                >
                  {t("phoneNumber")}
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel" // Use type="tel"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  placeholder={t("phoneNumberPlaceholder")} // Dynamic placeholder
                  required
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    phoneNumberError ? "border-red-500" : ""
                  }`}
                />
                {phoneNumberError && (
                  <p className="text-red-500 text-xs italic">
                    {phoneNumberError}
                  </p>
                )}
              </div>

              {/* Resume Upload - With error handling */}
              <div className="mb-4">
                <label
                  htmlFor="resume"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  {t("uploadResume")} *
                </label>
                <input
                  type="file"
                  accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  // eslint-disable-next-line react/no-unknown-property
                  max-size="400000"
                  id="resume"
                  name="resume"
                  onChange={handleFileChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {resumeError && (
                  <p className="text-red-500 text-xs italic">{resumeError}</p>
                )}
              </div>

              {/* Cover Letter - Optional, same handling as resume  */}
              <div className="mb-4">
                <label
                  htmlFor="coverLetter"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  {t("uploadCoverLetter")}
                </label>
                <input
                  type="file"
                  accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  // eslint-disable-next-line react/no-unknown-property
                  max-size="400000"
                  id="coverLetter"
                  name="coverLetter"
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {coverLetterError && (
                  <p className="text-red-500 text-xs italic">
                    {coverLetterError}
                  </p>
                )}
              </div>

              {/* Education */}
              <div className="mb-4">
                <h3 className="text-lg font-bold text-gray-800">
                  {" "}
                  {t("education")}
                </h3>
                {educations.map((education, index) => (
                  <div key={index} className="mb-4 p-4 border rounded">
                    <div className="flex flex-col sm:flex-row sm:space-x-4">
                      <div className="mb-4 sm:mb-0 sm:w-1/4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          {t("institution")}
                        </label>
                        <input
                          type="text"
                          name="Institution"
                          value={education.Institution}
                          onChange={(e) =>
                            handleArrayInputChange(
                              educations,
                              setEducations,
                              index,
                              e
                            )
                          }
                          required
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            submissionAttempted &&
                            educationErrors[index]?.Institution &&
                            "border-red-500"
                          }`} // Dynamic red border
                        />
                        {submissionAttempted &&
                          educationErrors[index]?.Institution && (
                            <p className="text-red-500 text-xs italic">
                              {t("fieldRequired")}
                            </p>
                          )}
                      </div>
                      <div className="mb-4 sm:mb-0 sm:w-1/4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          {t("degree")}
                        </label>
                        <input
                          type="text"
                          name="Degree"
                          value={education.Degree}
                          onChange={(e) =>
                            handleArrayInputChange(
                              educations,
                              setEducations,
                              index,
                              e
                            )
                          }
                          required
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            submissionAttempted &&
                            educationErrors[index]?.Degree &&
                            "border-red-500"
                          }`} // Dynamic red border
                        />
                        {submissionAttempted &&
                          educationErrors[index]?.Degree && (
                            <p className="text-red-500 text-xs italic">
                              {t("fieldRequired")}
                            </p>
                          )}
                      </div>

                      <div className="mb-4 sm:mb-0 sm:w-1/4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          {t("fieldOfStudy")}
                        </label>
                        <input
                          type="text"
                          name="FieldOfStudy"
                          value={education.FieldOfStudy}
                          onChange={(e) =>
                            handleArrayInputChange(
                              educations,
                              setEducations,
                              index,
                              e
                            )
                          }
                          required
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            submissionAttempted &&
                            educationErrors[index]?.FieldOfStudy &&
                            "border-red-500"
                          }`} // Dynamic red border
                        />
                        {submissionAttempted &&
                          educationErrors[index]?.FieldOfStudy && (
                            <p className="text-red-500 text-xs italic">
                              {t("fieldRequired")}
                            </p>
                          )}
                      </div>
                      <div className="mb-4 sm:mb-0 sm:w-1/4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          {t("graduationYear")}
                        </label>
                        <input
                          type="number"
                          name="GraduationYear"
                          value={education.GraduationYear}
                          onChange={(e) =>
                            handleArrayInputChange(
                              educations,
                              setEducations,
                              index,
                              e
                            )
                          }
                          required
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            submissionAttempted &&
                            educationErrors[index]?.GraduationYear &&
                            "border-red-500"
                          }`} // Dynamic red border
                        />
                        {submissionAttempted &&
                          educationErrors[index]?.GraduationYear && (
                            <p className="text-red-500 text-xs italic">
                              {t("fieldRequired")}
                            </p>
                          )}
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={() =>
                        addOrRemoveItem(educations, setEducations, null, index)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      {t("remove")}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    addOrRemoveItem(educations, setEducations, {
                      Institution: "",
                      Degree: "",
                      FieldOfStudy: "",
                      GraduationYear: "",
                    })
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("addEducation")}
                </button>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="diplomas"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  {t("uploadDiploma")}
                </label>
                <input
                  type="file"
                  accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  // eslint-disable-next-line react/no-unknown-property
                  max-size="400000"
                  id="diplomas"
                  name="diplomas"
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {diplomaError && (
                  <p className="text-red-500 text-xs italic">{diplomaError}</p>
                )}
              </div>

              {/* Qualifications */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("qualifications")}
                </label>
                <textarea
                  value={qualifications}
                  onChange={(e) => setQualifications(e.target.value)}
                  rows="4"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={t("qualificationsPlaceholder")}
                ></textarea>
              </div>

              {/* Skills */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("skills")}
                </label>
                <textarea
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  rows="4"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={t("skillsPlaceholder")}
                ></textarea>
              </div>

              {/* References */}
              <div className="mb-4">
                <h3 className="text-lg font-bold text-gray-800">
                  {t("references")}
                </h3>
                {references.map((reference, index) => (
                  <div key={index} className="mb-4 p-4 border rounded">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("refereeName")}
                      </label>
                      <input
                        type="text"
                        name="RefereeName"
                        value={reference.RefereeName}
                        onChange={(e) =>
                          handleArrayInputChange(
                            references,
                            setReferences,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("relation")}
                      </label>
                      <input
                        type="text"
                        name="Relation"
                        value={reference.Relation}
                        onChange={(e) =>
                          handleArrayInputChange(
                            references,
                            setReferences,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("contactInformation")}
                      </label>
                      <input
                        type="text"
                        name="ContactInformation"
                        value={reference.ContactInformation}
                        onChange={(e) =>
                          handleArrayInputChange(
                            references,
                            setReferences,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <button
                      type="button"
                      onClick={() =>
                        addOrRemoveItem(references, setReferences, null, index)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      {t("remove")}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    addOrRemoveItem(references, setReferences, {
                      RefereeName: "",
                      Relation: "",
                      ContactInformation: "",
                    })
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("addReference")}
                </button>
              </div>

              {/* Certifications */}
              <div className="mb-4">
                <h3 className="text-lg font-bold text-gray-800">
                  {t("certifications")}
                </h3>
                {certificates.map((certificate, index) => (
                  <div key={index} className="mb-4 p-4 border rounded">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("certificationName")}
                      </label>
                      <input
                        type="text"
                        name="CertificationName"
                        value={certificate.CertificationName}
                        onChange={(e) =>
                          handleArrayInputChange(
                            certificates,
                            setCertificates,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("issuingOrganization")}
                      </label>
                      <input
                        type="text"
                        name="IssuingOrganization"
                        value={certificate.IssuingOrganization}
                        onChange={(e) =>
                          handleArrayInputChange(
                            certificates,
                            setCertificates,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("dateObtained")}
                      </label>
                      <input
                        type="date"
                        name="DateObtained"
                        value={certificate.DateObtained}
                        onChange={(e) =>
                          handleArrayInputChange(
                            certificates,
                            setCertificates,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("expiryDate")}
                      </label>
                      <input
                        type="date"
                        name="ExpiryDate"
                        value={certificate.ExpiryDate}
                        onChange={(e) =>
                          handleArrayInputChange(
                            certificates,
                            setCertificates,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        addOrRemoveItem(
                          certificates,
                          setCertificates,
                          null,
                          index
                        )
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      {t("remove")}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    addOrRemoveItem(certificates, setCertificates, {
                      CertificationName: "",
                      IssuingOrganization: "",
                      DateObtained: "",
                      ExpiryDate: "",
                    })
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("addCertification")}
                </button>
              </div>

              {/* Certification Document Upload - Optional */}
              <div className="mb-4">
                <label
                  htmlFor="certificatesDocument"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  {t("uploadCertificates")}
                </label>
                <input
                  type="file"
                  accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  // eslint-disable-next-line react/no-unknown-property
                  max-size="400000"
                  id="certificatesDocument"
                  name="certificatesDocument"
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {certificateError && (
                  <p className="text-red-500 text-xs italic">
                    {certificateError}
                  </p>
                )}
              </div>

              {/* Languages */}
              <div className="mb-4">
                <h3 className="text-lg font-bold text-gray-800">
                  {" "}
                  {t("languages")}
                </h3>
                {languages.map((language, index) => (
                  <div key={index} className="mb-4 p-4 border rounded">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("language")}
                      </label>
                      <input
                        type="text"
                        name="Language"
                        value={language.Language}
                        onChange={(e) =>
                          handleArrayInputChange(
                            languages,
                            setLanguages,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("proficiency")}
                      </label>
                      <select
                        name="Proficiency"
                        value={language.Proficiency}
                        onChange={(e) =>
                          handleArrayInputChange(
                            languages,
                            setLanguages,
                            index,
                            e
                          )
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value=""> {t("select")}</option>
                        <option value="Native">{t("native")}</option>
                        <option value="Fluent">{t("fluent")}</option>
                        <option value="Intermediate">
                          {t("intermediate")}
                        </option>
                        <option value="Basic">{t("basic")}</option>
                      </select>
                    </div>

                    <button
                      type="button"
                      onClick={() =>
                        addOrRemoveItem(languages, setLanguages, null, index)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      {t("remove")}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    addOrRemoveItem(languages, setLanguages, {
                      Language: "",
                      Proficiency: "",
                    })
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("addLanguage")}
                </button>
              </div>
            </div>

            <div className="pt-5 text-center">
              <button
                type="submit"
                disabled={disableSubmitButton} //useful
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {t("submitApplication")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsPage;
