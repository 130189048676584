import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';  // Import Link
import { useAuth } from '../useAuth'; // Import the useAuth hook
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase-config";


function DocumentationPage() {
  const { t } = useTranslation('DocumentationPage'); // Use 'global' or your specific namespace
  const [openSection, setOpenSection] = useState(null);
  const { currentUser } = useAuth(); // Get current user


  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
     useEffect(() => {
        document.title =  t('documentation.title') + " - ProRecruit";
         logEvent(analytics, "page_view", {
            page_title:  document.title,
            page_location: window.location.href
         });

        return () => {
            document.title = "ProRecruit"; // Reset title on unmount
        };
    }, [t]); // Add t as a dependency


  return (
    <div className="font-sans antialiased bg-gray-50 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
           {t('documentation.title')}
        </h1>
        <p className="text-lg text-gray-600 text-center mb-12">
            {t('documentation.intro')}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column:  Navigation (Table of Contents) */}
          <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
               {t('documentation.sections')}
            </h2>
            <nav className="space-y-2">
              <a href="#getting-started" className="block text-blue-600 hover:underline" onClick={()=>setOpenSection('getting-started')}>
                {t('gettingStarted')}
              </a>
              <a href="#account-management" className="block text-blue-600 hover:underline"  onClick={()=>setOpenSection('account-management')}>
                 {t('accountManagement')}
              </a>
              <a href="#job-postings" className="block text-blue-600 hover:underline" onClick={()=>setOpenSection('job-postings')}>
                {t('jobPostings')}
              </a>
              <a href="#candidate-management" className="block text-blue-600 hover:underline"  onClick={()=>setOpenSection('candidate-management')}>
                {t('candidateManagement')}
              </a>
              <a href="#assessments" className="block text-blue-600 hover:underline"  onClick={()=>setOpenSection('assessments')}>
                 {t('assessments')}
              </a>
              <a href="#reporting" className="block text-blue-600 hover:underline" onClick={()=>setOpenSection('reporting')}>
                {t('reporting')}
              </a>
              <a href="#affiliate-program" className="block text-blue-600 hover:underline" onClick={()=>setOpenSection('affiliate-program')}>
                {t('affiliateProgram')}
              </a>
              <a href="#faq" className="block text-blue-600 hover:underline" onClick={()=>setOpenSection('faq')}>
                {t('faq')}
              </a>
              {/* Add more sections as needed */}
            </nav>
          </div>

          {/* Right Column: Content */}
          <div className="bg-white rounded-lg shadow-md p-6 space-y-8">
            {/* Getting Started Section */}
            <Fragment>
            <section id="getting-started" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                  <button onClick={() => toggleSection('getting-started')} className="mr-2 focus:outline-none">
                    {openSection === 'getting-started' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>

                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                  </button>
                {t('gettingStarted')}
              </h2>
              {openSection === 'getting-started' && (
                <div>
                  <p className="text-gray-700">
                      {t('gettingStartedIntro')}
                  </p>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                      <strong className="text-gray-900">{t('gettingStarted.createAccount')}</strong> {t('gettingStarted.createAccountDesc')}
                    </li>
                    <li>
                      <strong className="text-gray-900">{t('gettingStarted.accessDashboard')}:</strong> {t('gettingStarted.accessDashboardDesc')}
                    </li>
                    <li>
                      <strong className="text-gray-900">{t('gettingStarted.postJob')}:</strong>{t('gettingStarted.postJobDesc')}
                    </li>
                  </ul>
                  <p className="mt-4">
                    {t('gettingStarted.fullGuide')} <Link to="/getting-started" className="text-blue-600 hover:underline">{t('gettingStarted.fullGuideLink')}</Link>.
                  </p>
                </div>
              )}
            </section>

             {/* Account Management Section */}
            <section id="account-management" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                <button onClick={() => toggleSection('account-management')} className="mr-2 focus:outline-none">
                    {openSection === 'account-management' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                </button>
                {t('accountManagement')}
                </h2>
                {openSection === 'account-management' && (
                <div>
                <p className="text-gray-700">
                    {t('accountManagementIntro')}
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                        <strong className="text-gray-900">{t('accountManagement.editProfile')}:</strong>  {t('accountManagement.editProfileDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('accountManagement.addUsers')}:</strong> {t('accountManagement.addUsersDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('accountManagement.notifications')}:</strong>  {t('accountManagement.notificationsDesc')}
                    </li>
                </ul>
                </div>
              )}
            </section>

            {/* Job Postings Section */}
            <section id="job-postings" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                 <button onClick={() => toggleSection('job-postings')} className="mr-2 focus:outline-none">
                    {openSection === 'job-postings' ? (
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                 </button>
                {t('jobPostings')}
              </h2>
              {openSection === 'job-postings' && (
                <div>
                  <p className="text-gray-700">
                      {t('jobPostingsIntro')}
                  </p>
                    <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                        <strong className="text-gray-900">{t('jobPostings.create')}:</strong> {t('jobPostings.createDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('jobPostings.types')}:</strong> {t('jobPostings.typesDesc')}
                    </li>
                     <li>
                        <strong className="text-gray-900">{t('jobPostings.publish')}:</strong> {t('jobPostings.publishDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('jobPostings.edit')}:</strong> {t('jobPostings.editDesc')}
                    </li>
                    </ul>
                </div>
              )}
            </section>

             {/* Candidate Management Section */}
            <section id="candidate-management" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                <button onClick={() => toggleSection('candidate-management')} className="mr-2 focus:outline-none">
                    {openSection === 'candidate-management' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                </button>
                 {t('candidateManagement')}
                </h2>
              {openSection === 'candidate-management' && (
                <div>
                  <p className="text-gray-700">
                    {t('candidateManagementIntro')}
                  </p>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                        <strong className="text-gray-900">{t('candidateManagement.view')}:</strong>  {t('candidateManagement.viewDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('candidateManagement.filter')}:</strong> {t('candidateManagement.filterDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('candidateManagement.profiles')}:</strong> {t('candidateManagement.profilesDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('candidateManagement.status')}:</strong> {t('candidateManagement.statusDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('candidateManagement.communicate')}:</strong> {t('candidateManagement.communicateDesc')}
                    </li>
                  </ul>
                </div>
              )}
            </section>

            {/* Assessments Section */}
            <section id="assessments" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                 <button onClick={() => toggleSection('assessments')} className="mr-2 focus:outline-none">
                    {openSection === 'assessments' ? (
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                 </button>
                 {t('assessments')}
                </h2>
                {openSection === 'assessments' && (
                <div>
                <p className="text-gray-700">
                    {t('assessmentsIntro')}
                </p>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                        <strong className="text-gray-900">{t('assessments.create')}:</strong> {t('assessments.createDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('assessments.useExisting')}:</strong>{t('assessments.useExistingDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('assessments.assign')}:</strong> {t('assessments.assignDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('assessments.analyze')}:</strong> {t('assessments.analyzeDesc')}
                    </li>
                  </ul>
                </div>
              )}
            </section>

              {/* Reporting Section */}
            <section id="reporting" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                 <button onClick={() => toggleSection('reporting')} className="mr-2 focus:outline-none">
                    {openSection === 'reporting' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                 </button>
                 {t('reporting')}
                </h2>
              {openSection === 'reporting' &&(
                <div>
                <p className="text-gray-700">
                    {t('reportingIntro')}
                </p>
                 <ul className="list-disc list-inside text-gray-700 ml-4">
                        <li>
                            <strong className="text-gray-900">{t('reporting.dashboard')}:</strong> {t('reporting.dashboardDesc')}
                        </li>
                        <li>
                            <strong className="text-gray-900">{t('reporting.custom')}:</strong> {t('reporting.customDesc')}
                        </li>
                        <li>
                            <strong className="text-gray-900">{t('reporting.affiliate')}:</strong> {t('reporting.affiliateDesc')}
                        </li>
                  </ul>
                </div>
                )}
            </section>

            {/* Affiliate Program Section */}
            <section id="affiliate-program" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                <button onClick={() => toggleSection('affiliate-program')} className="mr-2 focus:outline-none">
                    {openSection === 'affiliate-program' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                </button>
                {t('affiliateProgram')}
                </h2>
              {openSection === 'affiliate-program' && (
                <div>
                <p className="text-gray-700">
                    {t('affiliateProgramIntro')}
                </p>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>
                        <strong className="text-gray-900">{t('affiliateProgram.how')}:</strong> {t('affiliateProgram.howDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('affiliateProgram.commissions')}:</strong> {t('affiliateProgram.commissionsDesc')}
                    </li>
                    <li>
                        <strong className="text-gray-900">{t('affiliateProgram.signup')}:</strong>  {t('affiliateProgram.signupDesc')}
                    </li>
                     <li>
                        <strong className="text-gray-900">{t('affiliateProgram.resources')}:</strong> {t('affiliateProgram.resourcesDesc')}
                    </li>
                  </ul>
                </div>
               )}
            </section>

            {/* FAQ Section */}
            <section id="faq" className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                 <button onClick={() => toggleSection('faq')} className="mr-2 focus:outline-none">
                    {openSection === 'faq' ? (
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                 </button>
                {t('faqTitle')}
               </h2>
               {openSection === 'faq' && (
                <div className="space-y-4">
                    {/* FAQ Item 1 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question1')}</h3>
                        <p className="text-gray-600">{t('faq.answer1')}</p>
                    </div>

                    {/* FAQ Item 2 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question2')}</h3>
                        <p className="text-gray-600">{t('faq.answer2')}</p>
                    </div>

                      {/* FAQ Item 3 */}
                      <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question3')}</h3>
                        <p className="text-gray-600">{t('faq.answer3')}</p>
                    </div>

                    {/* FAQ Item 4 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question4')}</h3>
                        <p className="text-gray-600">{t('faq.answer4')}</p>
                    </div>
                    {/* FAQ Item 5 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question5')}</h3>
                        <p className="text-gray-600">{t('faq.answer5')}</p>
                    </div>
                    {/* FAQ Item 6 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question6')}</h3>
                        <p className="text-gray-600">{t('faq.answer6')}</p>
                    </div>
                    {/* FAQ Item 7 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question7')}</h3>
                        <p className="text-gray-600">{t('faq.answer7')}</p>
                    </div>
                     {/* FAQ Item 8 */}
                     <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question8')}</h3>
                        <p className="text-gray-600">{t('faq.answer8')}</p>
                    </div>
                     {/* FAQ Item 9*/}
                     <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question9')}</h3>
                        <p className="text-gray-600">{t('faq.answer9')}</p>
                    </div>
                    {/* FAQ Item 10 */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">{t('faq.question10')}</h3>
                        <p className="text-gray-600">{t('faq.answer10')}</p>
                    </div>
                </div>
               )}
            </section>
            </Fragment>
          </div>
        </div>
         {/* Conditional rendering based on authentication status */}
      {currentUser ? (
        <div className="text-center mt-4">
          <Link
            to="/dashboard"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("goToDashboard")}
          </Link>
        </div>
      ) : (
        <div className="text-center mt-4">
          <Link to="/signup" className="text-blue-600 hover:underline">
            {t("createAccount")}
          </Link>{" "}
          {t("or")}{" "}
          <Link to="/signin" className="text-blue-600 hover:underline">
            {t("signIn")}
          </Link>{" "}
          {t("toAccessDashboard")}
        </div>
      )}
      </div>
    </div>
  );
}

export default DocumentationPage;