// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import store from "./utils/redux/store";
import "./i18n"; // Import the i18n configuration
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn:
    "https://c4c39c7843c8b53e8f330549b999441c@o4508893477994496.ingest.us.sentry.io/4508893480026112",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Tracing
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/app\.prorecruit\.tech\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
  beforeSend(event, hint) {
    // Check if the event originated from a URL you want to exclude
    const excludedUrls = [
      "http://localhost:3000",
      "http://localhost:5001", // Add other local development URLs
      "https://qa-prorecruit-4c9d5.web.app", // Your QA/staging URL
      // Add any other URLs you want to exclude
    ];

    // Access the URL.  Different properties depending on the event type.
    const url =
      event.request?.url || // For HTTP requests
      event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename || // Common for errors
      event.stacktrace?.frames?.[0]?.filename || //Another option.
      ""; // Default to empty string if no URL found

    // Check if the URL is in the excluded list
    if (excludedUrls.some((excludedUrl) => url.startsWith(excludedUrl))) {
      return null; // Drop the event
    }

    // Otherwise, send the event
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

reportWebVitals();
