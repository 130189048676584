import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { signOut } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { auth, analytics, db } from "../firebase-config";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import TawkToChat from "../components/TawkToChat";
import Navbar from "../components/Navbar";
import { useAuth } from "../useAuth";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";
import { fetchUserByEmail } from "../utils/redux/userDataSlice";
import convertCurrencyWithFee from "../utils/convertCurrencyWithFee";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CinetPayButton from "../components/CinetPayButton";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addDoc, collection, doc, getDoc, Timestamp } from "firebase/firestore";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const calculateDiscountedTotal = async (
  basicLicenses,
  proLicenses,
  currency,
  discount
) => {
  const basicPriceData = await getDoc(doc(db, "price", "basic")).data();
  const proPriceData = await getDoc(doc(db, "price", "pro")).data();
  return (
    (1 - discount / 100) *
    (basicPriceData.price[currency.toLowerCase()] * basicLicenses +
      proPriceData.price[currency.toLowerCase()] * proLicenses)
  );
};

const LicenseBuyingPage = () => {
  const [basicLicenses, setBasicLicenses] = useState(0);
  const [proLicenses, setProLicenses] = useState(0);
  const [basicPricePerLicense, setBasicPricePerLicense] = useState(null);
  const [proPricePerLicense, setProPricePerLicense] = useState(null);
  const [currency, setCurrency] = useState("USD"); // Default currency
  const [discount, setDiscount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [message, setMessage] = useState(""); // For PayPal messages
  const companyData = useSelector(selectAllCompanys);
  const { currentUser } = useAuth();
  const [paymentStatus, setPaymentStatus] = useState(null); // 'pending', 'completed', 'failed', null
  const [paymentFailureMessage, setPaymentFailureMessage] = useState({});
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(0);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    document.title = "License Buying Page - ProRecruit"; // Set the title dynamically
    logEvent(analytics, "view_cart", {
      currency: "XOF", // Or "EUR", etc., depending on your pricing
      value: 0.0, //  (3 * 99) - Total value of the items in the cart
      items: [
        {
          item_id: "PRORECRUIT_LICENSE_BASIC", // Unique ID for the basic annual license
          item_name: "ProRecruit Basic License", // User-friendly name
          affiliation: "ProRecruit", // Your company
          coupon: "", //  If a coupon was applied, put the code here.  Otherwise, an empty string.
          discount: 0.0, // Actual discount amount.  0 if no coupon.
          index: 1, // Position in the cart (start with 1)
          item_brand: "ProRecruit",
          item_category: "Software License",
          item_category2: "Recruitment",
          item_category3: "License",
          item_category4: "Basic",
          item_category5: "", // Not applicable
          item_list_id: "checkout_page", //  Could be "pricing_page", "checkout_page", etc.
          item_list_name: "License Options", // User-friendly list name
          item_variant: "", // Use this if you have variations (e.g., "Small Business", "Enterprise") *within* the Basic tier
          location_id: "", // Not applicable for a digital product
          price: 10000, // Price *per license*
          quantity: 0, // Number of Basic licenses in the cart
        },
        {
          item_id: "PRORECRUIT_LICENSE_PRO",
          item_name: "ProRecruit Pro License",
          affiliation: "ProRecruit",
          coupon: "",
          discount: 0.0, // Discount amount (e.g., one month free)
          index: 2,
          item_brand: "ProRecruit",
          item_category: "Software License",
          item_category2: "Recruitment",
          item_category3: "License",
          item_category4: "Pro",
          item_category5: "",
          item_list_id: "checkout_page",
          item_list_name: "License Options",
          item_variant: "",
          location_id: "",
          price: 100000,
          quantity: 0,
        },
      ],
    });

    // Cleanup function: Reset the title when the component unmounts
    return () => {
      document.title = "ProRecruit"; // Reset to a default title
    };
  }, []); // Empty dependency array: runs only on mount (and cleanup on unmount)

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        // console.log(currentUser);
        setUserId(analytics, currentUser ? currentUser.uid : "");
        setUserProperties(analytics, {
          fullName: currentUser ? currentUser.displayName : "unknown",
          email: currentUser ? currentUser.email : "unknown",
        });
        const u = await dispatch(fetchUserByEmail(currentUser.email));
        // console.log(u);
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
        setCompany(c.payload[0]);
        setDiscount(c.payload[0].discountPercent || 0);
        setProLicenseCount(c.payload[0].proLicense);
        setBasicLicenseCount(c.payload[0].basicLicense);

        if (c.payload.proLicense + c.payload.basicLicense <= 0) {
          setHasAnyLicense(false);
        } else {
          setHasAnyLicense(true);
        }
      }
    };
    fetchData();
  }, [currentUser, dispatch]);

  useEffect(() => {
    const fetchPrices = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch company data to get the currency
        const companyDocRef = doc(db, "company", company.tenantId);
        const companyDocSnap = await getDoc(companyDocRef);

        if (companyDocSnap.exists()) {
          const companyData = companyDocSnap.data().companyData;
          setCurrency(companyData.country?.currency || "XOF"); // Default to XOF

          // Fetch prices, defaulting to USD
          const basicPriceDocRef = doc(db, "price", "basic");
          const proPriceDocRef = doc(db, "price", "pro");

          const basicPriceDocSnap = await getDoc(basicPriceDocRef);
          const proPriceDocSnap = await getDoc(proPriceDocRef);

          if (basicPriceDocSnap.exists() && proPriceDocSnap.exists()) {
            const basicPriceData = basicPriceDocSnap.data();
            const proPriceData = proPriceDocSnap.data();

            const getPrice = (priceData, currency) => {
              const lowerCaseCurrency = currency.toLowerCase();
              if (
                priceData.price &&
                typeof priceData.price[lowerCaseCurrency] === "number"
              ) {
                return priceData.price[lowerCaseCurrency];
              } else if (typeof priceData.price.usd === "number") {
                console.warn(
                  `Currency ${currency} not found, defaulting to USD.`
                );
                return priceData.price.usd;
              } else {
                console.error(
                  `Currency ${currency} and USD not found in price data.`
                );
                setError(
                  `Currency ${currency} and USD not found in price data.`
                );
                return null;
              }
            };

            const basicPrice = getPrice(
              basicPriceData,
              companyData.country?.currency || "XOF"
            );
            const proPrice = getPrice(
              proPriceData,
              companyData.country?.currency || "XOF"
            );

            if (basicPrice !== null) {
              setBasicPricePerLicense(basicPrice);
            }
            if (proPrice !== null) {
              setProPricePerLicense(proPrice);
            }
          } else {
            setError("Could not retrieve pricing information.");
          }
        } else {
          setError("Company data not found.");
        }
      } catch (error) {
        console.error("Error fetching prices:", error);
        setError("Failed to load prices: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    if (company.tenantId) {
      // Only fetch if companyId is available
      fetchPrices();
    }
  }, [company]); // db is a dependency

  useEffect(() => {
    const getTotalAmount = async () => {
      // Only proceed if *both* prices were retrieved successfully.
      // Fetch prices, defaulting to USD
      if (basicPricePerLicense !== null && proPricePerLicense !== null) {
        const t =
          basicLicenses * basicPricePerLicense +
          proLicenses * proPricePerLicense;
        const d = t * ((100 - discount) / 100);
        setTotalAmount(t);
        setDiscountedTotalAmount(d);
      } else {
        // Handle the error appropriately.  Don't set totalAmount.
        // setError("Failed to retrieve pricing information.");
        setTotalAmount(0);
        setDiscountedTotalAmount(0);
      }
    };
    getTotalAmount();
  }, [basicLicenses, proLicenses, company, discount]);

  useEffect(() => {
    if (paymentStatus === "completed") {
      alert("Payment successful!");
    } else if (paymentStatus === "failed") {
      alert(paymentFailureMessage.message);
    }
  }, [paymentStatus]);

  useEffect(() => {
    const params = getQueryStringParams();
    if (params.token) {
      alert(`Payment successful!${params.token}`);
    }
  }, []);

  // Stripe fetchClientSecret, now a useCallback
  const fetchClientSecret = useCallback(async () => {
    setError("");
    if (!companyData || companyData.length === 0 || !companyData[0].country) {
      // console.log("Company data not ready yet."); // Debugging
      return; // Exit if company data isn't loaded
    }
    setLoading(true);
    try {
      // console.log(companyData);
      logEvent(analytics, "begin_checkout", {
        currency: companyData[0].country.currency,
        couppon: companyData[0].couppon || "",
        value:
          basicPricePerLicense * basicLicenses +
          proPricePerLicense * proLicenses, // Total value of added items (1 * 10000 + 2 * 100000)
        items: [
          {
            item_id: "PRORECRUIT_LICENSE_BASIC",
            item_name: "ProRecruit Basic License",
            affiliation: "ProRecruit",
            coupon: "", // Add coupon code if applied
            discount:
              (basicPricePerLicense * basicLicenses) *
              (discount / 100), // Discount amount (if any)
            index: 1, // Order in which the item was added, can be arbitrary but consistent
            item_brand: "ProRecruit",
            item_category: "Software License",
            item_category2: "Recruitment",
            item_category3: "License",
            item_category4: "Basic",
            item_category5: "",
            item_list_id: "pricing_page", //  Where did the user add from?
            item_list_name: "Pricing Page", // User-friendly name
            item_variant: "",
            location_id: "",
            price: basicPricePerLicense,
            quantity: basicLicenses, // Number of Basic licenses added
          },
          {
            item_id: "PRORECRUIT_LICENSE_PRO",
            item_name: "ProRecruit Pro License",
            affiliation: "ProRecruit",
            coupon: "LAUNCH20", // Example coupon code
            discount: 
            (proPricePerLicense * proLicenses) *
            (discount / 100), // Example: 20% discount on 100000 = 20000
            index: 2, //Order in which the item was added.
            item_brand: "ProRecruit",
            item_category: "Software License",
            item_category2: "Recruitment",
            item_category3: "License",
            item_category4: "Pro",
            item_category5: "",
            item_list_id: "pricing_page",
            item_list_name: "Pricing Page",
            item_variant: "",
            location_id: "",
            price: proPricePerLicense,
            quantity: proLicenses, // Number of Pro licenses added
          },
        ],
      });
      const response = await fetch(`/api/StripeCheckoutSession`, {
        // Your API endpoint
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          companyID: companyData[0].tenantId, // Use tenantId
          basicLicenses,
          basicPricePerLicense,
          proPricePerLicense,
          proLicenses,
          currency: companyData[0].country.currency, // Use company's currency
          url: `${process.env.REACT_APP_BASE_URL}stripe_payment_return?session_id={CHECKOUT_SESSION_ID}`,
          userID: currentUser.email,
        }),
      });

      console.log(
        `fetching client secret`,
        response.ok,
        response.status,
        response.statusText
      );
      const data = await response.json();

      if (!response.ok) {
        setLoading(false); // Stop loading on error
        throw new Error(
          data.error?.message || "Failed to create payment intent."
        );
      }
      setLoading(false); // Stop loading on success
      return data.clientSecret; // Return the client secret
    } catch (error) {
      console.error("Error fetching client secret:", error.message);
      setError("Failed to initialize payment: " + error.message);
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      setLoading(false); // Stop loading on error
      return null; // Return null on error
    }
  }, [companyData, basicLicenses, proLicenses]); // Add dependencies

  const stripeSessionOptions = { options: { fetchClientSecret } };

  const handleSignOut = useCallback(async () => {
    try {
      logEvent(analytics, "select_content", {
        content_type: "ClickedSignout",
        content_id: `${currentUser.uid}`,
      });

      await fetch(`${API_ENDPOINT}api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
      if (process.env.REACT_APP_ENVIRONMENT === "prod") {
        Sentry.captureException(error);
      }
      // Log error to Firestore
      try {
        await addDoc(collection(db, "prorecruit_error"), {
          page: "LicenseBuyingPage",
          data: {
            companyName: companyData.name,
            tenantId: companyData.tenantId,
            country: companyData.country.name,
            basicLicenses: basicLicenses,
            proLicenses: proLicenses,
            amount: totalAmount,
          },
          message: error.message || "Unknown error",
          stack: error.stack || "No stack trace",
          name: error.name || "Error",
          timestamp: Timestamp.now(),
        });
      } catch (firestoreError) {
        console.error("Error saving error log to Firestore:", firestoreError);
        if (process.env.REACT_APP_ENVIRONMENT === "prod") {
          Sentry.captureException(firestoreError); // Log Firestore errors to Sentry too
        }
      }
    }
  }, [currentUser]);
  // Function to get query string parameters
  const getQueryStringParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      token: urlParams.get("token"),
      payment: urlParams.get("payment"), // Get the payment method
    };
  };

  const handleBasicChange = (event) => {
    var count = parseInt(event.target.value);
    setBasicLicenses(isNaN(count) || count < 0 ? 0 : count);
  };

  const handleProChange = (event) => {
    var count = parseInt(event.target.value);
    setProLicenses(isNaN(count) || count < 0 ? 0 : count);
  };

  const handlePaymentChange = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const handlePaymentSuccess = async (id, type) => {
    const functions = getFunctions();
    const processPayment = httpsCallable(functions, "processSuccessfulPayment");

    try {
      const result = await processPayment({
        companyID: companyData[0].tenantId, // Assuming you have companyData.id
        userID: currentUser.email, // From Firebase Authentication
        timestamp: Date.now(), // Current timestamp in milliseconds
        totalAmount: calculateDiscountedTotal(
          basicLicenses,
          proLicenses,
          discount
        ), // Calculate the total
        transactionId: id,
        transactionType: type,
        country: companyData[0].country.name || "",
        city: companyData[0].city || "",
        name: companyData[0].name || "",
        basicLicenses: basicLicenses, // Pass license quantities
        proLicenses: proLicenses,
      });

      console.log("Payment processed successfully:", result.data);
      // Redirect to success page, show confirmation message, etc.
      setPaymentStatus("completed");
      alert("Votre paiement a été effectué avec succès.");
      navigate("/");
    } catch (error) {
      console.error("Payment processing error:", error);
      // Handle errors (display error message to the user)
      if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
        Sentry.captureException(error);
      }
      setPaymentFailureMessage({
        status: "failed",
        message: error.message,
      });
      setPaymentStatus("failed");
      if (process.env.REACT_APP_ENVIRONMENT === "prod") {
        Sentry.captureException(error);
      }
      // Log error to Firestore
      try {
        await addDoc(collection(db, "prorecruit_error"), {
          page: "LicenseBuyingPage",
          data: {
            companyName: companyData.name,
            tenantId: companyData.tenantId,
            country: companyData.country.name,
            basicLicenses: basicLicenses,
            proLicenses: proLicenses,
          },
          message: error.message || "Unknown error",
          stack: error.stack || "No stack trace",
          name: error.name || "Error",
          timestamp: Timestamp.now(),
        });
      } catch (firestoreError) {
        console.error("Error saving error log to Firestore:", firestoreError);
        if (process.env.REACT_APP_ENVIRONMENT === "prod") {
          Sentry.captureException(firestoreError); // Log Firestore errors to Sentry too
        }
      }
    }
  };

  const handleCinetpaySuccess = async (response, transactionId) => {
    if (response.status === "ACCEPTED") {
      const functions = getFunctions();
      const processPayment = httpsCallable(
        functions,
        "processSuccessfulPayment"
      );

      try {
        const result = await processPayment({
          companyID: companyData[0].tenantId, // Assuming you have companyData.id
          userID: currentUser.email, // From Firebase Authentication
          timestamp: Date.now(), // Current timestamp in milliseconds
          totalAmount: calculateDiscountedTotal(
            basicLicenses,
            proLicenses,
            discount
          ), // Calculate the total
          transactionId: transactionId,
          transactionType: "CinetPay",
        });

        // Redirect to success page, show confirmation message, etc.
        setPaymentStatus("completed");

        alert("Votre paiement a été effectué avec succès.");
        navigate("/");
      } catch (error) {
        console.error("Payment processing error:", error);
        if (process.env.REACT_APP_ENVIRONEMENT === "prod") {
          Sentry.captureException(error);
        }
        // Handle errors (display error message to the user)
        setPaymentFailureMessage({
          status: "failed",
          message: error.message,
        });
        setPaymentStatus("failed");
        if (process.env.REACT_APP_ENVIRONMENT === "prod") {
          Sentry.captureException(error);
        }
        // Log error to Firestore
        try {
          await addDoc(collection(db, "prorecruit_error"), {
            page: "LicenseBuyingPage",
            data: {
              companyName: companyData.name,
              tenantId: companyData.tenantId,
              country: companyData.country.name,
              basicLicenses: basicLicenses,
              proLicenses: proLicenses,
            },
            message: error.message || "Unknown error",
            stack: error.stack || "No stack trace",
            name: error.name || "Error",
            timestamp: Timestamp.now(),
          });
        } catch (firestoreError) {
          console.error("Error saving error log to Firestore:", firestoreError);
          if (process.env.REACT_APP_ENVIRONMENT === "prod") {
            Sentry.captureException(firestoreError); // Log Firestore errors to Sentry too
          }
        }
      }
    } else if (response.status === "REFUSED") {
      console.log(transactionId, "REFUSED", response);
      setPaymentFailureMessage({
        status: "failed",
        message: "Votre paiement a échoué. Please try again.",
      });
      setPaymentStatus("failed");
    } else {
      handleCinetpayFailure(response, transactionId);
    }
  };

  const handleCinetpayFailure = (error, transactionId) => {
    console.error("Payment Failure:", error);
    if (error.status && error.status === "REFUSED") {
      setPaymentFailureMessage({
        status: "failed",
        message: "Votre paiement a échoué. Please try again.",
      });
      setPaymentStatus("failed");
      return;
    }

    // Extract error details
    const errorMessage = error.message || "Unknown error occurred.";
    const errorCode = error.code || "UNKNOWN_ERROR";

    // Log the failure for tracking
    // logger.log(`Transaction ID: ${transactionId}`);
    // logger.log(`Error Code: ${errorCode}`);
    // logger.log(`Error Message: ${errorMessage}`);

    // Define failure handling strategy
    switch (errorCode) {
      case "INSUFFICIENT_FUNDS":
        setPaymentFailureMessage({
          status: "failed",
          message:
            "Payment failed due to insufficient funds. Please try another payment method.",
        });
        setPaymentStatus("failed");
        break;

      case "NETWORK_ERROR":
        setPaymentFailureMessage({
          status: "pending",
          message:
            "Network error occurred. Please check your connection and retry.",
        });
        setPaymentStatus("failed");
        break;

      case "INVALID_CARD":
        setPaymentFailureMessage({
          status: "failed",
          message:
            "Invalid card details. Please verify your payment information and try again.",
        });
        setPaymentStatus("failed");
        break;

      case "TIMEOUT":
        setPaymentFailureMessage({
          status: "pending",
          message:
            "Transaction timeout. Please check if your payment was deducted before retrying.",
        });
        setPaymentStatus("failed");
        break;

      case "TRANSACTION_DECLINED":
        setPaymentFailureMessage({
          status: "failed",
          message:
            "Your payment was declined by the bank. Contact your bank for more details.",
        });
        setPaymentStatus("failed");
        break;

      default:
        setPaymentFailureMessage({
          status: "failed",
          message:
            "An unexpected error occurred. Please contact support if the issue persists.",
        });
        setPaymentStatus("failed");
        break;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Buy Licenses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 font-bold mb-2">
              Basic Licenses:
            </label>
            <div className="relative">
              <input
                type="number"
                value={basicLicenses}
                onChange={handleBasicChange}
                min={0}
                className="border border-gray-400 p-2 rounded w-full pr-10" // Add pr-10 for space
              />
              {basicPricePerLicense !== null && (
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500">
                  {basicPricePerLicense} {currency} / license
                </span>
              )}
            </div>
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">
              Pro Licenses:
            </label>
            <div className="relative">
              <input
                type="number"
                value={proLicenses}
                onChange={handleProChange}
                min={0}
                className="border border-gray-400 p-2 rounded w-full pr-10" // Add pr-10 for space
              />
              {proPricePerLicense !== null && (
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500">
                  {proPricePerLicense} {currency} / license
                </span>
              )}
            </div>
          </div>
        </div>

        <h3 className="text-xl font-bold mt-6 flex items-center space-x-2">
          <span className="text-black-400 bg-yellow-100 px-2 py-1 rounded-lg line-through text-lg mr-2">
            {totalAmount}{" "}
            {company.country ? company.country.currency.toLowerCase() : "XOF"}
          </span>
          <span className="text-green-600 text-2xl font-extrabold">
            {discountedTotalAmount}{" "}
            {company.country ? company.country.currency.toLowerCase() : "XOF"}
          </span>
          {discount >= 1 && (
            <span className="text-white bg-red-500 px-2 py-1 rounded-md text-sm">
              -{discount}% Off
            </span>
          )}
        </h3>

        <div className="mt-6">
          <h4 className="text-lg font-bold mb-4">
            Choose a payment method: {error ? error : null}
          </h4>
          <div className="flex flex-wrap gap-4 mb-4">
            <button
              className={`${
                selectedPayment === "cinetpay"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              disabled={
                basicLicenses + proLicenses < 1 || loading ? true : false
              }
              onClick={() => setSelectedPayment("cinetpay")}
              title="Cinetpay permet les paiements mobiles dans plusieurs pays d'Afrique de l'Ouest et Centrale, notamment en Côte d'Ivoire, au Sénégal, au Togo, au Bénin, au Mali, au Burkina Faso, au Cameroun, en Guinée et en RDC (CDF et USD)."
            >
              Paiement mobile (Cinetpay)
            </button>
            <button
              className={`${
                selectedPayment === "card"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              disabled={
                basicLicenses + proLicenses < 1 || loading ? true : false
              }
              onClick={() => handlePaymentChange("card")}
              title="Stripe permet les paiements par carte bancaire dans plusieurs pays à travers le monde."
            >
              Carte Banquaire (Stripe)
            </button>
            {/* <button
              className={`${
                selectedPayment === "paypal"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              disabled={
                basicLicenses + proLicenses < 1 || loading ? true : false
              }
              onClick={() => handlePaymentChange("paypal")}
              title="PayPal permet les paiements en ligne de manière sécurisée dans plusieurs pays à travers le monde."
            >
              PayPal
            </button> */}
          </div>
          {selectedPayment === "card" && (
            <EmbeddedCheckoutProvider
              options={stripeSessionOptions.options}
              stripe={stripePromise}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}

          {selectedPayment === "cinetpay" && (
            <CinetPayButton
              amount={discountedTotalAmount}
              currency="XOF"
              description="License purchase"
              onPaymentSuccess={(response, transactionId) =>
                handleCinetpaySuccess(response, transactionId)
              }
              onPaymentFailure={(r, t) => handleCinetpayFailure(r, t)}
            />
          )}
          {selectedPayment === "paypal" && (
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AfCRB2-04sY9JsO2WP3tDIx-XDDOiZ6l-FIaVoAOa25uaX3NbmVEmp3ysCGTpuVlWAAwrQBeDPPFroTj",
                components: "buttons",
                currency: "USD",
              }}
            >
              <PayPalButtons
                createOrder={async (data, actions) => {
                  try {
                    // console.log(data);
                    // Calculate the total amount in XOF first
                    const totalXOF = discountedTotalAmount;

                    // Convert the amount to USD, ensuring we wait for the result
                    const convertedAmountBasic = parseFloat(
                      (
                        await convertCurrencyWithFee(
                          await calculateDiscountedTotal(1, 0, discount),
                          company.country.currency,
                          "USD"
                        )
                      ).toFixed(2),
                      2
                    );
                    const convertedAmountPro = parseFloat(
                      (
                        await convertCurrencyWithFee(
                          await calculateDiscountedTotal(0, 1, discount),
                          company.country.currency,
                          "USD"
                        )
                      ).toFixed(2),
                      2
                    );
                    const convertedAmount =
                      convertedAmountBasic * basicLicenses +
                      convertedAmountPro * proLicenses;

                    // console.log(
                    //   `totalXOF:${totalXOF} convertedAmount:${convertedAmount} convertedAmountBasic:${convertedAmountBasic} convertedAmountPro:${convertedAmountPro}`
                    // );

                    const timestamp = Date.now().toString(36); // Convert timestamp to base36 for compactness
                    const randomString = Math.random()
                      .toString(36)
                      .substring(2, 10)
                      .toUpperCase(); // Random 8-character alphanumeric
                    const invoiceID = `PRLPP-${timestamp}-${randomString}`;
                    // console.log(`invoiceID: ${invoiceID}`);
                    // console.log(
                    //   `basicLicenses: ${basicLicenses}, proLicenses: ${proLicenses}`
                    // );
                    var items = [];

                    if (basicLicenses > 0) {
                      items.push({
                        name: "ProRecruit Basic License",
                        description: "ProRecruit Basic License",
                        unit_amount: {
                          currency_code: "USD",
                          value: `${convertedAmountBasic}`,
                        },
                        quantity: `${basicLicenses}`,
                        category: "DIGITAL_GOODS",
                        sku: "sku01",
                      });
                    }
                    if (proLicenses > 0) {
                      items.push({
                        name: "ProRecruit Pro License",
                        description: "ProRecruit Pro License",
                        unit_amount: {
                          currency_code: "USD",
                          value: `${convertedAmountPro}`,
                        },
                        quantity: `${proLicenses}`,
                        category: "DIGITAL_GOODS",
                        sku: "sku02",
                      });
                    }

                    return actions.order.create({
                      purchase_units: [
                        {
                          invoice_id: invoiceID,
                          amount: {
                            currency_code: "USD",
                            value: `${convertedAmount}`, // Ensuring it's in proper format
                            breakdown: {
                              item_total: {
                                currency_code: "USD",
                                value: `${convertedAmount}`,
                              },
                            },
                          },
                          items: items,
                        },
                      ],
                    });
                  } catch (error) {
                    console.error("Error creating order:");
                    console.log(error);
                    setMessage("Failed to create PayPal order");
                    if (process.env.REACT_APP_ENVIRONMENT === "prod") {
                      Sentry.captureException(error);
                    }
                    // Log error to Firestore
                    try {
                      await addDoc(collection(db, "prorecruit_error"), {
                        page: "LicenseBuyingPage",
                        data: {
                          companyName: companyData[0].name,
                          tenantId: companyData[0].tenantId,
                          country: companyData[0].country.name,
                          basicLicenses: basicLicenses,
                          proLicenses: proLicenses,
                        },
                        message: error.message || "Unknown error",
                        stack: error.stack || "No stack trace",
                        name: error.name || "Error",
                        timestamp: Timestamp.now(),
                      });
                    } catch (firestoreError) {
                      console.error(
                        "Error saving error log to Firestore:",
                        firestoreError
                      );
                      if (process.env.REACT_APP_ENVIRONMENT === "prod") {
                        Sentry.captureException(firestoreError); // Log Firestore errors to Sentry too
                      }
                    }
                  }
                }}
                onApprove={(data, actions) => {
                  console.log(data);
                  return actions.order.capture().then((details) => {
                    fetch(`${API_ENDPOINT}api/v1/invoice`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        details,
                      }),
                      credentials: "include",
                    });
                    setMessage(
                      "Transaction completed by " +
                        details.payer.name.given_name
                    );
                    handlePaymentSuccess(details.id, "Paypal");
                  });
                }}
                onError={(err) => {
                  console.error("PayPal Checkout onError");
                  console.log(err);
                  setPaymentFailureMessage({
                    status: "failed",
                    message: "An error occurred during the transaction",
                  });
                  setMessage("An error occurred during the transaction");
                  setPaymentStatus("failed");
                }}
              />
            </PayPalScriptProvider>
          )}
        </div>
        {message && <p className="mt-4 text-lg">{message}</p>}
        <TawkToChat />
      </div>
    </>
  );
};

export default LicenseBuyingPage;
