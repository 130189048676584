// /utils/redux/jobPostingsSlice.js
import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase-config";

const JOBS_PER_PAGE = 20;

export const fetchJobPostings = createAsyncThunk(
  "jobPostings/fetchJobPostings",
  async ({ tenantId }, { getState }) => {
    try {
      console.log(tenantId);
      const state = getState();
      const lastVisible = null; //state.jobPostings.lastVisible;
      let q = query(
        collection(db, `job-descriptions/${tenantId}/active`),
        where("Statut", "!=", "Annuler"),
        orderBy("createdOn", "desc")
      );
      console.log(`lastVisible: ${JSON.stringify(lastVisible)}`); // Log as string

      if (lastVisible) {
        // Reconstruct the startAfter clause using the stored values
        q = query(q, startAfter(lastVisible.createdOn));
      }

      q = query(q, limit(JOBS_PER_PAGE));

      const querySnapshot = await getDocs(q);

      const jobPostingsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();

        // Helper function to convert Timestamp to number (milliseconds)
        const convertTimestamp = (field) => {
          if (data[field] && data[field] instanceof Timestamp) {
            return data[field].toMillis(); // Store as milliseconds
          }
          return data[field]; // Return original if not a timestamp
        };

        // Process each field that might be a Timestamp
        const processedData = {
          uuid: doc.id,
          ...data,
          PublicationDate: convertTimestamp("PublicationDate"),
          DateLimiteDeCandidature: convertTimestamp("DateLimiteDeCandidature"),
          createdOn: convertTimestamp("createdOn"), // Convert createdOn
          modifiedOn: convertTimestamp("modifiedOn"), // and modifiedOn timestamps
          // lastVisible: convertTimestamp("lastVisible"), // No longer needed here
        };

        // Handle array of objects with Timestamp (using toMillis() here too)
        if (Array.isArray(processedData.experiences)) {
            processedData.experiences = processedData.experiences.map(experience => ({
                ...experience,
                startDate: convertTimestamp(experience.startDate),
                endDate: convertTimestamp(experience.endDate),
            }));
        }
        if (Array.isArray(processedData.education)) {
            processedData.education = processedData.education.map(education => ({
                ...education,
                startDate: convertTimestamp(education.startDate),
                endDate: convertTimestamp(education.endDate),
                graduationDate: convertTimestamp(education.graduationDate),
            }));
        }
        if (Array.isArray(processedData.certifications)) {
            processedData.certifications = processedData.certifications.map(certification => ({
                ...certification,
                dateObtained: convertTimestamp(certification.dateObtained),
                expiryDate: convertTimestamp(certification.expiryDate),

            }));
        }
        if (Array.isArray(processedData.assessmentNames)) {
            processedData.assessmentNames = processedData.assessmentNames.map(assessment => ({
                ...assessment,
                date: convertTimestamp(assessment.date),
            }));
          }

        return processedData;
      });

      // Get the last document *before* processing, to preserve the Timestamp object.
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        jobPostings: jobPostingsData,
        // Store only the necessary data for pagination
        lastVisible: lastDoc ? {
           createdOn: lastDoc.data().createdOn.toMillis(), // Use toMillis()
           docId: lastDoc.id
        } : null,
      };
    } catch (error) {
      console.log("Error fetching job postings:", error);
      throw error;
    }
  }
);

export const loadMoreJobPostings = createAsyncThunk(
    "jobPostings/loadMoreJobPostings",
    async ({ tenantId }, { getState }) => {
      try {
        const state = getState();
        const lastVisible = state.jobPostings.lastVisible;
        if (!lastVisible) return { jobPostings: [], lastVisible: null }; // Important: handle no more data

        let q = query(
          collection(db, `job-descriptions/${tenantId}/active`),
          where("Statut", "!=", "Annuler"),
          orderBy("createdOn", "desc"),
          startAfter(lastVisible.createdOn, lastVisible.docId), // Use stored values
          limit(JOBS_PER_PAGE)
        );

        const querySnapshot = await getDocs(q);

        const jobPostingsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();

          // Helper function to convert Timestamp to number (milliseconds)
          const convertTimestamp = (field) => {
            if (data[field] && data[field] instanceof Timestamp) {
               return data[field].toMillis(); // milliseconds
            }
            return data[field]; // Return original if not a timestamp
          };

          // Process each field that might be a Timestamp
          const processedData = {
            uuid: doc.id,
            ...data,
            PublicationDate: convertTimestamp("PublicationDate"),
            DateLimiteDeCandidature: convertTimestamp("DateLimiteDeCandidature"),
            createdOn: convertTimestamp("createdOn"), // Convert createdOn
            modifiedOn: convertTimestamp("modifiedOn"), // and modifiedOn timestamps
          };


        // Handle array of objects with Timestamp (using toMillis() here too)
        if (Array.isArray(processedData.experiences)) {
            processedData.experiences = processedData.experiences.map(experience => ({
                ...experience,
                startDate: convertTimestamp(experience.startDate),
                endDate: convertTimestamp(experience.endDate),
            }));
        }
        if (Array.isArray(processedData.education)) {
            processedData.education = processedData.education.map(education => ({
                ...education,
                startDate: convertTimestamp(education.startDate),
                endDate: convertTimestamp(education.endDate),
                graduationDate: convertTimestamp(education.graduationDate),
            }));
        }
        if (Array.isArray(processedData.certifications)) {
            processedData.certifications = processedData.certifications.map(certification => ({
                ...certification,
                dateObtained: convertTimestamp(certification.dateObtained),
                expiryDate: convertTimestamp(certification.expiryDate),

            }));
        }
        if (Array.isArray(processedData.assessmentNames)) {
            processedData.assessmentNames = processedData.assessmentNames.map(assessment => ({
                ...assessment,
                date: convertTimestamp(assessment.date),
            }));
          }

          return processedData;
        });

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        return {
          jobPostings: jobPostingsData,
            lastVisible: lastDoc ? {
              createdOn: lastDoc.data().createdOn.toMillis(), // milliseconds
              docId: lastDoc.id,
            } : null,
        };
      } catch (error) {
        console.error("Error loading more job postings:", error);
        throw error;
      }
    }
  );

const jobPostingsAdapter = createEntityAdapter({
  selectId: (jobPosting) => jobPosting.uuid,
  sortComparer: (a, b) => b.createdOn - a.createdOn, // Sort by number now
});

const initialState = jobPostingsAdapter.getInitialState({
  loading: false,
  error: null,
  lastVisible: null,
  hasMore: true,
});

const jobPostingsSlice = createSlice({
  name: "jobPostings",
  initialState,
  reducers: {
    // ... other reducers (if any) ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobPostings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobPostings.fulfilled, (state, action) => {
        state.loading = false;
        jobPostingsAdapter.setAll(state, action.payload.jobPostings);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.jobPostings.length === JOBS_PER_PAGE;
      })
      .addCase(fetchJobPostings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadMoreJobPostings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadMoreJobPostings.fulfilled, (state, action) => {
        state.loading = false;
        jobPostingsAdapter.addMany(state, action.payload.jobPostings);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.jobPostings.length === JOBS_PER_PAGE;
      })
      .addCase(loadMoreJobPostings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectJobPostings,
  selectById: selectJobPostingById,
  selectIds: selectJobPostingIds,
} = jobPostingsAdapter.getSelectors((state) => state.jobPostings);

export const selectJobPostingsLoading = (state) => state.jobPostings.loading;
export const selectJobPostingsError = (state) => state.jobPostings.error;

export default jobPostingsSlice.reducer;