import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { useTranslation } from 'react-i18next'; // Import useTranslation
import JobPostingDescription from './JobPostingDescription';

// Define the shape of a single score category item for validation clarity
// eslint-disable-next-line no-unused-vars
const scoreItemShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    explication_score: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    // Add other potential properties if they exist and need validation
});

const CandidateScoreReason = ({ description }) => {
  // --- Hooks ---
  const { t } = useTranslation("CandidateScoreReason"); // Initialize translation hook
  const [expandedKey, setExpandedKey] = useState(null);

  // --- Handlers ---
  const toggleExpand = (key /*: string */) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  // --- Logic ---
  // 1. Handle missing or invalid description prop type
  //    (PropTypes will warn in development, but this adds runtime safety)
  if (!description || typeof description !== 'object') {
    // Use t() for the fallback message
    return (
      <div className="border-b border-gray-300 pb-4">
        <p className="mt-1 text-sm text-gray-600">
          {t('proLicenseFallback')}
        </p>
      </div>
    );
  }

  // 2. Filter out metadata and get only the actual score category entries
  const scoreCategories = Object.entries(description)
    // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) =>
      typeof value === 'object' && value !== null &&
      Object.prototype.hasOwnProperty.call(value, 'name') &&
      Object.prototype.hasOwnProperty.call(value, 'score') &&
      Object.prototype.hasOwnProperty.call(value, 'explication_score')
    );
     // Optional: Sort categories if needed
     // .sort(([, a], [, b]) => a.name.localeCompare(b.name));

  // 3. Handle case where no valid score categories are found
  if (scoreCategories.length === 0) {
     return (
       <div className="border-b border-gray-300 pb-4">
         <p className="mt-1 text-sm text-gray-600">
            {/* Use t() for the no details message */}
            {t('noDetails')}
         </p>
       </div>
     );
   }

  // --- Rendering ---
  return (
    <div className="space-y-4">
      {scoreCategories.map(([key, item]) => {
        const isExpanded = expandedKey === key;

        return (
          <div key={key} className="border-b border-gray-300 pb-4 last:border-b-0">
            {/* Header Row */}
            <div
              className="flex justify-between items-center cursor-pointer py-2"
              onClick={() => toggleExpand(key)}
              role="button"
              aria-expanded={isExpanded}
              aria-controls={`score-details-${key}`}
            >
              <div className="font-medium text-gray-700">
                {/* Use t() for "points" */}
                {item.name}: {item.score} {t('points')}
              </div>
              <button
                type="button"
                className="ml-4 flex-shrink-0 bg-transparent text-blue-600 hover:text-blue-800 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded"
                // Use t() for aria-label with interpolation
                aria-label={isExpanded
                    ? t('ariaCollapse', { name: item.name })
                    : t('ariaExpand', { name: item.name })
                }
              >
                {/* Use t() for button text */}
                {isExpanded ? t('seeLess') : t('seeMore')}
              </button>
            </div>

            {/* Expandable Content */}
            {isExpanded && (
              <div
                id={`score-details-${key}`}
                className="mt-2 text-gray-600 space-y-3"
              >
                {Array.isArray(item.explication_score) ? (
                  item.explication_score.map((explication, idx) => (
                    <JobPostingDescription key={idx} description={explication} />
                  ))
                ) : (
                  item.explication_score && <JobPostingDescription description={item.explication_score} />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

// --- Prop Validation ---
CandidateScoreReason.propTypes = {
  /**
   * The main object containing score breakdown.
   * Can include metadata keys (like ScoreDate, total_score) alongside
   * score category objects (like experience, education).
   * The component filters to find valid score categories.
   */
  description: PropTypes.object,
  /*
   * More specific validation (if needed, but makes assumptions):
   * description: PropTypes.objectOf(
   * PropTypes.oneOfType([
   * scoreItemShape, // Valid score category
   * PropTypes.string, // For things like ScoreDate
   * PropTypes.number, // For things like total_score
   * PropTypes.array,  // For other potential data structures
   * PropTypes.bool,
   * ])
   * ),
   */
};

// Optional: Default props if description might be undefined
// CandidateScoreReason.defaultProps = {
//  description: null,
// };


export default CandidateScoreReason;
